import { FunctionComponent, ReactNode } from 'react';
import { useCanAccess } from 'components/Authorization/utils';

interface WithPermissionsI {
  children: ReactNode;
  lnsResource: string;
  lnsAction: string;
  lnsOwners?: string[];
  disable?: boolean;
}

export const WithPermissions: FunctionComponent<WithPermissionsI> = ({
  children,
  lnsResource,
  lnsAction,
  lnsOwners,
  disable = false
}: WithPermissionsI) => {
  const canAccess = useCanAccess(lnsResource, lnsAction, lnsOwners);

  if (!canAccess) {
    if (disable) {
      return (
        <div style={{ pointerEvents: 'none', opacity: '0.6' }}>{children}</div>
      );
    }
    return <></>;
  }
  if (disable) {
    return (
      <div style={{ pointerEvents: 'none', opacity: '0.4' }}>{children}</div>
    );
  }
  return <>{children}</>;
};

WithPermissions.defaultProps = {
  disable: false,
  lnsOwners: []
};
