import {
  useEffect,
  useReducer,
  useState,
  FunctionComponent,
  ChangeEvent,
  useCallback,
  useRef
} from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  GridSize,
  Paper
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { isFormValid } from 'utils/form';
import {
  fetchStory,
  createStoryAsset,
  updateStory
} from 'pages/stories/actions';
import { StoryFormData } from 'pages/stories/interface';
import { FormData, defaultState } from 'pages/assignments/formData';
import validateForm, { getInitialState, reducer } from 'pages/stories/utils';
import {
  isSelfChild,
  buildChilds,
  calculateRelativeTime,
  convertAssetToArray,
  convertArrayToAsset,
  setParentRelativeTime,
  setChildRelativeTime
} from 'pages/stories/utils/asset';
import {
  fetchRundown,
  fetchRundownStories,
  updateRundown
} from 'pages/rundowns/actions';
import { createAssignment, fetchStoryStates } from 'pages/assignments/actions';
import { buildRundownStoryObject } from 'pages/rundowns/utils';
import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';
import { ImageAsset } from 'pages/stories/interfaces/imageAsset';
import { StoryState } from 'pages/stories/enums';
import { StarOutline, Lock } from '@material-ui/icons';
import DialogForm from 'pages/assignments/components/DialogForm';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import BtnWrapper from 'components/BtnWrapper';
import toggleNotification from 'actions/notifications';
import StoryFormTabs from 'pages/stories/components/StoryFormTabs';
import StoryBrowseTabs from 'pages/stories/components/StoryBrowseTabs';
import ConditionalWrapper from 'components/ConditionalWrapper';
import {
  useHasFullResourceAccess,
  useCanAccess
} from 'components/Authorization/utils';
import i18n from 'i18n';
import { Resource, Action } from 'components/Authorization';

const Edit: FunctionComponent = () => {
  const { guid, rundownGuid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [storyLock, setStoryLock] = useState(false);
  const [storyApproved, setStoryApproved] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [isUserStoryOwner, setIsUserStoryOwner] = useState(false);
  const [calculatedDuration, setCalculatedDuration] = useState<number>(1);
  const [storyInitState, setStoryInitState] = useState<$Lns.StoryState>();
  const [browseTabsWindow, toggleBrowseTabsWindow] = useState(true);
  const [fullScreen, toggleFullScreen] = useState(false);
  const [addMediaToScript, toggleAddMedia] = useState(false);
  const [editAssignment, setEditAssignment] = useState<FormData>();
  const isUserAdmin = useHasFullResourceAccess('STORY');
  const canApprove = useCanAccess(Resource.STORY, Action.APPROVE);

  const [gridSizes, setGridSize] = useState<{ [key: string]: GridSize }>({
    form: 6 as GridSize,
    browse: 6 as GridSize
  });

  const { storyStates, currentLnsUser, taskStates, currentSsoUser } =
    useSelector((globalState: $Lns.DefaultState) => globalState.default);

  const [state, dispatchReducer] = useReducer(reducer, {
    story: undefined,
    formData: getInitialState(),
    rundown: undefined
  });

  const formDataRef = useRef(state.formData);
  const [dynamicAsset, setDynamicAsset] = useState<AssetDynamic[]>([]);

  useEffect(() => {
    formDataRef.current = state.formData;
  }, [state.formData]);

  useEffect(() => {
    const assetsNew = [...dynamicAsset];
    let reletiveTime = 0;
    assetsNew.forEach((asset, key) => {
      const itSelfChild = isSelfChild(asset, assetsNew, key);

      if (itSelfChild === undefined) {
        const childObjects = buildChilds(asset, assetsNew, key);
        reletiveTime += calculateRelativeTime(asset, childObjects);
      }
    });

    setCalculatedDuration(reletiveTime);
  }, [dynamicAsset]);

  const handleStoryUpdate = (
    data: StoryFormData,
    hitUpdateRundown: boolean
  ) => {
    updateStory(data)
      .then(({ data: jsonData, code }) => {
        if (code === 201) {
          dispatchReducer({ type: 'setStory', payload: jsonData });
          dispatch(
            toggleNotification(
              i18n.t('notifications.stories.updateSuccess'),
              'success'
            )
          );
          if (!hitUpdateRundown) return;
          handleRundownStoriesUpdate(jsonData);
        }
      })
      .catch(() =>
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'))
      );
  };

  const updateStoryState = (data: $Lns.Story, states?: $Lns.StoryState[]) => {
    if (storyStates.length > 0 || states !== undefined) {
      const storyStatesArray = states || storyStates;

      const stateNow = storyStatesArray.find(
        pin => pin.guid === data.storyState
      );

      setStoryInitState(stateNow);
      const stateLock = storyStatesArray.find(
        pin => pin.name === StoryState.locked
      );

      if (
        stateNow &&
        stateLock &&
        stateNow.name !== StoryState.locked &&
        stateNow.name !== StoryState.approved
      ) {
        const formDataUpdateState = {
          ...getInitialState(data),
          storyState: stateLock.guid
        };
        handleStoryUpdate(formDataUpdateState, false);
      } else {
        setStoryLock(true);

        if (stateNow && stateNow.name === StoryState.approved) {
          setStoryApproved(true);
        }

        dispatch(
          toggleNotification(
            i18n.t('pages.stories.helperText.storyLockMsg'),
            'warning'
          )
        );
      }
    } else if (storyStates.length === 0) {
      fetchStoryStates().then(resp => {
        updateStoryState(data, resp.data);
      });
    }
  };

  const checkStoryLock = (storyData: StoryFormData) => {
    const formData = { ...storyData };
    fetchStory(guid).then(({ data }) => {
      const stateLock = storyStates.find(pin => pin.name === StoryState.locked);
      if (stateLock && data.storyState === stateLock.guid) {
        updateStoryState(data);
      } else {
        if (storyInitState) formData.storyState = storyInitState.guid;
        handleStoryUpdate(formData, true);
      }
    });
  };

  const onUpdateDynamicAssets = (assetsNew: AssetDynamic[]) => {
    const sortedAssets = sortAssetsBeforeSet(assetsNew);
    const assetsTemp = setParentRelativeTime(sortedAssets);
    const assetsWithRelativeTime = setChildRelativeTime(assetsTemp);

    setDynamicAsset([...assetsWithRelativeTime]);
  };

  const sortAssetsBeforeSet = (assetsNew: AssetDynamic[]) => {
    const arryFieldsAll = convertAssetToArray(assetsNew);
    arryFieldsAll.sort((a, b) => {
      return a.positionInScript - b.positionInScript;
    });
    const sortedAssets = convertArrayToAsset(arryFieldsAll);
    return sortedAssets;
  };

  useEffect(() => {
    fetchStory(guid).then(({ data }) => {
      dispatchReducer({ type: 'setStory', payload: data });
      dispatchReducer({
        type: 'setFormData',
        payload: getInitialState(data)
      });
      updateStoryState(data);
      if (data.createdBy === currentSsoUser.guid) setIsUserStoryOwner(true);
      if (data.script !== '') {
        const scriptItems = JSON.parse(data.script);
        const newDynamicAssets = convertArrayToAsset(scriptItems);
        onUpdateDynamicAssets([...newDynamicAssets]);
      }
      if (rundownGuid) {
        fetchRundown(rundownGuid).then(({ data: [newRundown] }) => {
          fetchRundownStories(newRundown.guid).then(({ data: storyData }) => {
            const stories = storyData;

            const updatedRundown = {
              ...newRundown,
              stories: stories.map((currentStory, index) => {
                return buildRundownStoryObject(
                  currentStory.story,
                  index,
                  currentStory.isSpare
                );
              })
            };

            const updatedFormData = {
              ...getInitialState(data),
              errors: {
                ...state.formData.errors,
                plannedDuration: i18n.t('helperText.required')
              }
            };
            dispatchReducer({ type: 'setFormData', payload: updatedFormData });
            dispatchReducer({ type: 'setRundown', payload: updatedRundown });
          });
        });
      }
    });
    // eslint-disable-next-line
  }, [dispatchReducer, guid, rundownGuid]);

  const onEditorChange = useCallback(
    (text: string, field: string) => {
      const updatedFormData = {
        ...formDataRef.current,
        errors: {
          [field]: validateForm(field, text)
        },
        [field]: text
      };

      return dispatchReducer({ type: 'setFormData', payload: updatedFormData });
    },
    [formDataRef]
  );

  if (!state.story) return <></>;

  const getFieldValue = (name: string, value: string) => {
    if (name === 'plannedDuration' || name === 'duration') {
      return parseInt(value, 10);
    }
    return value;
  };

  const handleRundownStoriesUpdate = (updatedStory: $Lns.Story) => {
    if (!state.rundown || !state.formData.guid || !state.rundown.stories)
      return;
    if (
      !state.rundown.stories
        ?.map(currentStory => currentStory.guid)
        .includes(state.formData.guid)
    ) {
      // update rundown and add story
      const updatedRundown = {
        ...state.rundown,
        stories: [
          ...state.rundown.stories,
          buildRundownStoryObject(
            updatedStory,
            state.rundown.stories.length,
            true
          )
        ]
      };

      updateRundown(updatedRundown).then(({ code }) => {
        if (code === 201) {
          dispatch(
            toggleNotification(
              i18n.t('notifications.rundowns.updateSuccess'),
              'success'
            )
          );
        }
        navigate(`/rundowns/${updatedRundown.guid}`);
      });
    }
  };

  const onChangeType = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value, name } = e.target;

    if (name === 'pool') {
      return onUpdatePool(value as string[]);
    }

    const updatedFormData = {
      ...state.formData,
      [name || '']: value
    };

    return dispatchReducer({ type: 'setFormData', payload: updatedFormData });
  };

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedFormData = {
      ...state.formData,
      errors: {
        [name]: validateForm(name, value)
      },
      [name]: getFieldValue(name, value)
    };

    return dispatchReducer({ type: 'setFormData', payload: updatedFormData });
  };

  const onUpdatePool = (poolArray: string[]) => {
    if (!state.formData.pools) return;

    const poolGuid = poolArray[poolArray.length - 1];

    const existing = state.formData.pools?.find(p => p.guid === poolGuid);
    let pools: { guid: string }[] = [];

    if (existing) {
      pools = state.formData.pools.filter(p => p.guid !== poolGuid);
    } else {
      pools = [...state.formData.pools, { guid: poolGuid }];
    }

    const updatedFormData = {
      ...state.formData,
      pools
    };

    dispatchReducer({ type: 'setFormData', payload: updatedFormData });
  };

  const onSave = async () => {
    const output = await createAssets();

    const inputAssets = dynamicAsset.map(itemAsset => {
      const returnItem = { ...itemAsset } as AssetDynamic;
      if (returnItem.assetI) {
        const urlObj = new URL(returnItem.assetI.proxyUrl);

        const fileName = urlObj.pathname.substring(
          urlObj.pathname.lastIndexOf('/') + 1
        );

        if (output && output.length > 0) {
          const exist = output.find(item => {
            return item.sourceUrn === fileName;
          });

          if (exist && exist.guid) {
            returnItem.assetI.guid = exist.guid;
          }
        }
      }
      return returnItem;
    });

    const arryFieldSAll = convertAssetToArray(inputAssets);

    const formDataScripted = {
      ...state.formData,
      calculatedDuration: Math.round(calculatedDuration),
      script: JSON.stringify([...arryFieldSAll])
    };

    if (storyLock && storyInitState) {
      formDataScripted.storyState = storyInitState.guid;
    }

    if (
      !storyLock &&
      state.story &&
      storyInitState &&
      state.story.storyState === storyInitState.guid
    ) {
      checkStoryLock(formDataScripted);
    } else {
      handleStoryUpdate(formDataScripted, true);
    }
  };

  const createAssets = async () => {
    const inputAssetListList = [] as $Lns.CreateAsset[];
    dynamicAsset.forEach(itemAsset => {
      if (itemAsset.assetI) {
        const urlObj = new URL(itemAsset.assetI.proxyUrl);

        const fileName = urlObj.pathname.substring(
          urlObj.pathname.lastIndexOf('/') + 1
        );

        const objItem = {
          name: itemAsset.assetI.title,
          description: itemAsset.assetI?.description || 'Description',
          sourceUrn: fileName,
          assetType: itemAsset.assetI.type,
          renderUrl: itemAsset.assetI.proxyUrl,
          playoutServerUrl: itemAsset.assetI.playoutServerUrl,
          durationSec: +itemAsset.assetI.durationInSeconds || 100,
          clipStartSec: itemAsset.assetI?.clipStartSec || 100,
          clipStopSec: itemAsset.assetI?.clipStopSec || 100,
          playoutTimepointSec: itemAsset?.assetI.playoutTimepointSec || 100,
          totalDurationSec: itemAsset.assetI?.totalDurationSec || 100
        };

        inputAssetListList.push(objItem);
      }
    });

    const input = {
      story: guid,
      assets: [...inputAssetListList]
    };

    return await createStoryAsset(input)
      .then(res => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.stories.attachSuccess'),
            'success'
          )
        );
        return res.data;
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.stories.attachError'),
            'error'
          )
        );
      });
  };

  const onCancel = () => {
    navigate('/my-lns');
  };

  const onToggleBrowseTabWindow = (closed: boolean) => {
    toggleBrowseTabsWindow(closed);
  };

  const onToggle12Grid = (window: string) => {
    const currentSize = gridSizes[window];
    const newSize = currentSize === 6 ? 12 : (6 as GridSize);

    const updatedGridSizes = {
      ...gridSizes,
      form: newSize === 12 ? 12 : (6 as GridSize),
      browse: newSize
    };

    setGridSize(updatedGridSizes);
  };

  const onInsertStoryAsset = (asset: AssetDynamic) => {
    const imageAssetObj = { ...asset.assetI } as ImageAsset;
    imageAssetObj.positionInScript = dynamicAsset.length;
    const assetInput = [...dynamicAsset, { assetI: { ...imageAssetObj } }];
    onUpdateDynamicAssets(assetInput);
  };

  const saveBtnDisabled = !isFormValid(
    ['title', 'slug', 'genre', 'location'],
    state.formData
  );

  const onAddDialogue = (dialogueObj: AssetDynamic) => {
    onUpdateDynamicAssets([...dynamicAsset, dialogueObj]);
  };

  const onUnlockStory = () => {
    const formDataScripted = {
      ...state.formData
    };

    if (storyLock && storyStates.length > 0) {
      formDataScripted.storyState = storyStates[0].guid;
      setStoryInitState(storyStates[0]);
      setStoryLock(false);
      handleStoryUpdate(formDataScripted, true);
    }

    if (storyApproved) {
      setStoryApproved(false);
    }
  };

  const addAssignment = () => {
    const input = { ...defaultState, story: guid, storyObj: state.story };
    setEditAssignment(input);
  };

  const onSaveAssignmentForm = (formData: FormData) => {
    if (formData) {
      createAssignment(formData).then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.assignments.createSuccess'),
            'success'
          )
        );
      });
    }
    setEditAssignment(undefined);
  };

  const onCancelForm = () => {
    setEditAssignment(undefined);
  };

  const onChangeTabValue = (val: number) => {
    setTabValue(val);
  };

  const onToggleAddMedia = (val: boolean) => {
    if (fullScreen) toggleAddMedia(val);
  };

  const onApproveStory = () => {
    const approveState = storyStates.find(stateItem => {
      return stateItem.name === StoryState.approved;
    });

    if (approveState) {
      const updatedFormData = {
        ...state.formData,
        storyState: approveState.guid
      };
      setStoryApproved(true);
      handleStoryUpdate(updatedFormData, false);
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <TitleBarWraper>
        <Grid item xs={6}>
          <TitleWaper
            title={state.formData?.title.toUpperCase()}
            subText={i18n.t('pages.stories.formLabel.title')}
            icon={<StarOutline color="primary" />}
            textIcon={storyLock ? <Lock color="primary" /> : <></>}
          />
        </Grid>
        <Grid item xs={6}>
          <BtnWrapper>
            {(storyLock || storyApproved) && (isUserAdmin || isUserStoryOwner) && (
              <Button
                color="primary"
                variant="contained"
                onClick={onUnlockStory}
              >
                {i18n.t('pages.stories.helperText.unlockStory')}
              </Button>
            )}
            {!storyLock && !storyApproved && (
              <Button
                disabled={storyLock || storyApproved}
                color="primary"
                variant="contained"
                onClick={addAssignment}
              >
                {i18n.t('pages.assignments.assignments')}
              </Button>
            )}
            {canApprove && !storyApproved && !storyLock && (
              <Button
                disabled={storyLock}
                color="primary"
                variant="contained"
                onClick={onApproveStory}
              >
                {i18n.t('button.approve')}
              </Button>
            )}
            <Button color="default" variant="contained" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
            <Button
              disabled={saveBtnDisabled || storyLock || storyApproved}
              color="secondary"
              name="save"
              variant="contained"
              onClick={onSave}
            >
              {i18n.t('button.save')}
            </Button>
          </BtnWrapper>
        </Grid>
      </TitleBarWraper>
      <Grid item>
        <Paper>
          <Box padding={2}>
            <Grid container spacing={2}>
              <ConditionalWrapper
                condition={fullScreen}
                wrapper={children => (
                  <Dialog open fullScreen>
                    <Container maxWidth="xl">
                      <Grid container spacing={2}>
                        {children}
                      </Grid>
                    </Container>
                  </Dialog>
                )}
              >
                <>
                  <Grid
                    item
                    xs={fullScreen || !browseTabsWindow ? 12 : gridSizes.form}
                  >
                    <StoryFormTabs
                      tabValue={tabValue}
                      storyLock={storyLock || storyApproved}
                      calculatedDuration={calculatedDuration}
                      dynamicAssets={dynamicAsset}
                      formData={state.formData}
                      fullScreen={fullScreen}
                      browseTabsWindow={browseTabsWindow}
                      onChangeTabValue={onChangeTabValue}
                      onAddDialogueAsset={onAddDialogue}
                      onChangeField={onChangeField}
                      onChangeType={onChangeType}
                      onEditorChange={onEditorChange}
                      onUpdateDynamicAssets={onUpdateDynamicAssets}
                      onToggleAddMedia={onToggleAddMedia}
                      onToggleBrowseTabsWindow={() =>
                        onToggleBrowseTabWindow(!browseTabsWindow)
                      }
                      onToggleFullscreenAttachmentsTabWindow={() =>
                        toggleFullScreen(!fullScreen)
                      }
                      story={guid}
                    />
                  </Grid>
                </>
              </ConditionalWrapper>
              {browseTabsWindow && (
                <Grid item xs={gridSizes.browse}>
                  <StoryBrowseTabs
                    storyLock={storyLock || storyApproved}
                    fullScreen={fullScreen}
                    gridSizes={gridSizes}
                    onUpdateAssets={onInsertStoryAsset}
                    onClose={() => onToggleBrowseTabWindow(!browseTabsWindow)}
                    onToggle12Grid={() => onToggle12Grid('browse')}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Grid>
      {editAssignment && (
        <DialogForm
          isAdmin={isUserAdmin}
          currentLnsUser={currentLnsUser}
          assignment={editAssignment}
          onSave={onSaveAssignmentForm}
          onCancel={onCancelForm}
          taskStates={taskStates}
        />
      )}
      {addMediaToScript && fullScreen && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={() => onToggleAddMedia(false)}
          aria-labelledby="simple-dialog-title"
          open
        >
          {browseTabsWindow && (
            <Grid item xs={12}>
              <StoryBrowseTabs
                storyLock={storyLock || storyApproved}
                fullScreen={fullScreen}
                gridSizes={{ ...gridSizes, browse: 12 }}
                onUpdateAssets={onInsertStoryAsset}
                onClose={() => onToggleBrowseTabWindow(!browseTabsWindow)}
                onToggle12Grid={() => onToggle12Grid('browse')}
              />
            </Grid>
          )}
        </Dialog>
      )}
    </Grid>
  );
};

export default Edit;
