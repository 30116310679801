import { FunctionComponent, useState, useEffect } from 'react';
import { TextareaAutosize } from '@material-ui/core';
import useTimeLineStyles from 'styles/timeLine';

interface TextAreaI {
  value: string;
  storyLock: boolean;
  handleUpdate: (value: string) => void;
}

const CustomTextArea: FunctionComponent<TextAreaI> = ({
  value,
  storyLock,
  handleUpdate
}: TextAreaI) => {
  const classesComp = useTimeLineStyles();
  const [textValue, setValue] = useState<string>(value);
  useEffect(() => {
    setValue(value);
  }, [value]);

  const updateOnBlur = () => {
    if (textValue) {
      handleUpdate(textValue);
    }
  };

  return (
    <TextareaAutosize
      rowsMin={3}
      disabled={storyLock}
      aria-label="maximum height"
      placeholder="Maximum 4 rows"
      value={textValue}
      onChange={event => {
        setValue(event.target.value);
      }}
      onBlur={updateOnBlur}
      className={classesComp.textArea}
    />
  );
};

export default CustomTextArea;
