import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dialog, Grid, Button } from '@material-ui/core';
import i18n from 'i18n';
import toggleNotification from 'actions/notifications';
import Loading from 'components/Loading';
import EmptyState from 'components/EmptyState';
import List from 'pages/administration/peripheral-settings/types/components/List';
import Form from 'pages/administration/peripheral-settings/types/components/Form';
import {
  fetchPeripheralTypes,
  createPeripheralType,
  updatePeripheralType,
  deletePeripheralType
} from 'pages/administration/peripheral-settings/actions';
import { FormData } from 'pages/administration/peripheral-settings/types/formData';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import BtnWrapper from 'components/BtnWrapper';
import { SettingsInputSvideo } from '@material-ui/icons';

const PeripheralTypes: FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleApiError = () => {
    dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
  };

  const [peripheralTypes, setPeripheralTypes] =
    useState<$Lns.PeripheralType[]>();

  const fetchSetTypes = () => {
    fetchPeripheralTypes().then(({ data }) => {
      setPeripheralTypes(data);
    });
  };
  useEffect(() => {
    fetchSetTypes();
  }, []);

  const [editType, setEditType] = useState<$Lns.PeripheralType>();

  const onAdd = () => {
    const newPeripheralType = {} as $Lns.PeripheralType;
    setEditType(newPeripheralType);
  };

  const onEdit = (typeItem: $Lns.PeripheralType) => {
    setEditType(typeItem);
  };

  const onSave = (formData: FormData) => {
    if (formData.guid === undefined) {
      createPeripheralType(formData)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.peripheralTypes.addSuccess'),
              'success'
            )
          );
          fetchSetTypes();
        })
        .catch(handleApiError);
    } else {
      updatePeripheralType(formData)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.peripheralTypes.editSuccess'),
              'success'
            )
          );
          fetchSetTypes();
        })
        .catch(handleApiError);
    }
    setEditType(undefined);
  };

  const onEditCancel = () => setEditType(undefined);

  const onDelete = (typeItem: $Lns.PeripheralType) => {
    deletePeripheralType(typeItem)
      .then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.peripheralTypes.deleteSuccess'),
            'success'
          )
        );
        fetchSetTypes();
        setEditType(undefined);
      })
      .catch(() => {
        toggleNotification(
          i18n.t('notifications.peripheralTypes.deleteError'),
          'error'
        );
      });
  };

  return (
    <Grid container spacing={2}>
      <TitleBarWraper>
        <Grid item xs={6}>
          <TitleWaper
            title={i18n.t(
              'links.sidebarNestedItems.administration.peripheralTypes'
            )}
            subText={i18n.t('pages.stories.formLabel.title')}
            icon={<SettingsInputSvideo color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <BtnWrapper>
            <Button color="secondary" variant="contained" onClick={onAdd}>
              {i18n.t('pages.administration.peripheralTypes.button.add')}
            </Button>
          </BtnWrapper>
        </Grid>
      </TitleBarWraper>
      <Grid item xs={12}>
        {!peripheralTypes && <Loading />}
        {peripheralTypes && peripheralTypes.length === 0 && (
          <EmptyState title="no type" subTitle="add one" />
        )}
        {peripheralTypes && peripheralTypes.length > 0 && (
          <List
            typeItems={peripheralTypes}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        )}
      </Grid>
      {editType && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="edit-peripheral-types-dialog"
          open
        >
          <Form typeItem={editType} onSave={onSave} onCancel={onEditCancel} />
        </Dialog>
      )}
    </Grid>
  );
};

export default PeripheralTypes;
