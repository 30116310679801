import { Box } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';

interface TitleBarWraperI {
  children: ReactNode;
}

const BtnWraper: FunctionComponent<TitleBarWraperI> = ({
  children
}: TitleBarWraperI) => {
  return (
    <Box
      display="flex"
      gridTemplateColumns="max-content max-content"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      height="100%"
      gridGap={10}
    >
      {children}
    </Box>
  );
};

export default BtnWraper;
