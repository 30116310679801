import { useCanAccess } from 'components/Authorization';
import { TaskStates } from 'pages/assignments/consts';
import { FormData } from 'pages/assignments/formData';

export type opStates = {
  [key: string]: boolean;
};

export const useOpStates = (
  assignment: FormData,
  currentUser: $Lns.User,
  isAdmin: boolean
): opStates => {
  const { taskStateObj, assignedBy, assignedTo } = assignment;
  const taskStateName = taskStateObj?.name || '';
  const hasEditPermissions = useCanAccess('task', 'edit');
  const hasDeletePermissions = useCanAccess('task', 'delete');

  const isStateNew = taskStateName === TaskStates.NEW;
  const isStatePending = taskStateName === TaskStates.PENDING_ASSIGNMENT;
  const isStateRejected = taskStateName === TaskStates.REJECTED;

  const isCreation = !assignment.guid;
  const isAssignedTo = assignedTo === currentUser.guid;
  const isAssignedBy = assignedBy === currentUser.guid;

  const canAccept = isStateNew || (isStatePending && isAssignedTo);
  const canReject = isStatePending && isAssignedTo;

  const canEdit =
    isCreation ||
    isAssignedBy ||
    (hasEditPermissions && (isAdmin || isAssignedBy || isAssignedTo));

  const canEditState =
    isAdmin ||
    isAssignedBy ||
    (isAssignedTo && !(isStateNew || isStatePending || isStateRejected));

  const canViewEdit =
    canEdit || (isAssignedTo && !isStateRejected) || canAccept;

  const canDelete =
    hasDeletePermissions &&
    // Case of AssignBy deleting assignments created him/herself
    // Case of Admin deleting assignments
    (((isAdmin || isAssignedBy) &&
      taskStateName !== TaskStates.IN_PROGRESS &&
      taskStateName !== TaskStates.ASSIGNED) ||
      // if undefined, then false
      false);

  return {
    isCreation,
    isAssignedTo,
    isAssignedBy,
    isStateNew,
    isStatePending,
    canEdit,
    canEditState,
    canViewEdit,
    canDelete,
    canAccept,
    canReject
  };
};
