import { FunctionComponent } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AdministrationRoutes from 'routes/Administration';
import ListStories from 'pages/stories';
import EditStory from 'pages/stories/edit';
import ShowStoryPool from 'pages/story-pools/show';
import EditStoryPool from 'pages/story-pools/edit';
import ListStoryPools from 'pages/story-pools';
import ListRundowns from 'pages/rundowns';
import ShowRundown from 'pages/rundowns/show';
import EditRundown from 'pages/rundowns/edit';
import NewswireServices from 'pages/newswires';
import NewswireStories from 'pages/newswires/stories';
import ListPublishedFolders from 'pages/publish-folders';
import EditPublishFolder from 'pages/publish-folders/edit';
import ShowPFolderStories from 'pages/publish-folders/show';
import UserListPage from 'pages/administration/security/users/index';
import UserPage from 'pages/user';
import Assignments from 'pages/assignments';
import { ProtectedRoute, Resource, Action } from 'components/Authorization';

const AuthedRoutes: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="my-lns" />} />
      <Route path="/my-lns/*" element={<ListStories />} />
      <Route path="/my-lns/:guid/edit" element={<EditStory />} />
      <Route path="/my-lns/:guid/edit/:rundownGuid" element={<EditStory />} />
      <ProtectedRoute
        lnsResource={Resource.NEWSWIRE}
        lnsAction={Action.READ}
        path="/newswires/:guid/stories"
        element={<NewswireStories />}
      />
      <ProtectedRoute
        lnsResource={Resource.NEWSWIRE}
        lnsAction={Action.READ}
        path="/newswires/:guid"
        element={<NewswireStories />}
      />
      <ProtectedRoute
        lnsResource={Resource.NEWSWIRE}
        lnsAction={Action.LIST}
        path="/newswires"
        element={<NewswireServices />}
      />
      <ProtectedRoute
        lnsResource={Resource.STORY_POOL}
        lnsAction={Action.LIST}
        path="/story-pools"
        element={<ListStoryPools />}
      />
      <ProtectedRoute
        lnsResource={Resource.STORY_POOL}
        lnsAction={Action.EDIT}
        path="/story-pools/:guid/edit"
        element={<EditStoryPool />}
      />
      <ProtectedRoute
        lnsResource={Resource.STORY_POOL}
        lnsAction={Action.READ}
        path="/story-pools/:guid/"
        element={<ShowStoryPool />}
      />
      <ProtectedRoute
        lnsResource={Resource.RUNDOWN}
        lnsAction={Action.LIST}
        path="/rundowns"
        element={<ListRundowns />}
      />
      <ProtectedRoute
        lnsResource={Resource.RUNDOWN}
        lnsAction={Action.READ}
        path="/rundowns/:guid"
        element={<ShowRundown />}
      />
      <ProtectedRoute
        lnsResource={Resource.RUNDOWN}
        lnsAction={Action.EDIT}
        path="/rundowns/:guid/stories"
        element={<EditRundown />}
      />
      <ProtectedRoute
        lnsResource={Resource.PUBLISH_FOLDER}
        lnsAction={Action.LIST}
        path="/publish-folders"
        element={<ListPublishedFolders />}
      />
      <ProtectedRoute
        lnsResource={Resource.PUBLISH_FOLDER}
        lnsAction={Action.READ}
        path="/publish-folders/:guid"
        element={<ShowPFolderStories />}
      />
      <Route
        path="/publish-folders/:guid/edit"
        element={<EditPublishFolder />}
      />
      <ProtectedRoute
        lnsResource={Resource.SECURITY}
        lnsAction={Action.READ}
        path="/user"
        element={<UserListPage />}
      />
      <ProtectedRoute
        allowedForCurrentUser
        lnsResource={Resource.SECURITY}
        lnsAction={Action.READ}
        path="/user/:guid"
        element={<UserPage />}
      />
      <Route path="/administration/*" element={<AdministrationRoutes />} />
      <ProtectedRoute
        lnsResource={Resource.TASK}
        lnsAction={Action.LIST}
        path="/assignments"
        element={<Assignments />}
      />
    </Routes>
  );
};

export default AuthedRoutes;
