import { makeStyles } from '@material-ui/core';

export const componentStyle = makeStyles(theme => ({
  paddinBottomNone: {
    paddingBottom: `${theme.spacing(0)}px !important`
  },
  wordBreak: {
    height: 'auto',
    wordBreak: 'break-all'
  }
}));
