import { makeStyles, Theme } from '@material-ui/core';
import colors from 'styles/colors';

export const dialogStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent'
  },
  container: {
    justifyContent: 'flex-end'
  },
  paper: {
    height: '80%',
    background: colors.backgrounds.grey20,
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

export const dialogBackdropStyle = makeStyles(() => ({
  root: {
    display: 'none'
  }
}));
