import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '200px',
      marginRight: theme.spacing(2),
      '&:last-child': {
        marginRight: 0
      }
    },
    flexRight: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  })
);

export default useStyles;
