import { Dispatch } from 'react';
import { SET_LOCALE, SET_DIRECTION } from 'redux/reducers/types';

export const setLocale = (locale: string): Dispatch<() => void> => {
  return (dispatch: Dispatch<{ type: string; locale: string }>) => {
    dispatch({
      type: SET_LOCALE,
      locale
    });
  };
};

export const setRTL = (dir: string): Dispatch<() => void> => {
  return (dispatch: Dispatch<{ type: string; dir: string }>) => {
    dispatch({
      type: SET_DIRECTION,
      dir
    });
  };
};
