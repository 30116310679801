import { FunctionComponent } from 'react';
import { Grid, Box } from '@material-ui/core';
import { AssignmentInd } from '@material-ui/icons';
import TitleBarWraper from 'components/TitleBarWraper';
import TitleWraper from 'components/TitleWraper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';

interface AssignmentHeaderI {
  onAddCallback: () => void;
}

const AssignmentHeader: FunctionComponent<AssignmentHeaderI> = ({
  onAddCallback
}: AssignmentHeaderI) => {
  const onAdd = () => {
    onAddCallback();
  };

  return (
    <TitleBarWraper>
      <Grid item xs={6}>
        <TitleWraper
          title="Assignments"
          subText="Title"
          icon={<AssignmentInd fontSize="large" />}
        />
      </Grid>
      <Grid item xs={6}>
        <Box
          display="flex"
          gridTemplateColumns="max-content max-content"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          height="100%"
          gridGap={10}
        >
          <ProtectedButton
            lnsResource={Resource.TASK}
            lnsAction={Action.CREATE}
            color="secondary"
            variant="contained"
            onClick={onAdd}
          >
            Create Assignment
          </ProtectedButton>
        </Box>
      </Grid>
    </TitleBarWraper>
  );
};

export default AssignmentHeader;
