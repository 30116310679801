import { Box } from '@material-ui/core';
import {
  Edit,
  Visibility,
  CheckBoxOutlineBlank,
  CheckBox
} from '@material-ui/icons';
import {
  ProtectedDeleteDialog,
  ProtectedIconButton,
  Resource,
  Action
} from 'components/Authorization';
import TableBuilder from 'components/Table';
import i18n from 'i18n';
import React, { FunctionComponent } from 'react';

interface StoryTypeListI {
  storyTypes: $Lns.StoryType[];
  onEdit: (storyType: $Lns.StoryType) => void;
  onDelete: (storyType: $Lns.StoryType) => void;
  onShow: (storyType: $Lns.StoryType) => void;
}

const StoryTypeList: FunctionComponent<StoryTypeListI> = ({
  storyTypes,
  onEdit,
  onDelete,
  onShow
}: StoryTypeListI) => {
  const rows = [
    i18n.t('pages.administration.storyTypes.tableRow.name'),
    i18n.t('pages.administration.storyTypes.tableRow.key'),
    i18n.t('pages.administration.storyTypes.tableRow.prompter'),
    i18n.t('pages.administration.storyTypes.tableRow.cg'),
    i18n.t('pages.administration.storyTypes.tableRow.clipType'),
    i18n.t('tableRow.actions')
  ];

  const clipType = {
    0: 'none',
    1: 'video',
    2: 'Live',
    3: 'video + live'
  } as $Lns.ObjectKey;

  const cells = storyTypes.map(storyType => {
    return {
      fields: {
        name: { value: storyType.name },
        key: { value: storyType.key },
        isPrompterRequired: {
          value: storyType.isPrompterRequired ? (
            <CheckBox color="primary" />
          ) : (
            <CheckBoxOutlineBlank color="primary" />
          )
        },
        isCgRequired: {
          value: storyType.isCgRequired ? (
            <CheckBox color="primary" />
          ) : (
            <CheckBoxOutlineBlank color="primary" />
          )
        },
        clipType: { value: clipType[storyType.clipType] },
        actions: {
          value: (
            <Box display="flex">
              <ProtectedIconButton
                size="medium"
                onClick={() => onShow(storyType)}
                lnsResource={Resource.STORY_TYPE}
                lnsAction={Action.READ}
              >
                <Visibility color="primary" />
              </ProtectedIconButton>
              <ProtectedIconButton
                size="small"
                onClick={() => onEdit(storyType)}
                lnsResource={Resource.STORY_TYPE}
                lnsAction={Action.EDIT}
              >
                <Edit color="primary" />
              </ProtectedIconButton>

              <ProtectedDeleteDialog
                lnsResource={Resource.STORY_TYPE}
                onDelete={() => onDelete(storyType)}
                target={storyType.name}
                targetDeleteTitle="Delete Story Type"
                targetDeletePrompt="Are you sure?"
                targetDeleteWarning="Cannot be undone"
                btnDisabled={false}
              />
            </Box>
          )
        }
      }
    };
  });

  return <TableBuilder rows={rows} cells={cells} />;
};

export default StoryTypeList;
