import { FunctionComponent, ChangeEvent, useState } from 'react';
import { Grid, Typography, Slider, TextField } from '@material-ui/core';
import i18n from 'i18n';

interface AssetDurationI {
  duration: number;
  clipStartSec: number;
  clipStopSec: number;
  onChangeHandle: (totalDuration: number, trackPoints: number[]) => void;
}

interface AssetDurationModal {
  calcAssetDuration: number;
  clipPoints: number[];
}

const AssetDuration: FunctionComponent<AssetDurationI> = ({
  duration,
  clipStartSec,
  clipStopSec,
  onChangeHandle
}: AssetDurationI) => {
  const inputAssetModal = {
    calcAssetDuration: duration,
    clipPoints: [clipStartSec, clipStopSec]
  };

  const [durationModalObj, setDurationModalObj] =
    useState<AssetDurationModal>(inputAssetModal);

  const onSliderChange = (
    e: ChangeEvent<Record<string, unknown>>,
    newValue: number | number[]
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    const durationInSec = calcDurationSec(newValue[0], newValue[1]);

    const inputAssetTemp = {
      calcAssetDuration: durationInSec,
      clipPoints: newValue
    };

    setDurationModalObj(inputAssetTemp);
    onChangeHandle(durationInSec, newValue);
  };

  const calcDurationSec = (startPoint: number, endPoint: number) => {
    const startDiff = startPoint;
    const endDiff = duration - endPoint;
    const durationSec = startDiff + endDiff;
    return duration - durationSec;
  };

  const onChangeField = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (!value) return;

    const condition = {
      in: () => updateInOut(true, +(value as string)),
      out: () => updateInOut(false, +(value as string))
    };

    condition[name as keyof typeof condition]();
  };

  const updateInOut = (flgInOut: boolean, updatedValue: number) => {
    const assetTrackDuration = flgInOut
      ? [updatedValue, durationModalObj.clipPoints[1]]
      : [durationModalObj.clipPoints[0], updatedValue];

    const durationSec = calcDurationSec(
      assetTrackDuration[0],
      assetTrackDuration[1]
    );

    const inputAssetTemp = {
      calcAssetDuration: durationSec,
      clipPoints: assetTrackDuration
    };

    setDurationModalObj(inputAssetTemp);
    onChangeHandle(durationSec, assetTrackDuration);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography align="left">
              {i18n.t('pages.stories.helperText.assetDuration')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Slider
              getAriaLabel={() => 'Duration'}
              value={durationModalObj.clipPoints}
              max={duration}
              onChange={onSliderChange}
              valueLabelDisplay="auto"
              getAriaValueText={number => `${number}s`}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="in"
          value={durationModalObj.clipPoints[0]}
          type="number"
          label={i18n.t('pages.stories.placeHolder.in')}
          name="in"
          onChange={onChangeField}
          autoFocus
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="out"
          value={durationModalObj.clipPoints[1]}
          type="number"
          label={i18n.t('pages.stories.placeHolder.out')}
          name="out"
          onChange={onChangeField}
          autoFocus
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
          id="out"
          value={durationModalObj.calcAssetDuration}
          type="number"
          label={i18n.t('pages.stories.placeHolder.calculatedDuration')}
          name="out"
        />
      </Grid>
    </Grid>
  );
};

export default AssetDuration;
