import { FunctionComponent } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { TableCellI } from 'interfaces/tableCell';
import StoriesTable from 'components/Story/Table';

interface StoriesI {
  stories: $Lns.Story[];
  tableCells?: TableCellI[];
  onToggleStory: (story: $Lns.Story) => void;
  onDragComplete?: (result: DropResult) => void;
}

const Stories: FunctionComponent<StoriesI> = ({
  stories,
  tableCells,
  onToggleStory,
  onDragComplete,
  ...props
}: StoriesI) => {
  const onClick = (rowItem: { guid: { value: string } }) => {
    const story = stories.find(
      (currentStory: $Lns.Story) => currentStory.guid === rowItem.guid.value
    );

    if (story) onToggleStory(story);
  };

  return (
    <StoriesTable
      stories={stories}
      tableCells={tableCells}
      onClick={onClick}
      onDragComplete={onDragComplete}
      {...props}
    />
  );
};

export default Stories;

Stories.defaultProps = {
  onDragComplete: undefined,
  tableCells: undefined
};
