import { createStyles, makeStyles, Theme } from '@material-ui/core';

const sideBarListItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(4)
    }
  })
);

export default sideBarListItemStyles;
