import { ChangeEvent, FunctionComponent, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import i18n from 'i18n';
import { validateForm } from 'pages/administration/newswires/utils';
import { FormData } from 'pages/administration/newswires/formData';

interface FormI {
  newswireService?: $Lns.NewswireService;
  newswireFormats: $Lns.NewswireFormat[];
  onSave: (formData: $Lns.NewswireService) => void;
  onCancel: () => void;
}

const Form: FunctionComponent<FormI> = ({
  newswireService,
  newswireFormats,
  onSave,
  onCancel
}: FormI) => {
  const [formData, setFormData] = useState<FormData>({
    guid: newswireService?.guid,
    name: newswireService?.name || '',
    url: newswireService?.url || '',
    format: newswireService?.format || '',
    isAuthRequired: newswireService?.isAuthRequired || false,
    username: newswireService?.username || '',
    password: newswireService?.password || '',
    errors: {
      name: '',
      url: '',
      username: '',
      password: ''
    }
  });

  const btnDisabled =
    Object.values(formData.errors).some(e => !!e) ||
    !formData.name ||
    !formData.url ||
    !formData.format ||
    (formData.isAuthRequired && (!formData.username || !formData.password));

  const [BtnDisabledChange, setBtnDisabledChange] = useState(
    Object.values(formData.errors).some(e => !!e) ||
      !!formData.name ||
      !!formData.url ||
      !!formData.format ||
      (formData.isAuthRequired && (!!formData.username || !!formData.password))
  );

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedFormData = {
      ...formData,
      [name]: value,
      errors: {
        ...formData.errors,
        [name]: validateForm(name, value)
      }
    };
    toggleSaveBtn(updatedFormData, name);
    setFormData(updatedFormData);
  };

  const handleFormatChange = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const { name, value } = e.target;

    const updatedFormData = {
      ...formData,
      [name || '']: value
    };
    toggleSaveBtn(updatedFormData, name || '');
    setFormData({ ...formData, format: e.target.value as string });
  };

  const handleAuthRequireChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedFormData = {
      ...formData,
      [name || '']: value
    };
    toggleSaveBtn(updatedFormData, name);
    setFormData({ ...formData, isAuthRequired: e.target.checked });
  };

  const handleOnSave = () => {
    onSave(formData);
  };

  const toggleSaveBtn = (updatedFormData: FormData, key: string) => {
    let checkFlg = true;
    const skipList = ['errors'];
    if (newswireService && Object.keys(newswireService).includes(key)) {
      const tempFormData = { ...updatedFormData };
      checkFlg = checkFormDataState(newswireService, tempFormData, skipList);
      setBtnDisabledChange(checkFlg);
    }
  };

  const checkFormDataState = (
    formDataObj: $Lns.NewswireService,
    updateFormDataObj: FormData,
    skipList: string[]
  ) => {
    let checkFlg = true;
    Object.keys(updateFormDataObj).forEach(pin => {
      if (
        pin in updateFormDataObj &&
        pin in formDataObj &&
        !skipList.includes(pin)
      ) {
        const Val1 = updateFormDataObj[pin as keyof typeof updateFormDataObj];
        const Val2 = formDataObj[pin as keyof typeof formDataObj];
        if (Val1 !== Val2) {
          checkFlg = false;
        }
      }
    });
    return checkFlg;
  };

  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label={i18n.t('pages.administration.newswires.formLabel.name')}
            value={formData.name}
            helperText={formData.errors.name || 'Name'}
            name="name"
            onChange={onChangeField}
            error={!!formData?.errors?.name}
            autoComplete="name"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="url"
            label={i18n.t('pages.administration.newswires.formLabel.url')}
            value={formData.url}
            helperText={formData.errors.url || 'Url'}
            name="url"
            onChange={onChangeField}
            error={!!formData?.errors?.url}
            autoComplete="url"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="selectFormat-eyecandy">
              {i18n.t('pages.administration.newswires.formLabel.formatType')}
            </InputLabel>
            <Select
              labelId="selectFormat"
              label={i18n.t(
                'pages.administration.newswires.formLabel.formatType'
              )}
              id="selectFormat"
              name="format"
              variant="outlined"
              value={formData.format}
              onChange={handleFormatChange}
            >
              {newswireFormats &&
                newswireFormats.length > 0 &&
                newswireFormats.map(formatItem => (
                  <MenuItem key={formatItem.id} value={formatItem.guid}>
                    {formatItem.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            label={i18n.t(
              'pages.administration.newswires.formLabel.isAuthRequired'
            )}
            control={
              <Checkbox
                name="isAuthRequired"
                onChange={handleAuthRequireChange}
                checked={formData.isAuthRequired}
                color="primary"
              />
            }
          />
        </Grid>

        {formData.isAuthRequired && (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="username"
              label={i18n.t(
                'pages.administration.newswires.formLabel.username'
              )}
              value={formData.username}
              helperText={formData.errors.username || 'Username'}
              name="username"
              onChange={onChangeField}
              error={!!formData?.errors?.username}
              autoComplete="username"
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              type="password"
              id="password"
              label={i18n.t(
                'pages.administration.newswires.formLabel.password'
              )}
              value={formData.password}
              helperText={formData.errors.password || 'Password'}
              name="password"
              onChange={onChangeField}
              error={!!formData?.errors?.password}
              autoComplete="password"
            />
          </Grid>
        )}

        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={btnDisabled || BtnDisabledChange}
              color="secondary"
              variant="contained"
              onClick={handleOnSave}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;

Form.defaultProps = {
  newswireService: undefined
};
