import { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Chip, Typography } from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';
import { Warning as WarningIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import i18n from 'i18n';
import GridTable from 'components/GridTable';
import TooltipWrap from 'components/TooltipWrap';
import { findTaskByGuid, LnsTaskToFormData } from 'pages/assignments/utils';
import PriorityTag from 'pages/assignments/components/PriorityTag';
import ActionButtons from 'pages/assignments/components/AssignmentList/ActionButtons';
import colors from 'styles/colors';

interface ListAssignmentsI {
  isAdmin: boolean;
  currentLnsUser: $Lns.User;
  assignments: $Lns.Task[];
  onViewAssignment: (assignment: $Lns.Task) => void;
  onEditAssignment: (assignment: $Lns.Task) => void;
  onDeleteAssignment: (assignmentGuid: string) => void;
}

const ListAssignments: FunctionComponent<ListAssignmentsI> = ({
  isAdmin,
  assignments,
  currentLnsUser,
  onViewAssignment,
  onEditAssignment,
  onDeleteAssignment
}: ListAssignmentsI) => {
  const onHandleView = (guid: string) => {
    const assignment = findTaskByGuid(guid, assignments);
    if (assignment) onViewAssignment(assignment);
  };

  const onHandleEdit = (guid: string) => {
    const assignment = findTaskByGuid(guid, assignments);
    if (assignment) onEditAssignment(assignment);
  };

  const onHandleDelete = (guid: string) => {
    const assignment = findTaskByGuid(guid, assignments);
    if (assignment) onDeleteAssignment(assignment.guid);
  };

  const columns: GridColDef[] = [
    {
      field: 'priority',
      headerName: i18n.t('pages.assignments.formLabel.priority'),
      flex: 1,
      renderCell: gridCell => {
        const {
          row: { priority }
        } = gridCell;

        return <PriorityTag priority={priority} />;
      }
    },
    {
      field: 'title',
      headerName: i18n.t('pages.assignments.formLabel.title'),
      flex: 1
    },
    {
      field: 'story',
      headerName: i18n.t('pages.assignments.formLabel.storySlug'),
      flex: 1,
      renderCell: gridCell => {
        const {
          row: { story }
        } = gridCell;

        if (story?.slug)
          return (
            <Link
              to={`/my-lns/${story.guid}/edit`}
              style={{ color: colors.primary[500] }}
            >
              {story.slug}
            </Link>
          );
        return 'Not Set';
      }
    },
    {
      field: 'dateDue',
      headerName: i18n.t('pages.assignments.formLabel.dateDue'),
      flex: 1,
      renderCell: gridCell => {
        const {
          row: { dateDue }
        } = gridCell;

        const warningFlag = new Date(dateDue) < new Date();

        return (
          <Box display="contents" whiteSpace="break-spaces">
            {warningFlag && (
              <Box paddingRight={1}>
                <TooltipWrap
                  title={i18n.t('pages.assignments.tooltip.overdue')}
                >
                  <WarningIcon color="error" />
                </TooltipWrap>
              </Box>
            )}
            <TooltipWrap title={format(new Date(dateDue), 'Pp')}>
              <Typography>{format(new Date(dateDue), 'Pp')}</Typography>
            </TooltipWrap>
          </Box>
        );
      }
    },
    {
      field: 'taskState',
      headerName: i18n.t('pages.assignments.formLabel.taskStates'),
      flex: 1,
      renderCell: gridCell => {
        const {
          row: { taskState }
        } = gridCell;

        return <Chip label={taskState.name} style={{ maxWidth: '100%' }} />;
      }
    },
    {
      field: 'assignedTo',
      headerName: 'Assignee',
      flex: 1,
      renderCell: ({ row: { assignedTo } }) => assignedTo.username || 'Not Set'
    },
    {
      field: 'actions',
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      headerName: i18n.t('tableRow.actions'),
      flex: 1,
      renderCell: gridCell => {
        const {
          row: { guid }
        } = gridCell;

        const currentCellAssignment = findTaskByGuid(guid, assignments);
        if (!currentCellAssignment) return <p>{i18n.t('data.error')}</p>;
        return (
          <ActionButtons
            isAdmin={isAdmin}
            assignment={LnsTaskToFormData(currentCellAssignment)}
            currentLnsUser={currentLnsUser}
            onHandleView={onHandleView}
            onHandleEdit={onHandleEdit}
            onHandleDelete={onHandleDelete}
          />
        );
      }
    }
  ];

  const tableAssignments: $Lns.Task[] = useMemo(() => {
    return assignments;
  }, [assignments]);

  return <GridTable columns={columns} cells={tableAssignments} />;
};

export default ListAssignments;
