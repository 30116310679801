import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import { Dialog, Grid, IconButton, Paper } from '@material-ui/core';
import FileTypeList from 'pages/administration/file-types/components/List';
import {
  createFileType,
  deleteFileType,
  fetchFileTypes,
  updateFileType
} from 'pages/administration/file-types/actions';
import i18n from 'i18n';
import Form from 'pages/administration/file-types/components/Form';
import { useDispatch } from 'react-redux';
import toggleNotification from 'actions/notifications';
import { FormData } from 'pages/administration/file-types/formData';
import { objectsWithItemRemoved, updatedObjects } from 'utils/state';
import { Search, InsertDriveFile } from '@material-ui/icons';
import storiesStyles from 'pages/stories/styles';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import BtnWrapper from 'components/BtnWrapper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import BasicTextField from 'components/BasicTextField';

const FileTypes: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [fileTypes, setFileTypes] = useState<$Lns.FileType[]>([]);
  const [fileTypesOrig, setFileTypesOrig] = useState<$Lns.FileType[]>([]);
  const [editFileType, setEditFileType] = useState<$Lns.FileType>();
  const classes = storiesStyles();
  useEffect(() => {
    fetchFileTypes().then(({ data }) => {
      const newFileTypes = data.slice().reverse();
      setFileTypes(newFileTypes);
      setFileTypesOrig(newFileTypes);
    });
  }, []);

  const onAdd = () => {
    const newFileType = { name: '', guid: '' };
    setEditFileType(newFileType);
  };

  const onCancel = () => {
    setEditFileType(undefined);
  };

  const onEdit = (fileType: $Lns.FileType) => {
    setEditFileType(fileType);
  };

  const onCreate = (fileType: $Lns.FileType) => {
    createFileType(fileType)
      .then(({ data, code }) => {
        if (code === 201) {
          dispatch(
            toggleNotification(
              i18n.t('notifications.fileTypes.createSuccess'),
              'success'
            )
          );
          const updatedFilesTypes = [data, ...fileTypes];
          setFileTypes(updatedFilesTypes);
        }
      })
      .catch(() =>
        dispatch(
          toggleNotification(
            i18n.t('notifications.fileTypes.createError'),
            'error'
          )
        )
      );
  };

  const onUpdate = (fileType: $Lns.FileType) => {
    updateFileType(fileType)
      .then(json => {
        if (json.code === 201) {
          dispatch(
            toggleNotification(
              i18n.t('notifications.fileTypes.updateSuccess'),
              'success'
            )
          );
          const updatedFilesTypes = updatedObjects(fileTypes, fileType);
          setFileTypes(updatedFilesTypes);
        }
      })
      .catch(() =>
        dispatch(
          toggleNotification(
            i18n.t('notifications.fileTypes.updateError'),
            'error'
          )
        )
      );
  };

  const onDelete = (fileType: $Lns.FileType) => {
    deleteFileType(fileType)
      .then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.fileTypes.deleteSuccess'),
            'success'
          )
        );
        const updatedFilesTypes = objectsWithItemRemoved(fileType, fileTypes);
        setFileTypes(updatedFilesTypes);
      })
      .catch(() => {
        toggleNotification(
          i18n.t('notifications.fileTypes.deleteError'),
          'error'
        );
      });
  };

  const onSave = (formData: FormData) => {
    if (formData.guid) {
      const fileType = {
        name: formData.name,
        extension: formData.extension,
        guid: formData.guid
      };
      onUpdate(fileType);
    } else {
      const fileType = { name: formData.name, extension: formData.extension };
      onCreate(fileType);
    }

    setEditFileType(undefined);
  };

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newfileTypesOrig = fileTypesOrig.filter(item => {
      return item.guid.includes(search) || item.name.includes(search);
    });
    setFileTypes(newfileTypesOrig);
  };

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={i18n.t(
                'links.sidebarNestedItems.administration.fileTypes'
              )}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<InsertDriveFile color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <ProtectedButton
                lnsResource={Resource.FILE_TYPE}
                lnsAction={Action.CREATE}
                color="secondary"
                variant="contained"
                onClick={onAdd}
              >
                {i18n.t('pages.administration.fileTypes.button.add')}
              </ProtectedButton>
            </BtnWrapper>
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={onSearchFieldChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <FileTypeList
            fileTypes={fileTypes}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Grid>
      </Grid>
      {editFileType && (
        <Dialog onClose={onCancel} aria-labelledby="edit-storypool" open>
          <Form fileType={editFileType} onSave={onSave} onCancel={onCancel} />
        </Dialog>
      )}
    </>
  );
};

export default FileTypes;
