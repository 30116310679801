import { FunctionComponent, useState } from 'react';
import { Box, Snackbar, Typography } from '@material-ui/core';
import { isAfter } from 'date-fns';
import { useInterval } from 'utils/interval';
import { secondsToDuration } from 'utils/time';
import { snackbarStyles } from 'pages/rundowns/styles';
import colors from 'styles/colors';
import addSeconds from 'date-fns/addSeconds';
import TimerField from 'pages/rundowns/components/TimerField';

interface RundownTimerI {
  startTime: string;
  stories: $Lns.PlayoutStory[];
}

const RundownTimer: FunctionComponent<RundownTimerI> = ({
  startTime,
  stories
}: RundownTimerI) => {
  const startTimeDate = new Date(startTime);
  const difference = (startTimeDate.valueOf() - new Date().valueOf()) / 1000;

  const [countdownSeconds, updateCountdownSeconds] = useState(
    difference <= 0 ? 0 : difference
  );

  const totalDuration = stories.reduce(
    (total: number, playoutStory: $Lns.PlayoutStory) => {
      return total + playoutStory.story.calculatedDuration;
    },
    0
  );

  const totalPlannedDuration = stories.reduce(
    (total: number, playoutStory: $Lns.PlayoutStory) => {
      return total + playoutStory.story.plannedDuration;
    },
    0
  );

  const endTimeDate = addSeconds(startTimeDate, totalDuration);
  const [remainingDuration, updateRemainingDuration] = useState(0);

  useInterval(
    () => {
      const remaining = countdownSeconds - 1;
      if (remaining > 0) {
        updateCountdownSeconds(remaining);
      }
    },
    1000,
    countdownSeconds < 0
  );

  useInterval(
    () => {
      if (isAfter(new Date(), startTimeDate)) {
        const totalDurationRemaining = Math.round(
          (endTimeDate.valueOf() - new Date().valueOf()) / 1000
        );

        updateRemainingDuration(
          totalDurationRemaining < 0 ? 0 : totalDurationRemaining
        );
      }
    },
    1000,
    remainingDuration < 0
  );

  const snackBarClasses = snackbarStyles();

  return (
    <Snackbar
      data-test-id="rundown-timer"
      open
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={snackBarClasses}
    >
      <Box display="grid" gridAutoFlow="column" gridGap={10}>
        <Box>
          <Typography color="secondary" component="span">
            CD
          </Typography>
        </Box>

        <Box data-test-id="countdown-duration">
          <TimerField
            value={secondsToDuration(countdownSeconds)}
            color={colors.primary[900]}
          />
        </Box>

        <Box>
          <Typography color="secondary" component="span">
            TR
          </Typography>
        </Box>

        <Box>
          <TimerField
            value={secondsToDuration(remainingDuration)}
            color={colors.success.dark}
          />
        </Box>

        <Box>
          <Typography color="secondary" component="span">
            TDU
          </Typography>
        </Box>

        <Box data-test-id="total-duration">
          <TimerField
            value={secondsToDuration(totalDuration)}
            color={colors.primary[900]}
          />
        </Box>

        <Box>
          <Typography color="secondary" component="span">
            TPD
          </Typography>
        </Box>

        <Box data-test-id="total-planned-duration">
          <TimerField
            value={secondsToDuration(totalPlannedDuration)}
            color={colors.primary[900]}
          />
        </Box>
      </Box>
    </Snackbar>
  );
};

export default RundownTimer;
