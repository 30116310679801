import i18n from 'i18n';
import validator from 'validator';

export const validateForm = (name: string, value: string): string => {
  let error = '';

  const alphanumbericFields = ['name', 'type', 'configName', 'configType'];

  const alphanumbericWithSpace = ['configDescription'];

  if (alphanumbericFields.includes(name)) {
    error = validator.isEmpty(value) ? i18n.t('formErrors.isEmpty') : '';
    error = !validator.isAlphanumeric(value)
      ? i18n.t('formErrors.isAlphanumeric')
      : '';
  }

  if (alphanumbericWithSpace.includes(name)) {
    error = validator.isEmpty(value) ? i18n.t('formErrors.isEmpty') : '';
    error = !validator.isAlphanumeric(validator.blacklist(value, ' '))
      ? i18n.t('formErrors.isAlphanumeric')
      : '';
  }

  return error;
};
