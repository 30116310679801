import { FunctionComponent, ReactElement } from 'react';

interface ConditionalWrapperI {
  // eslint-disable-next-line
  condition: any;
  // eslint-disable-next-line
  wrapper: (element: ReactElement<any, any>) => ReactElement<any, any> | null;
  // eslint-disable-next-line
  children: ReactElement<any, any>;
}

const ConditionalWrapper: FunctionComponent<ConditionalWrapperI> = ({
  condition,
  wrapper,
  children
}: ConditionalWrapperI) => {
  return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;
