import { Box, InputAdornment, TextField } from '@material-ui/core';
import { Search, Description } from '@material-ui/icons';
import TableBuilder from 'components/Table';
import i18n from 'i18n';
import { TableCellI } from 'interfaces/tableCell';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { secondsToDuration } from 'utils/time';

interface StoriesTableI {
  stories: $Lns.Story[];
  tableCells?: TableCellI[];
  storyPool?: $Lns.StoryPool;
  highlightedStories?: $Lns.Story[];
  onClick: (rowItem: { guid: { value: string } }) => void;
  onDragComplete?: (result: DropResult) => void;
}

const StoriesTable: FunctionComponent<StoriesTableI> = ({
  stories,
  highlightedStories,
  storyPool,
  tableCells,
  onDragComplete,
  onClick,
  ...props
}: StoriesTableI) => {
  const [filteredStories, setFilteredStories] = useState(stories);

  useEffect(() => {
    setFilteredStories(stories);
  }, [stories]);

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newStories = stories.filter(story => {
      return (
        story.title.includes(search) ||
        story.notes.includes(search) ||
        story.slug.includes(search)
      );
    });
    setFilteredStories(newStories);
  };

  const isHighlighted = (story: $Lns.Story): boolean => {
    if (!highlightedStories) return false;

    return !!highlightedStories.find(
      currentStory => currentStory.guid === story.guid
    );
  };

  const rows = [
    i18n.t('pages.stories.tableRow.pos'),
    i18n.t('pages.stories.tableRow.img'),
    i18n.t('pages.stories.tableRow.slug'),
    i18n.t('pages.stories.tableRow.title'),
    i18n.t('pages.stories.tableRow.duration'),
    i18n.t('pages.stories.tableRow.plannedDuration')
  ];

  const cells =
    tableCells !== undefined
      ? [...tableCells]
      : filteredStories.map((story, index) => {
          return {
            selected: isHighlighted(story),
            fields: {
              pos: { value: index },
              guid: { value: story.guid, hidden: true },
              img: {
                value: <Description color="primary" />
              },
              slug: { value: story.slug },
              title: { value: story.title },
              duration: { value: secondsToDuration(story.calculatedDuration) },
              plannedDuration: {
                value: secondsToDuration(story.plannedDuration)
              }
            }
          };
        });

  const searchPlaceholder = storyPool
    ? `Search ${storyPool.name} ...`
    : i18n.t('placeholders.search');

  return (
    <Box display="grid" gridGap={10} {...props}>
      <TextField
        variant="outlined"
        size="small"
        placeholder={searchPlaceholder}
        onChange={onSearchFieldChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="primary" />
            </InputAdornment>
          )
        }}
      />
      <TableBuilder
        rows={rows}
        cells={cells}
        onRowClick={onClick}
        isDraggable={!!onDragComplete}
        onDragComplete={onDragComplete}
      />
    </Box>
  );
};

export default StoriesTable;

StoriesTable.defaultProps = {
  highlightedStories: undefined,
  storyPool: undefined,
  onDragComplete: undefined,
  tableCells: undefined
};
