import { ListItem, ListItemProps } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';
import { useCanAccess } from 'components/Authorization/utils';
import TooltipWrap from 'components/TooltipWrap';
import i18n from 'i18n';

// eslint-disable-next-line
interface ProtectedListItemI extends ListItemProps<any, any> {
  lnsResource?: string;
  lnsAction?: string;
  children: ReactNode;
}

export const ProtectedListItem: FunctionComponent<ProtectedListItemI> = ({
  lnsResource,
  lnsAction,
  children,
  ...props
}: ProtectedListItemI) => {
  const canAccess = useCanAccess(lnsResource, lnsAction);

  if (canAccess) {
    return <ListItem {...props}>{children}</ListItem>;
  }
  return (
    <TooltipWrap title={i18n.t('authorization.tooltip.notAuthorized')}>
      <ListItem {...props} disabled>
        {children}
      </ListItem>
    </TooltipWrap>
  );
};

ProtectedListItem.defaultProps = {
  lnsResource: undefined,
  lnsAction: undefined
};
