import { FunctionComponent, useReducer, ChangeEvent, useEffect } from 'react';
import {
  Box,
  Grid,
  GridSize,
  Paper,
  Typography,
  IconButton
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import UserItem from 'components/UserList/components/UserItem';
import EmptyState from 'components/EmptyState';
import i18n from 'i18n';
import storiesStyles from 'pages/stories/styles';
import BasicTextField from 'components/BasicTextField';
import { initialState, reducer } from './reducer';

interface UserListI {
  userItems: $Lns.User[];
  selectable?: boolean;
  noSearch?: boolean;
  onClick?: (user: $Lns.User) => void;
  onEdit?: (user: $Lns.User) => void;
  onSneakPeek?: (user: $Lns.User) => void;
  onDelete?: (user: $Lns.User) => void;
  onChangeSelected?: (user: $Lns.User[]) => void;
  userItemXs?: number;
  userItemSm?: number;
  userItemMd?: number;
  userItemLg?: number;
  userItemElevation?: number;
  userItemSquare?: boolean;
  userItemVariant?: 'elevation' | 'outlined' | undefined;
}

const UserList: FunctionComponent<UserListI> = ({
  userItems: propsUserItems,
  selectable,
  noSearch,
  onClick,
  onEdit,
  onSneakPeek,
  onDelete,
  onChangeSelected,
  userItemXs,
  userItemSm,
  userItemMd,
  userItemLg,
  userItemElevation,
  userItemSquare,
  userItemVariant
}: UserListI) => {
  const [{ userItems, selectedUsers, filteredUsers }, dispatch] = useReducer(
    reducer,
    {
      ...initialState,
      userItems: propsUserItems,
      filteredUsers: propsUserItems
    }
  );

  const classes = storiesStyles();
  // userItems is a derived state of propsUserItems
  if (propsUserItems.length !== userItems.length)
    dispatch({ type: 'newProps', payload: propsUserItems });

  const handleOnAdd = (user: $Lns.User) =>
    dispatch({ type: 'addUser', payload: user });

  const handleOnRemove = (user: $Lns.User) =>
    dispatch({ type: 'removeUser', payload: user });

  const handleOnSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target)
      dispatch({ type: 'onSearch', payload: e.target.value || '' });
  };

  const handleOnEdit = onEdit ? (user: $Lns.User) => onEdit(user) : undefined;

  const handleOnSneakPeek = onSneakPeek
    ? (user: $Lns.User) => onSneakPeek(user)
    : undefined;

  const handleOnDelete = onDelete
    ? (user: $Lns.User) => onDelete(user)
    : undefined;

  useEffect(() => {
    // bubbling up the selected user state
    if (onChangeSelected) onChangeSelected(selectedUsers);
  }, [selectedUsers, onChangeSelected]);

  return (
    <Grid container direction="row" spacing={2}>
      {selectable && (
        <Grid item xs={12}>
          <Box marginTop={0} marginBottom={4}>
            <Paper>
              <Box padding={4}>
                <Typography variant="subtitle1">
                  {i18n.t('userList.selectedUsers')}
                </Typography>
              </Box>
              <Grid container spacing={2}>
                {selectedUsers.map(selectedUser => {
                  return (
                    <Grid
                      key={selectedUser.guid}
                      xs={userItemXs as GridSize}
                      sm={userItemSm as GridSize}
                      md={userItemMd as GridSize}
                      lg={userItemLg as GridSize}
                      item
                    >
                      <UserItem
                        userItem={selectedUser}
                        onClick={onClick}
                        onRemove={selectable ? handleOnRemove : undefined}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>
          </Box>
        </Grid>
      )}
      {!noSearch && (
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={handleOnSearch}
            />
            <IconButton className={classes.iconButton} aria-label="search">
              <SearchIcon color="primary" />
            </IconButton>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        {userItems.length > 0 && (
          <Grid container spacing={2}>
            {filteredUsers.map(filteredUser => {
              return (
                <Grid
                  item
                  key={filteredUser.guid}
                  xs={userItemXs as GridSize}
                  sm={userItemSm as GridSize}
                  md={userItemMd as GridSize}
                  lg={userItemLg as GridSize}
                >
                  <Paper>
                    <UserItem
                      userItem={filteredUser}
                      onClick={onClick}
                      onAdd={selectable ? handleOnAdd : undefined}
                      onEdit={handleOnEdit}
                      onSneakPeek={handleOnSneakPeek}
                      onDelete={handleOnDelete}
                      elevation={userItemElevation}
                      square={userItemSquare}
                      variant={userItemVariant}
                    />
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        )}
        {userItems.length === 0 && (
          <EmptyState
            title="No one is here"
            subTitle="There are no Users on this list"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default UserList;

UserList.defaultProps = {
  selectable: false,
  noSearch: false,
  onClick: undefined,
  onEdit: undefined,
  onSneakPeek: undefined,
  onDelete: undefined,
  onChangeSelected: undefined,
  userItemXs: 3,
  userItemSm: undefined,
  userItemMd: undefined,
  userItemLg: undefined,
  userItemElevation: 0,
  userItemSquare: false,
  userItemVariant: 'elevation'
};
