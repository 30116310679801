import React, {
  FunctionComponent,
  MouseEvent,
  ReactNode,
  useEffect
} from 'react';
import { WithStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import dialogStyles from 'styles/dialog';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import { ProtectedButton, ProtectedIconButton } from './Authorization';

export interface DialogTitleProps extends WithStyles {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

interface StyledDialogI {
  children: ReactNode;
  onConfirmCb: () => void;
  onCancelCb?: () => void;
  title: string;
  tooltip?: string;
  cancelLabel: string;
  confirmLabel: string;
  confirmBtnDisabled?: boolean;
  openBtnLabel: string;
  btnDisabled?: boolean;
  disableBackdropClick?: boolean;
  defaultOpen?: boolean;
  showBtn?: boolean;
  iconBtn?: ReactNode;
  lnsResource?: string;
  lnsAction?: string;
}

const StyledDialog: FunctionComponent<StyledDialogI> = ({
  children,
  onCancelCb,
  onConfirmCb,
  title,
  tooltip,
  cancelLabel,
  confirmLabel,
  confirmBtnDisabled,
  openBtnLabel,
  btnDisabled,
  disableBackdropClick,
  showBtn = true,
  defaultOpen = false,
  iconBtn,
  lnsResource,
  lnsAction
}: StyledDialogI) => {
  const [open, setOpen] = React.useState(defaultOpen);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const handleClickOpen = (e: MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (onCancelCb) onCancelCb();
  };

  const onConfirm = () => {
    handleClose();
    onConfirmCb();
  };

  const classes = dialogStyles();

  const triggerButton =
    !iconBtn && showBtn ? (
      <ProtectedButton
        variant="contained"
        disabled={btnDisabled}
        color="secondary"
        onClick={handleClickOpen}
        lnsResource={lnsResource}
        lnsAction={lnsAction}
        tooltip={tooltip || ''}
      >
        {openBtnLabel}
      </ProtectedButton>
    ) : (
      <ProtectedIconButton
        onClick={handleClickOpen}
        disabled={btnDisabled}
        lnsResource={lnsResource}
        lnsAction={lnsAction}
        tooltip={tooltip || ''}
      >
        {iconBtn}
      </ProtectedIconButton>
    );

  return (
    <Box>
      <span>{triggerButton}</span>
      <Dialog
        disableBackdropClick={disableBackdropClick}
        className={classes.root}
        onClose={handleClose}
        aria-labelledby={title}
        open={open}
      >
        <DialogTitle id="customized-dialog-title">
          <Box display="grid" gridGap={4}>
            <Typography component="div" variant="subtitle1" color="primary">
              {title}
            </Typography>
            <IconButton
              className={classes.closeButton}
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon color="primary" />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            variant="outlined"
            color="secondary"
          >
            {cancelLabel}
          </Button>
          <Button
            autoFocus
            disabled={confirmBtnDisabled}
            onClick={onConfirm}
            variant="contained"
            color="secondary"
          >
            {confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StyledDialog;

StyledDialog.defaultProps = {
  tooltip: undefined,
  disableBackdropClick: false,
  onCancelCb: undefined,
  confirmBtnDisabled: false,
  btnDisabled: false,
  defaultOpen: false,
  showBtn: true,
  iconBtn: undefined,
  lnsResource: undefined,
  lnsAction: undefined
};
