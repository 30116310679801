import { Box, Button, Grid, Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { fetchRundownTemplates } from 'pages/administration/rundown-templates/actions';
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { updateFormData } from 'utils/form';
import { validateForm } from 'pages/rundowns/utils';
import { FormData } from 'pages/rundowns/formData';
import i18n from 'i18n';
import SeriesFormFields from './SeriesFormFields';
import SingleFormFields from './SingleFormFields';

interface FormI {
  rundown?: $Lns.Rundown;
  series: boolean;
  onSave: (
    formData: FormData,
    rundownTemplates: $Lns.RundownTemplate[]
  ) => void;
  onCancel: () => void;
}

const Form: FunctionComponent<FormI> = ({
  rundown,
  series,
  onSave,
  onCancel
}: FormI) => {
  const [rundownTemplates, setRundownTemplates] = useState<
    $Lns.RundownTemplate[]
  >([]);

  const { storyCategories } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  useEffect(() => {
    fetchRundownTemplates().then(({ data }) => setRundownTemplates(data));
  }, []);

  const [formData, setFormData] = useState<FormData>({
    guid: rundown?.guid || '',
    name: rundown?.name || '',
    playoutDateTime: rundown?.playoutDateTime || null,
    isLocked: rundown?.isLocked || false,
    isPublished: rundown?.isPublished || false,
    isOnAir: rundown?.isOnAir || false,
    totalDurationSec: rundown?.totalDurationSec || 0,
    rundownTemplate: rundown?.rundownTemplate || '',
    category: rundown?.category || '',
    startDate: null,
    endDate: null,
    errors: {
      name: '',
      playoutDateTime: '',
      isLocked: '',
      isPublished: '',
      isOnAir: '',
      rundownTemplate: '',
      category: ''
    }
  });

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedFormData = updateFormData(e, formData, validateForm);
    setFormData(updatedFormData);
  };

  const onChangeType = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name || '']: value
    });
  };

  const getCategory = (guid: string) => {
    return storyCategories.find(s => s.guid === guid);
  };

  const onChangeDate = (date: MaterialUiPickersDate) => {
    if (!date || Number.isNaN(date.getTime())) return;
    setFormData({
      ...formData,
      playoutDateTime: date.toISOString()
    });
  };

  const onChangeSeriesDate = (field: string, date: MaterialUiPickersDate) => {
    if (!date || Number.isNaN(date.getTime())) return;
    const secondField = field === 'startDate' ? 'endDate' : 'startDate';
    const secondFieldValue = formData[secondField] || '';
    setFormData({
      ...formData,
      [field]: date,
      errors: {
        ...formData.errors,
        [field]: validateForm(field, date, formData),
        [secondField]: validateForm(field, secondFieldValue, formData)
      }
    });
  };

  const btnDisabled = () => {
    let requiredFields = ['category'];

    if (series) {
      requiredFields = requiredFields.concat([
        'startDate',
        'endDate',
        'rundownTemplate'
      ]);
    } else {
      requiredFields = ['name', ...requiredFields];
    }

    return (
      requiredFields.some(value => !formData[value]) ||
      Object.values(formData.errors).some(e => !!e)
    );
  };

  Object.values(formData.errors).some(e => !!e);

  return (
    <Box padding={4}>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography color="primary">
            {i18n.t(
              `pages.rundowns.formTypography.${series ? 'series' : 'single'}`
            )}
          </Typography>
        </Grid>

        {series && (
          <SeriesFormFields
            getCategory={getCategory}
            onChangeType={onChangeType}
            onChangeSeriesDate={onChangeSeriesDate}
            formData={formData}
            rundownTemplates={rundownTemplates}
            storyCategories={storyCategories}
          />
        )}

        {!series && (
          <SingleFormFields
            getCategory={getCategory}
            onChangeField={onChangeField}
            onChangeDate={onChangeDate}
            onChangeType={onChangeType}
            storyCategories={storyCategories}
            formData={formData}
          />
        )}

        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-test-id="save"
              disabled={btnDisabled()}
              color="secondary"
              variant="contained"
              onClick={() => onSave(formData, rundownTemplates)}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;

Form.defaultProps = {
  rundown: undefined
};
