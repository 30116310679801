import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper
} from '@material-ui/core';
import {
  createRundown,
  updateRundown,
  fetchRundowns,
  setRundownDate
} from 'pages/rundowns/actions';
import Form from 'pages/rundowns/components/Form';
import toggleNotification from 'actions/notifications';
import ListRundowns from 'pages/rundowns/components/List';
import i18n from 'i18n';
import { FormData } from 'pages/rundowns/formData';
import { generateRundownsFromTemplate } from 'pages/rundowns/utils';
import { startOfDay, endOfDay, toDate } from 'date-fns';
import TitleWaper from 'components/TitleWraper';
import BtnWrapper from 'components/BtnWrapper';
import TitleBarWraper from 'components/TitleBarWraper';
import { Search, Dns, DateRange } from '@material-ui/icons';
import storiesStyles from 'pages/stories/styles';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import BasicTextField from 'components/BasicTextField';

const Rundowns: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editRundown, setEditRundown] = useState<$Lns.Rundown>();
  const [series, toggleSeries] = useState(false);
  const [rundowns, setRundowns] = useState<$Lns.Rundown[]>([]);
  const [rundownsOrig, setRundownsOrig] = useState<$Lns.Rundown[]>([]);

  const { rundownDate } = useSelector(
    (globalState: $Lns.DefaultState) => globalState.default
  );

  const [startDateFilter, setStartDateFilter] = useState<MaterialUiPickersDate>(
    rundownDate.startDate
  );

  const [endDateFilter, setEndDateFilter] = useState<MaterialUiPickersDate>(
    rundownDate.endDate
  );

  const classes = storiesStyles();

  useEffect(() => {
    if (startDateFilter && endDateFilter) {
      const filterDatesObj = {
        startDate: toDate(startDateFilter),
        endDate: toDate(endDateFilter)
      };
      dispatch(setRundownDate(filterDatesObj));
    }
  }, [dispatch, startDateFilter, endDateFilter]);

  useEffect(() => {
    if (rundownsOrig.length !== 0) return;

    fetchRundowns()
      .then(({ data }) => {
        const filteredRundowns = data
          .filter(rundown => {
            const valDate = rundown.playoutDateTime;
            return (
              startDateFilter &&
              endDateFilter &&
              new Date(valDate) >= startOfDay(startDateFilter) &&
              new Date(valDate) <= endOfDay(endDateFilter)
            );
          })
          .sort((a, b) => (a.playoutDateTime > b.playoutDateTime ? -1 : 1))
          .slice()
          .reverse();
        setRundowns(filteredRundowns);
        setRundownsOrig(data);
      })
      .catch(() =>
        dispatch(toggleNotification('Failed to load rundowns', 'error'))
      );
  }, [dispatch, rundownsOrig.length, startDateFilter, endDateFilter]);

  const onEdit = (rundown: $Lns.Rundown) => {
    toggleSeries(false);
    setEditRundown(rundown);
  };

  const onShow = (rundown: $Lns.Rundown) => {
    navigate(`/rundowns/${rundown.guid}`);
  };

  const onEditStories = (rundown: $Lns.Rundown) => {
    navigate(`/rundowns/${rundown.guid}/stories`);
  };

  const onCreateRundown = (rundown: $Lns.Rundown) => {
    createRundown(rundown as $Lns.Rundown)
      .then(({ data: [first], code }) => {
        if (code === 201) {
          dispatch(
            toggleNotification(
              i18n.t('notifications.rundowns.createSuccess'),
              'success'
            )
          );
          navigate(`/rundowns/${first.guid}/stories`);

          const updatedRundowns = [first, ...rundowns];

          setRundowns(updatedRundowns);
          setEditRundown(undefined);
        } else {
          dispatch(
            toggleNotification(
              i18n.t('notifications.rundowns.createError'),
              'error'
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundowns.createError'),
            'error'
          )
        );
      });
  };

  const onCreateRundownSeries = async (rundown: $Lns.Rundown) => {
    await createRundown(rundown as $Lns.Rundown);
  };

  const onUpdateRundown = (rundownData: FormData, guid: string) => {
    const { startDate, endDate, ...rundown } = rundownData;
    updateRundown(rundown as unknown as $Lns.Rundown).then(({ data, code }) => {
      if (code === 201) {
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundowns.updateSuccess'),
            'success'
          )
        );
        const updatedRundowns = rundowns.map(currentRundown => {
          if (currentRundown.guid === guid) {
            return data[0];
          }
          return currentRundown;
        });

        setRundowns(updatedRundowns);
        setEditRundown(undefined);
      } else {
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundowns.updateError'),
            'error'
          )
        );
      }
    });
  };

  const onSave = (
    formData: FormData,
    rundownTemplates: $Lns.RundownTemplate[]
  ) => {
    // create series of rundowns
    if (series) {
      const newRundowns = generateRundownsFromTemplate(
        formData,
        rundownTemplates
      );

      if (newRundowns) {
        const promises = newRundowns?.map(async rundown =>
          onCreateRundownSeries(rundown)
        );
        Promise.allSettled(promises).then(results => {
          const success = results.filter(r => r.status === 'fulfilled');
          fetchRundowns()
            .then(({ data }) => {
              setRundowns(data.slice().reverse());
            })
            .catch(() =>
              dispatch(toggleNotification('Failed to load rundowns', 'error'))
            );
          setEditRundown(undefined);

          dispatch(
            toggleNotification(
              i18n.t('notifications.rundowns.createSeriesSuccess', {
                count: success.length
              }),
              'success'
            )
          );
        });
      }
      return;
    }

    // update existing rundown
    if (formData.guid) {
      const { errors, ...rundown } = formData;

      onUpdateRundown(rundown as FormData, formData.guid);
    } else {
      // create new single rundown
      const { errors, guid, rundownTemplate, startDate, endDate, ...rundown } =
        formData;
      onCreateRundown(rundown as unknown as $Lns.Rundown);
    }
  };

  const onAdd = () => {
    toggleSeries(false);
    setEditRundown({
      guid: '',
      name: '',
      totalDurationSec: 0,
      playoutDateTime: '',
      isLocked: false,
      isPublished: false,
      isOnAir: false,
      rundownTemplate: '',
      category: ''
    });
  };

  const onAddSeries = () => {
    onAdd();
    toggleSeries(true);
  };

  const onEditCancel = () => {
    setEditRundown(undefined);
  };

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newStorypool = rundownsOrig.filter(story => {
      return story.guid.includes(search) || story.name.includes(search);
    });
    setRundowns(newStorypool);
  };

  const onRowClick = (guid: string) => {
    const rundown = rundowns.find(pin => {
      return pin.guid === guid;
    });

    if (rundown) {
      navigate(`/rundowns/${rundown.guid}`);
    }
  };

  const filterRundown = () => {
    const newStorypool = rundownsOrig.filter(story => {
      const playoutDateTime = story?.playoutDateTime;
      return (
        playoutDateTime &&
        startDateFilter &&
        endDateFilter &&
        new Date(playoutDateTime) >= startOfDay(startDateFilter) &&
        new Date(playoutDateTime) <= endOfDay(endDateFilter)
      );
    });
    setRundowns(newStorypool);
  };

  return (
    <Box display="grid" gridAutoFlow="row" width="100%" gridGap={10}>
      <TitleBarWraper>
        <Grid item xs={6}>
          <TitleWaper
            title={i18n.t('links.sidebar.rundowns')}
            subText={i18n.t('pages.stories.formLabel.title')}
            icon={<Dns color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <BtnWrapper>
            <ProtectedButton
              data-test-id="add"
              lnsResource={Resource.RUNDOWN}
              lnsAction={Action.CREATE}
              color="secondary"
              variant="contained"
              onClick={onAdd}
            >
              {i18n.t('pages.rundowns.button.add')}
            </ProtectedButton>
            <ProtectedButton
              data-test-id="add-series"
              lnsResource={Resource.RUNDOWN}
              lnsAction={Action.CREATE}
              color="secondary"
              variant="contained"
              onClick={onAddSeries}
            >
              {i18n.t('pages.rundowns.button.addSeries')}
            </ProtectedButton>
          </BtnWrapper>
        </Grid>
      </TitleBarWraper>
      <TitleBarWraper>
        <Grid item xs={6}>
          <TitleWaper
            title="Date Ranges"
            subText=""
            icon={<DateRange color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <BtnWrapper>
            <DatePicker
              autoOk
              variant="inline"
              name="startDate"
              inputVariant="outlined"
              label="Start Date"
              format="MM/dd/yyyy"
              value={startDateFilter}
              onChange={date => setStartDateFilter(date)}
            />
            <Box>To</Box>
            <DatePicker
              autoOk
              variant="inline"
              name="endDate"
              inputVariant="outlined"
              label="End Date"
              format="MM/dd/yyyy"
              minDate={startDateFilter}
              value={endDateFilter}
              onChange={date => setEndDateFilter(date)}
            />
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={filterRundown}
              >
                Apply
              </Button>
            </Grid>
          </BtnWrapper>
        </Grid>
      </TitleBarWraper>
      {/* search */}
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <BasicTextField
            className={classes.input}
            placeholder="Search ..."
            onChange={onSearchFieldChange}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <Search />
          </IconButton>
        </Paper>
      </Grid>
      {/* search */}
      {/* Table */}
      <ListRundowns
        rundowns={rundowns}
        onEdit={onEdit}
        onShow={onShow}
        onRowClick={onRowClick}
        onEditStories={onEditStories}
      />

      {/* Overlay Form */}
      {editRundown && (
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <Form
            rundown={editRundown}
            onSave={onSave}
            onCancel={onEditCancel}
            series={series}
          />
        </Dialog>
      )}
    </Box>
  );
};

export default Rundowns;
