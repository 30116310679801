import mediaItemsJson from 'stories/fixtures/media-items.json';

export const updateMediaItemsInLS = (mediaItems: $Lns.MediaItem[]): boolean => {
  const mediaString = JSON.stringify(mediaItems);
  localStorage.setItem('mediaItems', mediaString);
  return true;
};

export const fetchMediaItemsFromLS = (): Promise<{
  data: $Lns.MediaItem[];
}> => {
  const mediaString = localStorage.getItem('mediaItems');

  if (mediaString) {
    return Promise.resolve({ data: JSON.parse(mediaString) });
  }

  const mediaItemsString = JSON.stringify(mediaItemsJson.data);
  localStorage.setItem('mediaItems', mediaItemsString);

  return Promise.resolve(mediaItemsJson);
};
