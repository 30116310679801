import { FunctionComponent, useState } from 'react';
import { resetPassword } from 'pages/password-reset/actions';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import Form from 'pages/password-reset/components/Form';
import i18n from 'i18n';
import toggleNotification from 'actions/notifications';
import Wrapper from 'pages/password-reset/components/Wrapper';

const PasswordReset: FunctionComponent = () => {
  const [isLoading, toggleLoading] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSave = (password: string) => {
    toggleLoading(true);
    resetPassword(password, token)
      .then(({ message }) => {
        toggleLoading(false);
        if (message === 'success') {
          dispatch(
            toggleNotification(
              i18n.t('notifications.passwordReset.success'),
              'success'
            )
          );
          navigate('/signin');
        } else {
          dispatch(
            toggleNotification(
              i18n.t('notifications.passwordReset.error'),
              'error'
            )
          );
        }
      })
      .catch(() => {
        toggleLoading(false);
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  };

  const onCancel = () => {
    navigate('/signin');
  };

  return (
    <Wrapper>
      <Form onSave={onSave} isLoading={isLoading} onCancel={onCancel} />
    </Wrapper>
  );
};

export default PasswordReset;
