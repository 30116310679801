import { callLnsApi } from 'utils/fetch';

export const fetchLocations = (): Promise<{
  code: number;
  data: $Lns.Location[];
}> => callLnsApi('/location');

export const createLocation = (
  location: $Lns.Location
): Promise<{
  code: number;
  data: $Lns.Location;
}> => callLnsApi('/location', 'POST', location);

export const deleteLocation = (
  location: $Lns.Location
): Promise<{
  code: number;
  message?: string;
}> => callLnsApi(`/location/${location.guid}`, 'DELETE');

export const updateLocation = (
  location: $Lns.Location
): Promise<{
  code: number;
  data: $Lns.Location;
}> => callLnsApi(`/location/${location.guid}`, 'POST');
