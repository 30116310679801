import { PaletteType } from '@material-ui/core';
import colors from 'styles/colors';

const themeStyle = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 719,
      md: 1023,
      lg: 1200,
      xl: 1920
    }
  },
  typography: {
    h1: {
      color: colors.neutral[80]
    },
    h2: {
      color: colors.neutral[80]
    },
    h3: {
      color: colors.neutral[80]
    },
    h4: {
      color: colors.neutral[80]
    },
    fontSize: 14,
    fontFamily: 'Work Sans'
  },
  palette: {
    type: 'light' as PaletteType,
    success: {
      light: colors.success.light,
      main: colors.success.medium,
      dark: colors.success.dark
    },
    warning: {
      light: colors.warning.light,
      main: colors.warning.medium,
      dark: colors.warning.dark
    },
    info: {
      light: colors.info.light,
      main: colors.info.medium,
      dark: colors.info.dark
    },
    error: {
      light: colors.error.light,
      main: colors.error.medium,
      dark: colors.error.dark
    },
    primary: {
      main: colors.primary[500],
      light: colors.primary[100],
      dark: colors.primary[900],
      contrastText: colors.neutral[0]
    },
    secondary: {
      main: colors.secondary[500],
      light: colors.secondary[100],
      dark: colors.secondary[900],
      contrastText: colors.neutral[0]
    },
    text: {
      secondary: colors.neutral[40]
    }
  },
  overrides: {
    // Style sheet name ⚛️
    MuiTabs: {
      root: {
        '& .MuiTabs-scroller': {
          width: 0
        }
      }
    },
    MuiFormLabel: {
      root: {
        '&.Mui-error': {
          color: colors.error.dark
        }
      }
    },
    MuiCheckbox: {
      root: {
        color: colors.primary[500]
      }
    },
    MuiTableCell: {
      root: {
        fontWeight: 600
      },
      sizeSmall: {
        padding: '10px 10px',
        fontWeight: 600
      }
    },
    MuiTypography: {
      body1: {
        fontWeight: 600
      }
    },
    MuiButton: {
      // Name of the rule
      containedSecondary: {
        '&:hover': {
          backgroundColor: colors.secondary[500],
          boxShadow: `0px 3px 3px ${colors.secondary[200]} !important`
        },
        color: 'white'
      },
      outlinedSecondary: {
        color: colors.neutral[80],
        borderColor: colors.backgrounds.secondary,
        '&:hover': {
          boxShadow: `0px 3px 3px ${colors.secondary[200]} !important`
        }
      },
      root: {
        '&:disabled': {
          color: ''
        },
        boxShadow: 'none !important',
        fontWeight: 'normal',
        textTransform: 'none',
        '&.Mui-disabled': {
          background: `${colors.neutral[5]} !important`,
          border: '1px solid rgba(0, 0, 0, 0.1)'
        }
      }
    }
  }
};

export default themeStyle;
