import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import colors from 'styles/colors';

const CopyRight: FunctionComponent = () => {
  return (
    <Box
      position="fixed"
      padding={0}
      bottom={2}
      width="100%"
      bgcolor={colors.backgrounds.grey}
    >
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        {new Date().getFullYear()} IOMedia Group
      </Typography>
    </Box>
  );
};

export default CopyRight;
