import { callSsoApi } from 'utils/fetch';

export const resetPassword = (
  newPassword: string,
  token: string
): Promise<{ message: string }> => {
  const data = {
    new_password: newPassword
  };

  return callSsoApi(`/password-reset/reset/${token}`, 'POST', data);
};

export const verifyEmail = (
  email: string
): Promise<{ token?: string; message?: string }> => {
  return callSsoApi('/password-reset/send-token', 'POST', { email });
};
