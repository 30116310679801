import { Route, Routes } from 'react-router';
import PasswordResetShow from 'pages/password-reset/show';
import EditPasswordReset from 'pages/password-reset/edit';
import PasswordReset from 'pages/password-reset';
import { FunctionComponent } from 'react';

const PasswordResetRoutes: FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/*" element={<PasswordReset />} />
      <Route path="/success" element={<PasswordResetShow />} />
      <Route path="/:token" element={<EditPasswordReset />} />
    </Routes>
  );
};

export default PasswordResetRoutes;
