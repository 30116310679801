import { callLnsApi } from 'utils/fetch';

export const createRundownTemplate = (
  rundownTemplate: $Lns.RundownTemplate
): Promise<{
  code: number;
  data: $Lns.RundownTemplate;
}> => {
  return callLnsApi('/rundown-template/', 'POST', rundownTemplate);
};

export const fetchRundownTemplates = (): Promise<{
  code: number;
  data: $Lns.RundownTemplate[];
}> => callLnsApi('/rundown-template');

export const updateRundownTemplate = (
  rundownTemplate: $Lns.RundownTemplate
): Promise<{
  code: number;
  data: $Lns.RundownTemplate;
}> =>
  callLnsApi(
    `/rundown-template/${rundownTemplate.guid}`,
    'POST',
    rundownTemplate
  );

export const deleteRundownTemplate = (
  rundownTemplate: $Lns.RundownTemplate
): Promise<{
  code: number;
}> => callLnsApi(`/rundown-template/${rundownTemplate.guid}`, 'DELETE');
