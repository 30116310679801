import { ChangeEvent, FunctionComponent } from 'react';
import {
  Avatar,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import i18n from 'i18n';
import Loading from 'components/Loading';
import { snakeToSpaces } from 'utils/string';
import useStyles from 'pages/user/components/styles';

interface HeroPanelI {
  user: $Lns.User;
  userStates: $Lns.UserState[];
  canEdit: boolean;
  onSaveCallback?: (newStatus: string) => void;
}

const HeroPanel: FunctionComponent<HeroPanelI> = ({
  user,
  userStates,
  canEdit,
  onSaveCallback
}: HeroPanelI) => {
  const classes = useStyles();
  const status = user?.userState || userStates[0]?.guid;

  const handleChange = (e: ChangeEvent<{ value: unknown }>) => {
    if (onSaveCallback) onSaveCallback(e.target.value as string);
  };

  return (
    <Box padding={4} display="flex" alignItems="center">
      <Avatar
        variant="circle"
        className={classes.largeAvatar}
        alt={user.username}
        style={{ fontSize: '44px' }}
      >
        {user.username[0]}
      </Avatar>
      {(!userStates || (userStates && !(userStates.length > 0))) && <Loading />}
      {userStates && userStates.length > 0 && (
        <Box padding={2}>
          <Typography variant="h4">{user.username}</Typography>
          <br />
          <FormControl
            variant="outlined"
            disabled={!canEdit}
            className={classes.statusControl}
          >
            <InputLabel id="select-status-label">
              {i18n.t('pages.users.statuses.label')}
            </InputLabel>
            <Select
              labelId="select-status-label"
              id="select-status"
              value={status}
              onChange={handleChange}
              label={i18n.t('pages.users.statuses.label')}
            >
              {userStates.map(item => (
                <MenuItem key={item.guid} value={item.guid}>
                  {snakeToSpaces(item.state)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </Box>
  );
};

export default HeroPanel;

HeroPanel.defaultProps = {
  onSaveCallback: undefined
};
