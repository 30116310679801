// @ts-nocheck
import { Resource, Action, RESOURCE_ACTIONS } from './resourceActions';
import { Groups } from './groups';

const createPermissionsGrid = isEnabled => {
  return Object.keys(RESOURCE_ACTIONS).reduce((accumulator, resource) => {
    return {
      ...accumulator,
      [resource]: RESOURCE_ACTIONS[resource].reduce((accumulator, action) => {
        return { ...accumulator, [action]: isEnabled };
      }, {})
    };
  }, {});
};

const ALL_ENABLED = createPermissionsGrid(true);

const ALL_DISABLED = createPermissionsGrid(false);

const removeDelete = permissions => {
  const newPerms = {};
  Object.keys(permissions).forEach(resource => {
    newPerms[resource] = {
      ...permissions[resource],
      ...(permissions[resource][Action.DELETE] && { [Action.DELETE]: false })
    };
  });
  return newPerms;
};

const someResourcesEnabled = resources => {
  const perms = { ...ALL_DISABLED };
  resources.forEach(resource => {
    perms[resource] = ALL_ENABLED[resource];
  });
  return perms;
};

const someResourcesDisabled = resources => {
  const perms = { ...ALL_ENABLED };
  resources.forEach(resource => {
    perms[resource] = ALL_DISABLED[resource];
  });
  return perms;
};

const someActionsEnabled = actions => {
  const perms = { ...ALL_DISABLED };
  Object.keys(perms).forEach(resource => {
    actions.forEach(action => {
      if (action in perms[resource]) {
        perms[resource][action] = true;
      }
    });
  });
  return perms;
};

const overrideSomePermissions = (targetPermissions, overrrides) => {
  const perms = { ...targetPermissions };
  Object.keys(perms).forEach(resource => {
    if (resource in overrrides)
      perms[resource] = { ...perms[resource], ...overrrides[resource] };
  });
  return perms;
};

const INITIAL_PERMISSIONS = {
  [Groups.SYSTEM_MANAGERS]: ALL_ENABLED,
  [Groups.SUPER_USERS]: removeDelete(ALL_ENABLED),
  [Groups.EDITORS]: removeDelete(
    someResourcesEnabled([Resource.STORY, Resource.RUNDOWN])
  ),
  [Groups.OPERATORS]: removeDelete(
    someResourcesDisabled([Resource.STORY, Resource.RUNDOWN])
  ),
  [Groups.STANDARD_USERS]: overrideSomePermissions(
    someActionsEnabled([Action.READ, Action.LIST]),
    {
      [Resource.STORY]: {
        [Action.CREATE]: true,
        [Action.EDIT]: true,
        [Action.DELETE]: true
      },
      [Resource.TASK]: {
        [Action.CREATE]: true,
        [Action.EDIT]: true,
        [Action.DELETE]: true
      }
    }
  )
};

export default INITIAL_PERMISSIONS;
