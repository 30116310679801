import React, { FunctionComponent, ReactNode, useState } from 'react';
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import clsx from 'clsx';
import i18n from 'i18n';
import mainLayoutStyles from 'styles/mainLayout';
import MainSidebar from 'components/MainSidebar';
import BreadCrumbs from 'components/Breadcrumbs';
import CopyRight from 'components/CopyRight';
import logo from 'images/lns-logo-white.png';
import { useCanAccess } from 'components/Authorization/utils';
import { Resource, Action } from 'components/Authorization';
import { setRTL } from 'actions';

interface MainLayoutI {
  children: ReactNode;
  onHandleLogout: () => void;
}

const MainLayout: FunctionComponent<MainLayoutI> = ({
  onHandleLogout,
  ...props
}: MainLayoutI) => {
  const navigate = useNavigate();
  const canAccess = useCanAccess(Resource.SECURITY, Action.READ);

  const { currentSsoUser, dir } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const classes = mainLayoutStyles();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const profileMenuOpen = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onToggleProfile = () => {
    if (canAccess) {
      navigate(`/administration/security/users/${currentSsoUser.guid}`);
    } else {
      navigate(`/user/${currentSsoUser.guid}`);
    }
  };

  const dispatch = useDispatch();

  const onToggleRTL = () => {
    const d = (dir ?? i18n.dir()) === 'ltr' ? 'rtl' : 'ltr';
    dispatch(setRTL(d));
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <Box flexGrow={1}>
            <img src={logo} width="50px" alt="Lns Logo" />
          </Box>
          <Typography variant="caption">
            v{process.env.REACT_APP_VERSION}
          </Typography>

          {currentSsoUser && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar>{currentSsoUser.username[0]}</Avatar>
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={profileMenuOpen}
                onClose={handleClose}
              >
                <MenuItem dense onClick={onToggleRTL}>
                  {(dir ?? i18n.dir()).toUpperCase()}
                </MenuItem>
                <MenuItem dense onClick={onToggleProfile}>
                  {i18n.t('links.navbar.profile')}
                </MenuItem>
                <MenuItem dense onClick={onHandleLogout}>
                  {i18n.t('links.navbar.logout')}
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>

      <MainSidebar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box mb={2}>
          <BreadCrumbs />
        </Box>
        {props.children}
        <CopyRight />
      </main>
    </div>
  );
};

export default MainLayout;
