import { ChangeEvent, FunctionComponent, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import CustomTextField from 'components/CustomTextField';
import { FormData } from 'pages/administration/security/groups/formData';
import i18n from 'i18n';

interface FormI {
  onSave: (formData: FormData) => void;
  onCancel: () => void;
  userGroup: FormData;
}

const Form: FunctionComponent<FormI> = ({
  userGroup,
  onSave,
  onCancel
}: FormI) => {
  const [formData, setFormData] = useState({
    id: userGroup?.id,
    guid: userGroup?.guid,
    name: userGroup?.name || '',
    errors: {
      name: ''
    }
  });

  const [BtnDisabledChange, setBtnDisabledChange] = useState(
    !!formData.name || !!Object.values(formData.errors).some(e => !!e)
  );

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedFormData = {
      ...formData,
      [name]: value,
      errors: {
        ...formData.errors
      }
    };
    toggleSaveBtn(updatedFormData, name);
    setFormData(updatedFormData);
  };

  const toggleSaveBtn = (updatedFormData: FormData, key: string) => {
    let checkFlg = true;
    const skipList = ['errors'];
    if (Object.keys(userGroup).includes(key)) {
      const tempFormData = { ...updatedFormData };
      checkFlg = checkFormDataState(userGroup, tempFormData, skipList);
      setBtnDisabledChange(checkFlg);
    }
  };

  const checkFormDataState = (
    formDataObj: FormData,
    updateFormData: FormData,
    skipList: string[]
  ) => {
    let checkFlg = true;
    Object.keys(updateFormData).forEach(pin => {
      if (
        pin in updateFormData &&
        pin in formDataObj &&
        !skipList.includes(pin)
      ) {
        const Val1 = updateFormData[pin as keyof typeof updateFormData];
        const Val2 = formDataObj[pin as keyof typeof formDataObj];
        if (Val1 !== Val2) {
          checkFlg = false;
        }
      }
    });
    return checkFlg;
  };

  const btnDisabled =
    !formData.name || !!Object.values(formData.errors).some(e => !!e);

  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CustomTextField
            onChange={onChangeField}
            value={formData.name}
            label={i18n.t('pages.administration.userGroups.formLabel.name')}
            name="name"
            error={!!formData?.errors.name}
            helperText={formData.errors.name || i18n.t('helperText.required')}
          />
        </Grid>

        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={btnDisabled || BtnDisabledChange}
              color="secondary"
              variant="contained"
              onClick={() => onSave(formData)}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;
