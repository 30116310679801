import { ChangeEvent, FunctionComponent, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { FormatBold, FormatSize, TextFormat } from '@material-ui/icons';
import i18n from 'i18n';
import {
  FONT_FAMILIES,
  FONT_SIZES,
  FONT_WEIGHTS
} from 'constants/fontSettings';
import { FontType } from 'pages/user/types';
import useStyles from 'pages/user/components/styles';

interface EditLayoutI {
  user: $Lns.User;
  canEdit: boolean;
  onSaveCallback?: (newLayout: { font: FontType }) => void;
}

const EditLayout: FunctionComponent<EditLayoutI> = ({
  user,
  canEdit,
  onSaveCallback
}: EditLayoutI) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);

  const [fontSettings, setFontSettings] = useState<FontType>(
    user.config.layoutSettings.font
  );

  const toggleIsEdit = () => setIsEdit(!isEdit);
  const noDataString = i18n.t('pages.users.formHelperText.noData');

  const onSave = () => {
    if (onSaveCallback) onSaveCallback({ font: fontSettings });
    toggleIsEdit();
  };

  const handleSelect = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (e?.target?.name)
      setFontSettings({
        ...fontSettings,
        [e.target.name]: e.target.value
      });
  };

  return (
    <>
      <Box className={classes.titleWrapperBox}>
        <Typography variant="h6" className={classes.grayPaperTitle}>
          {i18n.t('pages.users.formLabel.layout')}
        </Typography>
        {canEdit && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={!isEdit ? toggleIsEdit : onSave}
            >
              {!isEdit && i18n.t('button.edit')}
              {isEdit && i18n.t('button.save')}
            </Button>
            {isEdit && (
              <Box paddingLeft={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={toggleIsEdit}
                >
                  {i18n.t('button.cancel')}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {!isEdit && (
        <List>
          <ListItem>
            <ListItemIcon>
              <TextFormat />
            </ListItemIcon>
            <ListItemText
              primary={fontSettings?.fontFamily || noDataString}
              secondary={i18n.t('pages.users.formLabel.fontFamily')}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FormatSize />
            </ListItemIcon>
            <ListItemText
              primary={fontSettings?.fontSize || noDataString}
              secondary={i18n.t('pages.users.formLabel.fontSize')}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <FormatBold />
            </ListItemIcon>
            <ListItemText
              primary={fontSettings?.fontWeight || noDataString}
              secondary={i18n.t('pages.users.formLabel.fontWeight')}
            />
          </ListItem>
        </List>
      )}
      {isEdit && (
        <Box padding={2}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-fontFamily-label">
              {i18n.t('pages.users.formLabel.fontFamily')}
            </InputLabel>
            <Select
              labelId="select-fontFamily-label"
              id="select-fontFamily"
              value={fontSettings?.fontFamily || FONT_FAMILIES[0]}
              onChange={handleSelect}
              name="fontFamily"
              label={i18n.t('pages.users.formLabel.fontFamily')}
            >
              {FONT_FAMILIES.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-fontSize-label">
              {i18n.t('pages.users.formLabel.fontSize')}
            </InputLabel>
            <Select
              labelId="select-fontSize-label"
              id="select-fontSize"
              value={fontSettings?.fontSize || 1}
              onChange={handleSelect}
              name="fontSize"
              label={i18n.t('pages.users.formLabel.fontSize')}
            >
              {FONT_SIZES.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-fontWeight-label">
              {i18n.t('pages.users.formLabel.fontWeight')}
            </InputLabel>
            <Select
              labelId="select-fontWeight-label"
              id="select-fontWeight"
              value={fontSettings?.fontWeight || 400}
              onChange={handleSelect}
              name="fontWeight"
              label={i18n.t('pages.users.formLabel.fontWeight')}
            >
              {FONT_WEIGHTS.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default EditLayout;

EditLayout.defaultProps = {
  onSaveCallback: undefined
};
