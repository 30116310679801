import { secondsToDuration } from 'utils/time';
import { ImageAsset } from 'pages/stories/interfaces/imageAsset';
import { ScriptRows } from 'pages/stories/interfaces/scriptRow';
import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';
import { ScriptItem } from 'pages/stories/interfaces/scriptItem';
import { AssetType } from 'pages/stories/enums';
import { ScriptAsset } from '../interfaces/scriptAsset';

interface AssetsTimeline extends AssetDynamic {
  index?: number;
}

export const calculateRelativeTime = (
  asset: AssetsTimeline,
  childAssets: AssetsTimeline[]
): number => {
  let counterI = 0;
  let counterII = 0;

  if (asset.assetI) {
    counterI = +asset.assetI.durationInSeconds;
  } else if (asset.assetS) {
    counterI = +computeSpeechRate(asset?.assetS);
  }

  childAssets.forEach(currentAsset => {
    if (currentAsset.assetS) {
      const timeSec = computeSpeechRate(currentAsset.assetS);
      counterII += timeSec;
    }
    if (currentAsset.assetI) {
      const timeSec = +currentAsset.assetI.durationInSeconds;
      counterII += timeSec;
    }
  });
  let totalSecs = 0;

  if (counterI > counterII) totalSecs = counterI;
  else totalSecs = counterII;
  return totalSecs;
};

const updateAssetRelativeTime = (
  inputAssetItem: AssetsTimeline,
  typeAObj: ImageAsset | ScriptAsset
) => {
  const assetItem = { ...inputAssetItem };

  if (assetItem.assetS)
    assetItem.assetS = {
      ...assetItem.assetS,
      relativeStartTime: typeAObj.relativeStartTime
    };
  if (assetItem.assetI)
    assetItem.assetI = {
      ...assetItem.assetI,
      relativeStartTime: typeAObj.relativeStartTime
    };
  return assetItem;
};

export const setParentRelativeTime = (
  assets: AssetsTimeline[]
): AssetsTimeline[] => {
  let relativeTime = 0;
  return assets.map((item, key) => {
    let assetItem = { ...item };
    const typeAObj = assetItem.assetS || assetItem.assetI;
    const hasParent = isSelfChild(assetItem, assets, key);
    if (hasParent) {
      return assetItem;
    }

    const childObject = buildChilds(assetItem, assets, key);

    if (key !== 0)
      relativeTime += calculateRelativeTime(assetItem, childObject);

    if (typeAObj && typeAObj.relativeStartTime) {
      typeAObj.relativeStartTime = secondsToDuration(relativeTime);
      const updatesAsset = updateAssetRelativeTime(assetItem, typeAObj);
      assetItem = { ...updatesAsset };
    }
    return assetItem;
  });
};

export const setChildRelativeTime = (
  assets: AssetsTimeline[]
): AssetsTimeline[] => {
  return assets.map((item, key) => {
    let assetItem = { ...item };
    const hasParent = isSelfChild(assetItem, assets, key);
    if (hasParent) {
      const typeAObj = hasParent.assetS || hasParent.assetI;
      if (typeAObj && typeAObj.relativeStartTime) {
        const updatesAsset = updateAssetRelativeTime(assetItem, typeAObj);
        assetItem = { ...updatesAsset };
      }
      return assetItem;
    }

    return assetItem;
  });
};

export const isSelfChild = (
  dynamicAsset: AssetDynamic,
  dynamicAssets: AssetsTimeline[],
  key: number
): AssetsTimeline | undefined => {
  const position = getPosition(dynamicAsset);

  const itSelfChild = dynamicAssets.find((currentAsset, keyI) => {
    return (
      keyI < key &&
      keyI !== key &&
      ((currentAsset.assetI &&
        currentAsset.assetI.positionInScript === position) ||
        (currentAsset.assetS &&
          currentAsset.assetS.positionInScript === position))
    );
  });

  return itSelfChild;
};

export const filteredDynamicAssets = (
  child: boolean | undefined,
  dynamicAssets: AssetDynamic[],
  index: number
): AssetDynamic[] => {
  const updatedDynamicAssets = [] as AssetDynamic[];
  let removeAssetHasChilds = false;

  dynamicAssets.forEach((asset, key) => {
    const tempDynamicAsset = { ...asset } as AssetDynamic;
    let hasChild = false;

    if (key >= index) {
      const childObjects = buildChilds(asset, dynamicAssets, key);

      if (childObjects.length > 0) {
        hasChild = true;
        if (index === key) {
          removeAssetHasChilds = true;
        }
      }
    }

    if (key > index && child === undefined && !hasChild) {
      if (tempDynamicAsset.assetI && !removeAssetHasChilds) {
        tempDynamicAsset.assetI = {
          ...tempDynamicAsset.assetI,
          positionInScript: tempDynamicAsset.assetI.positionInScript - 1
        };
      }
      if (tempDynamicAsset.assetS && !removeAssetHasChilds) {
        tempDynamicAsset.assetS = {
          ...tempDynamicAsset.assetS,
          positionInScript: tempDynamicAsset.assetS.positionInScript - 1
        };
      }
    }
    updatedDynamicAssets.push(tempDynamicAsset);
  });

  return updatedDynamicAssets;
};

export const buildChilds = (
  asset: AssetDynamic,
  dynamicAssets: AssetsTimeline[],
  key: number
): AssetsTimeline[] => {
  const childObject = [] as AssetsTimeline[];
  const position = getPosition(asset);

  dynamicAssets.forEach((currentAsset, keyI) => {
    if (
      keyI > key &&
      currentAsset.assetS &&
      ((currentAsset.assetS &&
        position === currentAsset.assetS.positionInScript) ||
        (currentAsset.assetS &&
          currentAsset.assetS.positionInScript === position))
    ) {
      childObject.push({ ...currentAsset, index: keyI });
    }
  });

  return childObject;
};

export const buildScriptRows = (initVal: AssetDynamic[]): ScriptRows[] => {
  const scriptRows = [] as ScriptRows[];
  let initRelativeTime = 0;
  initVal.forEach((dynamicAssetItem, key) => {
    let scriptObj = {} as ScriptRows;
    const typeAObj = dynamicAssetItem.assetS || dynamicAssetItem.assetI;
    const selfChildVal = isSelfChild(dynamicAssetItem, initVal, key);
    if (typeAObj) scriptObj = { ...scriptObj, Asset: typeAObj };

    scriptObj.dialogues = [];
    if (!selfChildVal) {
      const childObject = buildChilds(dynamicAssetItem, initVal, key);

      const dialogues = childObject
        .filter(currentItem => !!currentItem.assetS)
        .map(dynamicAssetFilteredItem => {
          return dynamicAssetFilteredItem.assetS;
        }) as ScriptAsset[];

      const fromTime = initRelativeTime;
      if (key !== 0)
        initRelativeTime += calculateRelativeTime(
          dynamicAssetItem,
          childObject
        );
      const relativeTime = computeRelativeTime(fromTime, initRelativeTime);

      scriptObj = {
        ...scriptObj,
        dialogues,
        relativeTime,
        id: key
      };
      scriptRows.push(scriptObj);
    }
  });
  return scriptRows;
};

export const convertAssetToArray = (
  assets: AssetDynamic[]
): Array<ScriptAsset | ImageAsset> => {
  const arryFieldsAll = assets.map(assetItem => {
    if (assetItem.assetI) {
      return { ...assetItem.assetI } as ImageAsset;
    }

    return { ...assetItem.assetS } as ScriptAsset;
  });

  return arryFieldsAll;
};

export const convertArrayToAsset = (
  scriptItems: Array<ScriptAsset | ImageAsset>
): AssetDynamic[] => {
  const assetTypes = [AssetType.image, AssetType.video, AssetType.audio];

  const newDynamicAssets = scriptItems.map((scriptItem: ScriptItem) => {
    if (assetTypes.toString().includes(scriptItem.type)) {
      const assetI = { ...scriptItem } as ImageAsset;
      return { assetI };
    }
    const assetS = { ...scriptItem } as ScriptAsset;
    return { assetS };
  });

  return newDynamicAssets;
};

const computeRelativeTime = (
  fromTime: number,
  relativeTime: number
): string => {
  return secondsToDuration(fromTime)
    .concat(' - ')
    .concat(secondsToDuration(relativeTime));
};

const computeSpeechRate = (asset: ScriptAsset) => {
  return (
    asset.script.text.split(' ').length / asset.presenter.readRateWordsPerSecond
  );
};

const getPosition = (dynamicAsset: AssetDynamic) => {
  return (
    dynamicAsset.assetI?.positionInScript ||
    dynamicAsset.assetS?.positionInScript ||
    0
  );
};
