import { Alert, AlertTitle } from '@material-ui/lab';
import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { verifyEmail } from 'pages/email-confirmation/actions';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';

export const EmailConfirmation: FunctionComponent = () => {
  const [isLoading, toggleLoading] = useState(true);
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    verifyEmail(token)
      .then(({ message }) => {
        toggleLoading(false);
        if (message === 'success') {
          dispatch(
            toggleNotification(
              i18n.t('notifications.emailConfirmation.success'),
              'success'
            )
          );
          navigate('/signin');
        }
      })
      .catch(() => {
        toggleLoading(false);
      });
  }, [token, dispatch, navigate]);

  return isLoading ? null : (
    <Alert severity="error" data-test-id="error-unauthorized">
      <AlertTitle>EMAIL CONFIRMATION FAILED</AlertTitle>
      <strong>Unable to confirm email. Please contact support.</strong>
    </Alert>
  );
};
