import i18next from 'i18next';

export const languages = [
  { code: 'en', name: 'English' },
  { code: 'de', name: 'German' },
  { code: 'ar', name: 'Arabic' }
];

export const getLanguageName = (keyCode: string): string => {
  const searched = languages.filter(langItem => langItem.code === keyCode);
  if (searched && searched.length > 0) return searched[0].name;
  return languages[0].name;
};

export const i18nChangeLanguage = (newLocale: string): string => {
  if (!i18next.language || i18next.language !== newLocale)
    // eslint-disable-next-line
    i18next.changeLanguage(newLocale).catch(e => console.error(e));
  return newLocale;
};
