import { callLnsApi } from 'utils/fetch';

export const fetchAccessGroupsOfUserByGuid = (
  userGuid: string
): Promise<{ code: number; data: $Lns.UserGroup[] }> =>
  callLnsApi(`/user/${userGuid}/access-groups`);

export const fetchUserStates = (): Promise<{
  code: number;
  data: $Lns.UserState[];
}> => callLnsApi('/user-state');

export const fetchUserGroupByGuid = (
  userGroupGuid: string
): Promise<{
  code: number;
  data: {
    guid: string;
    name: string;
    users: $Lns.User[];
    lnsObjectPermissions: $Lns.LnsObjectPermission[];
  };
}> => callLnsApi(`/access/access-group/${userGroupGuid}`);
