import { FunctionComponent } from 'react';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import DefaultAdminActionField from 'pages/administration/components/DefaultAdminActionField';

interface ListI {
  protocols: $Lns.Protocol[];
  onShow: (userItem: $Lns.Protocol) => void;
  onEdit: (userItem: $Lns.Protocol) => void;
  onDelete: (userItem: $Lns.Protocol) => void;
}

const List: FunctionComponent<ListI> = ({
  protocols,
  onShow,
  onEdit,
  onDelete
}: ListI) => {
  const rows = [
    i18n.t('pages.administration.protocols.tableRow.name'),
    i18n.t('pages.administration.protocols.tableRow.classname'),
    i18n.t('tableRow.actions')
  ];

  const cells = protocols.map((protocolItem: $Lns.Protocol) => {
    return {
      fields: {
        name: { value: protocolItem.name },
        classname: { value: protocolItem.classname },
        actions: {
          value: (
            <DefaultAdminActionField
              target={protocolItem.name}
              onShow={() => onShow(protocolItem)}
              onEdit={() => onEdit(protocolItem)}
              onDelete={() => onDelete(protocolItem)}
            />
          )
        }
      }
    };
  });

  return <TableBuilder rows={rows} cells={cells} />;
};

export default List;
