import { ChangeEvent, FunctionComponent, useState, useRef } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import i18n from 'i18n';
import shortid from 'shortid';
import EmptyState from 'components/EmptyState';
import TopRightButton from 'components/TopRightButton';
import { FormData } from 'pages/administration/peripheral-settings/protocols/formData';
import { RowDataType } from 'pages/administration/peripheral-settings/protocols/components/types';
import ConfigRow from 'pages/administration/peripheral-settings/protocols/components/ConfigRow';

interface FormI {
  protocolItem?: $Lns.Protocol;
  onSave: (formData: FormData) => void;
  onCancel: () => void;
}

const Form: FunctionComponent<FormI> = ({
  protocolItem,
  onSave,
  onCancel
}: FormI) => {
  const [formData, setFormData] = useState<FormData>({
    guid: protocolItem?.guid || undefined,
    name: protocolItem?.name || '',
    classname: protocolItem?.classname || '',
    config: protocolItem?.config || {},
    errors: {
      name: '',
      classname: ''
    }
  });

  const [configListFields, setConfigListFields] = useState<number>(
    Object.keys(formData.config).length
  );

  const configList = useRef<RowDataType[]>(
    Object.keys(formData.config).map(keyName => {
      return {
        configName: keyName,
        configType: formData.config[keyName].type,
        configDescription: formData.config[keyName].description
      };
    })
  );

  const btnDisabled =
    Object.values(formData.errors).some(e => !!e) ||
    !formData.name ||
    !formData.classname;

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedFormData = {
      ...formData,
      [name]: value,
      errors: {
        ...formData.errors
      }
    };
    setFormData(updatedFormData);
  };

  const onAddConfig = () => {
    configList.current.push({
      configName: '',
      configType: 'string',
      configDescription: ''
    });
    setConfigListFields(configListFields + 1);
  };

  const onConfigItemChange = (index: number, rowData: RowDataType) => {
    configList.current[index] = rowData;
  };

  const handleOnSave = () => {
    const config: { [key: string]: { type: string; description: string } } = {};
    configList.current.forEach(item => {
      config[item.configName] = {
        type: item.configType,
        description: item.configDescription
      };
    });
    const aggregatedFormData = {
      ...formData,
      config
    };
    onSave(aggregatedFormData);
  };

  const handleOnDelete = (index: number) => {
    if (configListFields > 0) configList.current.splice(index, 1);
    else configList.current = [];
    setConfigListFields(configList.current.length);
  };

  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label={i18n.t('pages.administration.protocols.formLabel.name')}
            value={formData.name}
            helperText={i18n.t(
              'pages.administration.protocols.formHelperText.nameHelper'
            )}
            name="name"
            onChange={onChangeField}
            error={!!formData?.errors?.name}
            autoComplete="name"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="classname"
            label={i18n.t('pages.administration.protocols.formLabel.classname')}
            value={formData.classname}
            helperText={i18n.t(
              'pages.administration.protocols.formHelperText.classnameHelper'
            )}
            name="classname"
            onChange={onChangeField}
            error={!!formData?.errors?.classname}
            autoComplete="name"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h6">
                {i18n.t('pages.administration.protocols.formLabel.config')}
              </Typography>
            </Grid>
            <Grid item>
              <TopRightButton
                onClick={onAddConfig}
                label={i18n.t(
                  'pages.administration.protocols.button.addConfig'
                )}
              />
            </Grid>
          </Grid>
          <Box paddingTop={2}>
            <Divider />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {configListFields === 0 && (
            <EmptyState
              title={i18n.t('pages.administration.protocols.emptyConfig.title')}
              subTitle={i18n.t(
                'pages.administration.protocols.emptyConfig.subTitle'
              )}
            />
          )}
          {configList.current &&
            configList.current.map((configItem, index) => {
              return (
                <ConfigRow
                  key={shortid.generate()}
                  configName={configItem.configName}
                  configType={configItem.configType}
                  configDescription={configItem.configDescription}
                  onChangeCallback={rowData =>
                    onConfigItemChange(index, rowData)
                  }
                  onDeleteCallback={() => handleOnDelete(index)}
                />
              );
            })}
        </Grid>

        <Grid item container spacing={2} justify="flex-end">
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={btnDisabled}
              color="secondary"
              variant="contained"
              onClick={handleOnSave}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;

Form.defaultProps = {
  protocolItem: undefined
};
