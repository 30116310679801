import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  InputLabel
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import i18n from 'i18n';
import FileUpload from 'components/FileUpload';
import { uploadMedia } from 'actions/peripheral';
import toggleNotification from 'actions/notifications';

interface PeripheralI {
  storyLock: boolean;
  protocol: $Lns.Peripheral | undefined;
  peripheral: $Lns.Peripheral[];
  onSelectPeripheral: (peripheral: $Lns.Peripheral) => void;
}

const AssetUpload: FunctionComponent<PeripheralI> = ({
  protocol,
  peripheral,
  storyLock,
  onSelectPeripheral
}: PeripheralI) => {
  const dispatch = useDispatch();
  const [peripheralObj, setPeripheralObj] = useState(peripheral);
  const [selectedPref, setSelectedPref] = useState(protocol);

  useEffect(() => {
    setPeripheralObj(peripheral);
    if (protocol) {
      setSelectedPref(protocol);
    }
  }, [peripheral, protocol]);

  const onPreferalChange = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const { value } = e.target;
    const pref = peripheralObj?.find(pin => value === pin.guid);
    if (pref) {
      setSelectedPref(pref);
      onSelectPeripheral(pref);
    }
  };

  const onUploadAssetsFile = (assetFile: File) => {
    const data = new FormData();
    if (assetFile && selectedPref) {
      data.append('media', assetFile);
      uploadMedia(data, selectedPref?.guid)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.stories.assetUploaded'),
              'success'
            )
          );
        })
        .catch(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.stories.errorUpload'),
              'error'
            )
          );
        });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl margin="normal" variant="outlined" fullWidth>
          <InputLabel htmlFor="selectFormat-eyecandy">
            {i18n.t('pages.stories.placeHolder.peripheral')}
          </InputLabel>
          <Select
            fullWidth
            labelId="peripheral"
            id="peripheral"
            name="peripheral"
            label={i18n.t('pages.stories.placeHolder.peripheral')}
            required
            value={selectedPref?.guid || ''}
            onChange={pin => onPreferalChange(pin)}
            displayEmpty
          >
            {peripheralObj?.map(storyGenre => (
              <MenuItem key={storyGenre.guid} value={storyGenre.guid}>
                {storyGenre.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FileUpload storyLock={storyLock} onUploadHandle={onUploadAssetsFile} />
      </Grid>
    </Grid>
  );
};

export default AssetUpload;
