import { Box, InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import TableBuilder from 'components/Table';
import i18n from 'i18n';
import { ChangeEvent, FunctionComponent, useState } from 'react';

interface MultiSelectI {
  list: $Lns.Rundown[];
  highlighted?: $Lns.Rundown[];
  onClick: (rowItem: { guid: { value: string } }) => void;
}

const MultiSelect: FunctionComponent<MultiSelectI> = ({
  list,
  highlighted,
  onClick
}: MultiSelectI) => {
  const [filteredStories, setFilteredStories] = useState(list);

  const isHighlighted = (rundown: $Lns.Rundown): boolean => {
    if (!highlighted) return false;

    return !!highlighted.find(
      currentStory => currentStory.guid === rundown.guid
    );
  };

  const rows = [i18n.t('pages.rundowns.tableRow.name')];

  const cells = filteredStories.map(rundown => {
    return {
      selected: isHighlighted(rundown),
      fields: {
        title: { value: rundown.name },
        guid: { value: rundown.guid, hidden: true }
      }
    };
  });

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newRundown = list.filter(rundown => {
      return rundown.name.includes(search);
    });
    setFilteredStories(newRundown);
  };

  const searchPlaceholder = i18n.t('placeholders.search');

  return (
    <Box display="grid" gridGap={10}>
      <TextField
        variant="outlined"
        size="small"
        placeholder={searchPlaceholder}
        onChange={onSearchFieldChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="primary" />
            </InputAdornment>
          )
        }}
      />
      <TableBuilder rows={rows} cells={cells} onRowClick={onClick} />
    </Box>
  );
};

export default MultiSelect;

MultiSelect.defaultProps = {
  highlighted: undefined
};
