import { addDays } from 'date-fns';
import { updateFormData, updateFormKeyValue } from 'utils/form';
import {
  FormData,
  defaultState as defaultFormData
} from 'pages/assignments/formData';
import { findTaskStateGuidByName } from 'pages/assignments/utils';
import { validateForm } from 'pages/assignments/components/DialogForm/utils';
import {
  Action,
  State
} from 'pages/assignments/components/DialogForm/reducerTypes';

export const createInitialStates = (
  propAssignment: FormData,
  currentLnsUser: $Lns.User,
  isAdmin: boolean,
  taskStates: $Lns.TaskState[]
): State => {
  const { guid, assignedByObj: propsAssignedByObj } = propAssignment;

  const isCreation = !guid;

  const assignedByObj =
    isCreation || !propsAssignedByObj ? currentLnsUser : propsAssignedByObj;

  const now = new Date();

  const assignedAtObj = isCreation ? now : new Date(propAssignment.assignedAt);

  const dateDueObj = isCreation
    ? addDays(now, 1)
    : new Date(propAssignment.dateDue);

  const taskState = isCreation
    ? findTaskStateGuidByName('new', taskStates) || ''
    : propAssignment.taskState;

  const initialState: State = {
    formData: {
      // State Values that are submitted in this FORM
      ...defaultFormData,
      ...propAssignment,
      taskState,
      assignedByObj,
      assignedAtObj,
      dateDueObj
    },
    editStory: false,
    saveBtnDisabled: true
  };

  return initialState;
};

// return true if required fields are filled in
const checkFormDataRequiredFields = (formData: FormData): boolean => {
  return !!formData.title && formData.title.length > 0;
};

const checkFormDataStateChange = (
  formDataObj: FormData,
  updateFormDataObj: FormData,
  skipList: string[]
): boolean => {
  let checkFlg = true;
  Object.keys(updateFormDataObj).forEach(pin => {
    if (
      pin in updateFormDataObj &&
      pin in formDataObj &&
      !skipList.includes(pin)
    ) {
      const Val1 = updateFormDataObj[pin as keyof typeof updateFormDataObj];
      const Val2 = formDataObj[pin as keyof typeof formDataObj];
      if (Val1 !== Val2) {
        checkFlg = false;
      }
    }
  });
  return checkFlg;
};

const toggleSaveBtn = (
  prevFormData: FormData,
  nextFormData: FormData,
  key: string
): boolean => {
  // First Check for the required fields
  let passed = checkFormDataRequiredFields(nextFormData);
  if (!passed) return true; // disable is true is not passed

  // Check if the field experienced a change
  const skipList = ['errors'];
  if (Object.keys(prevFormData).includes(key)) {
    const tempFormData = { ...nextFormData };
    passed = checkFormDataStateChange(prevFormData, tempFormData, skipList);
    return passed;
  }
  return false;
};

export const reducer = (state: State, action: Action): State => {
  const { formData } = state;
  switch (action.type) {
    case 'onChangeHTMLInputElement': {
      const updatedFormData = updateFormData(
        action.payload,
        formData,
        validateForm
      );

      const { name } = action.payload.target;

      const saveBtnDisabled = toggleSaveBtn(
        state.formData,
        updatedFormData,
        name
      );

      return {
        ...state,
        formData: updatedFormData,
        saveBtnDisabled
      };
    }

    case 'onChangeSelectElement':
    case 'onChangeDateTime': {
      const updatedFormData = updateFormKeyValue(
        action.payload.key,
        action.payload.value,
        state.formData,
        validateForm
      );

      const saveBtnDisabled = toggleSaveBtn(
        state.formData,
        updatedFormData,
        action.payload.key
      );

      return {
        ...state,
        formData: updatedFormData,
        saveBtnDisabled
      };
    }

    case 'editStory': {
      return {
        ...state,
        editStory: action.payload
      };
    }

    case 'toggleSaveButton': {
      const saveBtnDisabled = checkFormDataRequiredFields(formData);
      if (saveBtnDisabled !== state.saveBtnDisabled)
        return {
          ...state,
          saveBtnDisabled
        };
      return state;
    }

    case 'forceSaveButton': {
      // if required fields are not filled in
      // save button can not be forced to be enabled
      const saveBtnDisabled =
        action.payload && !checkFormDataRequiredFields(state.formData);

      if (saveBtnDisabled !== state.saveBtnDisabled)
        return {
          ...state,
          saveBtnDisabled
        };
      return state;
    }

    default:
      return state;
  }
};
