import { makeStyles } from '@material-ui/core/styles';
import colors from 'styles/colors';

export default makeStyles(theme => ({
  grayPaper: {
    backgroundColor: colors.backgrounds.grey20
  },
  grayPaperTitle: {
    color: colors.primary[500]
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: '100%'
  },
  largeAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10)
  },
  titleWrapperBox: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  statusControl: {
    width: 180
  }
}));
