import { ChangeEvent, FunctionComponent, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { validateForm } from 'pages/administration/story-pools/utils';
import { FormData } from 'pages/administration/story-genres/formData';
import { updateFormData } from 'utils/form';
import CustomTextField from 'components/CustomTextField';
import i18n from 'i18n';

interface FormI {
  storyGenre: $Lns.StoryGenre;
  onSave: (formData: FormData) => void;
  onCancel: () => void;
}

const Form: FunctionComponent<FormI> = ({
  storyGenre,
  onSave,
  onCancel
}: FormI) => {
  const [formData, setFormData] = useState({
    name: storyGenre.name || '',
    guid: storyGenre.guid || '',
    key: storyGenre.key || '',
    errors: {
      key: '',
      name: '',
      guid: ''
    }
  });

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedFormData = updateFormData(e, formData, validateForm);
    const { name } = e.target;
    toggleSaveBtn(updatedFormData, name);
    setFormData(updatedFormData);
  };

  const toggleSaveBtn = (updatedFormData: FormData, key: string) => {
    let checkFlg = true;
    const skipList = ['errors'];
    if (Object.keys(storyGenre).includes(key)) {
      const tempFormData = { ...updatedFormData };
      checkFlg = checkFormDataState(storyGenre, tempFormData, skipList);
      setBtnDisabledChange(checkFlg);
    }
  };

  const checkFormDataState = (
    formDataObj: $Lns.StoryGenre,
    updateFormDataObj: FormData,
    skipList: string[]
  ) => {
    let checkFlg = true;
    Object.keys(updateFormDataObj).forEach(pin => {
      if (
        pin in updateFormDataObj &&
        pin in formDataObj &&
        !skipList.includes(pin)
      ) {
        const Val1 = updateFormDataObj[pin as keyof typeof updateFormDataObj];
        const Val2 = formDataObj[pin as keyof typeof formDataObj];
        if (Val1 !== Val2) {
          checkFlg = false;
        }
      }
    });
    return checkFlg;
  };

  const btnDisabled =
    Object.values(formData.errors).some(e => !!e) || !formData.name;

  const [BtnDisabledChange, setBtnDisabledChange] = useState(
    !!formData.name || !!Object.values(formData.errors).some(e => !!e)
  );

  return (
    <Box padding={4}>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <CustomTextField
            onChange={onChangeField}
            value={formData.name}
            label={i18n.t('pages.administration.storyGenres.formLabel.name')}
            name="name"
            error={!!formData?.errors.name}
            helperText={formData.errors.name || 'required field'}
          />
        </Grid>
        <Grid item>
          <CustomTextField
            onChange={onChangeField}
            value={formData.key}
            label={i18n.t('pages.administration.storyGenres.formLabel.key')}
            name="key"
            error={!!formData?.errors.key}
            helperText={formData.errors.key || 'required field'}
          />
        </Grid>
        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={btnDisabled || BtnDisabledChange}
              color="secondary"
              variant="contained"
              onClick={() => onSave(formData)}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;
