import { FunctionComponent } from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { Edit, Visibility } from '@material-ui/icons';
import i18n from 'i18n';
import { WithPermissions, Resource, Action } from 'components/Authorization';
import DeleteDialog from 'components/DeleteDialog';
import { FormData } from 'pages/assignments/formData';
import { useOpStates } from 'pages/assignments/utils';

interface ActionButtonsI {
  isAdmin: boolean;
  currentLnsUser: $Lns.User;
  assignment: FormData;
  onHandleView: (assignmentGuid: string) => void;
  onHandleEdit: (assignmentGuid: string) => void;
  onHandleDelete: (assignmentGuid: string) => void;
}

const ActionButtons: FunctionComponent<ActionButtonsI> = ({
  isAdmin,
  currentLnsUser,
  assignment,
  onHandleView,
  onHandleEdit,
  onHandleDelete
}: ActionButtonsI) => {
  const { guid, title } = assignment;

  const { canViewEdit, canDelete } = useOpStates(
    assignment,
    currentLnsUser,
    isAdmin
  );

  if (!guid) return <p>{i18n.t('badData')}</p>;

  return (
    <Box display="flex">
      <Box>
        <Tooltip
          title={i18n.t('pages.assignments.tooltip.view').toString()}
          aria-label="view"
        >
          <IconButton color="primary" onClick={() => onHandleView(guid)}>
            <Visibility />
          </IconButton>
        </Tooltip>
      </Box>
      <WithPermissions
        lnsResource={Resource.TASK}
        lnsAction={Action.EDIT}
        disable={!canViewEdit}
      >
        <Box>
          <Tooltip
            title={i18n.t('pages.assignments.tooltip.edit').toString()}
            aria-label="view"
          >
            <IconButton color="primary" onClick={() => onHandleEdit(guid)}>
              <Edit />
            </IconButton>
          </Tooltip>
        </Box>
      </WithPermissions>
      <WithPermissions
        lnsResource={Resource.TASK}
        lnsAction={Action.DELETE}
        disable={!canDelete}
      >
        <DeleteDialog
          lnsResource={Resource.TASK}
          lnsAction={Action.DELETE}
          onDelete={() => onHandleDelete(guid)}
          tooltip={i18n.t('pages.assignments.tooltip.delete')}
          target={title}
          targetDeleteTitle={i18n.t('deleteDialog.assignments.title')}
          targetDeletePrompt={i18n.t('deleteDialog.assignments.prompt')}
          targetDeleteWarning={i18n.t('deleteDialog.assignments.warning')}
          btnDisabled={!canDelete}
        />
      </WithPermissions>
    </Box>
  );
};

export default ActionButtons;
