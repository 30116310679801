import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const userStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      flexWrap: 'wrap'
    },
    divider: {
      width: '100%',
      clear: 'left',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);
