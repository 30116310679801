import { InputBaseComponentProps, TextField } from '@material-ui/core';
import i18n from 'i18n';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

interface CustomTextFieldI {
  name: string;
  helperText?: string;
  error: boolean;
  value: string | unknown;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  rows?: number;
  label: string;
  inputProps?: InputBaseComponentProps;
  required?: boolean;
  disabled?: boolean;
  type?: string;
  size?: 'small' | 'medium' | undefined;
  margin?: 'dense' | 'none' | 'normal' | undefined;
  className?: string | undefined;
}

const CustomTextField: FunctionComponent<CustomTextFieldI> = ({
  name,
  helperText,
  inputProps,
  error,
  type,
  value,
  required,
  disabled,
  label,
  rows,
  size,
  margin,
  className,
  onChange
}: CustomTextFieldI) => {
  const { dir } = useSelector((state: $Lns.DefaultState) => state.default);
  const direction = dir ?? i18n.dir();

  return (
    <TextField
      size={size}
      fullWidth
      variant="outlined"
      name={name}
      margin={margin}
      value={value}
      type={type}
      inputProps={inputProps}
      onChange={onChange}
      disabled={disabled}
      error={error}
      rows={rows || 1}
      multiline={!!rows && rows > 1}
      helperText={helperText}
      required={!!required}
      id={name}
      label={label}
      className={`${direction} ${className}`}
    />
  );
};

export default CustomTextField;

CustomTextField.defaultProps = {
  rows: undefined,
  helperText: undefined,
  required: false,
  disabled: false,
  onChange: pin => pin,
  inputProps: {},
  size: 'small',
  margin: 'normal',
  type: 'text',
  className: undefined
};
