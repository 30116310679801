import { FunctionComponent } from 'react';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import DefaultAdminActionField from 'pages/administration/components/DefaultAdminActionField';
import { WithPermissions, Resource, Action } from 'components/Authorization';

interface ListI {
  userGroups: $Lns.UserGroup[];
  onRowClick: (rowItem: {
    guid: { value: string };
    name: { value: string };
  }) => void;
  onEdit: (userGroupItem: $Lns.UserGroup) => void;
  onDelete: (userGroupItem: $Lns.UserGroup) => void;
}

const List: FunctionComponent<ListI> = ({
  userGroups,
  onRowClick,
  onEdit,
  onDelete
}: ListI) => {
  const rows = [
    i18n.t('pages.administration.userGroups.tableRow.name'),
    'GUID',
    i18n.t('tableRow.actions')
  ];

  const cells = userGroups.map((item: $Lns.UserGroup) => {
    return {
      fields: {
        name: { value: item.name },
        guid: { value: item.guid },
        actions: {
          value: (
            <WithPermissions
              lnsResource={Resource.SECURITY}
              lnsAction={Action.EDIT}
            >
              <DefaultAdminActionField
                target={item.name}
                onEdit={() => onEdit(item)}
                onDelete={() => onDelete(item)}
              />
            </WithPermissions>
          )
        }
      }
    };
  });

  return <TableBuilder rows={rows} cells={cells} onRowClick={onRowClick} />;
};

export default List;
