import { Dispatch } from 'react';
import { UPDATE_PERIPHERALS_STATUS } from 'redux/reducers/types';
import { TaskStates } from 'pages/assignments/consts';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';

export const updatePeripheralsStatus = (
  peripheralsStatus: $Lns.PeripheralsStatus
): Dispatch<() => void> => {
  return (
    dispatch: Dispatch<{
      type: string;
      peripheralsStatus: $Lns.PeripheralsStatus;
    }>
  ) => {
    dispatch({
      type: UPDATE_PERIPHERALS_STATUS,
      peripheralsStatus
    });
  };
};

export const toggleAssignmentNotification = (
  currentLnsUser: $Lns.User,
  assignment: $Lns.Task
): Dispatch<() => void> => {
  const { title, assignedTo, taskState } = assignment;

  const assignedToGuid =
    assignedTo && typeof assignedTo !== 'string' ? assignedTo.guid : assignedTo;

  const taskStateName =
    taskState && typeof taskState !== 'string' ? taskState.name : '';

  if (assignedToGuid === currentLnsUser.guid) {
    if (taskStateName === TaskStates.PENDING_ASSIGNMENT)
      return (dispatch: Dispatch<unknown>) => {
        const message = `${i18n.t(
          'notifications.assignments.taskAssigned'
        )} ${title}`;
        dispatch(toggleNotification(message, 'info'));
      };
    if (
      taskStateName === TaskStates.ASSIGNED ||
      taskStateName === TaskStates.IN_PROGRESS
    )
      return (dispatch: Dispatch<unknown>) => {
        const message = `${i18n.t(
          'notifications.assignments.taskChanged'
        )}:${title}`;
        dispatch(toggleNotification(message, 'info'));
      };
  }

  return (dispatch: Dispatch<unknown>) => {
    const message = `${i18n.t(
      'notifications.assignments.taskUnassigned'
    )} ${title}`;
    dispatch(toggleNotification(message, 'info'));
  };
};
