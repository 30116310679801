import React, { FunctionComponent, useEffect, useState } from 'react';
import toggleNotification from 'actions/notifications';
import Loading from 'components/Loading';
import Stories from 'pages/stories/components/List';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { DropResult } from 'react-beautiful-dnd';
import { fetchStoryPoolStories } from 'actions/storyPools';
import { Grid, Button, Box, Dialog } from '@material-ui/core';
import i18n from 'i18n';
import TitleBarWraper from 'components/TitleBarWraper';
import TitleWaper from 'components/TitleWraper';
import { deleteStory } from 'pages/stories/actions';
import { addStoryToRundown, fetchRundowns } from 'pages/rundowns/actions';
import MultiSelect from 'pages/story-pools/components/MultiSelect';
import { ProtectedButton, Resource, Action } from 'components/Authorization';

const ShowStoryPool: FunctionComponent = () => {
  const { guid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stories, setStories] = useState<$Lns.Story[]>([]);
  const [rundown, setRundown] = useState<$Lns.Rundown[]>([]);
  const [highlightedList, setHighlightedList] = useState<$Lns.Rundown[]>([]);
  const [isLoading, toggleLoading] = useState(false);
  const [showAddRundown, toggleShowAddRundown] = useState<$Lns.Story>();

  const storyPools = useSelector(
    (state: $Lns.DefaultState) => state.default.storyPools
  );

  const storyPool = storyPools.find(sp => {
    return sp.guid === guid;
  });

  useEffect(() => {
    toggleLoading(true);
    fetchStoryPoolStories(guid)
      .then(({ data }) => {
        toggleLoading(false);
        setStories(data);
      })
      .catch(() => {
        toggleLoading(false);
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyPools.storyFetchError'),
            'error'
          )
        );
      });
    fetchRundowns()
      .then(({ data }) => {
        setRundown(data);
      })
      .catch(() =>
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundowns.failedToLoad'),
            'error'
          )
        )
      );
  }, [dispatch, guid]);

  if (!storyPool) {
    dispatch(
      toggleNotification(i18n.t('notifications.storyPools.fetchError'), 'error')
    );
    navigate('/story-pools');
    return <></>;
  }

  if (stories.length < 1 && isLoading) return <Loading />;

  const onDragComplete = (result: DropResult) => {
    // todo replace
    // eslint-disable-next-line
    console.log(result);
  };

  const onEditStory = (story: $Lns.Story) => {
    navigate(`/my-lns/${story.guid}/edit`);
  };

  const gotoAddStory = () => {
    navigate(`/story-pools/${guid}/edit`);
  };

  const onDelete = (story: $Lns.Story) => {
    if (story.guid !== undefined) {
      deleteStory(story.guid).then(res => {
        if (res && res.code === 500) {
          dispatch(
            toggleNotification(
              res.message || i18n.t('pages.administration.storyPools.error'),
              'error'
            )
          );
        } else {
          dispatch(
            toggleNotification(
              i18n.t('notifications.storyPools.removeSuccess'),
              'success'
            )
          );
        }
        fetchSetStoryPools();
      });
    }
  };

  const onAddRundown = (story: $Lns.Story) => {
    toggleShowAddRundown(story);
  };

  const onRowClick = (rowItem: { guid: { value: string } }) => {
    const isSelected = highlightedList.find(pin => {
      return pin.guid === rowItem.guid.value;
    });

    if (isSelected) {
      const updatedList = highlightedList.filter(pin => {
        return pin.guid !== rowItem.guid.value;
      });
      setHighlightedList(updatedList);
    } else {
      const findItem = rundown.find(pin => {
        return pin.guid === rowItem.guid.value;
      });

      if (!findItem) return;
      const arryHighlited = [findItem, ...highlightedList];
      setHighlightedList(arryHighlited);
    }
  };

  const toggleAddRundown = () => {
    toggleShowAddRundown(undefined);
  };

  const fetchSetStoryPools = () => {
    toggleLoading(true);
    fetchStoryPoolStories(guid)
      .then(({ data }) => {
        toggleLoading(false);
        setStories(data);
      })
      .catch(() => {
        toggleLoading(false);

        dispatch(
          toggleNotification(
            i18n.t('notifications.storyPools.storyFetchError'),
            'error'
          )
        );
      });
  };

  const onCallSaveStoryToRundown = (index: number, list: $Lns.Rundown[]) => {
    toggleLoading(true);
    if (list.length > index && showAddRundown) {
      const input = { story: showAddRundown?.guid };
      addStoryToRundown(list[index].guid, input)
        .then(({ code }) => {
          if (code === 201) {
            onCallSaveStoryToRundown(index + 1, list);
            dispatch(
              toggleNotification(
                i18n.t('notifications.storyPools.createSuccess'),
                'success'
              )
            );
          }
        })
        .catch(() => {
          toggleLoading(false);

          dispatch(
            toggleNotification(
              i18n.t('notifications.storyPools.createError'),
              'error'
            )
          );
        });
    } else {
      toggleLoading(false);
      toggleAddRundown();
    }
  };

  const onSave = () => {
    if (highlightedList.length > 0)
      onCallSaveStoryToRundown(0, highlightedList);
  };

  return (
    <Grid item xs={12}>
      <Grid container direction="row" spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper title={storyPool.name} subText="Title" />
          </Grid>
          <Grid item xs={6}>
            <Box
              display="flex"
              gridTemplateColumns="max-content max-content"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              height="100%"
              gridGap={10}
            >
              <ProtectedButton
                color="secondary"
                variant="contained"
                onClick={gotoAddStory}
                lnsResource={Resource.STORY_POOL}
                lnsAction={Action.EDIT}
              >
                {i18n.t('pages.storyPools.button.edit')}
              </ProtectedButton>
            </Box>
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          <Stories
            stories={stories}
            storyPoolId={guid}
            onEditClick={onEditStory}
            onDragComplete={onDragComplete}
            onDeleteClick={onDelete}
            onAddRundown={onAddRundown}
          />
        </Grid>
      </Grid>
      {showAddRundown && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={toggleAddRundown}
          aria-labelledby="simple-dialog-title"
          open
        >
          <Box padding={4}>
            <Grid container direction="column" spacing={4}>
              <MultiSelect
                list={rundown}
                highlighted={highlightedList}
                onClick={onRowClick}
              />
              <Grid item container spacing={2} justify="flex-end">
                <Grid item>
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={toggleAddRundown}
                  >
                    {i18n.t('button.cancel')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button color="secondary" variant="outlined" onClick={onSave}>
                    {i18n.t('button.save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      )}
    </Grid>
  );
};

export default ShowStoryPool;
