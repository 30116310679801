import { FunctionComponent, ReactNode } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions, ThemeProvider } from '@material-ui/core';
import themeStyle from 'styles/theme';
import { useSelector } from 'react-redux';
import { DEFAULT_FONT_SIZE, DEFAULT_FONT_UNIT } from 'constants/fontSettings';

interface MainThemeI {
  children?: ReactNode;
}

const MainTheme: FunctionComponent = ({ children }: MainThemeI) => {
  const { currentLnsUser } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const fontSize = currentLnsUser?.config?.layoutSettings?.font?.fontSize;
  const fontUnit = fontSize || DEFAULT_FONT_UNIT;

  const themeSettings = {
    ...themeStyle,
    typography: {
      ...themeStyle.typography,
      fontSize: DEFAULT_FONT_SIZE * fontUnit
    }
  };

  const theme = createMuiTheme(themeSettings as unknown as ThemeOptions);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

MainTheme.defaultProps = {
  children: []
};

export default MainTheme;
