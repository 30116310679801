import { FunctionComponent, useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  Grid,
  Typography
} from '@material-ui/core';
import i18n from 'i18n';
import {
  fetchStory,
  fetchStoryHistory,
  updateStory
} from 'pages/stories/actions';
import { User } from 'interface';
import { useDispatch } from 'react-redux';
import toggleNotification from 'actions/notifications';
import { getInitialState } from 'pages/stories/utils';
import { StoryFormData } from 'pages/stories/interface';
import ActionDialog from 'components/ActionDialogue';
import { HistoryRounded } from '@material-ui/icons';

interface HistoryI {
  story: string;
}

interface HistoryObject {
  guid: string;
  session: string;
  objectName: string;
  objectId: string;
  objectAttribute: string;
  objectAttributeType: string | undefined;
  previousValue: string | undefined;
  newValue: string | undefined;
  modifiedBy: User;
  modifiedAt: string;
}

const HistoryTab: FunctionComponent<HistoryI> = ({ story }: HistoryI) => {
  const [storyHistory, setStoryHistory] = useState<HistoryObject[]>([]);
  const [currentStory, setCurrentStory] = useState<StoryFormData>();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchStoryHistory(story).then(({ data }) => {
      setStoryHistory([...data].reverse());
    });
    fetchStory(story).then(({ data }) => {
      setCurrentStory(getInitialState(data));
    });
  }, [story]);

  const revertChanges = (item: HistoryObject) => {
    if (!currentStory) return;

    const storyData: StoryFormData = {
      ...currentStory,
      [item.objectAttribute]: item.previousValue
    };

    updateStory(storyData).then(({ code }) => {
      if (code === 201) {
        dispatch(
          toggleNotification(
            i18n.t('notifications.stories.updateSuccess'),
            'success'
          )
        );
      }
    });
  };

  return (
    <Box padding={2}>
      <Grid container spacing={2} style={{ overflowY: 'scroll' }}>
        {storyHistory &&
          storyHistory
            .filter(
              item =>
                !['created_at', 'story_state'].includes(item.objectAttribute)
            )
            .map(item => {
              return (
                <Grid item xs={12}>
                  <Card style={{ padding: '14px' }}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Avatar aria-label="recipe">
                          {item.modifiedBy.username.split('')[0]}
                        </Avatar>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {item.modifiedBy.username}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          style={{ marginTop: '10px' }}
                        >
                          {item.objectAttribute?.replace('_', ' ')}
                          {', '}
                          {new Date(item.modifiedAt).toLocaleString()}{' '}
                        </Typography>
                      </Grid>
                      <Grid item xs={2} style={{ marginLeft: 'auto' }}>
                        <CardActions style={{ float: 'right' }}>
                          <ActionDialog
                            onConfirm={() => revertChanges(item)}
                            target=" "
                            tooltip={i18n.t('button.revertChanges')}
                            icon={<HistoryRounded color="primary" />}
                            targetDeleteTitle={i18n.t('button.revertChanges')}
                            targetDeletePrompt={i18n.t(
                              'deleteDialog.archiveDialog'
                            )}
                            targetDeleteWarning={i18n.t('deleteDialog.warning')}
                            btnDisabled={false}
                          />
                        </CardActions>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              );
            })}
      </Grid>
    </Box>
  );
};

export default HistoryTab;

HistoryTab.defaultProps = {};
