import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { FunctionComponent } from 'react';
import i18n from 'i18n';
import DetailDialog from 'components/DetailDialog';
import {
  CheckCircleOutline,
  HighlightOff,
  Info,
  VideoLabel
} from '@material-ui/icons';
import { clipTypes } from 'pages/administration/story-types/constants';

interface DetailI {
  storyType: $Lns.StoryType;
  onClose: () => void;
}

const Detail: FunctionComponent<DetailI> = ({
  storyType,
  onClose
}: DetailI) => {
  const dialogContent = () => {
    return (
      <Box>
        <List>
          <ListItem>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText
              primary={i18n.t('pages.administration.storyTypes.formLabel.name')}
              secondary={storyType.name}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              {storyType.isPrompterRequired ? (
                <CheckCircleOutline />
              ) : (
                <HighlightOff />
              )}
            </ListItemIcon>
            <ListItemText
              primary={i18n.t(
                'pages.administration.storyTypes.formLabel.isPrompterRequired'
              )}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              {storyType.isCgRequired ? (
                <CheckCircleOutline />
              ) : (
                <HighlightOff />
              )}
            </ListItemIcon>
            <ListItemText
              primary={i18n.t(
                'pages.administration.storyTypes.formLabel.isCgRequired'
              )}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <VideoLabel />
            </ListItemIcon>
            <ListItemText
              primary={i18n.t(
                'pages.administration.storyTypes.formLabel.clipType'
              )}
              secondary={clipTypes[storyType.clipType]}
            />
          </ListItem>
        </List>
      </Box>
    );
  };

  const dialogActions = () => {
    return (
      <>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {i18n.t('button.close')}
        </Button>
      </>
    );
  };

  return (
    <DetailDialog
      dialogTitle={storyType.name}
      dialogContent={dialogContent()}
      dialogActions={dialogActions()}
      onHandleClose={onClose}
    />
  );
};

export default Detail;
