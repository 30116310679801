import { Resource, RESOURCE_ACTIONS } from '@lns/consts';
import toggleNotification from 'actions/notifications';
import { useDispatch, useSelector } from 'react-redux';

export const canAccess = (
  permissions: $Lns.CurrentUserPermissions,
  resource?: string,
  action?: string
): boolean => {
  return resource && action
    ? permissions[resource] && permissions[resource][action]
    : true;
};

export const usePermissions = (): $Lns.CurrentUserPermissions => {
  const { currentUserPermissions } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  return currentUserPermissions;
};

export const useCurrentLnsUser = (): $Lns.User => {
  const { currentLnsUser } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  return currentLnsUser;
};

export const useCanAccess = (
  resource?: string,
  action?: string,
  owners?: string[]
): boolean => {
  const permissions = usePermissions();
  const currentUser = useCurrentLnsUser();
  return (
    owners?.includes(currentUser.guid) ||
    canAccess(permissions, resource, action)
  );
};

export const useWithPermissions = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targetHandler: (params: any) => void,
  resource: string,
  action: string,
  owners?: string[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ((params: any) => void) => {
  const dispatch = useDispatch();
  if (useCanAccess(resource, action, owners)) {
    return targetHandler;
  }
  return () => dispatch(toggleNotification('Not authorized', 'warning'));
};

// Check if the user can perform all available actions on any resource

export const useHasFullResourceAccess = (
  resource: keyof typeof Resource
): boolean => {
  const userPermissions = usePermissions();
  const permissions = Object.entries(Resource);

  const [, resourceValueName] =
    permissions.find(([key]) => key === resource) || [];

  if (!resourceValueName) return false;
  const taskPermissions = RESOURCE_ACTIONS[resourceValueName];

  const canUserPerformAll = taskPermissions.every(action =>
    canAccess(userPermissions, resourceValueName, action)
  );

  return canUserPerformAll;
};
