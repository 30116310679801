import { Box, Button, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';
import i18n from 'i18n';
import DetailDialog from 'components/DetailDialog';

interface ItemDetailI {
  newswireServiceItem: $Lns.NewswireService;
  formatName: string;
  onClose: () => void;
}

const ItemDetail: FunctionComponent<ItemDetailI> = ({
  newswireServiceItem,
  formatName,
  onClose
}: ItemDetailI) => {
  const DialogContent = () => (
    <Box>
      <Typography> Format : {formatName}</Typography>
      <Typography>
        Auth Required? : {newswireServiceItem.isAuthRequired ? 'Yes' : 'No'}
      </Typography>
    </Box>
  );

  const dialogActions = () => {
    return (
      <Button variant="outlined" color="secondary" onClick={onClose}>
        {i18n.t('button.close')}
      </Button>
    );
  };

  return (
    <DetailDialog
      dialogTitle={newswireServiceItem.name}
      dialogContent={<DialogContent />}
      dialogActions={dialogActions()}
      onHandleClose={onClose}
    />
  );
};

export default ItemDetail;
