import { Dispatch } from 'react';
import {
  SET_STORY_POOLS,
  TOGGLE_LOADING,
  SET_STORY_TYPES,
  SET_STORY_GENRES,
  SET_STORY_CATEGORIES,
  SET_STORY_STATES,
  SET_LOCATIONS,
  SET_RUNDOWN_STORIES,
  SET_INITIAL_DATA
} from 'redux/reducers/types';

const toggleLoading = (loading: boolean): Dispatch<() => void> => {
  return (dispatch: Dispatch<{ type: string; loading: boolean }>) => {
    dispatch({
      type: TOGGLE_LOADING,
      loading
    });
  };
};

export const setInitialData = ({
  storyPools,
  storyStates,
  storyTypes,
  storyGenres,
  storyCategories,
  locations,
  taskStates
}: {
  storyPools: $Lns.StoryPool[];
  storyTypes: $Lns.StoryType[];
  storyStates: $Lns.StoryState[];
  storyGenres: $Lns.StoryGenre[];
  storyCategories: $Lns.StoryCategory[];
  locations: $Lns.Location[];
  taskStates: $Lns.TaskState[];
}) => {
  return (
    dispatch: Dispatch<{
      type: string;
      storyPools: $Lns.StoryPool[];
      storyTypes: $Lns.StoryType[];
      storyStates: $Lns.StoryState[];
      storyGenres: $Lns.StoryGenre[];
      storyCategories: $Lns.StoryCategory[];
      locations: $Lns.Location[];
      taskStates: $Lns.TaskState[];
    }>
  ): void => {
    return dispatch({
      type: SET_INITIAL_DATA,
      storyPools,
      storyStates,
      storyGenres,
      storyCategories,
      storyTypes,
      locations,
      taskStates
    });
  };
};

export const setStoryPools = (
  storyPools: $Lns.StoryPool[]
): Dispatch<() => void> => {
  return (
    dispatch: Dispatch<{ type: string; storyPools: $Lns.StoryPool[] }>
  ) => {
    dispatch({
      type: SET_STORY_POOLS,
      storyPools
    });
  };
};

export const setStoryStates = (
  storyStates: $Lns.StoryState[]
): Dispatch<() => void> => {
  return (
    dispatch: Dispatch<{ type: string; storyStates: $Lns.StoryState[] }>
  ) => {
    dispatch({
      type: SET_STORY_STATES,
      storyStates
    });
  };
};

export const setStoryGenres = (
  storyGenres: $Lns.StoryGenre[]
): Dispatch<() => void> => {
  return (
    dispatch: Dispatch<{ type: string; storyGenres: $Lns.StoryGenre[] }>
  ) => {
    dispatch({
      type: SET_STORY_GENRES,
      storyGenres
    });
  };
};

export const setStoryCategories = (
  storyCategories: $Lns.StoryCategory[]
): Dispatch<() => void> => {
  return (
    dispatch: Dispatch<{ type: string; storyCategories: $Lns.StoryCategory[] }>
  ) => {
    dispatch({
      type: SET_STORY_CATEGORIES,
      storyCategories
    });
  };
};

export const setLocations = (
  locations: $Lns.Location[]
): Dispatch<() => void> => {
  return (dispatch: Dispatch<{ type: string; locations: $Lns.Location[] }>) => {
    dispatch({
      type: SET_LOCATIONS,
      locations
    });
  };
};

export const setStoryTypes = (
  storyTypes: $Lns.StoryType[]
): Dispatch<() => void> => {
  return (
    dispatch: Dispatch<{ type: string; storyTypes: $Lns.StoryType[] }>
  ) => {
    dispatch({
      type: SET_STORY_TYPES,
      storyTypes
    });
  };
};

export const setRundownStories = (
  rundownStories: $Lns.RundownStories
): Dispatch<() => void> => {
  return (
    dispatch: Dispatch<{ type: string; rundownStories: $Lns.RundownStories }>
  ) => {
    dispatch({
      type: SET_RUNDOWN_STORIES,
      rundownStories
    });
  };
};

export default toggleLoading;
