import {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  ChangeEvent
} from 'react';
import { Dialog, Grid, IconButton, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  fetchRundownTemplates,
  createRundownTemplate,
  updateRundownTemplate,
  deleteRundownTemplate
} from 'pages/administration/rundown-templates/actions';
import RundownTemplateList from 'pages/administration/rundown-templates/components/List';
import Form from 'pages/administration/rundown-templates/components/Form';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';
import { Search, Dns } from '@material-ui/icons';
import storiesStyles from 'pages/stories/styles';
import TitleWaper from 'components/TitleWraper';
import BtnWrapper from 'components/BtnWrapper';
import TitleBarWraper from 'components/TitleBarWraper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import BasicTextField from 'components/BasicTextField';

const RundownTemplates: FunctionComponent = () => {
  const [rundownTemplates, setRundownTemplates] = useState<
    $Lns.RundownTemplate[]
  >([]);

  const [rundownTemplatesOrig, setRundownTemplatesOrig] = useState<
    $Lns.RundownTemplate[]
  >([]);

  const classes = storiesStyles();

  const [editTemplate, setEditTemplate] = useState<$Lns.RundownTemplate>();
  const dispatch = useDispatch();

  const fetchSetRundownTemplates = useCallback(() => {
    fetchRundownTemplates()
      .then(({ data }) => {
        setRundownTemplates(data);
        setRundownTemplatesOrig(data);
      })
      .catch(() => {
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  }, [dispatch]);

  useEffect(() => {
    fetchSetRundownTemplates();
  }, [fetchSetRundownTemplates]);

  const onEditCancel = () => {
    setEditTemplate(undefined);
  };

  const onEdit = (rundownTemplate: $Lns.RundownTemplate) => {
    setEditTemplate(rundownTemplate);
  };

  const onSaveEdit = (formData: $Lns.RundownTemplate) => {
    updateRundownTemplate(formData)
      .then(() => {
        // resolve
        fetchSetRundownTemplates();
        setEditTemplate(undefined);
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundownTemplates.updateSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        // reject
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  };

  const onAdd = () => {
    setEditTemplate({
      name: '',
      playoutStartTime: '00:00',
      playoutEndTime: '00:00',
      schedule: {
        mo: false,
        tu: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false
      }
    } as $Lns.RundownTemplate);
  };

  const onSave = (formData: $Lns.RundownTemplate) => {
    if (formData.guid) {
      onSaveEdit(formData);
    } else {
      onCreate(formData);
    }
  };

  const onCreate = (formData: $Lns.RundownTemplate) => {
    createRundownTemplate(formData)
      .then(() => {
        // resolve
        fetchSetRundownTemplates();
        setEditTemplate(undefined);
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundownTemplates.createSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        // reject
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  };

  const onDelete = (deleteTarget: $Lns.RundownTemplate) => {
    deleteRundownTemplate(deleteTarget)
      .then(() => {
        fetchSetRundownTemplates();
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundownTemplates.deleteSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundownTemplates.deleteError'),
            'error'
          )
        );
      });
  };

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newRundownTemp = rundownTemplatesOrig.filter(rundown => {
      return rundown.guid?.includes(search) || rundown.name.includes(search);
    });
    setRundownTemplates(newRundownTemp);
  };

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={i18n.t(
                'links.sidebarNestedItems.administration.rundownTemplates'
              )}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<Dns color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <ProtectedButton
                lnsResource={Resource.RUNDOWN_TEMPLATE}
                lnsAction={Action.CREATE}
                color="secondary"
                variant="contained"
                onClick={onAdd}
              >
                {i18n.t('pages.administration.rundownTemplates.button.add')}
              </ProtectedButton>
            </BtnWrapper>
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={onSearchFieldChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <RundownTemplateList
            rundownTemplates={rundownTemplates}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Grid>
      </Grid>
      {editTemplate && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <Form
            rundownTemplate={editTemplate}
            onSave={onSave}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
    </>
  );
};

export default RundownTemplates;
