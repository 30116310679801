import {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  ChangeEvent
} from 'react';
import { Dialog, Grid, IconButton, InputBase, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  fetchLocations,
  createLocation,
  updateLocation,
  deleteLocation
} from 'pages/administration/locations/actions';
import LocationList from 'pages/administration/locations/components/List';
import Form from 'pages/administration/locations/components/Form';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';
import { Search, Dns } from '@material-ui/icons';
import TitleWaper from 'components/TitleWraper';
import BtnWrapper from 'components/BtnWrapper';
import TitleBarWraper from 'components/TitleBarWraper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import storiesStyles from 'pages/stories/styles';

const Locations: FunctionComponent = () => {
  const [locations, setLocations] = useState<$Lns.Location[]>([]);
  const [locationsOrig, setLocationsOrig] = useState<$Lns.Location[]>([]);

  const classes = storiesStyles();

  const [editTemplate, setEditTemplate] = useState<$Lns.Location>();
  const dispatch = useDispatch();

  const fetchSetLocations = useCallback(() => {
    fetchLocations()
      .then(({ data }) => {
        setLocations(data);
        setLocationsOrig(data);
      })
      .catch(() => {
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  }, [dispatch]);

  useEffect(() => {
    fetchSetLocations();
  }, [fetchSetLocations]);

  const onEditCancel = () => {
    setEditTemplate(undefined);
  };

  const onEdit = (location: $Lns.Location) => {
    setEditTemplate(location);
  };

  const onSaveEdit = (formData: $Lns.Location) => {
    updateLocation(formData)
      .then(() => {
        fetchSetLocations();
        setEditTemplate(undefined);
        dispatch(
          toggleNotification(
            i18n.t('notifications.locations.updateSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.locations.apiError'),
            'error'
          )
        );
      });
  };

  const onAdd = () => {
    setEditTemplate({
      city: '',
      country: ''
    } as $Lns.Location);
  };

  const onSave = (formData: $Lns.Location) => {
    if (formData.guid) {
      onSaveEdit(formData);
    } else {
      onCreate(formData);
    }
  };

  const onCreate = (formData: $Lns.Location) => {
    createLocation(formData)
      .then(() => {
        fetchSetLocations();
        setEditTemplate(undefined);
        dispatch(
          toggleNotification(
            i18n.t('notifications.locations.createSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  };

  const onDelete = (deleteTarget: $Lns.Location) => {
    deleteLocation(deleteTarget)
      .then(() => {
        fetchSetLocations();
        dispatch(
          toggleNotification(
            i18n.t('notifications.locations.deleteSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.locations.deleteError'),
            'error'
          )
        );
      });
  };

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newLocationTemp = locationsOrig.filter(location => {
      return (
        location.guid?.includes(search) ||
        location.city.includes(search) ||
        location.country.includes(search)
      );
    });
    setLocations(newLocationTemp);
  };

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={i18n.t(
                'links.sidebarNestedItems.administration.locations'
              )}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<Dns color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <ProtectedButton
                lnsResource={Resource.LOCATION}
                lnsAction={Action.CREATE}
                color="secondary"
                variant="contained"
                onClick={onAdd}
              >
                {i18n.t('pages.administration.locations.button.add')}
              </ProtectedButton>
            </BtnWrapper>
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <InputBase
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={onSearchFieldChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <LocationList
            locations={locations}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Grid>
      </Grid>
      {editTemplate && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <Form
            location={editTemplate}
            onSave={onSave}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
    </>
  );
};

export default Locations;
