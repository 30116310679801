import { ChangeEvent, FunctionComponent, useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Typography,
  FormHelperText
} from '@material-ui/core';
import i18n from 'i18n';
import { useDispatch } from 'react-redux';
import { fetchUsers } from 'actions/user';
import { DialogueData } from 'pages/stories/interfaces/dialogueForm';
import toggleNotification from 'actions/notifications';

interface DialogueForm1 {
  initVal: DialogueData;
  onSave: (formData: DialogueData, user: $Lns.User) => void;
  onEdit: (formData: DialogueData, initVal: DialogueData) => void;
  onCancel: () => void;
}

const readRates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const DialogueForm: FunctionComponent<DialogueForm1> = ({
  initVal,
  onSave,
  onEdit,
  onCancel
}: DialogueForm1) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<DialogueData>(initVal);
  const [allUsers, setAllUsers] = useState<$Lns.User[]>([]);

  useEffect(() => {
    fetchUsers().then(({ data }) => {
      setAllUsers(data);
    });
  }, []);
  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name || '']: value
    });
  };

  const onChangeType = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name || '']: value
    });
  };

  const getReadRate = (targetRate: number) => {
    return readRates.find(rateToCompare => rateToCompare === targetRate);
  };

  const getUser = (pin: string) => {
    return allUsers.find(sg => sg.guid === pin || sg.username === pin);
  };

  const onSaveDialoge = (formDataObj: DialogueData) => {
    if (formDataObj.camera > 50) {
      dispatch(
        toggleNotification(
          i18n.t('notifications.validations.cameraValidation'),
          'error'
        )
      );
      return;
    }
    const TempUser = getUser(formDataObj.presenter);
    if (initVal.presenter !== '' && initVal.dialogue !== '') {
      if (TempUser) {
        onEdit(formDataObj, initVal);
        onCancel();
      }
    } else if (TempUser) {
      onSave(formDataObj, TempUser);
      onCancel();
    }
  };

  const btnDisabled =
    !formData.presenter ||
    !formData.dialogue ||
    !formData.camera ||
    !formData.instructions ||
    !formData.readRate;

  return (
    <Box padding={4}>
      <Grid container spacing={2}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12}>
            <Typography variant="h4">
              {i18n.t('pages.stories.helperText.dialogue')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl margin="normal" variant="outlined" fullWidth>
              <InputLabel>
                {i18n.t('pages.stories.helperText.presenter')}
              </InputLabel>
              <Select
                fullWidth
                labelId="presenter"
                id="presenter"
                name="presenter"
                label={i18n.t('pages.stories.helperText.presenter')}
                placeholder={i18n.t('pages.stories.helperText.presenter')}
                required
                value={formData?.presenter}
                onChange={onChangeType}
                displayEmpty
                renderValue={(value: unknown) =>
                  getUser(value as string)?.username
                }
              >
                {allUsers.map(user => (
                  <MenuItem key={user.username} value={user.username}>
                    {user.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="camera"
              name="camera"
              type="number"
              label={i18n.t('pages.stories.helperText.camera')}
              value={formData?.camera}
              error={!!formData?.camera && formData?.camera > 50}
              onChange={onChangeField}
              autoComplete="dialogue"
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="instructions"
              name="instructions"
              label={i18n.t('pages.stories.helperText.instructions')}
              value={formData.instructions}
              onChange={onChangeField}
              autoComplete="dialogue"
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              multiline
              rows={3}
              id="dialogue"
              name="dialogue"
              label={i18n.t('pages.stories.helperText.dialogue')}
              value={formData.dialogue}
              onChange={onChangeField}
              autoComplete="dialogue"
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl margin="normal" variant="outlined" fullWidth>
              <InputLabel>
                {i18n.t('pages.stories.helperText.readRate')}
              </InputLabel>
              <Select
                labelId="readRate"
                id="readRate"
                name="readRate"
                label={i18n.t('pages.stories.helperText.readRate')}
                placeholder={i18n.t('pages.stories.helperText.readRate')}
                value={formData.readRate}
                onChange={onChangeType}
                displayEmpty
                renderValue={(value: unknown) => getReadRate(value as number)}
              >
                {readRates.map(rate => {
                  return (
                    <MenuItem key={rate} value={rate}>
                      {rate}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                {i18n.t('pages.storyScriptEdit.formHelperText.readRate')}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={btnDisabled}
              color="secondary"
              name="saveBtn"
              variant="contained"
              onClick={() => onSaveDialoge(formData)}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DialogueForm;
