import { ChangeEvent, FunctionComponent, useState } from 'react';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import i18n from 'i18n';
import { validateForm } from 'pages/administration/peripheral-settings/types/utils';
import { FormData } from 'pages/administration/peripheral-settings/types/formData';

interface FormI {
  typeItem?: $Lns.PeripheralType;
  onSave: (formData: FormData) => void;
  onCancel: () => void;
}

const Form: FunctionComponent<FormI> = ({
  typeItem,
  onSave,
  onCancel
}: FormI) => {
  const [formData, setFormData] = useState<FormData>({
    guid: typeItem?.guid || undefined,
    name: typeItem?.name || '',
    key: typeItem?.key || '',
    errors: {
      name: '',
      key: ''
    }
  });

  const btnDisabled =
    Object.values(formData.errors).some(e => !!e) ||
    !formData.name ||
    !formData.key;

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedFormData = {
      ...formData,
      [name]: value,
      errors: {
        ...formData.errors,
        [name]: validateForm(name, value)
      }
    };

    setFormData(updatedFormData);
  };

  const handleOnSave = () => {
    onSave(formData);
  };

  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label={i18n.t(
              'pages.administration.peripheralTypes.formLabel.name'
            )}
            value={formData.name}
            helperText={i18n.t(
              'pages.administration.peripheralTypes.formHelperText.nameHelper'
            )}
            name="name"
            onChange={onChangeField}
            error={!!formData?.errors?.name}
            autoComplete="name"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="key"
            label={i18n.t('pages.administration.peripheralTypes.formLabel.key')}
            value={formData.key}
            helperText={i18n.t(
              'pages.administration.peripheralTypes.formHelperText.keyHelper'
            )}
            name="key"
            onChange={onChangeField}
            error={!!formData?.errors?.key}
            autoComplete="key"
          />
        </Grid>

        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={btnDisabled}
              color="secondary"
              variant="contained"
              onClick={handleOnSave}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;

Form.defaultProps = {
  typeItem: undefined
};
