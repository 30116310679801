import enLocale from 'date-fns/locale/en-US';
import deLocale from 'date-fns/locale/de';
import i18next from 'i18next';

export const getLocale = (): string => {
  const { language } = i18next;
  return language.split('-').pop() || 'en';
};

export const localeMap = (): { [key: string]: Locale } => {
  return {
    en: enLocale,
    de: deLocale
  };
};
