import { ChangeEvent, FunctionComponent, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { validateForm } from 'pages/administration/publish-folders/utils';
import CustomTextField from 'components/CustomTextField';
import { FormData } from 'pages/administration/publish-folders/formData';
import i18n from 'i18n';

interface FormI {
  onSave: (formData: FormData) => void;
  onCancel: () => void;
  publishFolder: FormData;
  publishFolders: $Lns.PublishFolder[];
}

const Form: FunctionComponent<FormI> = ({
  publishFolder,
  publishFolders,
  onSave,
  onCancel
}: FormI) => {
  const [formData, setFormData] = useState({
    id: publishFolder?.id,
    guid: publishFolder?.guid,
    name: publishFolder?.name || '',
    errors: {
      name: ''
    }
  });

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedFormData = {
      ...formData,
      [name]: value,
      errors: {
        ...formData.errors,
        [name]: validateForm(name, value, publishFolders)
      }
    };
    toggleSaveBtn(updatedFormData, name);
    setFormData(updatedFormData);
  };

  const toggleSaveBtn = (updatedFormData: FormData, key: string) => {
    let checkFlg = true;
    const skipList = ['errors'];
    if (Object.keys(publishFolder).includes(key)) {
      const tempFormData = { ...updatedFormData };
      checkFlg = checkFormDataState(publishFolder, tempFormData, skipList);
      setBtnDisabledChange(checkFlg);
    }
  };

  const checkFormDataState = (
    formDataObj: FormData,
    updateFormDataObj: FormData,
    skipList: string[]
  ) => {
    let checkFlg = true;
    Object.keys(updateFormDataObj).forEach(pin => {
      if (
        pin in updateFormDataObj &&
        pin in formDataObj &&
        !skipList.includes(pin)
      ) {
        const Val1 = updateFormDataObj[pin as keyof typeof updateFormDataObj];
        const Val2 = formDataObj[pin as keyof typeof formDataObj];
        if (Val1 !== Val2) {
          checkFlg = false;
        }
      }
    });
    return checkFlg;
  };

  const btnDisabled =
    !formData.name || !!Object.values(formData.errors).some(e => !!e);

  const [BtnDisabledChange, setBtnDisabledChange] = useState(
    !!formData.name || !!Object.values(formData.errors).some(e => !!e)
  );

  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CustomTextField
            onChange={onChangeField}
            value={formData.name}
            label={i18n.t('pages.administration.publishFolders.formLabel.name')}
            name="name"
            error={!!formData?.errors.name}
            helperText={formData.errors.name || i18n.t('helperText.required')}
          />
        </Grid>

        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={btnDisabled || BtnDisabledChange}
              color="secondary"
              variant="contained"
              onClick={() => onSave(formData)}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;
