import { callLnsApi } from 'utils/fetch';

export const fetchFileTypes = (): Promise<{
  data: $Lns.FileType[];
  code: number;
}> => callLnsApi('/file-type');

export const createFileType = (
  fileType: $Lns.FileType
): Promise<{
  data: $Lns.FileType;
  code: number;
}> => callLnsApi('/file-type', 'POST', fileType);

export const updateFileType = (
  fileType: $Lns.FileType
): Promise<{
  data: $Lns.FileType;
  code: number;
}> => callLnsApi(`/file-type/${fileType.guid}`, 'POST', fileType);

export const deleteFileType = (
  fileType: $Lns.FileType
): Promise<{
  data: $Lns.FileType;
  code: number;
}> => callLnsApi(`/file-type/${fileType.guid}`, 'DELETE');
