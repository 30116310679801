import React, { FunctionComponent, useState, useEffect } from 'react';
import { TabContext, TabPanel } from '@material-ui/lab';
import StoryPoolTabsList from 'pages/story-pools/components/TabsList';
import { Tab, Tabs } from '@material-ui/core';
import StoriesTable from 'components/Story/Table';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserStories } from 'actions/user';
import { generalStyle } from 'pages/rundowns/styles';
import { StoryState } from 'pages/stories/enums';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';

interface StoryPoolTabsI {
  storyPools: $Lns.StoryPool[];
  onToggleStory: (story: $Lns.Story) => void;
  highlightedStories: $Lns.Story[];
  approvedStories?: boolean;
}

const StoryPoolTabs: FunctionComponent<StoryPoolTabsI> = ({
  storyPools,
  highlightedStories,
  onToggleStory,
  approvedStories
}: StoryPoolTabsI) => {
  const dispatch = useDispatch();
  const [value, setTabValue] = useState('my-lns');
  const [myStories, setMyStories] = useState<$Lns.Story[]>([]);
  const generalStyles = generalStyle();

  const { currentLnsUser, storyStates } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  useEffect(() => {
    fetchUserStories(currentLnsUser.guid).then(({ data }) => {
      setMyStories(data);
    });
  }, [currentLnsUser]);

  const handleChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    setTabValue(newValue.toString());
  };

  const onMyStoryClick = (storyRow: { guid: { value: string } }) => {
    const story = myStories.find(
      (s: $Lns.Story) => s.guid === storyRow.guid.value
    );

    if (story) {
      const approveState = storyStates.find(stateItem => {
        return stateItem.name === StoryState.approved;
      });

      if (approvedStories) {
        if (approveState && story.storyState === approveState.guid) {
          onToggleStory(story);
        } else {
          dispatch(
            toggleNotification(
              i18n.t('notifications.publishFolders.storyNotApproved'),
              'error'
            )
          );
        }
      } else {
        onToggleStory(story);
      }
    }
  };

  return (
    <TabContext value={value.toString()}>
      <Tabs
        variant="scrollable"
        onChange={handleChange}
        value={value.toString()}
        scrollButtons="on"
      >
        <Tab label="My LNS" value="my-lns" />
        {storyPools.map(sp => {
          return <Tab label={sp.name} value={sp.guid} key={sp.guid} />;
        })}
      </Tabs>
      <TabPanel value="my-lns" className={generalStyles.paddingTopOnly}>
        <StoriesTable
          data-test-id="my-stories"
          stories={myStories}
          onClick={onMyStoryClick}
          highlightedStories={highlightedStories}
        />
      </TabPanel>
      {storyPools.map((sp: $Lns.StoryPool) => {
        return (
          <TabPanel value={sp.guid} key={sp.guid}>
            <StoryPoolTabsList
              onToggleStory={onToggleStory}
              storyPool={sp}
              highlightedStories={highlightedStories}
            />
          </TabPanel>
        );
      })}
    </TabContext>
  );
};

StoryPoolTabs.defaultProps = {
  approvedStories: true
};

export default StoryPoolTabs;
