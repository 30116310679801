import Loading from 'components/Loading';
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  useRef
} from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchUserPermissions,
  setCurrentUserPermissions
} from 'components/Authorization/actions';
import { useCurrentLnsUser } from 'components/Authorization/utils';

const PERMISSION_REFRESH_IN_MS = 1000000;

interface PermissionLoaderI {
  children: ReactNode;
}

const PermissionLoader: FunctionComponent<PermissionLoaderI> = ({
  children
}: PermissionLoaderI) => {
  const dispatch = useDispatch();
  const [permissionsLoaded, togglePermissionsLoaded] = useState(false);
  const permissionRefreshInterval = useRef<NodeJS.Timeout | null>(null);
  const currentLnsUser = useCurrentLnsUser();

  const fetchUserPermissionsCb = useCallback(
    (guid: string) => {
      fetchUserPermissions(guid).then(response => {
        togglePermissionsLoaded(true);
        dispatch(setCurrentUserPermissions(response.data));
      });
    },
    [dispatch]
  );

  useEffect(() => {
    return () =>
      clearTimeout(permissionRefreshInterval.current as NodeJS.Timeout);
  }, []);

  useEffect(() => {
    if (currentLnsUser && !permissionRefreshInterval.current) {
      fetchUserPermissionsCb(currentLnsUser.guid);
      permissionRefreshInterval.current = setInterval(
        () => fetchUserPermissionsCb(currentLnsUser.guid),
        PERMISSION_REFRESH_IN_MS
      );
    }
  }, [currentLnsUser, fetchUserPermissionsCb]);

  if (!permissionsLoaded) return <Loading />;

  return <>{children}</>;
};

export default PermissionLoader;
