import { callLnsApi } from 'utils/fetch';
import { SetupFormData } from 'pages/administration/setup/formData';

export const createSystemConfig = (
  configData: SetupFormData
): Promise<{ code: number; data: SetupFormData }> =>
  callLnsApi('/system-config', 'POST', configData);

export const fetchAllSystemConfigs = (): Promise<{
  code: number;
  data: SetupFormData[];
}> => callLnsApi('/system-config');

export const fetchSystemConfigByGuid = (
  configGuid: string
): Promise<{ code: number; data: SetupFormData }> =>
  callLnsApi(`/system-config/${configGuid}`);

export const updateSystemConfig = (
  configData: SetupFormData
): Promise<{ code: number; data: SetupFormData }> => {
  if (configData.guid)
    return callLnsApi(`/system-config/${configData.guid}`, 'POST', configData);
  return Promise.reject(new Error('Malformed Data'));
};
