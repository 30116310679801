import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import {
  createStoryGenre,
  deleteStoryGenre,
  fetchStoryGenres,
  updateStoryGenre
} from 'actions/storyGenres';
import { Dialog, Grid, IconButton, Paper } from '@material-ui/core';
import { Search, Category } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { FormData } from 'pages/administration/story-genres/formData';
import List from 'pages/administration/story-genres/components/List';
import storiesStyles from 'pages/stories/styles';
import i18n from 'i18n';
import Form from 'pages/administration/story-genres/components/Form';
import toggleNotification from 'actions/notifications';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import BtnWrapper from 'components/BtnWrapper';
import {
  ProtectedButton,
  Resource,
  Action,
  useWithPermissions
} from 'components/Authorization';
import BasicTextField from 'components/BasicTextField';

const StoryGenres: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [storyGenres, setStoryGenres] = useState<$Lns.StoryGenre[]>([]);
  const [storyGenresOrig, setStoryGenresOrig] = useState<$Lns.StoryGenre[]>([]);
  const [editStoryGenre, setEditStoryGenre] = useState<$Lns.StoryGenre>();
  const classes = storiesStyles();

  useEffect(() => {
    fetchStoryGenres().then(({ data }) => {
      setStoryGenres(data);
      setStoryGenresOrig(data);
    });
  }, []);

  const onRowClick = useWithPermissions(
    (rowItem: { name: { value: string } }) => {
      const {
        name: { value: nameValue }
      } = rowItem;

      const storyGenre = { guid: nameValue, key: nameValue, name: nameValue };
      setEditStoryGenre(storyGenre);
    },
    Resource.STORY_GENRE,
    Action.EDIT
  );

  if (storyGenresOrig.length < 1) return <></>;

  const onAdd = () => {
    setEditStoryGenre({
      key: '',
      name: '',
      guid: ''
    });
  };

  const onSave = (formData: FormData) => {
    let updatedStoryGenres = [];

    const storyGenre = {
      key: formData.key,
      name: formData.name
    } as $Lns.StoryGenre;

    if (formData.guid) {
      storyGenre.guid = formData.guid;
      updateStoryGenre(storyGenre).then(() => {
        updatedStoryGenres = storyGenres.map(sg => {
          if (sg.guid === storyGenre.guid) {
            return {
              ...sg,
              ...storyGenre
            };
          }
          return sg;
        });
        setStoryGenres(updatedStoryGenres);
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyGenres.updateSuccess'),
            'success'
          )
        );
      });
    } else {
      createStoryGenre(storyGenre).then(({ data }) => {
        updatedStoryGenres = [data, ...storyGenres];
        setStoryGenres(updatedStoryGenres);
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyGenres.createSuccess'),
            'success'
          )
        );
      });
    }
    setEditStoryGenre(undefined);
  };

  const onRemove = (storyGenre: $Lns.StoryGenre) => {
    deleteStoryGenre(storyGenre)
      .then(() => {
        const updatedStoryGenres = storyGenres.filter(
          sg => sg.guid !== storyGenre.guid
        );
        setStoryGenres(updatedStoryGenres);
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyGenres.deleteSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyGenres.deleteError'),
            'error'
          )
        );
      });
  };

  const onEdit = (storyGenre: $Lns.StoryGenre) => {
    setEditStoryGenre(storyGenre);
  };

  const onEditCancel = () => {
    setEditStoryGenre(undefined);
  };

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newStoriesGenre = storyGenresOrig.filter(story => {
      return story.name.includes(search) || story.key.includes(search);
    });
    setStoryGenres(newStoriesGenre);
  };

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={i18n.t(
                'links.sidebarNestedItems.administration.storyGenres'
              )}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<Category color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <ProtectedButton
                lnsResource={Resource.STORY_GENRE}
                lnsAction={Action.CREATE}
                color="secondary"
                variant="contained"
                onClick={onAdd}
              >
                {i18n.t('pages.administration.storyGenres.button.add')}
              </ProtectedButton>
            </BtnWrapper>
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={onSearchFieldChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <List
            storyGenres={storyGenres}
            onRemove={onRemove}
            onEdit={onEdit}
            onRowClick={onRowClick}
          />
        </Grid>
      </Grid>
      {editStoryGenre && (
        <Dialog onClose={onEditCancel} open>
          <Form
            onSave={onSave}
            storyGenre={editStoryGenre}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
    </>
  );
};

export default StoryGenres;
