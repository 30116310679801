import { ChangeEvent, FunctionComponent, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { Language } from '@material-ui/icons';
import i18n from 'i18n';
import { languages, getLanguageName } from 'utils/languages';
import useStyles from 'pages/user/components/styles';

interface EditLanguageSettingsI {
  user: $Lns.User;
  canEdit: boolean;
  onSaveCallback?: (language: string) => void;
}

const EditLanguageSettings: FunctionComponent<EditLanguageSettingsI> = ({
  user,
  canEdit,
  onSaveCallback
}: EditLanguageSettingsI) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [language, setLanguage] = useState(user.config?.language || 'en');
  const toggleIsEdit = () => setIsEdit(!isEdit);
  const noDataString = i18n.t('pages.users.formHelperText.noData');

  const onSave = () => {
    if (onSaveCallback) onSaveCallback(language);
    toggleIsEdit();
  };

  const handleLanguageChange = (e: ChangeEvent<{ value: unknown }>) => {
    setLanguage(e.target.value as string);
  };

  return (
    <>
      <Box className={classes.titleWrapperBox}>
        <Typography variant="h6" className={classes.grayPaperTitle}>
          {i18n.t('pages.users.formLabel.language')}
        </Typography>
        {canEdit && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={!isEdit ? toggleIsEdit : onSave}
            >
              {!isEdit && i18n.t('button.edit')}
              {isEdit && i18n.t('button.save')}
            </Button>
            {isEdit && (
              <Box paddingLeft={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={toggleIsEdit}
                >
                  {i18n.t('button.cancel')}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {!isEdit && (
        <List>
          <ListItem>
            <ListItemIcon>
              <Language />
            </ListItemIcon>
            <ListItemText
              primary={
                user.config?.language
                  ? getLanguageName(user.config.language)
                  : noDataString
              }
              secondary={i18n.t('pages.users.formLabel.language')}
            />
          </ListItem>
        </List>
      )}
      {isEdit && (
        <Box padding={2}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-language-label">
              {i18n.t('pages.users.formLabel.language')}
            </InputLabel>
            <Select
              labelId="select-language-label"
              id="select-language"
              value={language}
              onChange={handleLanguageChange}
              label={i18n.t('pages.users.formLabel.language')}
              placeholder={i18n.t('pages.users.formHelperText.language')}
            >
              {languages.map(item => (
                <MenuItem key={item.code} value={item.code}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default EditLanguageSettings;

EditLanguageSettings.defaultProps = {
  onSaveCallback: undefined
};
