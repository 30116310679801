import React, { FunctionComponent, MouseEvent, useState } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import SignInForm from 'pages/signin/components/Form';
import { Button, Grid, Hidden, Link, Typography } from '@material-ui/core';
import backgroundImg from 'images/img_bg_newsroom.jpg';
import logoImg from 'images/lns-logo-primary-color.png';
import colors from 'styles/colors';
import { storeTokens } from 'utils/auth';
import { useNavigate } from 'react-router';
import toggleNotification from 'actions/notifications';
import { useDispatch } from 'react-redux';
import {
  fetchUser,
  setCurrentSsoUser,
  setCurrentLnsUser,
  signIn
} from 'pages/signin/actions';
import camelcase from 'camelcase-keys';
import i18n from 'i18n';

const SignIn: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, toggleLoading] = useState(false);

  const onClick = (email: string, password: string) => {
    toggleLoading(true);
    signIn(email, password)
      .then(({ token, tokenExpiresOn }) => {
        toggleLoading(false);
        storeTokens(token, tokenExpiresOn);
        fetchUser().then(fetchUserJson => {
          // Temporary until api returns camelcase json
          // Work around still necessary
          // Last check on 18Aug2021
          const currentSsoUser = camelcase(fetchUserJson);
          dispatch(
            setCurrentSsoUser(currentSsoUser as unknown as $Lns.SsoUser)
          );
          dispatch(
            setCurrentLnsUser(currentSsoUser as unknown as $Lns.SsoUser)
          );
          dispatch(
            toggleNotification(
              i18n.t('notifications.signin.success'),
              'success'
            )
          );
          navigate('/');
        });
      })
      .catch(() => {
        toggleLoading(false);
        dispatch(
          toggleNotification(i18n.t('notifications.signin.error'), 'error')
        );
      });
  };

  const onSignupBtnClick = () => {
    navigate('/signup');
  };

  const onResetPassword = (e: MouseEvent) => {
    e.preventDefault();
    navigate('/password-reset');
  };

  return (
    <Grid container style={{ height: '100%' }}>
      <Grid item sm={6} xs={12}>
        <Box height="100%" display="grid" alignContent="center">
          <Container component="main" maxWidth="xs">
            <Grid container spacing={4} justify="center" alignContent="center">
              <Grid item>
                <Box display="flex" justifyContent="center">
                  <img alt="logo" src={logoImg} width="25%" height="25%" />
                </Box>
              </Grid>
              <Grid item>
                <SignInForm onClick={onClick} isLoading={isLoading} />
              </Grid>

              <Grid item>
                <Box textAlign="center">
                  <Link href="/" onClick={onResetPassword}>
                    <Typography variant="body2" color="secondary">
                      {i18n.t('pages.signin.forgotPassword')}
                    </Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Grid>

      <Hidden xsDown>
        <Grid item sm={6}>
          <Box
            width="100%"
            height="100%"
            style={{
              backgroundImage: `url(${backgroundImg})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPositionY: 'bottom',
              boxShadow: 'inset 0 0 0 1000px rgb(0 0 0 / 20%)'
            }}
          >
            <Box
              display="grid"
              justifyContent="end"
              gridAutoFlow="column"
              alignItems="center"
              gridGap={20}
              pt={4}
              pr={4}
            >
              <Typography
                style={{ color: colors.neutral[0] }}
                variant="caption"
                color="inherit"
              >
                {i18n.t('pages.signin.missingAccount')}
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                onClick={onSignupBtnClick}
              >
                {i18n.t('button.signup')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default SignIn;
