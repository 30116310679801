import i18n from 'i18n';
import validator from 'validator';

export const validateForm = (name: string, value: string): string => {
  let error = '';

  if (name === 'name') {
    const valueNoSpace = value.split(' ').join('');
    error = validator.isEmpty(value) ? i18n.t('formErrors.isEmpty') : '';
    error = !validator.isAlphanumeric(valueNoSpace)
      ? i18n.t('formErrors.isAlphanumeric')
      : '';
  }

  if (name === 'url') {
    error = !validator.isURL(value) ? i18n.t('formErrors.isUrl') : '';
  }

  return error;
};
