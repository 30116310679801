import { FunctionComponent } from 'react';
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import { Category, Info, DeviceHub } from '@material-ui/icons';
import shortid from 'shortid';
import i18n from 'i18n';
import {
  findProtocolName,
  findTypeName
} from 'pages/administration/peripheral-settings/utils';
import DetailDialog from 'components/DetailDialog';

interface ItemDetailI {
  peripheralItem: $Lns.Peripheral;
  types: $Lns.PeripheralType[];
  protocols: $Lns.Protocol[];
  onClose: () => void;
}

const ItemDetail: FunctionComponent<ItemDetailI> = ({
  peripheralItem,
  protocols,
  types,
  onClose
}: ItemDetailI) => {
  const currProtocol = findProtocolName(peripheralItem.protocol, protocols);
  const currType = findTypeName(peripheralItem.type, types);

  const DialogContent = () => (
    <Box>
      <List>
        <ListItem>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText
            primary={peripheralItem.name}
            secondary={i18n.t(
              'pages.administration.peripherals.formLabel.name'
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Category />
          </ListItemIcon>
          <ListItemText
            primary={currType || ''}
            secondary={i18n.t(
              'pages.administration.peripherals.formLabel.type'
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <DeviceHub />
          </ListItemIcon>
          <ListItemText
            primary={currProtocol || ''}
            secondary={i18n.t(
              'pages.administration.peripherals.formLabel.protocol'
            )}
          />
        </ListItem>
        <br />
        <Typography variant="subtitle2">Protocol Configs</Typography>
        <Divider />
        {peripheralItem?.config &&
          Object.keys(peripheralItem.config).map(configName => {
            return (
              <ListItem key={shortid.generate()}>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText
                  primary={
                    peripheralItem.config[configName]
                      ? peripheralItem.config[configName]
                      : i18n.t(
                          'pages.administration.perpherals.formHelperText.noConfig'
                        )
                  }
                  primaryTypographyProps={{ variant: 'body1' }}
                  secondary={configName}
                />
              </ListItem>
            );
          })}
      </List>
    </Box>
  );

  const dialogActions = () => {
    return (
      <Button variant="outlined" color="secondary" onClick={onClose}>
        {i18n.t('button.close')}
      </Button>
    );
  };

  return (
    <DetailDialog
      dialogTitle={peripheralItem.name}
      dialogContent={<DialogContent />}
      dialogActions={dialogActions()}
      onHandleClose={onClose}
    />
  );
};

export default ItemDetail;
