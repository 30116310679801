import { FunctionComponent, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  Typography
} from '@material-ui/core';
import Loading from 'components/Loading';
import TableBuilder from 'components/Table';
import { fetchStories, fetchStoryStates } from 'pages/assignments/actions';
import { findStoryStateNameByGuid } from 'pages/assignments/utils';
import { stateStyles } from 'pages/stories/styles';
import i18n from 'i18n';
import toggleNotification from 'actions/notifications';
import { useDispatch } from 'react-redux';

interface DialogStoriesI {
  selectedStory?: $Lns.Story | string;
  onSave: (story: $Lns.Story | undefined) => void;
  onCancel: () => void;
}

const DialogStories: FunctionComponent<DialogStoriesI> = ({
  selectedStory: propsSelectedStory,
  onSave,
  onCancel
}: DialogStoriesI) => {
  const [stories, setStories] = useState<$Lns.Story[]>();
  const [filteredStories, setFilteredStories] = useState<$Lns.Story[]>([]);

  const [selectedStory, setSelectedStory] = useState<$Lns.Story | undefined>(
    typeof propsSelectedStory !== 'string' ? propsSelectedStory : undefined
  );

  const [storyStates, setStoryStates] = useState<$Lns.StoryState[]>([]);
  const stateClasses = stateStyles();

  const dispatch = useDispatch();

  const rows = [
    i18n.t('pages.stories.tableRow.title'),
    i18n.t('pages.stories.tableRow.slug'),
    i18n.t('pages.stories.tableRow.state')
  ];

  const isHighlighted = (story: $Lns.Story) => {
    if (selectedStory?.guid === story.guid) return true;
    return false;
  };

  const cells = filteredStories.map(storyItem => {
    const storyItemName = findStoryStateNameByGuid(
      storyItem.storyState,
      storyStates
    );

    const findStoryStateClassname = () => {
      switch (storyItemName) {
        case 'draft':
          return stateClasses.draft;
        case 'pending approval':
          return stateClasses.pendingApproval;
        case 'approved':
          return stateClasses.approved;
        default:
          return stateClasses.draft;
      }
    };

    return {
      selected: isHighlighted(storyItem),
      fields: {
        guid: { value: storyItem.guid, hidden: true },
        title: { value: storyItem.title },
        slug: { value: storyItem.slug },
        story: {
          value: (
            <Chip
              classes={{ root: findStoryStateClassname() }}
              label={storyItemName}
            />
          )
        }
      }
    };
  });

  const handleOnRowClick = (item: { guid: { value: string } }) => {
    const story = filteredStories.find(pin => {
      return pin.guid === item.guid.value;
    });
    setSelectedStory(story);
  };

  const handleOnSave = () => {
    if (selectedStory) onSave(selectedStory);
    onCancel();
  };

  useEffect(() => {
    fetchStoryStates()
      .then(storyStatesJson => {
        setStoryStates(storyStatesJson.data);
      })
      .catch(() =>
        dispatch(
          toggleNotification(
            i18n.t('notifications.storiesStates.fetchAllError'),
            'error'
          )
        )
      );
    fetchStories()
      .then(storyJson => {
        setStories(storyJson.data);
        setFilteredStories(storyJson.data);
      })
      .catch(() =>
        dispatch(
          toggleNotification(
            i18n.t('notifications.stories.fetchAllError'),
            'error'
          )
        )
      );
  }, [dispatch]);

  return (
    <Dialog onClose={onCancel} aria-labelledby="edit-assignment-story" open>
      <DialogTitle id="edit-assignment-story">
        {i18n.t('pages.assignments.dialogStory.title')}
      </DialogTitle>
      {selectedStory && (
        <Box paddingX={3}>
          <Typography>
            {i18n.t('pages.assignments.dialogStory.formLabel.selectedStory')}
          </Typography>
          <Typography variant="body2">{selectedStory.title}</Typography>
          <Typography>
            {i18n.t(
              'pages.assignments.dialogStory.formLabel.selectedStorySlug'
            )}
          </Typography>
          <Typography variant="body2">{selectedStory.slug}</Typography>
        </Box>
      )}
      {!selectedStory && (
        <Box paddingX={3}>
          <Typography>Selected Story:</Typography>
          <Typography variant="body2">None Selected</Typography>
          <Typography variant="body2">
            {i18n.t('pages.assignments.dialogStory.title')}
          </Typography>
        </Box>
      )}
      <Box paddingX={3} paddingY={2}>
        {!stories && <Loading />}
        {stories && (
          <TableBuilder
            rows={rows}
            cells={cells}
            onRowClick={handleOnRowClick}
          />
        )}
      </Box>
      <Box padding={2} display="flex" justifyContent="flex-end" width="100%">
        <Button color="secondary" variant="outlined" onClick={onCancel}>
          {i18n.t('button.cancel')}
        </Button>
        <Box paddingLeft={2}>
          <Button color="secondary" variant="contained" onClick={handleOnSave}>
            {i18n.t('button.save')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogStories;

DialogStories.defaultProps = {
  selectedStory: undefined
};
