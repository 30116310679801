import { FunctionComponent } from 'react';
import TableBuilder from 'components/Table';
import EmptyState from 'components/EmptyState';
import i18n from 'i18n';
import { ProtectedAdminActionField, Resource } from 'components/Authorization';

interface PublishFoldersListI {
  publishFolders: $Lns.PublishFolder[];
  onShow: (publishFolder: $Lns.PublishFolder) => void;
  onEdit: (publishFolder: $Lns.PublishFolder) => void;
  onRemove: (publishFolder: $Lns.PublishFolder) => void;
}

const PublishFoldersList: FunctionComponent<PublishFoldersListI> = ({
  publishFolders,
  onShow,
  onEdit,
  onRemove
}: PublishFoldersListI) => {
  const rows = [
    i18n.t('pages.administration.publishFolders.tableRow.name'),
    i18n.t('pages.administration.publishFolders.tableRow.stories'),
    i18n.t('tableRow.actions')
  ];

  const cells = publishFolders.map(item => {
    return {
      fields: {
        name: { value: item.name },
        guid: { value: item.guid, hidden: true },
        stories: { value: item.stories?.length || 0 },
        actions: {
          value: (
            <ProtectedAdminActionField
              lnsResource={Resource.PUBLISH_FOLDER}
              target={item.name}
              onShow={() => onShow(item)}
              onEdit={() => onEdit(item)}
              onDelete={() => onRemove(item)}
            />
          )
        }
      }
    };
  });

  if (publishFolders.length > 0)
    return <TableBuilder rows={rows} cells={cells} />;
  return (
    <EmptyState
      title={i18n.t('pages.administration.publishFolder.emptyState.title')}
      subTitle={i18n.t(
        'pages.administration.publishFolder.emptyState.subTitle'
      )}
    />
  );
};

export default PublishFoldersList;
