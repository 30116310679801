import { FunctionComponent, useEffect, useReducer } from 'react';
import { useParams } from 'react-router';
import { Box, Divider, Grid, Paper } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import i18n from 'i18n';
import Loading from 'components/Loading';
import {
  fetchAccessGroupsOfUserByGuid,
  fetchUserStates
} from 'pages/user/actions';
import { SET_CURRENT_LNS_USER } from 'redux/reducers/types';
import toggleNotification from 'actions/notifications';
import FormData, { FontType } from 'pages/user/types';
import { initialState, reducer } from 'pages/user/reducer';
import HeroPanel from 'pages/user/components/HeroPanel';
import EditLanguage from 'pages/user/components/EditLanguage';
import EditLayout from 'pages/user/components/EditLayout';
import EditProfile from 'pages/user/components/EditProfile';
import EditUserGroups from 'pages/user/components/EditUserGroups';
import EditLocale, {
  FormData as LocaleFormData
} from 'pages/user/components/EditLocale';
import { updateUser, fetchUserByGuid } from 'actions/user';
import useStyles from 'pages/user/components/styles';
import { useCanAccess } from 'components/Authorization/utils';
import { Resource, Action } from 'components/Authorization';

const UserPage: FunctionComponent = () => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const canEdit = useCanAccess(Resource.SECURITY, Action.EDIT);
  const { guid: userGuid } = useParams();

  const { currentSsoUser } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const [state, dispatchReducer] = useReducer(reducer, {
    ...initialState,
    canEdit: !!currentSsoUser, // should be bounded by currentSsoUser
    isMe: currentSsoUser.guid === userGuid
  });

  const handleApiError = () => {
    dispatchRedux(
      toggleNotification(i18n.t('notifications.apiError'), 'error')
    );
  };

  useEffect(() => {
    dispatchReducer({
      type: 'reset',
      payload: {
        ...initialState,
        canEdit, // !!currentSsoUser should be bounded by currentSsoUser
        isMe: currentSsoUser.guid === userGuid
      }
    });

    fetchUserByGuid(userGuid).then(({ data, code }) => {
      const inputData = data;
      if (code === 200) {
        inputData.config = {
          ...initialState.formData.config,
          ...inputData.config
        };
        dispatchReducer({
          type: 'setViewUser',
          payload: inputData
        });
      }
    });

    fetchAccessGroupsOfUserByGuid(userGuid).then(({ data: payload }) => {
      dispatchReducer({ type: 'setUserGroups', payload });
    });

    fetchUserStates().then(({ data: payload }) => {
      dispatchReducer({
        type: 'setUserStates',
        payload
      });
    });
  }, [userGuid, currentSsoUser, canEdit]);

  const onSave = (formData: FormData) => {
    updateUser(formData)
      .then(() => {
        dispatchRedux(
          toggleNotification(
            i18n.t('notifications.users.editSuccess'),
            'success'
          )
        );

        fetchUserByGuid(userGuid).then(({ data: payload }) => {
          dispatchReducer({
            type: 'setViewUser',
            payload
          });

          if (currentSsoUser && state.isMe) {
            dispatchRedux({
              type: SET_CURRENT_LNS_USER,
              currentLnsUser: payload
            });
          }
        });
      })
      .catch(handleApiError);
  };

  const onSaveLanguage = (newLanguage: string) => {
    if (state.viewUser) {
      const updatedFormData = {
        ...state.viewUser,
        errors: initialState.formData.errors
      };
      updatedFormData.config.language = newLanguage;
      onSave(updatedFormData);
    }
  };

  const onSaveLocation = (formData: LocaleFormData) => {
    const updatedFormData = { ...state.formData, ...state.viewUser };
    updatedFormData.config.location = formData.location;
    updatedFormData.config.timezone = formData.timezone;
    onSave(updatedFormData);
  };

  const onSaveLayout = (newLayout: { font: FontType }) => {
    if (state.viewUser) {
      const updatedFormData = {
        ...state.viewUser,
        errors: initialState.formData.errors
      };
      updatedFormData.config.layoutSettings = newLayout;
      onSave(updatedFormData);
    }
  };

  const onSaveStatus = (newStatus: string) => {
    if (state.viewUser) {
      const updatedFormData = {
        ...state.viewUser,
        errors: initialState.formData.errors
      };
      updatedFormData.userState = newStatus;
      onSave(updatedFormData);
    }
  };

  if (!state?.viewUser) return <></>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <Paper className={classes.grayPaper}>
          <HeroPanel
            user={state.viewUser}
            userStates={state.userStates || []}
            canEdit={canEdit || state.isMe}
            onSaveCallback={onSaveStatus}
          />
          <Divider />
          {state?.viewUser && (
            <EditProfile
              user={state.viewUser}
              canEdit={canEdit || state.isMe}
              onSaveCallback={onSave}
            />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box marginBottom={2}>
          <Paper className={classes.grayPaper}>
            {state?.viewUser && (
              <EditLayout
                user={state.viewUser}
                canEdit={canEdit || state.isMe}
                onSaveCallback={onSaveLayout}
              />
            )}
          </Paper>
        </Box>
        <Paper className={classes.grayPaper}>
          {state?.viewUser && (
            <EditLanguage
              user={state.viewUser}
              canEdit={canEdit || state.isMe}
              onSaveCallback={onSaveLanguage}
            />
          )}
          <Divider />
          {state?.viewUser && (
            <EditLocale
              user={state.viewUser}
              canEdit={canEdit || state.isMe}
              onSaveCallback={onSaveLocation}
            />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Paper className={classes.grayPaper}>
          {state?.userGroups && (
            <EditUserGroups userGroups={state.userGroups} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );

  return <Loading />;
};

export default UserPage;
