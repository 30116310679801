import { ChangeEvent, FunctionComponent, useState } from 'react';
import { StoryFormData } from 'pages/stories/interface';
import { isFormValid, updateFormData } from 'utils/form';
import { Box, Button, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import validateForm from 'pages/stories/utils';
import i18n from 'i18n';
import SimpleFields from 'pages/stories/components/Form/SimpleFields';

interface StoryFormI {
  onSave: (formData: StoryFormData) => void;
  onCancel?: () => void;
  defaultState?: { title: string; script: string; text: string };
}

const StoryForm: FunctionComponent<StoryFormI> = ({
  onSave,
  onCancel,
  defaultState
}: StoryFormI) => {
  const { storyTypes, storyGenres, locations, storyStates } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const initialState = {
    title: defaultState?.title || '',
    slug: '',
    type: storyTypes.length ? storyTypes[0].guid : '',
    plannedDuration: 0,
    calculatedDuration: 0,
    notes: '',
    text: defaultState?.text || '',
    script: defaultState?.script || '',
    storyState: storyStates.length ? storyStates[0].guid : '',
    location: locations.length ? locations[0].guid : '',
    genre: storyGenres.length ? storyGenres[0].guid : '',
    errors: {
      title: '',
      slug: '',
      type: ''
    }
  };

  const [formData, setFormData] = useState<StoryFormData>(initialState);

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const formDataInput = capsTitles(formData);
    const updatedFormData = updateFormData(e, formDataInput, validateForm);
    setFormData(updatedFormData);
  };

  const capsTitles = (data: StoryFormData) => {
    const updateData = { ...data };
    updateData.slug = data.slug.toLocaleUpperCase();
    updateData.title = data.title.toLocaleUpperCase();
    return updateData;
  };

  const onChangeType = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value, name } = e.target;

    setFormData({
      ...formData,
      [name || '']: value
    });
  };

  const btnDisabled = !isFormValid(
    ['slug', 'title', 'type', 'genre', 'location'],
    formData
  );

  return (
    <Box padding={4}>
      <Grid container spacing={2}>
        <SimpleFields
          formData={formData}
          storyTypes={storyTypes}
          storyGenres={storyGenres}
          locations={locations}
          onChangeField={onChangeField}
          onChangeType={onChangeType}
        />
        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-test-id="save-btn"
              disabled={btnDisabled}
              color="secondary"
              variant="contained"
              onClick={() => onSave(formData)}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StoryForm;

StoryForm.defaultProps = {
  onCancel: undefined,
  defaultState: undefined
};
