import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import {
  useWithPermissions,
  Resource,
  Action,
  ProtectedAdminActionField
} from 'components/Authorization';

interface ListI {
  users: $Lns.User[];
  isAdmin: boolean;
  onEdit: (userItem: $Lns.User) => void;
}

const List: FunctionComponent<ListI> = ({ users, isAdmin, onEdit }: ListI) => {
  const rows = [
    i18n.t('pages.administration.users.tableRow.username'),
    i18n.t('pages.administration.users.tableRow.email'),
    i18n.t('pages.administration.users.tableRow.contactNumber'),
    i18n.t('pages.administration.users.tableRow.team')
  ];

  if (isAdmin) {
    rows.push(i18n.t('tableRow.actions'));
  }

  const cells = users.map((item: $Lns.User) => {
    const fields = {
      guid: { value: item.guid, hidden: true },
      username: { value: item.username },
      email: { value: item.email },
      contactNumber: { value: item.contactNumber },
      team: { value: item.team }
    };

    if (isAdmin)
      return {
        fields: {
          ...fields,
          actions: {
            value: (
              <ProtectedAdminActionField
                lnsResource={Resource.SECURITY}
                target={item.username}
                onEdit={() => onEdit(item)}
              />
            )
          }
        }
      };
    return { fields };
  });

  const navigate = useNavigate();

  const onRowClick = useWithPermissions(
    (rowItem: { guid: { value: string } }) => {
      navigate(`/administration/security/users/${rowItem.guid.value}`);
    },
    Resource.SECURITY,
    Action.READ
  );

  return <TableBuilder rows={rows} cells={cells} onRowClick={onRowClick} />;
};

export default List;
