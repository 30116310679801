import i18n from 'i18n';
import validator from 'validator';
import { fetchUserByGuid } from 'actions/user';

export const validateForm = (name: string, value: string): string => {
  let error = '';

  if (name === 'username') {
    error = validator.isEmpty(value) ? i18n.t('formErrors.isEmpty') : '';
    error = !validator.isAlphanumeric(value)
      ? i18n.t('formErrors.isAlphanumeric')
      : '';
  }

  if (name === 'email') {
    error = !validator.isEmail ? i18n.t('formErrors.isEmail') : '';
  }

  if (name === 'contactNumber') {
    error = !validator.isMobilePhone
      ? i18n.t('formErrors.isContactNumber')
      : '';
  }
  return error;
};

export const handleFetchUser = async (
  user: string | $Lns.User
): Promise<$Lns.User | undefined> => {
  if (typeof user === 'string') {
    const userName = fetchUserByGuid(user).then(
      ({ code: fetchUserCode, data: fetchUserData }) => {
        if (fetchUserCode === 200) {
          return fetchUserData;
        }
        return undefined;
      }
    );

    return await userName;
  }
  return user;
};
