import { Box, Grid, Button, Typography, TextField } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { FunctionComponent, ChangeEvent, useState } from 'react';
import shortid from 'shortid';
import { generalStyle } from 'styles/generalStyle';
import i18n from 'i18n';

interface FileUploadI {
  file?: File;
  storyLock: boolean;
  onUploadHandle: (asset: File) => void;
}

const FileUpload: FunctionComponent<FileUploadI> = ({
  file,
  storyLock,
  onUploadHandle
}: FileUploadI) => {
  const elementId = shortid.generate();
  const generalStyles = generalStyle();
  const [assetFile, setAssetFile] = useState<File>();

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target) {
      const { files } = e.target;
      if (files && files?.length > 0) {
        setAssetFile(files[0]);
      }
    }
  };

  const onUpload = () => {
    if (assetFile) onUploadHandle(assetFile);
  };

  const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e && e.dataTransfer) {
      const dataFiles = e.dataTransfer.files;
      if (dataFiles.length > 0) {
        setAssetFile(dataFiles[0]);
      }
    }
  };

  const onFileDropOver = (e: React.DragEvent<HTMLDivElement>) =>
    e.preventDefault();

  return (
    <Grid container direction="row" spacing={4}>
      <Grid item xs={12}>
        <Typography component="label" htmlFor={elementId}>
          <TextField
            id={elementId}
            type="file"
            value={file?.name || ''}
            onChange={onFileChange}
            style={{ display: 'none' }}
          />
          <Box
            className={generalStyles.uploadZone}
            onDragEnter={onFileDropOver}
            onDragOver={onFileDropOver}
            onDropCapture={onFileDrop}
            onDrop={onFileDrop}
          >
            <Add color="primary" fontSize="large" />
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={9}>
            <Typography>{assetFile?.name}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={storyLock}
              variant="contained"
              color="primary"
              component="span"
              onClick={onUpload}
            >
              {i18n.t('pages.stories.helperText.uploadAsset')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FileUpload;

FileUpload.defaultProps = {
  file: undefined
};
