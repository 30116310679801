import { makeStyles } from '@material-ui/core';

const tableStyles = makeStyles({
  root: {
    minWidth: 275
  },
  tableContainer: {
    maxHeight: 800,
    maxWidth: '100vw',
    marginBottom: '5%'
  },
  footerRow: {
    backgroundColor: '#f5f3ef'
  }
});

export default tableStyles;
