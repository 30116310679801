import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  IconButton,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import i18n from 'i18n';
import useStyles from 'pages/administration/peripheral-settings/protocols/components/styles';
import {
  RowDataType,
  RowErrorType
} from 'pages/administration/peripheral-settings/protocols/components/types';
import { validateForm } from 'pages/administration/peripheral-settings/protocols/utils';

interface ConfigRowI {
  configName: string;
  configType: string;
  configDescription: string;
  onChangeCallback: (rowData: RowDataType) => void;
  onDeleteCallback: () => void;
}

const ConfigRow: FunctionComponent<ConfigRowI> = ({
  configName: configNameProps,
  configType: configTypeProps,
  configDescription: configDescrtionProps,
  onChangeCallback,
  onDeleteCallback
}: ConfigRowI) => {
  // Get Custom tyles
  const classes = useStyles();

  // State Data
  const [state, setState] = useState<{
    rowData: RowDataType;
    errors: RowErrorType;
  }>({
    rowData: {
      configName: configNameProps,
      configType: configTypeProps,
      configDescription: configDescrtionProps
    },
    errors: {
      configName: '',
      configType: '',
      configDescription: ''
    }
  });

  // States Quickhands
  const { rowData, errors } = state;
  const { configName, configType, configDescription } = rowData;

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedStates = {
      rowData: {
        ...rowData,
        [name]: value
      },
      errors: {
        ...errors,
        [name]: validateForm(name, value)
      }
    };
    setState(updatedStates);
  };

  const onChangeSelect = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const { value } = e.target;

    const updatedStates = {
      rowData: {
        ...rowData,
        configType: value as string
      },
      errors: {
        ...errors,
        configType: validateForm('configType', value as string)
      }
    };
    setState(updatedStates);
  };

  const onDelete = () => {
    onDeleteCallback();
  };

  useEffect(() => {
    if (onChangeCallback) onChangeCallback(rowData);
  }, [rowData, onChangeCallback]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <TextField
          id="configName"
          name="configName"
          value={configName}
          onChange={onChangeField}
          autoComplete="name"
          label={i18n.t('pages.administration.protocols.formLabel.configName')}
          helperText={i18n.t(
            'pages.administration.protocols.formHelperText.configName'
          )}
          error={!!errors.configName}
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl
          className={classes.formControl}
          variant="outlined"
          size="small"
          fullWidth
          required
        >
          <InputLabel>
            {i18n.t('pages.administration.protocols.formLabel.configType')}
          </InputLabel>
          <Select
            id="configType"
            labelId="select-config-type"
            name="configType"
            placeholder="type"
            value={configType}
            onChange={onChangeSelect}
            variant="outlined"
            required
            label={i18n.t(
              'pages.administration.protocols.formLabel.configType'
            )}
          >
            <MenuItem key="string" value="string">
              String
            </MenuItem>
            <MenuItem key="number" value="number">
              Number
            </MenuItem>
            <MenuItem key="boolean" value="boolean">
              Boolean
            </MenuItem>
          </Select>
          <FormHelperText>{i18n.t('helperText.required')}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          id="configDescription"
          name="configDescription"
          value={configDescription}
          onChange={onChangeField}
          autoComplete="description"
          label={i18n.t(
            'pages.administration.protocols.formLabel.configDescription'
          )}
          helperText={i18n.t(
            'pages.administration.protocols.formHelperText.configDescription'
          )}
          error={!!errors.configDescription}
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={12} sm={1}>
        <IconButton
          className={classes.iconButton}
          size="medium"
          onClick={onDelete}
        >
          <Delete />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default ConfigRow;
