import { createStyles, makeStyles, Theme } from '@material-ui/core';

const notificationStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '50%',
      top: theme.spacing(1)
    },
    alertRoot: {
      width: '50%'
    }
  })
);

export default notificationStyles;
