import { TableRow } from '@material-ui/core';
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle
} from 'react-beautiful-dnd';

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)'
  })
});

const DraggableComponent =
  (
    id: string,
    index: number,
    isDraggable: boolean
    // eslint-disable-next-line
  ) =>
  // eslint-disable-next-line
  (props: any) => {
    return (
      <Draggable isDragDisabled={!isDraggable} draggableId={id} index={index}>
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
            {...props}
          >
            {props.children}
          </TableRow>
        )}
      </Draggable>
    );
  };

export default DraggableComponent;
