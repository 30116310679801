import { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';
import FileTypes from 'pages/administration/file-types';
import StoryGenres from 'pages/administration/story-genres';
import StoryTypes from 'pages/administration/story-types';
import StoryPools from 'pages/administration/story-pools';
import PeripheralSettings from 'pages/administration/peripheral-settings';
import Peripherals from 'pages/administration/peripheral-settings/peripherals';
import PeripheralTemplates from 'pages/administration/peripheral-settings/templates';
import PeripheralTypes from 'pages/administration/peripheral-settings/types';
import Protocols from 'pages/administration/peripheral-settings/protocols';
import RundownTemplates from 'pages/administration/rundown-templates';
import Locations from 'pages/administration/locations';
import Setup from 'pages/administration/setup';
import SocialMediaFeeds from 'pages/administration/social-media-feeds';
import Newswires from 'pages/administration/newswires';
import PublishFolders from 'pages/administration/publish-folders';
import Users from 'pages/administration/security/users';
import UserGroups from 'pages/administration/security/groups';
import EditUserGroups from 'pages/administration/security/groups/edit';
import Security from 'pages/administration/security';
import Media from 'pages/administration/media';
import { ProtectedRoute, Resource, Action } from 'components/Authorization';
import UserPage from 'pages/user';

const SettingRoutes: FunctionComponent = () => {
  return (
    <Routes>
      <ProtectedRoute
        lnsResource={Resource.SYSTEM_SETUP}
        lnsAction={Action.ADMIN}
        path="/setup"
        element={<Setup />}
      />
      <ProtectedRoute
        lnsResource={Resource.RUNDOWN_TEMPLATE}
        lnsAction={Action.ADMIN}
        path="/rundown-templates"
        element={<RundownTemplates />}
      />
      <ProtectedRoute
        lnsResource={Resource.STORY_TYPE}
        lnsAction={Action.ADMIN}
        path="/story-types"
        element={<StoryTypes />}
      />
      <ProtectedRoute
        lnsResource={Resource.STORY_POOL}
        lnsAction={Action.ADMIN}
        path="/story-pools"
        element={<StoryPools />}
      />
      <ProtectedRoute
        lnsResource={Resource.STORY_GENRE}
        lnsAction={Action.ADMIN}
        path="/story-genres"
        element={<StoryGenres />}
      />
      <ProtectedRoute
        lnsResource={Resource.PERIPHERAL}
        lnsAction={Action.ADMIN}
        path="/peripheral-settings"
        element={<PeripheralSettings />}
      />
      <ProtectedRoute
        lnsResource={Resource.PERIPHERAL}
        lnsAction={Action.ADMIN}
        path="/peripheral-settings/peripherals"
        element={<Peripherals />}
      />
      <ProtectedRoute
        lnsResource={Resource.PERIPHERAL}
        lnsAction={Action.ADMIN}
        path="/peripheral-settings/templates"
        element={<PeripheralTemplates />}
      />
      <ProtectedRoute
        lnsResource={Resource.PERIPHERAL}
        lnsAction={Action.ADMIN}
        path="/peripheral-settings/protocols"
        element={<Protocols />}
      />
      <ProtectedRoute
        lnsResource={Resource.PERIPHERAL}
        lnsAction={Action.ADMIN}
        path="/peripheral-settings/types"
        element={<PeripheralTypes />}
      />
      <ProtectedRoute
        lnsResource={Resource.PUBLISH_FOLDER}
        lnsAction={Action.ADMIN}
        path="/publish-folders"
        element={<PublishFolders />}
      />
      <ProtectedRoute
        lnsResource={Resource.LOCATION}
        lnsAction={Action.ADMIN}
        path="/locations"
        element={<Locations />}
      />
      <ProtectedRoute
        lnsResource={Resource.SECURITY}
        lnsAction={Action.ADMIN}
        path="/security"
        element={<Security />}
      />
      <ProtectedRoute
        lnsResource={Resource.SECURITY}
        lnsAction={Action.ADMIN}
        path="/security/users"
        element={<Users isAdmin />}
      />
      <ProtectedRoute
        lnsResource={Resource.SECURITY}
        lnsAction={Action.ADMIN}
        path="/security/user-groups"
        element={<UserGroups />}
      />
      <ProtectedRoute
        lnsResource={Resource.SECURITY}
        lnsAction={Action.ADMIN}
        path="/security/user-groups/:guid"
        element={<EditUserGroups />}
      />
      <ProtectedRoute
        lnsResource={Resource.FILE_TYPE}
        lnsAction={Action.ADMIN}
        path="/file-types"
        element={<FileTypes />}
      />
      <ProtectedRoute
        lnsResource={Resource.NEWSWIRE}
        lnsAction={Action.ADMIN}
        path="/newswires"
        element={<Newswires />}
      />
      <Route path="/media" element={<Media />} />
      <Route path="/security/users/:guid" element={<UserPage />} />
      <Route path="/social-media-feeds" element={<SocialMediaFeeds />} />
    </Routes>
  );
};

export default SettingRoutes;
