import { filterUsers } from './utils';

type Action =
  | {
      type: 'newProps';
      payload: $Lns.User[];
    }
  | {
      type: 'addUser' | 'removeUser';
      payload: $Lns.User;
    }
  | {
      type: 'onSearch';
      payload: string;
    };

export type State = {
  userItems: $Lns.User[];
  selectedUsers: $Lns.User[];
  filteredUsers: $Lns.User[];
  filterString: string;
};

export const initialState = {
  userItems: [],
  selectedUsers: [],
  filteredUsers: [],
  filterString: ''
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'newProps': {
      return {
        ...state,
        userItems: action.payload,
        selectedUsers: [],
        filteredUsers: filterUsers(state.filterString, action.payload, [])
      };
    }
    case 'addUser': {
      const newSelectedUsers = [...state.selectedUsers, action.payload];
      return {
        ...state,
        selectedUsers: newSelectedUsers,
        filteredUsers: filterUsers(
          state.filterString,
          state.userItems,
          newSelectedUsers
        )
      };
    }
    case 'removeUser': {
      const newSelectedUsers = state.selectedUsers.filter(
        user => user.guid !== action.payload.guid
      );

      return {
        ...state,
        selectedUsers: newSelectedUsers,
        filteredUsers: filterUsers(
          state.filterString,
          state.userItems,
          newSelectedUsers
        )
      };
    }
    case 'onSearch': {
      return {
        ...state,
        filterString: action.payload,
        filteredUsers: filterUsers(
          action.payload,
          state.userItems,
          state.selectedUsers
        )
      };
    }
    default:
      return state;
  }
};
