import moment, { MomentZoneOffset } from 'moment-timezone';

const timezoneCountries = ['US', 'CA', 'GB', 'DE'];
let timezonesBuilder: MomentZoneOffset[] = [];
timezoneCountries.forEach(item => {
  const zonesInCountry = moment.tz.zonesForCountry(item, true);
  timezonesBuilder = [...timezonesBuilder, ...zonesInCountry];
});
const timezones: MomentZoneOffset[] = timezonesBuilder;
export { timezones };
