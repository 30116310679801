import { makeStyles } from '@material-ui/styles';
import colors from 'styles/colors';

export const gridTableStyles = makeStyles({
  root: {
    fontWeight: 600
  },
  columnHeader: {
    '&:focus-within': {
      outline: '0px solid !important'
    },
    color: colors.neutral[40],
    backgroundColor: colors.primary[50]
  },
  cell: {
    '&.MuiDataGrid-cell:focus-within': {
      outline: '0px solid !important'
    },
    textAlign: 'left',
    color: colors.neutral[40]
  }
});
