import { ChangeEvent, FunctionComponent } from 'react';
import { Timer } from '@material-ui/icons';
import { secondsToDuration } from 'utils/time';
import { StoryFormData } from 'pages/stories/interface';
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import i18n from 'i18n';
import { generalStyle } from 'styles/generalStyle';

interface DetailedFieldsI {
  formData: StoryFormData;
  storyLock: boolean;
  calculatedDuration: number;
  locations: $Lns.Location[];
  storyTypes: $Lns.StoryType[];
  storyGenres: $Lns.StoryGenre[];
  storyPools: $Lns.StoryPool[];
  onChangeField: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeType: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
}

const DetailedFields: FunctionComponent<DetailedFieldsI> = ({
  formData,
  calculatedDuration,
  storyLock,
  locations,
  storyTypes,
  storyPools,
  storyGenres,
  onChangeField,
  onChangeType
}: DetailedFieldsI) => {
  const generalStyles = generalStyle();

  const getGenre = (guid: string) => {
    return storyGenres.find(sg => sg.guid === guid);
  };

  const getPool = (guid: string) => {
    return storyPools.find(sg => sg.guid === guid);
  };

  const getStoryType = (guid: string) => {
    return storyTypes.find(st => st.guid === guid);
  };

  const getLocation = (guid: string) => {
    return locations.find(l => l.guid === guid);
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <TextField
          disabled={storyLock}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="slug"
          className={generalStyles.textUppercase}
          label={i18n.t('pages.stories.formLabel.slug')}
          value={formData.slug}
          helperText={
            formData.errors.slug || i18n.t('pages.stories.placeHolder.slug')
          }
          name="slug"
          onChange={onChangeField}
          error={!!formData?.errors?.slug}
          autoComplete="slug"
          autoFocus
        />
      </Grid>
      <Grid item>
        <TextField
          disabled={storyLock}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="title"
          className={generalStyles.textUppercase}
          label={i18n.t('pages.stories.formLabel.title')}
          value={formData.title}
          helperText={
            formData.errors.title || i18n.t('pages.stories.placeHolder.title')
          }
          name="title"
          onChange={onChangeField}
          error={!!formData?.errors?.title}
          autoComplete="title"
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <FormControl margin="normal" variant="outlined" fullWidth>
            <InputLabel id="demo-mutiple-name-label">
              {i18n.t('pages.stories.helperText.genre')}
            </InputLabel>
            <Select
              fullWidth
              disabled={storyLock}
              labelId="genre"
              id="genre"
              name="genre"
              label={i18n.t('pages.stories.helperText.genre')}
              placeholder={i18n.t('pages.stories.placeHolder.genre')}
              required
              value={formData.genre}
              onChange={onChangeType}
              displayEmpty
              renderValue={(value: unknown) =>
                getGenre(value as string)?.name ||
                i18n.t('pages.stories.helperText.genre')
              }
            >
              {storyGenres.map(storyGenre => (
                <MenuItem key={storyGenre.key} value={storyGenre.guid}>
                  {storyGenre.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl margin="normal" variant="outlined" fullWidth>
            <InputLabel id="demo-mutiple-name-label">
              {i18n.t('pages.stories.helperText.pool')}
            </InputLabel>

            <Select
              fullWidth
              disabled={storyLock}
              labelId="pool"
              id="pool"
              multiple
              name="pool"
              displayEmpty
              label={i18n.t('pages.stories.helperText.pool')}
              placeholder={i18n.t('pages.stories.placeHolder.pool')}
              value={formData.pools}
              onChange={onChangeType}
              renderValue={(selected: unknown) => (
                <Box display="flex" flexWrap="wrap">
                  {(selected as { guid: string }[]).map(
                    (value: { guid: string }) => {
                      return (
                        <Chip
                          style={{ margin: 2 }}
                          key={value.guid}
                          label={getPool(value.guid)?.name}
                        />
                      );
                    }
                  )}
                </Box>
              )}
            >
              {storyPools.map(storyPool => {
                const pool = formData.pools?.find(
                  p => p.guid === storyPool.guid
                );

                return (
                  <MenuItem
                    selected
                    className={pool ? 'Mui-selected' : ''}
                    key={storyPool.guid}
                    value={storyPool.guid}
                  >
                    {storyPool.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <FormControl margin="normal" variant="outlined" fullWidth>
            <InputLabel id="demo-mutiple-name-label">
              {i18n.t('pages.stories.helperText.location')}
            </InputLabel>
            <Select
              labelId="location"
              disabled={storyLock}
              id="location"
              name="location"
              label={i18n.t('pages.stories.helperText.location')}
              placeholder={i18n.t('pages.stories.placeHolder.location')}
              value={formData.location}
              onChange={onChangeType}
              displayEmpty
              renderValue={(value: unknown) =>
                getLocation(value as string)?.city ||
                i18n.t('pages.stories.helperText.location')
              }
            >
              {locations.map(location => {
                return (
                  <MenuItem key={location.guid} value={location.guid}>
                    {location.city}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl margin="normal" variant="outlined" fullWidth>
            <InputLabel id="demo-mutiple-name-label">
              {i18n.t('pages.stories.helperText.type')}
            </InputLabel>
            <Select
              labelId="type"
              id="type"
              disabled={storyLock}
              name="type"
              label={i18n.t('pages.stories.helperText.type')}
              placeholder={i18n.t('pages.stories.placeHolder.type')}
              required
              value={formData.type}
              onChange={onChangeType}
              displayEmpty
              renderValue={(value: unknown) =>
                getStoryType(value as string)?.name ||
                i18n.t('pages.stories.helperText.type')
              }
            >
              {storyTypes.map(storyType => (
                <MenuItem key={storyType.key} value={storyType.guid}>
                  {storyType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={storyLock}
            variant="outlined"
            margin="normal"
            type="number"
            id="plannedDuration"
            label={i18n.t('pages.stories.formLabel.plannedDuration')}
            inputProps={{ min: 0 }}
            value={formData.plannedDuration}
            helperText={
              formData.errors.plannedDuration ||
              i18n.t('pages.stories.helperText.seconds')
            }
            name="plannedDuration"
            onChange={onChangeField}
            error={!!formData?.errors?.plannedDuration}
            autoComplete="plannedDuration"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled={storyLock}
            variant="outlined"
            label={i18n.t('pages.stories.formLabel.plannedDuration')}
            name="plannedDurationString"
            margin="normal"
            helperText={
              formData.errors.number ||
              i18n.t('pages.stories.helperText.duration')
            }
            value={secondsToDuration(formData.plannedDuration || 0)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Timer />{' '}
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            disabled
            variant="outlined"
            label={i18n.t('pages.stories.formLabel.calculatedDuration')}
            margin="normal"
            helperText={
              formData.errors.number ||
              i18n.t('pages.stories.formLabel.calculatedDuration')
            }
            value={secondsToDuration(calculatedDuration) || 0}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Timer />{' '}
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailedFields;
