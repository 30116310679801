import { makeStyles, Theme } from '@material-ui/core';

const loadingButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    position: 'absolute'
  }
}));

export default loadingButtonStyles;
