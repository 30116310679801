import { FunctionComponent } from 'react';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { Box } from '@material-ui/core';

interface BooleanIconI {
  value: boolean;
}

const BooleanIcon: FunctionComponent<BooleanIconI> = ({
  value
}: BooleanIconI) => {
  return (
    <Box display="grid">
      {value ? <CheckCircle color="primary" /> : <Cancel color="disabled" />}
    </Box>
  );
};

export default BooleanIcon;
