import { callLnsApi } from 'utils/fetch';

export const fetchPublishFolders = (): Promise<{
  code: number;
  data: $Lns.PublishFolder[];
}> => callLnsApi('/publish');

export const fetchPublishFolderStoriesByGuid = (
  guid: string
): Promise<{
  code: number;
  data: $Lns.Story[];
}> => callLnsApi(`/publish/${guid}`);

export const createPublishFolder = (
  newFolder: $Lns.PublishFolder
): Promise<{
  code: number;
  data: $Lns.PublishFolder;
}> => callLnsApi('/publish', 'POST', newFolder);

export const updatePublishFolder = (
  publishFolder: $Lns.PublishFolder
): Promise<{
  code: number;
  data: $Lns.PublishFolder;
}> => callLnsApi(`/publish/${publishFolder.guid}`, 'POST', publishFolder);

export const removePublishFolder = (
  publishFolder: $Lns.PublishFolder
): Promise<{
  code: number;
  message?: string;
}> => callLnsApi(`/publish/${publishFolder.guid}`, 'DELETE');

export const updatePublishFolderStories = (
  publishFolderGuid: string,
  stories: $Lns.Story[]
): Promise<{
  code: number;
  data: $Lns.PublishFolder;
}> =>
  callLnsApi(`/publish/${publishFolderGuid}/stories`, 'POST', {
    publishFolder: publishFolderGuid,
    stories: stories.map((item: $Lns.Story) => {
      return { story: item.guid };
    })
  });
