import { createStyles, makeStyles } from '@material-ui/styles';
import { TaskPriorityColors } from 'pages/assignments/consts';

const usePriorityStyles = makeStyles(() =>
  createStyles({
    low: {
      color: TaskPriorityColors.low,
      cursor: 'default'
    },
    normal: {
      color: TaskPriorityColors.normal,
      cursor: 'default'
    },
    high: {
      color: TaskPriorityColors.high,
      cursor: 'default'
    },
    urgent: {
      color: TaskPriorityColors.urgent,
      cursor: 'default'
    }
  })
);

export { usePriorityStyles };
