import { TaskStates } from 'pages/assignments/consts';
import { FormData } from 'pages/assignments/formData';
import { findTaskStateByGuid } from 'pages/assignments/utils/search';

export const filterAvailableTaskStates = (
  taskStates: $Lns.TaskState[],
  assignment: FormData,
  currentUser: $Lns.User,
  isAdmin: boolean
): $Lns.TaskState[] => {
  const { assignedTo } = assignment;

  const isNew =
    findTaskStateByGuid(assignment.taskState, taskStates)?.name ===
    TaskStates.NEW;

  const isPending =
    findTaskStateByGuid(assignment.taskState, taskStates)?.name ===
    TaskStates.PENDING_ASSIGNMENT;

  const isRejected =
    findTaskStateByGuid(assignment.taskState, taskStates)?.name ===
    TaskStates.REJECTED;

  const isAssignedTo = currentUser.guid === assignedTo;

  // Admin can do anything
  if (isAdmin) return taskStates;

  // New can't be changed via selection
  if (isNew) return taskStates.filter(state => state.name === TaskStates.NEW);

  // Pending can't be changed via selection
  if (isPending)
    return taskStates.filter(
      state => state.name === TaskStates.PENDING_ASSIGNMENT
    );

  // Pending can't be changed via selection
  if (isRejected)
    return taskStates.filter(state => state.name === TaskStates.REJECTED);

  // States that could be choose when user is the AssignedTo User
  const assignedToWorkFlow: string[] = [
    TaskStates.ASSIGNED,
    TaskStates.IN_PROGRESS,
    TaskStates.COMPLETED
  ];

  if (isAssignedTo) {
    return taskStates.filter(state => assignedToWorkFlow.includes(state.name));
  }

  // AssignedBy user or the Manager can reject a completion
  // and re-assign the task to someone else, this is not dealt with here

  // Default case being the State CANNOT be changed.
  return taskStates.filter(state => state.guid === assignment.taskState);
};
