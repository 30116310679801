import { FunctionComponent, useState } from 'react';
import { nestedItems } from 'constants/administration';
import { useNavigate } from 'react-router';
import { Divider, Drawer, IconButton, List, useTheme } from '@material-ui/core';
import {
  ChevronLeft,
  ChevronRight,
  Dns,
  RssFeed,
  StarOutline,
  AssignmentInd,
  Settings,
  ListAlt,
  Folder
} from '@material-ui/icons';
import mainLayoutStyles from 'styles/mainLayout';
import clsx from 'clsx';
import i18n from 'i18n';
import {
  ProtectedSidebarListItem,
  Resource,
  Action
} from 'components/Authorization';
import { useSelector } from 'react-redux';

interface MainSidebarI {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

const MainSidebar: FunctionComponent<MainSidebarI> = ({
  open,
  handleDrawerOpen,
  handleDrawerClose
}: MainSidebarI) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedGuid, setSelectedGuid] = useState<string>();

  const onClickSettings = (item: { name: string; guid: string }) => {
    setSelectedGuid(item.guid);
    navigate(`/administration/${item.guid}`);
  };

  const onResetAdminSelection = () => {
    setSelectedGuid(undefined);
  };

  const onClickMain = () => {
    onResetAdminSelection();
  };

  const { currentUserPermissions } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const showAdminSidebar = Object.values(currentUserPermissions).some(
    p => !!p.admin
  );

  const classes = mainLayoutStyles();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx({
          [classes.drawerBg]: true,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ProtectedSidebarListItem
          title={i18n.t('links.sidebar.myLns')}
          linkPath="/my-lns"
          icon={<StarOutline color="primary" />}
          sideBarOpen={open}
          onClickMain={onClickMain}
          handleDrawerOpen={handleDrawerOpen}
        />

        <ProtectedSidebarListItem
          title="Assignments"
          linkPath="/assignments"
          icon={<AssignmentInd color="primary" />}
          sideBarOpen={open}
          onClickMain={onClickMain}
          handleDrawerOpen={handleDrawerOpen}
          lnsResource={Resource.TASK}
          lnsAction={Action.LIST}
        />

        <ProtectedSidebarListItem
          title={i18n.t('links.sidebar.storyPools')}
          linkPath="/story-pools"
          icon={<ListAlt color="primary" />}
          sideBarOpen={open}
          onClickMain={onClickMain}
          handleDrawerOpen={handleDrawerOpen}
          lnsResource={Resource.STORY_POOL}
          lnsAction={Action.LIST}
        />

        <ProtectedSidebarListItem
          title={i18n.t('links.sidebar.newswires')}
          linkPath="/newswires"
          icon={<RssFeed color="primary" />}
          sideBarOpen={open}
          onClickMain={onClickMain}
          handleDrawerOpen={handleDrawerOpen}
          lnsResource={Resource.NEWSWIRE}
          lnsAction={Action.LIST}
        />

        <ProtectedSidebarListItem
          title={i18n.t('links.sidebar.rundowns')}
          linkPath="/rundowns"
          icon={<Dns color="primary" />}
          sideBarOpen={open}
          onClickMain={onClickMain}
          handleDrawerOpen={handleDrawerOpen}
          lnsResource={Resource.RUNDOWN}
          lnsAction={Action.LIST}
        />

        <ProtectedSidebarListItem
          title={i18n.t('links.sidebar.publishFolders')}
          linkPath="/publish-folders"
          icon={<Folder color="primary" />}
          sideBarOpen={open}
          onClickMain={onClickMain}
          handleDrawerOpen={handleDrawerOpen}
          lnsResource={Resource.PUBLISH_FOLDER}
          lnsAction={Action.LIST}
        />

        <Divider />
        {showAdminSidebar && (
          <ProtectedSidebarListItem
            title={i18n.t('links.sidebar.administration')}
            icon={<Settings color="primary" />}
            sideBarOpen={open}
            selected={selectedGuid}
            handleDrawerOpen={handleDrawerOpen}
            onClickNestedItem={onClickSettings}
            nestedItems={nestedItems()}
            nestedItemsPath="/administration"
          />
        )}
      </List>
    </Drawer>
  );
};

export default MainSidebar;
