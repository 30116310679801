import { FunctionComponent, ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import { Grid, Paper, IconButton } from '@material-ui/core';
import storiesStyles from 'pages/stories/styles';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import { SettingsInputSvideo, Search } from '@material-ui/icons';
import { TableCellI } from 'interfaces/tableCell';
import BasicTextField from 'components/BasicTextField';

const Peripherals: FunctionComponent = () => {
  const navigate = useNavigate();
  const classes = storiesStyles();

  const onRowClick = (rowItem: { guid: { value: string } }) => {
    navigate(rowItem.guid.value);
  };

  const cells = [
    {
      fields: {
        name: {
          value: i18n.t(
            'pages.administration.peripheralSettings.tableCell.peripherals'
          )
        },
        guid: {
          value: '/administration/peripheral-settings/peripherals',
          hidden: true
        }
      }
    },
    {
      fields: {
        name: {
          value: i18n.t(
            'pages.administration.peripheralSettings.tableCell.templates'
          )
        },
        guid: {
          value: '/administration/peripheral-settings/templates',
          hidden: true
        }
      }
    },
    {
      fields: {
        name: {
          value: i18n.t(
            'pages.administration.peripheralSettings.tableCell.types'
          )
        },
        guid: {
          value: '/administration/peripheral-settings/types',
          hidden: true
        }
      }
    },
    {
      fields: {
        name: {
          value: i18n.t(
            'pages.administration.peripheral-settings.tableCell.protocols'
          )
        },
        guid: {
          value: '/administration/peripheral-settings/protocols',
          hidden: true
        }
      }
    }
  ];

  const [filteredList, setFilteredList] = useState<TableCellI[]>(cells);

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const filterCells = cells.filter(item => {
      return item.fields.name.value.includes(search);
    });
    setFilteredList(filterCells);
  };

  return (
    <Grid container spacing={2}>
      <TitleBarWraper>
        <Grid item xs={6}>
          <TitleWaper
            title="Peripheral Settings"
            subText="Title"
            icon={<SettingsInputSvideo color="primary" />}
          />
        </Grid>
      </TitleBarWraper>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <BasicTextField
            className={classes.input}
            placeholder={i18n.t('placeholders.search')}
            onChange={onSearchFieldChange}
          />
          <IconButton className={classes.iconButton} aria-label="search">
            <Search />
          </IconButton>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <TableBuilder
          rows={['Sub-menu']}
          cells={filteredList}
          onRowClick={onRowClick}
        />
      </Grid>
    </Grid>
  );
};

export default Peripherals;
