import colors from 'styles/colors';

enum TaskPriority {
  LOW = 0,
  NORMAL = 1,
  HIGH = 2,
  URGENT = 3
}

const TaskPriorityColors = {
  low: colors.success.dark,
  normal: colors.info.dark,
  high: colors.warning.dark,
  urgent: colors.error.dark
};

enum TaskStates {
  NEW = 'new',
  ASSIGNED = 'assigned',
  PENDING_ASSIGNMENT = 'pending assignment',
  IN_PROGRESS = 'in progress',
  COMPLETED = 'completed',
  REJECTED = 'rejected'
}

export { TaskPriority, TaskPriorityColors, TaskStates };
