import { Button, ButtonProps } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { useCanAccess } from 'components/Authorization/utils';
import i18n from 'i18n';
import TooltipWrap from 'components/TooltipWrap';

interface ProtectedButtonI extends ButtonProps {
  lnsResource?: string;
  lnsAction?: string;
  tooltip?: string;
  lnsOwners?: string[];
}

export const ProtectedButton: FunctionComponent<ProtectedButtonI> = ({
  lnsResource,
  lnsAction,
  tooltip,
  lnsOwners,
  ...props
}: ProtectedButtonI) => {
  const canAccess = useCanAccess(lnsResource, lnsAction, lnsOwners);

  if (canAccess) {
    if (tooltip) {
      return (
        <TooltipWrap title={tooltip}>
          <Button {...props} />
        </TooltipWrap>
      );
    }
    return <Button {...props} />;
  }
  return (
    <TooltipWrap title={i18n.t('authorization.tooltip.notAuthorized')}>
      <Button {...props} disabled />
    </TooltipWrap>
  );
};

ProtectedButton.defaultProps = {
  lnsResource: undefined,
  lnsAction: undefined,
  tooltip: undefined,
  lnsOwners: []
};
