import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React, { FunctionComponent, ReactNode } from 'react';
import { dialogStyles, dialogBackdropStyle } from 'styles/detailDialog';

interface DetailDialogI {
  dialogTitle: string | ReactNode;
  onHandleClose: () => void;
  dialogContent: ReactNode;
  dialogActions: ReactNode;
}

const DetailDialog: FunctionComponent<DetailDialogI> = ({
  dialogTitle,
  dialogContent,
  dialogActions,
  onHandleClose
}: DetailDialogI) => {
  const classes = dialogStyles();
  const backdropStyles = dialogBackdropStyle();
  return (
    <Dialog
      maxWidth="xs"
      classes={classes}
      BackdropProps={{
        classes: backdropStyles
      }}
      onClose={onHandleClose}
      aria-labelledby="simple-dialog-title"
      open
    >
      <DialogTitle id="simple-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
};

export default DetailDialog;
