import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import { Dialog, Grid, IconButton, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  createPublishFolder,
  fetchPublishFolders,
  updatePublishFolder,
  removePublishFolder
} from 'actions/publishFolders';
import toggleNotification from 'actions/notifications';
import Form from 'pages/administration/publish-folders/components/Form';
import List from 'pages/administration/publish-folders/components/List';
import ItemDetail from 'pages/administration/publish-folders/components/ItemDetail';
import { FormData } from 'pages/administration/publish-folders/formData';
import i18n from 'i18n';
import { Search, PermMedia } from '@material-ui/icons';
import storiesStyles from 'pages/stories/styles';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import BtnWrapper from 'components/BtnWrapper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import BasicTextField from 'components/BasicTextField';

const PublishFolders: FunctionComponent = () => {
  const dispatch = useDispatch();
  const classes = storiesStyles();

  const handleApiError = () => {
    dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
  };

  const [editPublishFolder, setEditPublishFolder] = useState<FormData>();

  const [showPublishFolder, setShowPublishFolder] =
    useState<$Lns.PublishFolder>();

  const [publishFolders, setPublishFolders] = useState<$Lns.PublishFolder[]>(
    []
  );

  const [publishedFoldersOrig, setPublishedFoldersOrig] = useState<
    $Lns.PublishFolder[]
  >([]);

  const fetchSetPublishFolders = () => {
    fetchPublishFolders().then(({ data }) => {
      setPublishFolders(data || []);
      setPublishedFoldersOrig(data || []);
    });
  };

  useEffect(() => {
    fetchSetPublishFolders();
  }, []);

  const onAdd = () => {
    setEditPublishFolder({ name: '', errors: { name: '' } });
  };

  const onRemove = (publishFolder: $Lns.PublishFolder) => {
    removePublishFolder(publishFolder).then(
      () => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.publishFolders.removeSuccess'),
            'success'
          )
        );
        fetchSetPublishFolders();
      },
      () => {
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      }
    );
  };

  const onEdit = (publishFolder: $Lns.PublishFolder) => {
    const formData = { ...publishFolder, errors: { name: '' } };
    setEditPublishFolder(formData);
  };

  const onEditCancel = () => {
    setEditPublishFolder(undefined);
  };

  const onSave = (formData: FormData) => {
    if (formData.guid) {
      updatePublishFolder(formData as $Lns.PublishFolder)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.publishFolders.saveSuccess'),
              'success'
            )
          );
          fetchSetPublishFolders();
        })
        .catch(handleApiError);
    } else {
      createPublishFolder(formData as $Lns.PublishFolder)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.publishFolders.createSuccess'),
              'success'
            )
          );
          fetchSetPublishFolders();
        })
        .catch(handleApiError);
    }
    onEditCancel();
  };

  const onShow = (publishFolder: $Lns.PublishFolder) => {
    setShowPublishFolder(publishFolder);
  };

  const onClose = () => {
    setShowPublishFolder(undefined);
  };

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newpublishFolder = publishedFoldersOrig.filter(item => {
      return item.guid.includes(search) || item.name.includes(search);
    });
    setPublishFolders(newpublishFolder);
  };

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={i18n.t(
                'links.sidebarNestedItems.administration.publishFolders'
              )}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<PermMedia color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <ProtectedButton
                color="secondary"
                variant="contained"
                onClick={onAdd}
                lnsResource={Resource.PUBLISH_FOLDER}
                lnsAction={Action.CREATE}
              >
                {i18n.t('pages.administration.publishFolders.button.add')}
              </ProtectedButton>
            </BtnWrapper>
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={onSearchFieldChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <List
              publishFolders={publishFolders}
              onShow={onShow}
              onRemove={onRemove}
              onEdit={onEdit}
            />
          </Grid>
        </Grid>
      </Grid>

      {editPublishFolder && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <Form
            onSave={onSave}
            onCancel={onEditCancel}
            publishFolder={editPublishFolder}
            publishFolders={publishFolders}
          />
        </Dialog>
      )}

      {showPublishFolder && (
        <ItemDetail item={showPublishFolder} onClose={onClose} />
      )}
    </>
  );
};

export default PublishFolders;
