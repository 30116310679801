const Groups = {
  SYSTEM_MANAGERS: 'System Managers',
  SUPER_USERS: 'Super Users',
  EDITORS: 'Editors',
  OPERATORS: 'Operators',
  STANDARD_USERS: 'Standard Users'
};

const DEFAULT_GROUP = Groups.STANDARD_USERS;

export { Groups, DEFAULT_GROUP };
