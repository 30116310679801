import React, { FunctionComponent } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import 'App.css';
import './fonts/NotoSans-Light.ttf';
import './fonts/NotoSans-Regular.ttf';
import './fonts/NotoSans-Medium.ttf';
import './fonts/NotoSans-Bold.ttf';

import MainTheme from 'components/MainTheme';
import Notification from 'components/Notification';
import { useSelector } from 'react-redux';
import { localeMap } from 'utils/date';
import LnsRouter from 'routes';

const App: FunctionComponent = () => {
  const { locale } = useSelector((state: $Lns.DefaultState) => state.default);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap()[locale]}>
      <MainTheme>
        <Notification />
        <LnsRouter />
      </MainTheme>
    </MuiPickersUtilsProvider>
  );
};

export default App;
