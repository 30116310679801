import { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import i18n from 'i18n';
import { Alert } from '@material-ui/lab';
import Wrapper from 'pages/password-reset/components/Wrapper';

const PasswordResetShow: FunctionComponent = () => {
  return (
    <Wrapper>
      <Box display="flex" justifyContent="center">
        <Alert severity="warning">
          {i18n.t('pages.passwordReset.typography.showTitle')}
        </Alert>
      </Box>
    </Wrapper>
  );
};

export default PasswordResetShow;
