const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

const validatePassword = (password: string): boolean => {
  return password.length >= 6;
};

const validateForm = (name: string, value: string): boolean => {
  let error = false;

  if (name === 'email') {
    error = !validateEmail(value);
  }

  if (name === 'password') {
    error = !validatePassword(value);
  }

  return error;
};

export default validateForm;
