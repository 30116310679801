import { Dialog, Grid, IconButton, Paper, Button } from '@material-ui/core';
import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchMediaItemsFromLS,
  updateMediaItemsInLS
} from 'pages/administration/media/actions';
import { FormData } from 'pages/administration/media/formData';
import EmptyState from 'components/EmptyState';
import Form from 'pages/administration/media/components/Form';
import MediaItemList from 'pages/administration/media/components/List';
import MediaItemDetail from 'pages/administration/media/components/MediaItemDetail';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';
import shortid from 'shortid';
import { Search, Theaters } from '@material-ui/icons';
import storiesStyles from 'pages/stories/styles';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import BtnWrapper from 'components/BtnWrapper';
import BasicTextField from 'components/BasicTextField';

const Media: FunctionComponent = () => {
  const [mediaItems, setMediaItems] = useState<$Lns.MediaItem[]>([]);
  const [mediaItemsOrig, setMediaItemsOrig] = useState<$Lns.MediaItem[]>([]);
  const [editMediaItem, setEditMediaItem] = useState<$Lns.MediaItem>();
  const [showMediaItem, setShowMediaItem] = useState<$Lns.MediaItem>();
  const dispatch = useDispatch();
  const classes = storiesStyles();

  useEffect(() => {
    fetchMediaItemsFromLS().then(({ data }) => {
      if (data) {
        setMediaItems(data);
        setMediaItemsOrig(data);
      }
    });
  }, []);

  const onAdd = () => {
    setEditMediaItem({
      name: '',
      type: '',
      sourceUrl: '',
      guid: ''
    });
  };

  const onEditCancel = () => {
    setEditMediaItem(undefined);
  };

  const onSave = (formData: FormData) => {
    let updatedMediaItems: $Lns.MediaItem[] = [];
    if (!formData.guid) {
      const newMediaItem = {
        ...formData,
        guid: shortid.generate()
      };
      updatedMediaItems = [newMediaItem, ...mediaItems];
    } else {
      updatedMediaItems = mediaItems.map(mi => {
        if (mi.guid === formData.guid) {
          return {
            ...formData,
            mi
          };
        }
        return mi;
      });
    }

    updateMediaItemsInLS(updatedMediaItems);
    onEditCancel();
    setMediaItems(updatedMediaItems);
    dispatch(
      toggleNotification(
        i18n.t('notifications.mediaItems.createSuccess'),
        'success'
      )
    );
  };

  const onEdit = (mediaItem: $Lns.MediaItem) => {
    setEditMediaItem(mediaItem);
  };

  const onShow = (mediaItem: $Lns.MediaItem) => {
    setShowMediaItem(mediaItem);
  };

  const onClose = () => {
    setShowMediaItem(undefined);
  };

  const onRemove = (mediaItem: $Lns.MediaItem) => {
    const updatedMediaItems = mediaItems.filter(
      mi => mi.guid !== mediaItem.guid
    );
    updateMediaItemsInLS(updatedMediaItems);
    setMediaItems(updatedMediaItems);
    dispatch(
      toggleNotification(
        i18n.t('notifications.mediaItems.removeSuccess'),
        'success'
      )
    );
  };

  if (!mediaItemsOrig.length) {
    return (
      <EmptyState
        title={i18n.t('pages.administration.media.emptyState.title')}
        subTitle={i18n.t('pages.administration.media.emptyState.subTitle')}
      />
    );
  }

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newMediaItems = mediaItemsOrig.filter(item => {
      return item.guid.includes(search) || item.name.includes(search);
    });
    setMediaItems(newMediaItems);
  };

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={i18n.t('links.sidebarNestedItems.administration.media')}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<Theaters color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <Button color="secondary" variant="contained" onClick={onAdd}>
                {i18n.t('pages.administration.media.button.add')}
              </Button>
            </BtnWrapper>
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={onSearchFieldChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <MediaItemList
            onShow={onShow}
            mediaItems={mediaItems}
            onEdit={onEdit}
            onRemove={onRemove}
          />
        </Grid>
      </Grid>
      {editMediaItem && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <Form
            onSave={onSave}
            mediaItem={editMediaItem}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
      {showMediaItem && (
        <MediaItemDetail mediaItem={showMediaItem} onClose={onClose} />
      )}
    </>
  );
};

export default Media;
