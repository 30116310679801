import { FunctionComponent, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  updatePeripheralsStatus,
  toggleAssignmentNotification
} from 'actions/updates';
import toggleNotification from 'actions/notifications';
import { useCurrentLnsUser } from 'utils/redux';

import io from 'socket.io-client';

export const socket = io(process.env.REACT_APP_LNS_SERVER as string);

interface RealTimeUpdatesI {
  children: ReactNode;
}

const RealTimeUpdates: FunctionComponent<RealTimeUpdatesI> = ({
  children
}: RealTimeUpdatesI) => {
  const dispatch = useDispatch();
  const currentLnsUser = useCurrentLnsUser();

  // Purely for Server to Send a Notification Message
  useEffect(() => {
    socket.on('message', payload => {
      try {
        // if the server passed a object of messae & type
        const { message, type } = JSON.parse(payload);
        dispatch(toggleNotification(message, type));
      } catch (e) {
        // if the server passed a string
        dispatch(toggleNotification(payload, 'success'));
      }
    });
  });

  // Realtime Updates for Peripheral Statuses
  useEffect(() => {
    socket.on('peripherals-status', payload => {
      dispatch(updatePeripheralsStatus(JSON.parse(payload)));
    });
  }, [dispatch]);

  // Push notification of being assigned an assignment
  // and the viceversa of being deassigned
  useEffect(() => {
    socket.on('assignment-notifications', payloadString => {
      const payload = JSON.parse(payloadString);
      if (currentLnsUser.guid === payload.targetUser)
        dispatch(
          toggleAssignmentNotification(currentLnsUser, payload.assignment)
        );
    });
  });

  return <div>{children}</div>;
};

export default RealTimeUpdates;
