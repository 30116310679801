export const humanize = (string: string): string => {
  return string
    .replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
    .replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`);
};

export const toUrlSlug = (string: string): string => {
  return string.replace(/\s+/g, '-').toLowerCase();
};

export const snakeToCamel = (str: string): string =>
  str.replace(/([-_][a-z])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );

export const snakeToSpaces = (str: string): string =>
  str.replace(/([-_][a-z])/g, group =>
    group.replace('-', ' ').replace('_', ' ')
  );
