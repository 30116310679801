import { useEffect, useRef } from 'react';

export const useInterval = (
  // eslint-disable-next-line
  callback: any,
  delay: number,
  clear = false
  // eslint-disable-next-line
): any => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      // eslint-disable-next-line
      // @ts-ignore
      savedCallback.current();
    }
    if (!clear) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay, clear]);
};
