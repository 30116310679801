import validator from 'validator';
import i18n from 'i18n';
import { FormData } from 'pages/assignments/formData';

export const validateForm = (name: string, value: string): string => {
  let error = '';

  if (name === 'title') {
    error = validator.isEmpty(value) ? i18n.t('formErrors.isEmpty') : '';
    error = !validator.matches(value, /^[a-z0-9 '-]+$/i)
      ? i18n.t('formErrors.isAlphanumeric')
      : '';
  }

  return error;
};

export const combineFormData = (
  formData: FormData,
  storyObj: $Lns.Story | undefined,
  assignedByObj: $Lns.User | undefined,
  assignedAtObj: Date | undefined,
  assignedToObj: $Lns.User | undefined,
  dateDueObj: Date | undefined
): FormData => {
  let retFormData = {
    ...formData,
    assignedBy: assignedByObj?.guid || ''
  };

  if (storyObj && storyObj.guid) {
    retFormData = {
      ...retFormData,
      story: storyObj.guid,
      storyObj
    };
  }

  if (assignedAtObj) {
    retFormData = {
      ...retFormData,
      assignedAt: assignedAtObj.toISOString()
    };
  }

  if (dateDueObj) {
    retFormData = {
      ...retFormData,
      dateDue: dateDueObj.toISOString()
    };
  }

  retFormData = {
    ...retFormData,
    assignedTo: assignedToObj?.guid || undefined
  };

  return retFormData;
};
