import {
  DataGrid,
  GridColumns,
  GridRowData,
  GridCellParams
} from '@material-ui/data-grid';
import { FunctionComponent, memo } from 'react';
import { gridTableStyles } from 'styles/gridTable';

interface GridTableI {
  columns: GridColumns;
  cells: GridRowData[];
  pageSize?: number;
  onRowClick?: (params: GridCellParams) => void;
}

const GridTable: FunctionComponent<GridTableI> = ({
  columns,
  cells,
  onRowClick,
  pageSize = 10
}: GridTableI) => {
  const classes = gridTableStyles();

  const customRowClick = (cell: GridCellParams) => {
    if (onRowClick && cell.field !== 'actions') {
      onRowClick(cell);
    }
  };

  return (
    <DataGrid
      classes={classes}
      columns={columns}
      rows={cells}
      pageSize={pageSize}
      hideFooterSelectedRowCount
      onCellClick={pin => customRowClick(pin)}
      autoHeight
    />
  );
};

export default memo(GridTable);

GridTable.defaultProps = {
  pageSize: 10,
  onRowClick: undefined
};
