export const getEnumKey = (
  // eslint-disable-next-line
  enumCollection: any,
  enumValue: string | number
): string | null => {
  const keys = Object.keys(enumCollection).filter(
    x => enumCollection[x] === enumValue
  );

  return keys.length > 0 ? keys[0] : null;
};
