import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import i18n from 'i18n';
import { useDispatch } from 'react-redux';
import NewswireServiceTable from 'pages/newswires/components/NewswireServices';
import Form from 'pages/administration/newswires/components/Form';
import ItemDetail from 'pages/administration/newswires/components/ItemDetail';
import {
  fetchNewswireServices,
  fetchNewswireFormats,
  createNewswireService,
  updateNewswireService,
  deleteNewswireService
} from 'pages/newswires/actions';
import toggleNotification from 'actions/notifications';
import { Dialog, Grid, IconButton, Paper, Button } from '@material-ui/core';
import { Search, RssFeed } from '@material-ui/icons';
import storiesStyles from 'pages/stories/styles';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import BtnWrapper from 'components/BtnWrapper';
import BasicTextField from 'components/BasicTextField';

const Newswires: FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleApiError = () => {
    dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
  };

  const classes = storiesStyles();

  // LIST OF NEWSWIRE SERVICES, and the FX to GET THEM
  const [newswireServices, setNewswireServices] = useState<
    $Lns.NewswireService[]
  >([]);

  const [newswiresOrig, setNewswiresOrig] = useState<$Lns.NewswireService[]>(
    []
  );

  const fetchSetNewswireServices = () => {
    fetchNewswireServices().then(({ data }) => {
      setNewswireServices(data);
      setNewswiresOrig(data);
    });
  };

  // LIST of NEWSWIRE FORMATS, needed to match format GUID vs Names
  const [formatList, setFormatList] = useState<$Lns.NewswireFormat[]>([]);

  // STATE to SHOW NEWSWIRE SERVICES, and the CB to change STATES
  const [showNewswireService, setShowNewswireService] =
    useState<$Lns.NewswireService>();

  const findFormatName = (formatSearchGuid: string): string => {
    const formatObj = formatList.find(o => o.guid === formatSearchGuid);
    return formatObj?.name || 'Format Not Found';
  };

  const onShow = (item: $Lns.NewswireService) => {
    setShowNewswireService(item);
  };

  const onClose = () => {
    setShowNewswireService(undefined);
  };

  // State to ADD/EDIT NEWSWIRE SERVICES, and the CB to manage the STATES
  const [editNewswireService, setEditNewswireService] =
    useState<$Lns.NewswireService>();

  const onAdd = () => {
    const newNewswire = {} as $Lns.NewswireService;
    setEditNewswireService(newNewswire);
  };

  const onEdit = (item: $Lns.NewswireService) => {
    const temp = item;
    if (temp.username === '') delete temp.username;
    if (temp.password === '') delete temp.password;
    setEditNewswireService(temp);
  };

  const onEditCancel = () => {
    setEditNewswireService(undefined);
  };

  const onSave = (formData: $Lns.NewswireService) => {
    if (formData.guid === undefined) {
      createNewswireService(formData)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.newswires.addSuccess'),
              'success'
            )
          );
          fetchSetNewswireServices();
        })
        .catch(handleApiError);
    } else {
      updateNewswireService(formData)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.newswires.editSuccess'),
              'success'
            )
          );
          fetchSetNewswireServices();
        })
        .catch(handleApiError);
    }
    setEditNewswireService(undefined);
  };

  // CB to DELETE a NEWSWIRE SERVICE
  const onDelete = (newswireServiceGuid: string) => {
    deleteNewswireService(newswireServiceGuid).then(res => {
      if (res && res.code === 500) {
        dispatch(
          toggleNotification(
            res.message || i18n.t('pages.administration.newswires.error'),
            'error'
          )
        );
      } else {
        dispatch(
          toggleNotification(
            i18n.t('notifications.newswires.deleteSuccess'),
            'success'
          )
        );
      }
      fetchSetNewswireServices();
    });
  };

  useEffect(() => {
    fetchSetNewswireServices();
    fetchNewswireFormats().then(({ data }) => {
      setFormatList(data);
    });
  }, []);

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newStorypool = newswiresOrig.filter(story => {
      return story.name.includes(search) || story.format.includes(search);
    });
    setNewswireServices(newStorypool);
  };

  return (
    <Grid container spacing={2}>
      <TitleBarWraper>
        <Grid item xs={6}>
          <TitleWaper
            title={i18n.t('links.sidebarNestedItems.administration.newswires')}
            subText={i18n.t('pages.stories.formLabel.title')}
            icon={<RssFeed color="primary" />}
          />
        </Grid>
        <Grid item xs={6}>
          <BtnWrapper>
            <Button color="secondary" variant="contained" onClick={onAdd}>
              {i18n.t('pages.administration.newswires.button.add')}
            </Button>
          </BtnWrapper>
        </Grid>
      </TitleBarWraper>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <BasicTextField
            className={classes.input}
            placeholder="Search ..."
            onChange={onSearchFieldChange}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <Search />
          </IconButton>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <NewswireServiceTable
          onShow={onShow}
          onEdit={onEdit}
          onDelete={onDelete}
          newswireServices={newswireServices}
          newswireFormats={formatList}
        />
      </Grid>
      {editNewswireService && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="edit-newswire-dialog"
          open
        >
          <Form
            newswireService={editNewswireService}
            newswireFormats={formatList}
            onSave={onSave}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
      {showNewswireService && (
        <ItemDetail
          newswireServiceItem={showNewswireService}
          formatName={findFormatName(showNewswireService.format)}
          onClose={onClose}
        />
      )}
    </Grid>
  );
};

export default Newswires;
