import { callLnsApi } from 'utils/fetch';
import { Dispatch } from 'react';
import { SET_RUNDOWN_DATE } from 'redux/reducers/types';
import { subDays, addDays } from 'date-fns';

export const fetchRundowns = (): Promise<{
  code: number;
  data: $Lns.Rundown[];
}> => callLnsApi('/rundown');

export const createRundown = (
  rundown: $Lns.Rundown
): Promise<{ code: number; data: $Lns.Rundown[] }> =>
  callLnsApi('/rundown', 'POST', rundown);

export const updateRundown = (
  rundown: $Lns.Rundown
): Promise<{ code: number; data: $Lns.Rundown[] }> =>
  callLnsApi(`/rundown/${rundown.guid}`, 'POST', rundown);

export const addStoryToRundown = (
  guid: string,
  input: { story: string }
): Promise<{ code: number; data: $Lns.Story }> =>
  callLnsApi(`/rundown/${guid}/story`, 'POST', input);

export const fetchRundown = (
  guid: string
): Promise<{ code: number; data: $Lns.Rundown[] }> =>
  callLnsApi(`/rundown/${guid}`);

export const fetchRundownStories = (
  guid: string
): Promise<{ code: number; data: $Lns.PlayoutStory[] }> =>
  callLnsApi(`/rundown/${guid}/stories`);

export const setRundownDate = (
  rundownDate?: $Lns.DateInterface
): Dispatch<() => void> => {
  return (
    dispatch: Dispatch<{
      type: string;
      rundownDate: $Lns.DateInterface;
    }>
  ) => {
    const intiDateVals = {
      startDate: subDays(new Date(), 1),
      endDate: addDays(new Date(), 1)
    };
    dispatch({
      type: SET_RUNDOWN_DATE,
      rundownDate: rundownDate || intiDateVals
    });
  };
};
