import { ChangeEvent, FunctionComponent, useState, useRef } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  InputLabel,
  FormHelperText
} from '@material-ui/core';
import i18n from 'i18n';
import { FormData } from 'pages/administration/peripheral-settings/peripherals/formData';
import CustomTextField from 'components/CustomTextField';
import EmptyState from 'components/EmptyState';
import ConfigRow from 'pages/administration/peripheral-settings/peripherals/components/ConfigRow';
import { updateFormData } from 'utils/form';
import { findProtocol } from 'pages/administration/peripheral-settings/utils';
import { validateForm } from 'pages/administration/peripheral-settings/peripherals/utils';

interface FormI {
  peripheral: $Lns.Peripheral;
  types: $Lns.PeripheralType[];
  protocols: $Lns.Protocol[];
  onSave: (formData: FormData) => void;
  onCancel: () => void;
}

const Form: FunctionComponent<FormI> = ({
  peripheral,
  types,
  protocols,
  onSave,
  onCancel
}: FormI) => {
  const [formData, setFormData] = useState<FormData>({
    guid: peripheral.guid || undefined,
    name: peripheral.name || '',
    protocol: peripheral.protocol || '',
    type: peripheral.type || '',
    errors: {
      name: '',
      protocol: '',
      type: ''
    },
    config: peripheral.config
  });

  const refConfig = useRef<{
    [key: string]: string | number | boolean | undefined;
  }>(peripheral.config);

  const currProtocol = findProtocol(formData.protocol, protocols);

  const configNames = currProtocol?.config
    ? Object.keys(currProtocol.config)
    : [];

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedFormData = updateFormData(e, formData, validateForm);
    setFormData(updatedFormData);
  };

  const onChangeSelect = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const { value, name } = e.target;

    const newProtocolGuid =
      name === 'protocol' && typeof value === 'string'
        ? findProtocol(value, protocols).guid
        : formData.protocol;

    setFormData({
      ...formData,
      [name || '']: value,
      protocol: newProtocolGuid
    });
  };

  const onChangeConfigField = (
    name: string,
    value: string | number | boolean | undefined
  ) => {
    refConfig.current[name] = value;
  };

  const onSaveHandler = () => {
    const cleanedConfig: {
      [key: string]: string | number | boolean | undefined;
    } = {};
    configNames.forEach(itemName => {
      cleanedConfig[itemName] = refConfig.current[itemName];
    });
    const updatedFormData = {
      ...formData,
      config: cleanedConfig
    };
    onSave(updatedFormData);
  };

  return (
    <Box padding={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="primary" variant="subtitle1">
            {i18n.t('pages.administration.peripherals.text.main')}
          </Typography>
        </Grid>

        <Grid item xs={12} container spacing={4}>
          <Grid item xs={12} sm={4}>
            <CustomTextField
              onChange={onChangeField}
              value={formData.name}
              label={i18n.t('pages.administration.peripherals.formLabel.name')}
              name="name"
              margin="none"
              error={!!formData?.errors.name}
              helperText={formData.errors.name || i18n.t('helperText.required')}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" size="small" fullWidth required>
              <InputLabel>
                {i18n.t('pages.administration.peripherals.tableRow.protocol')}
              </InputLabel>
              <Select
                id="selectProtocol"
                labelId="selectProtocol"
                name="protocol"
                placeholder="protocol"
                value={formData.protocol}
                onChange={onChangeSelect}
                variant="outlined"
                displayEmpty
                required
                label={i18n.t(
                  'pages.administration.peripherals.tableRow.protocol'
                )}
              >
                {protocols.map(protocol => (
                  <MenuItem key={protocol.key} value={protocol.guid}>
                    {protocol.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{i18n.t('helperText.required')}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl variant="outlined" size="small" fullWidth required>
              <InputLabel>
                {i18n.t('pages.administration.peripherals.tableRow.type')}
              </InputLabel>
              <Select
                id="selectType"
                name="type"
                placeholder="Type"
                labelId="selectType"
                value={formData.type}
                onChange={onChangeSelect}
                variant="outlined"
                displayEmpty
                required
                label={i18n.t('pages.administration.peripherals.tableRow.type')}
              >
                {types.map(currentType => {
                  return (
                    <MenuItem key={currentType.key} value={currentType.guid}>
                      {currentType.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{i18n.t('helperText.required')}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography color="primary" variant="subtitle1">
            {i18n.t('pages.administration.peripherals.text.config')}
          </Typography>
          <Divider />
          <Grid container>
            {configNames?.length > 0 &&
              configNames.map(itemName => {
                return (
                  <ConfigRow
                    configName={itemName}
                    configValue={refConfig.current[itemName]}
                    configType={currProtocol.config[itemName].type}
                    configDescription={
                      currProtocol.config[itemName].description
                    }
                    onChangeCallback={onChangeConfigField}
                  />
                );
              })}
            {(!configNames || !(configNames.length > 0)) && (
              <Grid item xs={12}>
                <Box padding={2}>
                  <EmptyState
                    title={i18n.t(
                      'pages.administration.peripherals.emptyConfig.title'
                    )}
                    subTitle={i18n.t(
                      'pages.administration.peripherals.emptyConfig.subTitle'
                    )}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={onSaveHandler}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;
