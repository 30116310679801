import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { FunctionComponent } from 'react';
import i18n from 'i18n';
import DetailDialog from 'components/DetailDialog';
import { Info, Functions } from '@material-ui/icons';

interface DetailI {
  item: $Lns.PublishFolder;
  onClose: () => void;
}

const Detail: FunctionComponent<DetailI> = ({ item, onClose }: DetailI) => {
  const dialogContent = () => {
    return (
      <Box>
        <List>
          <ListItem>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText
              primary={item.name}
              secondary={i18n.t(
                'pages.administration.publishFolder.formLabel.name'
              )}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Functions />
            </ListItemIcon>
            <ListItemText
              primary={item.stories || 0}
              secondary={i18n.t(
                'pages.administration.publishFolder.formLabel.clipType'
              )}
            />
          </ListItem>
        </List>
      </Box>
    );
  };

  const dialogActions = () => {
    return (
      <>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {i18n.t('button.close')}
        </Button>
      </>
    );
  };

  return (
    <DetailDialog
      dialogTitle={item.name}
      dialogContent={dialogContent()}
      dialogActions={dialogActions()}
      onHandleClose={onClose}
    />
  );
};

export default Detail;
