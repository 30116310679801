import { Reducer } from 'redux';
import {
  TOGGLE_LOADING,
  SET_STORY_POOLS,
  SET_STORY_TYPES,
  SET_CURRENT_SSO_USER,
  SET_CURRENT_LNS_USER,
  SET_CURRENT_USER_PERMISSIONS,
  SET_NEWSWIRE_SERVICES,
  SET_LOCALE,
  SET_STORY_GENRES,
  SET_LOCATIONS,
  SET_STORY_CATEGORIES,
  SET_STORY_STATES,
  SET_INITIAL_DATA,
  SET_RUNDOWN_STORIES,
  SET_RUNDOWN_DATE,
  SET_DIRECTION,
  UPDATE_PERIPHERALS_STATUS,
  PURGE_SESSION,
  DEFAULT_STATE
} from 'redux/reducers/types';
import { i18nChangeLanguage } from 'utils/languages';
import i18next from 'i18next';

import isEqual from 'lodash.isequal';

interface Action {
  dir: string;
  type: string;
  locale: string;
  currentSsoUser?: $Lns.SsoUser;
  currentLnsUser?: $Lns.User;
  loading: boolean;
  locations: $Lns.Location[];
  storyPools: $Lns.StoryPool[];
  storyTypes: $Lns.StoryType[];
  storyGenres: $Lns.StoryGenre[];
  storyStates: $Lns.StoryState[];
  storyCategories: $Lns.StoryCategory[];
  newswireServices: $Lns.NewswireService[];
  currentUserPermissions: $Lns.CurrentUserPermissions;
  rundownStories: $Lns.RundownStories;
  rundownDate?: $Lns.DateInterface;
  peripheralsStatus: $Lns.PeripheralsStatus;
  taskStates: $Lns.TaskState[];
}
interface State {
  type: string;
  locale: string;
  dir: string;
  currentSsoUser: $Lns.SsoUser | undefined;
  currentLnsUser: $Lns.User | undefined;
  loading: boolean;
  locations: $Lns.Location[];
  storyPools: $Lns.StoryPool[];
  storyTypes: $Lns.StoryType[];
  storyGenres: $Lns.StoryGenre[];
  storyCategories: $Lns.StoryCategory[];
  storyStates: $Lns.StoryState[];
  newswireServices: $Lns.NewswireService[];
  currentUserPermissions: $Lns.CurrentUserPermissions;
  rundownStories: $Lns.RundownStories;
  rundownDate: $Lns.DateInterface | undefined;
}

const initialState = {
  type: '',
  locale: 'en',
  dir: 'ltr',
  currentSsoUser: undefined,
  currentLnsUser: undefined,
  loading: false,
  locations: [],
  storyPools: [],
  storyTypes: [],
  storyGenres: [],
  storyCategories: [],
  storyStates: [],
  newswireServices: [],
  currentUserPermissions: {},
  rundownStories: {},
  rundownDate: undefined
};

const DefaultReducer: Reducer<State, Action> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case TOGGLE_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }
    case SET_INITIAL_DATA: {
      return {
        ...state,
        storyPools: action.storyPools,
        storyTypes: action.storyTypes,
        storyGenres: action.storyGenres,
        storyStates: action.storyStates,
        storyCategories: action.storyCategories,
        locations: action.locations,
        taskStates: action.taskStates
      };
    }
    case SET_STORY_POOLS: {
      return {
        ...state,
        storyPools: action.storyPools
      };
    }
    case SET_STORY_TYPES: {
      return {
        ...state,
        storyTypes: action.storyTypes
      };
    }
    case SET_STORY_GENRES: {
      return {
        ...state,
        storyGenres: action.storyGenres
      };
    }
    case SET_STORY_CATEGORIES: {
      return {
        ...state,
        storyCategories: action.storyCategories
      };
    }
    case SET_STORY_STATES: {
      return {
        ...state,
        storyStates: action.storyStates
      };
    }
    case SET_LOCATIONS: {
      return {
        ...state,
        locations: action.locations
      };
    }
    case SET_RUNDOWN_STORIES:
      return {
        ...state,
        rundownStories: {
          ...state.rundownStories,
          ...action.rundownStories
        }
      };
    case SET_RUNDOWN_DATE:
      return {
        ...state,
        rundownDate: action.rundownDate
      };
    case UPDATE_PERIPHERALS_STATUS:
      if (state.rundownStories[action.peripheralsStatus.rundownGuid]) {
        return {
          ...state,
          rundownStories: {
            ...state.rundownStories,
            [action.peripheralsStatus.rundownGuid]: state.rundownStories[
              action.peripheralsStatus.rundownGuid
            ].map(playoutStory => {
              if (
                playoutStory.guid === action.peripheralsStatus.playoutStoryGuid
              ) {
                return {
                  ...playoutStory,
                  isCgReady: action.peripheralsStatus.isCgReady,
                  isVideoReady: action.peripheralsStatus.isVideoReady,
                  isPrompterReady: action.peripheralsStatus.isPrompterReady
                };
              }
              return playoutStory;
            })
          }
        };
      }
      return state;
    case SET_LOCALE: {
      i18nChangeLanguage(action.locale);
      if (action.locale !== state.locale) {
        return {
          ...state,
          locale: action.locale
        };
      }
      return state;
    }
    case SET_DIRECTION: {
      if (action.dir !== state.dir) {
        return {
          ...state,
          dir: action.dir
        };
      }
      return state;
    }
    case SET_CURRENT_SSO_USER: {
      return {
        ...state,
        currentSsoUser: action.currentSsoUser
      };
    }
    case SET_CURRENT_LNS_USER: {
      const newLocale = action.currentLnsUser?.config.language;
      if (newLocale) i18nChangeLanguage(newLocale);
      return {
        ...state,
        currentLnsUser: action.currentLnsUser,
        dir: i18next.dir(newLocale),
        locale: action.currentLnsUser?.config.language || state.locale
      };
    }
    case SET_CURRENT_USER_PERMISSIONS: {
      const ARE_PERMISSIONS_SAME = isEqual(
        state.currentUserPermissions,
        action.currentUserPermissions
      );

      if (ARE_PERMISSIONS_SAME) return state;

      return {
        ...state,
        currentUserPermissions: action.currentUserPermissions
      };
    }
    case SET_NEWSWIRE_SERVICES: {
      return {
        ...state,
        newswireServices: action.newswireServices
      };
    }
    case PURGE_SESSION: {
      return initialState;
    }

    case DEFAULT_STATE:
    default:
      return state;
  }
};

export default DefaultReducer;
