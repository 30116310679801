import { FunctionComponent } from 'react';
import { Avatar, Box, IconButton, Paper, Typography } from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import DefaultAdminActionField from 'pages/administration/components/DefaultAdminActionField';
import { userStyles } from '../styles';

interface UserItemI {
  userItem: $Lns.User;
  onClick?: (user: $Lns.User) => void;
  onAdd?: (user: $Lns.User) => void;
  onRemove?: (user: $Lns.User) => void;
  onSneakPeek?: (user: $Lns.User) => void;
  onEdit?: (user: $Lns.User) => void;
  onDelete?: (user: $Lns.User) => void;
  elevation?: number;
  square?: boolean;
  variant?: 'elevation' | 'outlined' | undefined;
}

const UserItem: FunctionComponent<UserItemI> = ({
  userItem,
  onClick,
  onAdd,
  onRemove,
  onSneakPeek,
  onEdit,
  onDelete,
  elevation,
  square,
  variant
}: UserItemI) => {
  const classes = userStyles();
  const handleOnClick = onClick ? () => onClick(userItem) : undefined;
  const handleOnAdd = onAdd ? () => onAdd(userItem) : undefined;
  const handleOnRemove = onRemove ? () => onRemove(userItem) : undefined;
  const handleOnShow = onSneakPeek ? () => onSneakPeek(userItem) : undefined;
  const handleOnEdit = onEdit ? () => onEdit(userItem) : undefined;
  const handleOnDelete = onDelete ? () => onDelete(userItem) : undefined;

  return (
    <Paper
      onClick={handleOnClick}
      elevation={elevation}
      square={square}
      variant={variant}
      className={classes.root}
    >
      <Box padding={1}>
        <Avatar variant="circle" alt={userItem.username} />
      </Box>
      <Box flex={1}>
        <Typography variant="body1" noWrap>
          {userItem.username}
        </Typography>
      </Box>
      <Box>
        {onAdd && (
          <IconButton size="medium" onClick={handleOnAdd}>
            <AddCircleOutline color="primary" />
          </IconButton>
        )}
        {onRemove && (
          <IconButton size="medium" onClick={handleOnRemove}>
            <RemoveCircleOutline color="primary" />
          </IconButton>
        )}
        <DefaultAdminActionField
          target={userItem.username}
          onShow={handleOnShow}
          onEdit={handleOnEdit}
          onDelete={handleOnDelete}
        />
      </Box>
    </Paper>
  );
};

export default UserItem;

UserItem.defaultProps = {
  onAdd: undefined,
  onRemove: undefined,
  onClick: undefined,
  onEdit: undefined,
  onSneakPeek: undefined,
  onDelete: undefined,
  elevation: 0,
  square: false,
  variant: 'elevation'
};
