import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router';
import EmptyState from 'components/EmptyState';
import {
  fetchNewswireServices,
  fetchNewswireFormats
} from 'pages/newswires/actions';
import NewswireServices from 'pages/newswires/components/NewswireServices';
import i18n from 'i18n';
import { Grid, IconButton, Paper } from '@material-ui/core';
import { Search, RssFeed } from '@material-ui/icons';
import storiesStyles from 'pages/stories/styles';
import { useDispatch } from 'react-redux';
import toggleNotification from 'actions/notifications';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import { useWithPermissions, Resource, Action } from 'components/Authorization';
import BasicTextField from 'components/BasicTextField';

const NewswiresContainer: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [newswires, setNewswires] = useState<$Lns.NewswireService[]>([]);

  const [newswiresOrig, setNewswiresOrig] = useState<$Lns.NewswireService[]>(
    []
  );

  const [formatList, setFormatList] = useState<$Lns.NewswireFormat[]>([]);
  const classes = storiesStyles();

  useEffect(() => {
    fetchNewswireServices()
      .then(({ data }) => {
        setNewswires(data);
        setNewswiresOrig(data);
      })
      .catch(() =>
        dispatch(toggleNotification('Failed to load storypools', 'error'))
      );
    fetchNewswireFormats().then(({ data }) => {
      setFormatList(data);
    });
  }, [dispatch]);

  const onRowClick = useWithPermissions(
    (rowItem: { guid: { value: string } }) => {
      const {
        guid: { value: guidValue }
      } = rowItem;

      navigate(`/newswires/${guidValue}/stories`);
    },
    Resource.NEWSWIRE,
    Action.READ
  );

  if (newswiresOrig.length < 1)
    return (
      <EmptyState
        title={i18n.t('pages.administration.newswires.emptyState.title')}
        subTitle={i18n.t('pages.administration.newswires.emptyState.subTitle')}
      />
    );

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newStorypool = newswiresOrig.filter(story => {
      return story.name.includes(search) || story.format.includes(search);
    });
    setNewswires(newStorypool);
  };

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={i18n.t('links.sidebar.newswires')}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<RssFeed color="primary" />}
            />
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder="Search ..."
              onChange={onSearchFieldChange}
              inputProps={{ 'aria-label': 'search newswires' }}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <NewswireServices
            newswireServices={newswires}
            newswireFormats={formatList}
            onRowClick={onRowClick}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NewswiresContainer;
