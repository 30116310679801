import { FunctionComponent, MouseEvent } from 'react';
import { Box, IconButton } from '@material-ui/core';
import i18n from 'i18n';
import { Edit, Visibility } from '@material-ui/icons';
import DeleteDialog from 'components/DeleteDialog';
import TooltipWrap from 'components/TooltipWrap';

interface DefaultAdminActionFieldI {
  target: string;
  targetDeleteTitle?: string;
  targetDeletePrompt?: string;
  targetDeleteWarning?: string;
  onShow?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

const DefaultAdminActionField: FunctionComponent<DefaultAdminActionFieldI> = ({
  target,
  targetDeleteTitle,
  targetDeletePrompt,
  targetDeleteWarning,
  onShow,
  onEdit,
  onDelete
}: DefaultAdminActionFieldI) => {
  const onEditHandler = (e: MouseEvent) => {
    e.stopPropagation();
    if (onEdit) onEdit();
  };

  return (
    <Box width={1} display="flex">
      {onShow && (
        <IconButton size="small" onClick={onShow}>
          <Visibility color="primary" />
        </IconButton>
      )}
      {onEdit && (
        <TooltipWrap title={i18n.t('button.edit')}>
          <IconButton size="small" onClick={onEditHandler}>
            <Edit color="primary" />
          </IconButton>
        </TooltipWrap>
      )}
      {onDelete && (
        <DeleteDialog
          onDelete={onDelete}
          target={target}
          targetDeleteTitle={targetDeleteTitle}
          targetDeletePrompt={targetDeletePrompt}
          targetDeleteWarning={targetDeleteWarning}
          btnDisabled={false}
        />
      )}
    </Box>
  );
};

export default DefaultAdminActionField;

DefaultAdminActionField.defaultProps = {
  targetDeleteTitle: undefined,
  targetDeletePrompt: undefined,
  targetDeleteWarning: undefined,
  onEdit: undefined,
  onShow: undefined,
  onDelete: undefined
};
