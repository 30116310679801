export const secondsToDuration = (seconds: number): string => {
  let date;

  try {
    date = new Date(1000 * seconds).toISOString();
  } catch {
    date = new Date().toISOString();
  }
  return date.substr(11, 8);
};
