import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from 'translations/en.json';
import translationDE from 'translations/de.json';
import translationAR from 'translations/ar.json';

// the translations
const resources = {
  en: { translation: translationEN },
  de: { translation: translationDE },
  ar: { translation: translationAR }
};

const language = navigator.language.split('-')[0];
const supportedLocales = ['en', 'de', 'us', 'ar'];
const lng = supportedLocales.includes(language) ? language : 'en';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng,
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'test',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
