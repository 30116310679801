import { Box, Grid, Typography } from '@material-ui/core';
import { FunctionComponent, ReactEventHandler } from 'react';
import {
  AUDIO_SAMPLE,
  PHOTO_SAMPLE,
  VIDEO_SAMPLE
} from 'pages/stories/components/Asset/constants';
import { MediaItemTypeMap } from 'pages/stories/components/Asset/type';
import assetFormStyles from 'pages/stories/styles/assetForm';
import AudioPlayer from 'material-ui-audio-player';
import VideoPlayer from 'components/VideoPlayer';

interface MediaItemI {
  peripheralAsset: $Lns.PeripheralAsset;
  onLoadedVideoCb?: ReactEventHandler<HTMLVideoElement> | undefined;
}

const MediaItem: FunctionComponent<MediaItemI> = ({
  onLoadedVideoCb,
  peripheralAsset
}: MediaItemI) => {
  const classes = assetFormStyles();

  const mediaItemContent = () => {
    if (!peripheralAsset) return <></>;

    const elements: MediaItemTypeMap = {
      Image: (
        <img
          className={classes.root}
          alt=""
          src={peripheralAsset?.path || PHOTO_SAMPLE}
        />
      ),
      Audio: (
        <>
          <audio onLoadedData={onLoadedVideoCb}>
            <source
              src={peripheralAsset.path || AUDIO_SAMPLE}
              type="audio/x-wav"
            />
            <track src="captions_es.vtt" kind="captions" />
          </audio>
          <AudioPlayer src={peripheralAsset.path || AUDIO_SAMPLE} />
        </>
      ),
      Video: (
        <VideoPlayer
          id={peripheralAsset.objectID}
          type="mp4"
          controls
          src={peripheralAsset?.path || VIDEO_SAMPLE}
          height={300}
          width={100}
          onLoadedVideoCb={onLoadedVideoCb}
        />
      )
    };

    return elements[peripheralAsset.type];
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography className={classes.paddingAlign} variant="h5">
          {peripheralAsset.name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.centerWidth100}>{mediaItemContent()}</Box>
      </Grid>
    </Grid>
  );
};

export default MediaItem;

MediaItem.defaultProps = {
  onLoadedVideoCb: undefined
};
