import {
  AppBar,
  Box,
  Button,
  Tab,
  Tabs,
  Tooltip,
  Grid
} from '@material-ui/core';
import {
  Attachment,
  Close,
  Fullscreen,
  History,
  Info,
  Launch,
  Toc,
  Web
} from '@material-ui/icons';
import { ChangeEvent, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { StoryFormData } from 'pages/stories/interface';
import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';
import EmptyState from 'components/EmptyState';
import DetailedFields from 'pages/stories/components/Form/DetailedFields';
import TabPanel from 'components/TabPanel';
import StoryScriptForm from 'pages/stories/components/Form/StoryScript';
import Editor from 'pages/stories/components/Form/Editor';
import i18n from 'i18n';
import HistoryTab from './History';

interface StoryFormTabsI {
  fullScreen: boolean;
  tabValue: number;
  browseTabsWindow: boolean;
  formData: StoryFormData;
  storyLock: boolean;
  calculatedDuration: number;
  dynamicAssets: AssetDynamic[];
  onToggleFullscreenAttachmentsTabWindow: () => void;
  onToggleBrowseTabsWindow: () => void;
  onChangeField: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeType: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
  onEditorChange: (text: string, field: string) => void;
  onAddDialogueAsset: (dialogue: AssetDynamic) => void;
  onUpdateDynamicAssets: (assetsNew: AssetDynamic[]) => void;
  onChangeTabValue: (val: number) => void;
  onToggleAddMedia: (val: boolean) => void;
  story: string;
}

const StoryFormTabs: FunctionComponent<StoryFormTabsI> = ({
  formData,
  tabValue,
  storyLock,
  calculatedDuration,
  dynamicAssets,
  fullScreen,
  browseTabsWindow,
  onToggleBrowseTabsWindow,
  onToggleFullscreenAttachmentsTabWindow,
  onChangeField,
  onChangeType,
  onEditorChange,
  onAddDialogueAsset,
  onUpdateDynamicAssets,
  onChangeTabValue,
  onToggleAddMedia,
  story
}: StoryFormTabsI) => {
  const { storyTypes, storyGenres, storyPools, locations } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const handleChange = (
    event: ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    onChangeTabValue(newValue);
  };

  return (
    <Box>
      {fullScreen && (
        <Tooltip title={i18n.t('pages.stories.tooltip.fullscreen').toString()}>
          <Box component="span">
            <Button
              onClick={onToggleFullscreenAttachmentsTabWindow}
              startIcon={<Close color="primary" />}
            />
          </Box>
        </Tooltip>
      )}
      {!fullScreen && (
        <Tooltip title={i18n.t('pages.stories.tooltip.fullscreen').toString()}>
          <Box component="span">
            <Button
              onClick={onToggleFullscreenAttachmentsTabWindow}
              startIcon={<Fullscreen color="primary" />}
            />
          </Box>
        </Tooltip>
      )}

      {!browseTabsWindow && (
        <Tooltip title={i18n.t('pages.stories.tooltip.toggle').toString()}>
          <Box component="span">
            <Button onClick={onToggleBrowseTabsWindow} startIcon={<Launch />} />
          </Box>
        </Tooltip>
      )}

      <AppBar position="static">
        <Tabs
          value={tabValue}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          aria-label="story form tabs"
        >
          <Tab label={i18n.t('pages.stories.tabLabel.info')} icon={<Info />} />
          <Tab
            data-test-id="script-tab"
            label={i18n.t('pages.stories.tabLabel.script')}
            icon={<Toc />}
          />
          <Tab
            data-test-id="print-format-tab"
            label={i18n.t('pages.stories.tabLabel.printFormat')}
            icon={<Web />}
          />
          <Tab
            data-test-id="attachments-tab"
            label={i18n.t('pages.stories.tabLabel.attachments')}
            icon={<Attachment />}
          />
          <Tab
            data-test-id="history-tab"
            label={i18n.t('pages.stories.tabLabel.history')}
            icon={<History />}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0} data-test-id="form-tab">
        <DetailedFields
          storyLock={storyLock}
          calculatedDuration={calculatedDuration}
          formData={formData}
          locations={locations}
          storyTypes={storyTypes}
          storyPools={storyPools}
          storyGenres={storyGenres}
          onChangeField={onChangeField}
          onChangeType={onChangeType}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1} data-test-id="script-tab">
        <Box padding={2}>
          <StoryScriptForm
            onToggleAddMedia={onToggleAddMedia}
            calculatedDuration={calculatedDuration}
            storyLock={storyLock}
            fullScreen={fullScreen}
            formData={formData}
            dynamicAssets={dynamicAssets}
            onAddDialogueAsset={onAddDialogueAsset}
            onUpdateDynamicAssets={onUpdateDynamicAssets}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Box padding={2}>
          <Grid item xs={12}>
            <Editor
              text={formData.text}
              onTextChange={onEditorChange}
              field="text"
            />
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <Box padding={2}>
          <EmptyState
            title={i18n.t('pages.stories.tabLabel.attachments')}
            subTitle="No content yet"
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <Box padding={2}>
          <HistoryTab story={story} />
        </Box>
      </TabPanel>
    </Box>
  );
};

export default StoryFormTabs;
