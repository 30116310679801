import { Route } from 'react-router-dom';
import { FunctionComponent } from 'react';
import { RouteProps } from 'react-router';
import { Unauthorized } from 'pages/errors/Unauthorized';
import { useCanAccess } from 'components/Authorization/utils';

interface ProtectedRouteI extends RouteProps {
  lnsResource?: string;
  lnsAction?: string;
  allowedForCurrentUser?: boolean;
}

export const ProtectedRoute: FunctionComponent<ProtectedRouteI> = ({
  lnsResource,
  lnsAction,
  allowedForCurrentUser,
  ...props
}: ProtectedRouteI) => {
  const canAccess =
    useCanAccess(lnsResource, lnsAction) || allowedForCurrentUser;

  if (canAccess) {
    return <Route {...props} />;
  }
  return <Route path={props.path} element={<Unauthorized />} />;
};

ProtectedRoute.defaultProps = {
  lnsResource: undefined,
  lnsAction: undefined,
  allowedForCurrentUser: false
};
