const Resource = {
  STORY: 'story',
  STORY_POOL: 'storyPool',
  NEWSWIRE: 'newswire',
  RUNDOWN: 'rundown',
  PUBLISH_FOLDER: 'publishFolder',
  TASK: 'task',
  SYSTEM_SETUP: 'systemSetup',
  RUNDOWN_TEMPLATE: 'rundownTemplate',
  STORY_TYPE: 'storyType',
  PERIPHERAL: 'peripheral',
  STORY_GENRE: 'storyGenre',
  FILE_TYPE: 'fileType',
  SECURITY: 'security',
  LOCATION: 'location'
};

const Action = {
  READ: 'read',
  EDIT: 'edit',
  CREATE: 'create',
  DELETE: 'delete',
  CHANGE_STATE: 'changeState',
  LIST: 'list',
  COPY: 'copy',
  SHARE: 'share',
  APPROVE: 'approve',
  ADMIN: 'admin'
};

const CRUD = [
  Action.READ,
  Action.EDIT,
  Action.CREATE,
  Action.DELETE,
  Action.LIST
];

const RESOURCE_ACTIONS = {
  [Resource.STORY]: [
    ...CRUD,
    Action.CHANGE_STATE,
    Action.COPY,
    Action.SHARE,
    Action.APPROVE,
    Action.ADMIN
  ],
  [Resource.STORY_POOL]: [...CRUD, Action.ADMIN],
  [Resource.NEWSWIRE]: [...CRUD, Action.ADMIN],
  [Resource.RUNDOWN]: [...CRUD, Action.CHANGE_STATE, Action.ADMIN],
  [Resource.PUBLISH_FOLDER]: [...CRUD, Action.CHANGE_STATE, Action.ADMIN],
  [Resource.TASK]: [...CRUD, Action.CHANGE_STATE, Action.ADMIN],
  [Resource.SYSTEM_SETUP]: [Action.READ, Action.EDIT, Action.ADMIN],
  [Resource.RUNDOWN_TEMPLATE]: [...CRUD, Action.ADMIN],
  [Resource.STORY_TYPE]: [...CRUD, Action.ADMIN],
  [Resource.PERIPHERAL]: [...CRUD, Action.ADMIN],
  [Resource.STORY_GENRE]: [...CRUD, Action.ADMIN],
  [Resource.FILE_TYPE]: [...CRUD, Action.ADMIN],
  [Resource.SECURITY]: [Action.READ, Action.EDIT, Action.ADMIN],
  [Resource.LOCATION]: [...CRUD, Action.ADMIN]
};

export { Resource, Action, RESOURCE_ACTIONS };
