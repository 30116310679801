export const INIT_DURATION_SECONDS = 100;
export const INIT_START_DURATION_SECONDS = 0;
export const FILE_TYPE_MAP: $Lns.ObjectKey = {
  image: 'png',
  audio: 'mp3',
  video: 'mp4'
};
export const PHOTO_SAMPLE =
  'https://i.picsum.photos/id/866/200/300.jpg?hmac=rcadCENKh4rD6MAp6V_ma-AyWv641M4iiOpe1RyFHeI';
export const AUDIO_SAMPLE =
  'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3';
export const VIDEO_SAMPLE =
  'http://techslides.com/demos/sample-videos/small.mp4';
