import { Button } from '@material-ui/core';
import { FunctionComponent } from 'react';
import i18n from 'i18n';
import DetailDialog from 'components/DetailDialog';
import MediaItem from 'components/MediaItem';

interface MediaItemDetailI {
  mediaItem: $Lns.MediaItem;
  onClose: () => void;
}

const MediaItemDetail: FunctionComponent<MediaItemDetailI> = ({
  mediaItem,
  onClose
}: MediaItemDetailI) => {
  const dialogContent = () => {
    return (
      <MediaItem mediaItem={mediaItem} height={150} width={250} controls />
    );
  };

  const dialogActions = () => {
    return (
      <>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          {i18n.t('button.close')}
        </Button>
      </>
    );
  };

  return (
    <DetailDialog
      dialogTitle={mediaItem.name}
      dialogContent={dialogContent()}
      dialogActions={dialogActions()}
      onHandleClose={onClose}
    />
  );
};

export default MediaItemDetail;
