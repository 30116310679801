import { ChangeEvent, FunctionComponent, useRef, useReducer } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  Grid,
  MenuItem,
  Select
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import i18n from 'i18n';
import CustomTextField from 'components/CustomTextField';
import SearchSelectUsers from 'components/SearchSelectUsers';
import { TaskStates } from 'pages/assignments/consts';
import { FormData } from 'pages/assignments/formData';
import DialogStories from 'pages/assignments/components/DialogStories';
import PriorityTag from 'pages/assignments/components/PriorityTag';
import {
  findTaskStateGuidByName,
  filterAvailableTaskStates,
  getTaskPriorityObject,
  useOpStates
} from 'pages/assignments/utils';
import {
  createInitialStates,
  reducer
} from 'pages/assignments/components/DialogForm/reducer';
import { combineFormData } from 'pages/assignments/components/DialogForm/utils';
import useStyles from 'pages/assignments/components/DialogForm/styles';

interface AssignmentEditFormI {
  isAdmin: boolean;
  currentLnsUser: $Lns.User;
  onSave: (formData: FormData) => void;
  onCancel: () => void;
  assignment: FormData;
  taskStates: $Lns.TaskState[];
}

const AssignmentEditForm: FunctionComponent<AssignmentEditFormI> = ({
  isAdmin,
  currentLnsUser,
  onSave,
  onCancel,
  assignment,
  taskStates
}: AssignmentEditFormI) => {
  const classes = useStyles();

  const {
    isCreation,
    isStateNew,
    canEditState,
    canEdit,
    canAccept,
    canReject
  } = useOpStates(assignment, currentLnsUser, isAdmin);

  // Managing Props Values and Existing Values
  const { assignedToObj: propsAssignedToObj, storyObj: propsStoryObj } =
    assignment;

  const { keys: taskPriorityKeys, values: taskPriorityValues } =
    getTaskPriorityObject();

  // Create the State Reducer
  const [states, dispatch] = useReducer(
    reducer,
    createInitialStates(assignment, currentLnsUser, isAdmin, taskStates)
  );

  // Decontruct quickhand access to the states
  const { editStory, formData, saveBtnDisabled } = states;
  const { assignedAtObj, dateDueObj, assignedByObj, priority } = formData;

  // Not All TaskStates are Available to the Select
  const selectableTaskStates = filterAvailableTaskStates(
    taskStates,
    formData,
    currentLnsUser,
    isAdmin
  );

  // ASSIGN_TO is a state managed in a sub component
  const refAssignedTo = useRef<$Lns.User | undefined>(propsAssignedToObj);

  // This is needed as the SearchSelectUser component expects an array of users
  const arrayedAssignedTo =
    refAssignedTo.current && Object.keys(refAssignedTo.current).length !== 0
      ? [refAssignedTo.current]
      : [];

  // Story associated with this assignment is managed in a sub component
  const refStoryObj = useRef<$Lns.Story | undefined>(propsStoryObj);
  const { current: storyObj } = refStoryObj;

  // A generalized function that deals with general inputs
  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'onChangeHTMLInputElement',
      payload: e
    });
  };

  // A generalized function that deals with general selections
  const onChangeSelect = (key: string, value: number | string) => {
    dispatch({
      type: 'onChangeSelectElement',
      payload: { key, value }
    });
  };

  // Uses OnChangeSelect to handle selection of taskstate
  const handleTaskStateChange = (e: ChangeEvent<{ value: unknown }>) => {
    onChangeSelect('taskState', e.target.value as string);
  };

  // Uses OnChangeSelect to handle selection of priorities
  const handlePriorityChange = (e: ChangeEvent<{ value: unknown }>) => {
    onChangeSelect('priority', e.target.value as number);
  };

  // A generalized functon that deals with Picking Date & TIme
  const onChangeDateTime = (key: string, value: Date) => {
    dispatch({
      type: 'onChangeDateTime',
      payload: { key, value }
    });
  };

  // STORY PICKER Component's handlers & callbacks
  const onEditStory = () => {
    // this brings up the edit story dialog
    dispatch({ type: 'editStory', payload: storyObj });
  };

  const onCancelEditStory = () => {
    dispatch({ type: 'editStory', payload: false });
  };

  const handleOnSaveStory = (newStory: $Lns.Story | undefined) => {
    if (
      newStory &&
      (!storyObj || (storyObj && newStory.guid !== storyObj.guid))
    ) {
      refStoryObj.current = newStory;
      toggleSaveButton();
    }
    onCancelEditStory();
  };
  // END - STORY PICKER Component's handlers & callbacks

  // Handler that deals with Search Select User Component
  const handleSelectedUsers = (newSelected: $Lns.User[]) => {
    let changedFlag = false;

    if (newSelected.length > 0) {
      if (refAssignedTo.current)
        changedFlag = refAssignedTo.current.guid !== newSelected[0].guid;
      else changedFlag = true;

      [refAssignedTo.current] = newSelected;
    } else {
      if (refAssignedTo.current) changedFlag = true;
      refAssignedTo.current = undefined;
    }

    // if there are changes, iow changedFlag is true,
    // then disableSaveButton should be true, and vice versa.
    forceSaveButton(!changedFlag);
  };

  // Functions to handle Save Button
  const toggleSaveButton = () => {
    dispatch({ type: 'toggleSaveButton' });
  };

  const forceSaveButton = (forceFlag: boolean) => {
    dispatch({
      type: 'forceSaveButton',
      payload: forceFlag
    });
  };
  // END Functions to handle Save Button

  // Save and close form
  const handleOnSaveForm = () => {
    let updatedFormData: FormData = formData;

    if ((isCreation || isStateNew) && refAssignedTo.current) {
      const pendingStateGuid = findTaskStateGuidByName(
        TaskStates.PENDING_ASSIGNMENT,
        taskStates
      );

      updatedFormData = {
        ...formData,
        taskState: pendingStateGuid || ''
      };
    }

    if (propsAssignedToObj && !refAssignedTo.current) {
      const newStateGuid = findTaskStateGuidByName(TaskStates.NEW, taskStates);
      updatedFormData = {
        ...formData,
        taskState: newStateGuid || ''
      };
    }

    onSave(
      combineFormData(
        updatedFormData,
        refStoryObj.current,
        assignedByObj,
        assignedAtObj,
        refAssignedTo.current,
        dateDueObj
      )
    );
  };

  // Accept and Save Form
  const handleOnAccept = () => {
    const acceptStateGuid = findTaskStateGuidByName(
      TaskStates.ASSIGNED,
      taskStates
    );

    const updatedFormData: FormData = {
      ...formData,
      taskState: acceptStateGuid || ''
    };

    onSave(
      combineFormData(
        updatedFormData,
        refStoryObj.current,
        assignedByObj,
        assignedAtObj,
        currentLnsUser, // assign current user to Assigned_To
        dateDueObj
      )
    );
  };

  // Reject and Save Form
  const handleOnReject = () => {
    const rejectStateGuid = findTaskStateGuidByName(
      TaskStates.REJECTED,
      taskStates
    );

    const updatedFormData: FormData = {
      ...formData,
      taskState: rejectStateGuid || ''
    };

    onSave(
      combineFormData(
        updatedFormData,
        refStoryObj.current,
        assignedByObj,
        assignedAtObj,
        refAssignedTo.current,
        dateDueObj
      )
    );
  };

  return (
    <>
      <Dialog onClose={onCancel} aria-labelledby="edit-assignment" open>
        <Box display="flex">
          <DialogTitle id="edit-assignment-title">
            {isCreation
              ? i18n.t('pages.assignments.dialogCreate.title')
              : i18n.t('pages.assignments.dialogEdit.title')}
          </DialogTitle>
          <Box className={classes.flexRight}>
            <FormControl
              size="small"
              variant="outlined"
              className={classes.formControl}
              disabled={!canEdit}
            >
              <InputLabel id="select-priority-label">
                {i18n.t('pages.assignments.formLabel.priority')}
              </InputLabel>
              <Select
                labelId="select-priority-label"
                id="select-priority"
                value={priority}
                onChange={handlePriorityChange}
                label={i18n.t('pages.assignments.formLabel.priority')}
              >
                {taskPriorityKeys.map((taskKey, index) => {
                  return (
                    <MenuItem
                      key={taskPriorityValues[index]}
                      value={taskPriorityValues[index]}
                    >
                      <PriorityTag priority={taskKey} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {!isCreation && (
              <FormControl
                size="small"
                variant="outlined"
                className={classes.formControl}
                disabled={!canEditState}
              >
                <InputLabel id="select-taskstate-label">
                  {i18n.t('pages.assignments.formLabel.taskStates')}
                </InputLabel>
                <Select
                  labelId="select-taskstate-label"
                  id="select-taskstate"
                  value={formData.taskState}
                  onChange={handleTaskStateChange}
                  label={i18n.t('pages.assignments.formLabel.taskStates')}
                >
                  {selectableTaskStates.map(stateItem => (
                    <MenuItem key={stateItem.guid} value={stateItem.guid}>
                      {stateItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Box paddingLeft={2} paddingRight={2}>
              <CustomTextField
                onChange={onChangeField}
                value={formData.title}
                label={i18n.t('pages.assignments.formLabel.title')}
                name="title"
                error={!!formData?.errors.title}
                helperText={
                  formData.errors.title || i18n.t('helperText.required')
                }
                disabled={!canEdit}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box paddingLeft={2} paddingRight={2}>
              <CustomTextField
                rows={3}
                onChange={onChangeField}
                value={formData.description}
                label={i18n.t('pages.assignments.formLabel.description')}
                name="description"
                error={!!formData?.errors.description}
                helperText={
                  formData.errors.description || i18n.t('helperText.required')
                }
                disabled={!canEdit}
              />
            </Box>
          </Grid>
          {isAdmin && ( // Only Visible and Editable by Admins
            <Grid item xs={12}>
              <Box padding={2}>
                <DateTimePicker
                  fullWidth
                  autoOk
                  variant="inline"
                  name="assignedAt"
                  inputVariant="outlined"
                  label={i18n.t('pages.assignments.formLabel.assignedAt')}
                  format="MM/dd/yyyy hh:mm"
                  value={assignedAtObj}
                  onChange={date =>
                    onChangeDateTime('assignedAtObj', date as Date)
                  }
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box padding={2}>
              <DateTimePicker
                fullWidth
                autoOk
                variant="inline"
                name="dateDue"
                inputVariant="outlined"
                label={i18n.t('pages.assignments.formLabel.dateDue')}
                format="MM/dd/yyyy hh:mm"
                value={dateDueObj}
                onChange={date => onChangeDateTime('dateDueObj', date as Date)}
                minDate={assignedAtObj || new Date()}
                disabled={!canEdit}
              />
            </Box>
          </Grid>
          <Grid container item xs={12} alignItems="center">
            <Box flex={1} padding={2}>
              <CustomTextField
                disabled
                margin="none"
                onChange={() => {}}
                value={storyObj ? storyObj.slug : 'No Story Selected'}
                label={i18n.t('pages.assignments.formLabel.storySlug')}
                name="story-slug"
                error={false}
              />
            </Box>
            <Box paddingLeft={1} paddingRight={2}>
              <Button
                variant="contained"
                onClick={onEditStory}
                disabled={!canEdit}
              >
                ...
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box paddingRight={2} paddingBottom={2}>
              <SearchSelectUsers
                title={i18n.t('pages.assignments.formLabel.assignedTo')}
                placeholderText={i18n.t(
                  'pages.assignments.placeholderText.assignedTo'
                )}
                maxSelect={1}
                selectedUserList={arrayedAssignedTo}
                onChangeSelect={handleSelectedUsers}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              padding={2}
              display="flex"
              justifyContent="flex-end"
              width="100%"
            >
              <Button color="secondary" variant="outlined" onClick={onCancel}>
                {i18n.t('button.cancel')}
              </Button>
              {(isAdmin || canEdit || canEditState) && (
                <Box paddingLeft={2}>
                  <Button
                    disabled={saveBtnDisabled}
                    color="secondary"
                    variant="contained"
                    onClick={handleOnSaveForm}
                  >
                    {i18n.t('button.save')}
                  </Button>
                </Box>
              )}
              {canAccept && (
                <Box paddingLeft={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleOnAccept}
                  >
                    {i18n.t('pages.assignments.button.accept')}
                  </Button>
                </Box>
              )}
              {canReject && (
                <Box paddingLeft={2}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleOnReject}
                  >
                    {i18n.t('pages.assignments.button.reject')}
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Dialog>
      {editStory !== false && (
        <DialogStories
          selectedStory={editStory}
          onSave={handleOnSaveStory}
          onCancel={onCancelEditStory}
        />
      )}
    </>
  );
};

export default AssignmentEditForm;
