import { Box, Button, Card, Typography } from '@material-ui/core';
import CustomTextField from 'components/CustomTextField';
import LoadingButton from 'components/LoadingButton';
import i18n from 'i18n';
import { validateForm } from 'pages/signup/utils';
import { ChangeEvent, useState, FunctionComponent } from 'react';

interface EmailFormI {
  onSave: (email: string) => void;
  onCancel: () => void;
  isLoading: boolean;
}

const EmailForm: FunctionComponent<EmailFormI> = ({
  onCancel,
  onSave,
  isLoading
}: EmailFormI) => {
  const [formData, setFormData] = useState({
    email: '',
    errors: {
      email: ''
    }
  });

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData({
      ...formData,
      errors: {
        ...formData.errors,
        [name]: validateForm(name, value)
      },
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  const btnDisabled =
    Object.values(formData.errors).some(e => !!e) || !formData.email;

  return (
    <Card raised elevation={8}>
      <Box padding={2} display="flex" justifyContent="center">
        <Typography variant="h5" color="primary">
          {i18n.t('pages.passwordReset.typography.email')}
        </Typography>
      </Box>

      <Box padding={2}>
        <CustomTextField
          label={i18n.t('pages.signup.formLabel.email')}
          name="email"
          error={!!formData.errors.email}
          value={formData.email}
          onChange={onChangeField}
        />
        <Box display="grid" gridAutoFlow="column" gridGap={10} pt={4}>
          <Button
            style={{ maxWidth: 'min-content', justifySelf: 'start' }}
            fullWidth
            variant="contained"
            color="default"
            onClick={onCancel}
          >
            {i18n.t('button.cancel')}
          </Button>
          <LoadingButton
            style={{ maxWidth: 'min-content', justifySelf: 'end' }}
            label={i18n.t('button.send')}
            isLoading={isLoading}
            type="submit"
            fullWidth
            disabled={btnDisabled}
            variant="contained"
            color="secondary"
            onClick={() => onSave(formData.email)}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default EmailForm;
