export const DEFAULT_STATE = 'DEFAULT_STATE';
export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const SET_STORY_POOLS = 'SET_STORY_POOLS';
export const SET_STORY_TYPES = 'SET_STORY_TYPES';
export const SET_STORY_CATEGORIES = 'SET_STORY_CATEGORIES';
export const SET_STORY_STATES = 'SET_STORY_STATES';
export const SET_STORY_GENRES = 'SET_STORY_GENRES';
export const SET_RUNDOWN_STORIES = 'SET_RUNDOWN_STORIES';
export const SET_RUNDOWN_DATE = 'SET_RUNDOWN_DATE';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_CURRENT_SSO_USER = 'SET_CURRENT_SSO_USER';
export const SET_CURRENT_LNS_USER = 'SET_CURRENT_LNS_USER';
export const SET_CURRENT_USER_PERMISSIONS = 'SET_CURRENT_USER_PERMISSIONS';
export const SET_NEWSWIRE_SERVICES = 'SET_NEWSWIRE_SERVICES';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_INITIAL_DATA = 'SET_INITIAL_DATA';
export const UPDATE_PERIPHERALS_STATUS = 'UPDATE_PERIPHERALS_STATUS';
export const PURGE_SESSION = 'PURGE_SESSION';
export const SET_DIRECTION = 'SET_DIRECTION';
