import { callSsoApi } from 'utils/fetch';
import { SignUpFormData } from './interface';

export const signup = (
  formData: SignUpFormData
): Promise<{ code: number; errors?: $Lns.ObjectKey[] }> => {
  const { email, username, password } = formData;

  const data = {
    email,
    username,
    password,
    contact_number: formData.contactNumber,
    password_reset_answer: formData.passwordResetAnswer,
    password_reset_question: formData.passwordResetQuestion
  };

  return callSsoApi('/register', 'POST', data);
};
