import { filterUsers } from 'components/SearchSelectUsers/utils';

type Action =
  | {
      type: 'newProps';
      payload: {
        maxSelect: number | undefined;
        allUserList: $Lns.User[];
        selectedUserList: $Lns.User[];
      };
    }
  | {
      type: 'onSearchTermChanged';
      payload: string;
    }
  | {
      type: 'setAllUserList';
      payload: $Lns.User[];
    }
  | {
      type: 'selectUser' | 'unselectUser';
      payload: $Lns.User;
    }
  | { type: 'deleteLastUser' };

export type State = {
  maxSelect: number | undefined;
  searchTerm: string;
  allUserList: $Lns.User[];
  filteredUserList: $Lns.User[] | undefined;
  selectedUserList: $Lns.User[];
};

export const initialState: State = {
  maxSelect: undefined,
  searchTerm: '',
  allUserList: [],
  filteredUserList: undefined,
  selectedUserList: []
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'newProps': {
      return {
        ...state,
        maxSelect: action.payload.maxSelect,
        allUserList: action.payload.allUserList,
        selectedUserList: action.payload.selectedUserList
      };
    }
    case 'onSearchTermChanged': {
      return {
        ...state,
        searchTerm: action.payload,
        filteredUserList:
          action.payload !== ''
            ? filterUsers(
                action.payload,
                state.allUserList,
                state.selectedUserList
              )
            : undefined
      };
    }
    case 'setAllUserList': {
      return {
        ...state,
        allUserList: action.payload
      };
    }
    case 'selectUser': {
      return {
        ...state,
        searchTerm: '',
        filteredUserList: undefined,
        selectedUserList:
          (state.maxSelect &&
            state.selectedUserList.length < state.maxSelect) ||
          !state.maxSelect
            ? [...state.selectedUserList, action.payload]
            : state.selectedUserList
      };
    }
    case 'unselectUser': {
      const newSelectedUserList = state.selectedUserList.filter(
        userItem => userItem.guid !== action.payload.guid
      );

      return { ...state, selectedUserList: newSelectedUserList };
    }
    case 'deleteLastUser': {
      const newSelectedUserList = state.selectedUserList.slice(
        0,
        state.selectedUserList.length
      );

      return { ...state, selectedUserList: newSelectedUserList };
    }
    default:
      return state;
  }
};
