import { createStyles, makeStyles } from '@material-ui/core';

export const generalStyle = makeStyles(theme =>
  createStyles({
    textUppercase: {
      '& input': {
        textTransform: 'uppercase'
      }
    },
    uploadZone: {
      border: '2px dashed',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      height: `${theme.spacing(12)}px`
    }
  })
);
