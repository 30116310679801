import { FunctionComponent, useEffect, useState, useReducer } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  Grid,
  Typography,
  Dialog,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import {
  fetchUsersInUserGroupByGuid,
  fetchUserGroupByGuid,
  fetchAllLnsObjects,
  bulkUpdateUsersInUserGroupByGuid,
  updateUserGroupByGuid,
  updateLnsObjectPermissionOfUserGroup
} from 'pages/administration/security/groups/actions';
import { reducer } from 'pages/administration/security/groups/utils';
import Loading from 'components/Loading';
import Form from 'pages/administration/security/groups/components/Form';
import UserList from 'components/UserList';
import Tabs from 'pages/administration/security/groups/components/Tabs';
import ListAddDialog from 'pages/administration/security/groups/components/ListAddDialog';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';
import { FormData } from 'pages/administration/security/groups/formData';
import { makeStyles } from '@material-ui/styles';
import TitleBarWraper from 'components/TitleBarWraper';
import { InfoOutlined } from '@material-ui/icons';
import BtnWrapper from 'components/BtnWrapper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';

const initialState = {
  guid: '',
  name: '',
  users: [],
  lnsObjectPermissions: [],
  loaded: false
};

interface UpdatePermissions {
  lnsObjectPList: $Lns.LnsObjectPermission;
  lnsObject: $Lns.LnsObject;
}

const useStyles = makeStyles({
  pointer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right'
  }
});

const EditPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { guid } = useParams();
  const [lnsObjects, setLnsObjects] = useState<$Lns.LnsObject[]>([]);
  const styleSheet = useStyles();

  const [
    {
      name: userGroupName,
      users,
      lnsObjectPermissions,
      loaded: userGroupLoaded
    },
    reducerDispatch
  ] = useReducer(reducer, initialState);

  const [lnsObjectPermissionsList, setLnsObjectPermissions] = useState<
    UpdatePermissions[]
  >([]);

  const fetchSetUsers = (fetchGuid: string) => {
    fetchUsersInUserGroupByGuid(fetchGuid).then(({ data: payload }) => {
      reducerDispatch({ type: 'reloadUserList', payload });
    });
  };

  const handleApiError = () => {
    dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
  };

  useEffect(() => {
    fetchUserGroupByGuid(guid).then(({ data }) => {
      reducerDispatch({
        type: 'fetchedUserGroup',
        payload: {
          guid: data.guid,
          name: data.name,
          users: data.users,
          lnsObjectPermissions: data.lnsObjectPermissions,
          loaded: true
        }
      });
    });
    fetchAllLnsObjects().then(({ data }) => {
      setLnsObjects(data);
    });
  }, [guid]);

  const onBulkAddUsers = (selectedUsers: $Lns.User[]) => {
    const newSelectedUsers = [...(users || []), ...selectedUsers];
    bulkUpdateUsersInUserGroupByGuid(
      guid,
      newSelectedUsers.map(item => {
        return { user: item.guid };
      })
    )
      .then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notification.userGroups.addUsersSuccess'),
            'success'
          )
        );
        fetchSetUsers(guid);
      })
      .catch(() => {
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  };

  const onDeleteUser = users
    ? (userToDelete: $Lns.User) => {
        const newSelectedUsers = users.filter(
          user => user.guid !== userToDelete.guid
        );
        bulkUpdateUsersInUserGroupByGuid(
          guid,
          newSelectedUsers.map(item => {
            return { user: item.guid };
          })
        )
          .then(() => {
            dispatch(
              toggleNotification(
                i18n.t('notification.userGroups.removeUserSuccess'),
                'success'
              )
            );
            fetchSetUsers(guid);
          })
          .catch(() => {
            dispatch(
              toggleNotification(i18n.t('notifications.apiError'), 'error')
            );
          });
      }
    : undefined;

  const onSavePermissions = (
    newPermissions: $Lns.LnsObjectPermission,
    targetLnsObject: $Lns.LnsObject
  ) => {
    if (newPermissions && newPermissions.guid)
      updateLnsObjectPermissionOfUserGroup(newPermissions)
        .then(() => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.userGroups.updatePermissionSuccess'),
              'success'
            )
          );
        })
        .catch(() => {
          dispatch(
            toggleNotification(i18n.t('notifications.apiError'), 'error')
          );
        });
    else
      dispatch(
        toggleNotification(
          `LnsObjectPermission does not exist between UserGroup:${userGroupName} and LnsObject:${targetLnsObject.name}`,
          'error'
        )
      );
  };

  const [editUserGroup, setEditUserGroup] = useState<FormData>();

  const onEdit = () => {
    const formData = {
      guid,
      name: userGroupName,
      errors: { name: '' }
    };
    setEditUserGroup(formData);
  };

  const onEditCancel = () => {
    setEditUserGroup(undefined);
  };

  const onSave = (formData: FormData) => {
    if (formData.guid) {
      updateUserGroupByGuid(formData)
        .then(({ data }) => {
          dispatch(
            toggleNotification(
              i18n.t('notifications.userGroups.saveSuccess'),
              'success'
            )
          );
          setEditUserGroup(undefined);
          reducerDispatch({
            type: 'fetchedUserGroup',
            payload: {
              guid: data.guid,
              name: data.name,
              users,
              lnsObjectPermissions,
              loaded: true
            }
          });
        })
        .catch(handleApiError);
    }
  };

  const onUpdate = (
    newPermissions: $Lns.LnsObjectPermission,
    targetLnsObject: $Lns.LnsObject
  ) => {
    const exist = lnsObjectPermissionsList.find(
      item => item.lnsObjectPList.guid === newPermissions.guid
    );

    if (exist) {
      const inputPermissions = lnsObjectPermissionsList.map(item => {
        if (item.lnsObjectPList.guid === newPermissions.guid) {
          const lnsObjectPList = { ...item.lnsObjectPList, ...newPermissions };
          const lnsObject = { ...item.lnsObject, ...targetLnsObject };
          return { lnsObjectPList, lnsObject };
        }
        return item;
      });
      setLnsObjectPermissions(inputPermissions);
      return;
    }

    const input = [
      ...lnsObjectPermissionsList,
      { lnsObjectPList: newPermissions, lnsObject: targetLnsObject }
    ];
    setLnsObjectPermissions(input);
  };

  const handleWholeUpdate = () => {
    lnsObjectPermissionsList.forEach(item => {
      onSavePermissions(item.lnsObjectPList, item.lnsObject);
    });
  };

  return (
    <Grid container direction="row" spacing={1}>
      <TitleBarWraper>
        <Grid item xs={6}>
          <Box display="flex" style={{ overflowWrap: 'break-word' }}>
            <ListItem>
              <ListItemIcon>
                <InfoOutlined color="primary" />
              </ListItemIcon>
              <ListItemText secondary="Title">
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="h6" color="primary">
                      {userGroupLoaded
                        ? userGroupName
                        : i18n.t('helperText.loading')}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} className={styleSheet.pointer}>
                    <Box onClick={onEdit}>
                      <CreateIcon color="primary" fontSize="small" />
                    </Box>
                  </Grid>
                </Grid>
              </ListItemText>
            </ListItem>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <BtnWrapper>
            <ListAddDialog
              onConfirmCb={onBulkAddUsers}
              addedUsers={users || []}
            />
            <ProtectedButton
              tooltip={i18n.t('button.update')}
              lnsResource={Resource.SECURITY}
              lnsAction={Action.EDIT}
              color="secondary"
              variant="contained"
              onClick={handleWholeUpdate}
              data-test-id="addStoryBtn"
            >
              {i18n.t('button.update')}
            </ProtectedButton>
          </BtnWrapper>
        </Grid>
      </TitleBarWraper>
      <Grid item xs={12}>
        {(!userGroupLoaded || lnsObjects.length === 0) && <Loading />}
        {userGroupLoaded && lnsObjects.length > 0 && (
          <Tabs
            lnsObjects={lnsObjects}
            lnsObjectPermissions={lnsObjectPermissions}
            onHandleChange={onUpdate}
          />
        )}
        {lnsObjects.length === 0 && <Loading />}
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={4} justify="space-between">
          <Grid item>
            <Typography>
              {i18n.t('pages.administration.security.typography.users')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {users && <UserList userItems={users} onDelete={onDeleteUser} />}
        {!users && <Loading />}
      </Grid>
      {editUserGroup && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="delete-usergroup-dialog"
          open
        >
          <Form
            onSave={onSave}
            onCancel={onEditCancel}
            userGroup={editUserGroup}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default EditPage;
