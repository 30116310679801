const colors = {
  primary: {
    50: '#d6e4eb',
    100: '#b0cad8',
    200: '#89b0c5',
    300: '#6397b2',
    400: '#3c7d9f',
    500: '#16638c',
    600: '#124f70',
    700: '#0d3b54',
    800: '#092838',
    900: '#04141c'
  },
  secondary: {
    50: '#dcf4f9',
    100: '#bae9f3',
    200: '#99dfed',
    300: '#77d4e7',
    400: '#56cae1',
    500: '#34bfdb',
    600: '#2a99af',
    700: '#1f7383',
    800: '#154c58',
    900: '#0a262c'
  },
  text: {
    secondary: '#084d74'
  },
  neutral: {
    0: '#fff',
    5: '#f4f4f4',
    10: '#e4e5e6',
    20: '#caccce',
    40: '#797a7c',
    60: '#515252',
    80: '#282929'
  },
  success: {
    light: '#e3f0e9',
    medium: '#9dcbb0',
    dark: '#108040'
  },
  warning: {
    light: '#fcf3d8',
    medium: '#f2c94c',
    dark: '#dab544'
  },
  info: {
    light: '#e6f3fb',
    medium: '#94cded',
    dark: '#2d9cdb'
  },
  error: {
    light: '#fbe8e8',
    medium: '#ec9c9c',
    dark: '#da3d3d'
  },
  backgrounds: {
    primary: '#eaf2f5',
    secondary: '#34bfdb',
    helper: '#fafafa',
    grey: '#fcf9f4',
    grey10: '#f5f3ef',
    grey20: '#f2efeb',
    grey30: '#ede9e3',
    grey40: '#e8e3db',
    grey50: '#e3ded2'
  },
  opacities: {
    dark: {
      5: '#000, 05%',
      10: '#000, 10%',
      20: '#000, 20%',
      40: '#000, 40%',
      80: '#000, 80%'
    },
    light: {
      5: '#fff, 05%',
      10: '#fff, 10%',
      20: '#fff, 20%',
      40: '#fff, 40%',
      80: '#fff, 80%'
    }
  },
  shadows: {
    soft: '2, 0, 2, 15%',
    medium: '8, 0, 4, 15%',
    strong: '16, 0, 8, 20%'
  }
};

export default colors;
