import { DialogueData } from 'pages/stories/interfaces/dialogueForm';
import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';
import { updateDialogueObj } from 'pages/stories/utils/dialogueObject';
import { TableCellI } from 'interfaces/tableCell';
import { ViewTask } from 'interfaces/viewTask';

interface CellInterFace {
  [key: string]: {
    value: string;
  };
}

export const updateDialogueValues = (
  dynamicAssets: AssetDynamic[],
  scriptObj: DialogueData,
  initVals: DialogueData
): AssetDynamic[] => {
  const dialogueObj = [...dynamicAssets];
  dialogueObj.forEach((pin, key) => {
    if (!pin.assetS || initVals.parentAsset !== key) return;
    if (dialogueObj[key] && dialogueObj[key].assetS) {
      const input = { ...scriptObj };
      delete input.parentAsset;
      const tempDialogue = updateDialogueObj(
        scriptObj,
        pin.assetS.positionInScript
      );
      dialogueObj[key].assetS = tempDialogue;
    }
  });
  return dialogueObj;
};

export const makeObjCells = (obj: $Lns.ObjectKey): CellInterFace => {
  const objTemp = {} as CellInterFace;
  Object.keys(obj).forEach(pin => {
    const tempVal = { value: obj[pin as keyof typeof obj] };
    objTemp[pin as keyof typeof objTemp] = tempVal;
  });
  return objTemp;
};

export const makeCells = (objs: $Lns.ObjectKey[]): TableCellI[] => {
  return objs.map(pin => {
    const dynamicFields = makeObjCells(pin);
    return {
      fields: { ...dynamicFields }
    };
  });
};

export const buildStatObject = (
  stateGuid: string,
  editTask?: ViewTask
): $Lns.Task => {
  const dataObj = { ...editTask, taskState: stateGuid || '' };

  const storyInDataObj =
    dataObj && dataObj.story && typeof dataObj.story !== 'string'
      ? dataObj.story.guid
      : undefined;

  const storyGuidInDataObj =
    dataObj && dataObj.story && typeof dataObj.story === 'string'
      ? dataObj.story
      : undefined;

  const statObj = {
    guid: dataObj?.guid || '',
    title: dataObj?.title || '',
    description: dataObj?.description || '',
    story: storyInDataObj || storyGuidInDataObj || '',
    dateDue: dataObj?.dateDue || '',
    assignedTo: dataObj?.assignedTo || '',
    assignedBy: dataObj?.assignedBy || '',
    assignedAt: dataObj?.assignedAt || '',
    priority: dataObj?.priority || 0,
    taskState: dataObj?.taskState || ''
  };

  return statObj;
};
