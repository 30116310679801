import { FunctionComponent } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import { contextMenuStyle } from 'styles/contextMenu';

interface ContextMenus {
  items: {
    title: string;
    action: () => void;
    disable?: boolean;
  }[];
}

const ContextMenu: FunctionComponent<ContextMenus> = ({
  items
}: ContextMenus) => {
  const classes = contextMenuStyle();

  return (
    <Card className={classes.paddingNone}>
      <CardContent className={classes.paddingNone}>
        <MenuList className={classes.root} aria-label="contacts">
          {items.map(item => {
            return (
              <MenuItem disabled={item?.disable}>
                <Typography variant="inherit" onClick={item.action}>
                  {item.title}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </CardContent>
    </Card>
  );
};
export default ContextMenu;
