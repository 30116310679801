import toggleNotification from 'actions/notifications';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { secondsToDuration } from 'utils/time';
import { DropResult } from 'react-beautiful-dnd';
import { Timer, Dns } from '@material-ui/icons';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import {
  fetchRundown,
  fetchRundownStories,
  updateRundown
} from 'pages/rundowns/actions';
import {
  buildRundownStoryObject,
  formattedPlayoutDate,
  getTotalDurationSec
} from 'pages/rundowns/utils';
import StoryPoolTabs from 'pages/story-pools/components/Tabs';
import Stories from 'pages/rundowns/components/Stories';
import i18n from 'i18n';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import ContextMenu from 'components/contextMenu';
import BtnWrapper from 'components/BtnWrapper';
import { generalStyle } from 'pages/rundowns/styles';

interface CSSPositionOffsets {
  top: number;
  left: number;
}

const EditRundown: FunctionComponent = () => {
  const { guid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const generalStyles = generalStyle();

  const { storyPools } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const [rundown, setRundown] = useState<$Lns.Rundown>();
  const [visiable, setVisiable] = useState<boolean>();
  const [positionMenu, setPositionMenu] = useState<CSSPositionOffsets>();
  const [rundownStories, setRundownStories] = useState<$Lns.Story[]>([]);

  useEffect(() => {
    fetchRundownStories(guid)
      .then(({ data }) => {
        const stories = data
          .slice()
          .sort((a, b) => (a.relativeOrder > b.relativeOrder ? 1 : -1))
          .map(ps => ps.story);
        setRundownStories(stories);
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundowns.fetchStoriesError'),
            'error'
          )
        );
      });
    // eslint-disable-next-line
  }, [rundown, guid]);

  useEffect(() => {
    if (!rundown) {
      fetchRundown(guid)
        .then(({ data }) => {
          setRundown(data[0]);

          const newSelectedRundown = data.find(
            (r: $Lns.Rundown) => r.guid === guid
          );

          if (newSelectedRundown) {
            setRundown(newSelectedRundown);
          } else {
            dispatch(
              toggleNotification(
                i18n.t('notifications.rundowns.notFoundError'),
                'error'
              )
            );
            navigate('/');
          }
        })
        .catch(() =>
          dispatch(toggleNotification('Failed to load rundown', 'error'))
        );
    }
  }, [dispatch, rundown, guid, navigate]);

  if (!rundown) return <></>;

  const handleRundownUpdate = (updatedRundown: $Lns.Rundown) => {
    updateRundown(updatedRundown)
      .then(({ code }) => {
        if (code === 201) {
          dispatch(
            toggleNotification(
              i18n.t('notifications.rundowns.updateSuccess'),
              'success'
            )
          );
        }
      })
      .catch(() =>
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundowns.rundownUpdateError'),
            'error'
          )
        )
      );
  };

  const onCancel = () => {
    navigate('/rundowns');
  };

  const onToggleStory = (story: $Lns.Story) => {
    const exists = rundownStories.find(
      currentStory => currentStory.guid === story.guid
    );

    if (exists) {
      setRundownStories(
        rundownStories.filter(currentStory => currentStory.guid !== story.guid)
      );
    } else {
      setRundownStories([...rundownStories, story]);
    }
  };

  const onUpdate = () => {
    const updatedRundown = {
      ...rundown,
      totalDurationSec: getTotalDurationSec(rundownStories),
      stories: rundownStories.map((story, index) => {
        return buildRundownStoryObject(story, index);
      })
    };
    setRundown(updatedRundown as unknown as $Lns.Rundown);
    handleRundownUpdate(updatedRundown as unknown as $Lns.Rundown);
  };

  const onPublish = (live = false, publish = false) => {
    const updatedRundown = {
      ...rundown,
      isPublished: publish,
      playoutDateTime: live ? new Date().toISOString() : rundown.playoutDateTime
    };

    updateRundown(updatedRundown).then(() => {
      dispatch(
        toggleNotification(
          publish
            ? i18n.t('notifications.rundowns.publishSuccess')
            : i18n.t('notifications.rundowns.updateSuccess'),
          'success'
        )
      );
      navigate(`/rundowns/${guid}`);
    });
  };

  const onDragComplete = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorder = (
      list: $Lns.Story[],
      startIndex: number,
      endIndex: number
    ) => {
      const ordered = Array.from(list);
      const [removed] = ordered.splice(startIndex, 1);
      ordered.splice(endIndex, 0, removed);

      return ordered;
    };

    const reorderdStories = reorder(
      rundownStories,
      result.source.index,
      result.destination.index
    );

    const updatedRundown = {
      ...rundown,
      stories: reorderdStories.map((story, index) => {
        return buildRundownStoryObject(story, index);
      })
    };

    setRundownStories(reorderdStories);
    handleRundownUpdate(updatedRundown as unknown as $Lns.Rundown);
  };

  const duration = Object.values(rundownStories).reduce((total, item) => {
    return item.plannedDuration + total;
  }, 0);

  const makeMenuBtns = () => {
    const btns = [
      { title: i18n.t('button.cancel'), action: onCancel },
      { title: i18n.t('button.update'), action: onUpdate }
    ];

    const objPub = {
      title: i18n.t('button.publish'),
      disable: rundown.isPublished || rundownStories.length === 0,
      action: () => onPublish(false, true)
    };
    btns.push(objPub);
    const obj = {
      title: i18n.t('button.publishLive'),
      disable: rundown.isPublished || rundownStories.length === 0,
      action: () => onPublish(true, true)
    };
    btns.push(obj);
    return btns;
  };

  const contextMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (e.type === 'contextmenu') {
      setVisiable(true);
      const left = e.clientX;
      const top = e.clientY;

      const positionMenuObj = {
        top,
        left
      };
      setPositionMenu({ ...positionMenuObj });
    } else {
      setVisiable(false);
    }
  };

  return (
    <Box onClick={contextMenu} onContextMenu={contextMenu}>
      {visiable && (
        <Box
          position="absolute"
          {...positionMenu}
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          <ContextMenu items={makeMenuBtns()} />
        </Box>
      )}
      <Grid container direction="row" spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={rundown.name.toUpperCase()}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<Dns color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <Button color="default" variant="contained" onClick={onCancel}>
                {i18n.t('button.cancel')}
              </Button>
              <Button
                data-test-id="update"
                variant="outlined"
                color="secondary"
                onClick={onUpdate}
              >
                {i18n.t('button.update')}
              </Button>
              {!rundown.isPublished && (
                <Button
                  data-test-id="publish"
                  variant="contained"
                  color="secondary"
                  onClick={() => onPublish(false, true)}
                >
                  {i18n.t('button.publish')}
                </Button>
              )}
              {rundown.isPublished && (
                <Button
                  data-test-id="unpublish"
                  variant="contained"
                  color="secondary"
                  onClick={() => onPublish(false, false)}
                >
                  {i18n.t('button.unPublish')}
                </Button>
              )}
              <Button
                disabled={rundown.isPublished || rundownStories.length === 0}
                variant="contained"
                color="primary"
                onClick={() => onPublish(true, true)}
              >
                {i18n.t('button.publishLive')}
              </Button>
            </BtnWrapper>
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <List aria-label="main mailbox folders">
                <Grid container direction="row">
                  <Grid item xs={4}>
                    <ListItem>
                      <ListItemIcon>
                        <Timer />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ color: 'primary' }}
                        secondary="Playout Date Time"
                        primary={formattedPlayoutDate(rundown)}
                      />
                    </ListItem>
                  </Grid>
                  <Grid item xs={4}>
                    <ListItem>
                      <ListItemIcon>
                        <Timer />
                      </ListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{ color: 'primary' }}
                        secondary="Duration in Seconds"
                        primary={secondsToDuration(rundown.totalDurationSec)}
                      />
                    </ListItem>
                  </Grid>
                </Grid>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <TabContext value="stories">
            <Tabs value="stories" scrollButtons="on" variant="scrollable">
              <Tab
                label={i18n.t('pages.storyPools.helperText.selectedStories')}
                value="stories"
              />
            </Tabs>
            <TabPanel value="stories" className={generalStyles.paddingTopOnly}>
              <Stories
                data-test-id="assigned-stories"
                stories={rundownStories}
                onToggleStory={onToggleStory}
                onDragComplete={onDragComplete}
              />
            </TabPanel>
          </TabContext>
        </Grid>
        <Grid item xs={6}>
          <StoryPoolTabs
            storyPools={storyPools}
            highlightedStories={rundownStories}
            onToggleStory={onToggleStory}
            approvedStories={false}
          />
        </Grid>

        <Grid item xs={12}>
          <Snackbar
            message={
              <Box>
                <Typography component="span">
                  {i18n.t('pages.rundowns.typography.duration')}
                </Typography>
                <Typography component="span">
                  &nbsp;{secondsToDuration(duration)}
                </Typography>
              </Box>
            }
            open
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditRundown;
