import { callLnsApi } from 'utils/fetch';

export const createTask = (
  task: $Lns.Task
): Promise<{ code: number; data: $Lns.Story }> =>
  callLnsApi('/task', 'POST', task);

export const fetchTaskStates = (): Promise<{
  code: number;
  data: $Lns.TaskState[];
}> => callLnsApi('/task-state');

export const updateTask = (
  task: $Lns.Task
): Promise<{ code: number; data: $Lns.Task }> =>
  callLnsApi(`/task/${task.guid}`, 'POST', task);
