import { Box } from '@material-ui/core';
import {
  useRef,
  useEffect,
  useState,
  FunctionComponent,
  memo,
  ReactEventHandler
} from 'react';
import videojs, { VideoJsPlayer } from 'video.js';
import 'video.js/dist/video-js.css';

interface VideoPlayerI {
  id: string;
  height: number;
  src: string;
  width: number;
  type: string;
  fluid?: boolean;
  controls?: boolean;
  onLoadedVideoCb?: ReactEventHandler<HTMLVideoElement> | undefined;
}

const VideoPlayer: FunctionComponent<VideoPlayerI> = ({
  id,
  height,
  src,
  width,
  type,
  controls = false,
  fluid = false,
  onLoadedVideoCb
}: VideoPlayerI) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [player, setPlayer] = useState<VideoJsPlayer>();
  let className = 'video-js vjs-big-play-centered vjs-default-skin';
  if (fluid) className += 'vjs-16-9';

  useEffect(() => {
    if (!player && videoRef.current) {
      setPlayer(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        videojs(videoRef.current, {
          fluid,
          controls,
          controlBar: {
            liveDisplay: true,
            pictureInPictureToggle: false
          },
          html5: {
            hls: {
              // required for playback on android mobile chrome
              overrideNative: !videojs.browser.IS_SAFARI,
              // required to send signed cookies
              withCredentials: false
            }
          }
        })
      );
    }
  }, [player, fluid, controls]);

  useEffect(() => {
    if (player) {
      player.src({ src });
    }
  });

  return (
    <Box width={width} height={height} data-vjs-player>
      <video
        ref={videoRef}
        id={id}
        className={className}
        controls
        onLoadedData={onLoadedVideoCb}
        preload="auto"
      >
        <track kind="captions" />
        <source src={src} type={type} />
      </video>
    </Box>
  );
};

export default memo(VideoPlayer);

VideoPlayer.defaultProps = {
  fluid: true,
  controls: false,
  onLoadedVideoCb: undefined
};
