import { Button, CircularProgress } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import loadingButtonStyles from 'styles/loadingButton';

interface LoadingButtonI {
  isLoading: boolean;
  label: string;
  // eslint-disable-next-line
  [x: string]: any;
}

const LoadingButton: FunctionComponent<LoadingButtonI> = ({
  isLoading,
  label,
  ...buttonProps
}: LoadingButtonI) => {
  const classes = loadingButtonStyles();
  return (
    <Button {...buttonProps} disabled={isLoading || buttonProps.disabled}>
      {label}
      {isLoading && <CircularProgress size={24} className={classes.root} />}
    </Button>
  );
};

export default LoadingButton;
