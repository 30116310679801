import i18n from 'i18n';
import validator from 'validator';
import { FormData } from './formData';

export const validateForm = (name: string, value: string): string => {
  let error = '';

  error = validator.isEmpty(value) ? i18n.t('formErrors.isEmpty') : '';

  if (name === 'ip') {
    error = !validator.isIP(value) ? i18n.t('formErrors.isIP') : '';
  }

  if (name.toLowerCase().includes('port')) {
    error = !validator.isNumeric(value) ? i18n.t('formErrors.isNumeric') : '';
  }

  if (name.toLowerCase().includes('host')) {
    error = !validator.isURL(value, {
      require_tld: false,
      require_valid_protocol: true
    })
      ? i18n.t('formErrors.isURL')
      : '';
  }

  return error;
};

export const btnDisabled = (formData: FormData): boolean => {
  if (Object.values(formData.config.errors).some(e => !!e)) return true;
  return false;
};
