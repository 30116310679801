import { callLnsApi } from 'utils/fetch';
import { StoryFormData } from 'pages/stories/interface';

interface createAssetI {
  story: string;
  assets: $Lns.CreateAsset[];
}

export const attachAsset = (
  Task: $Lns.AttachAsset
): Promise<{ code: number; data: $Lns.AttachAsset }> =>
  callLnsApi('/story-attachment', 'POST', Task);

export const createStoryAsset = (
  input: createAssetI
): Promise<{ code: number; data: $Lns.CreateStoryAsset[] }> =>
  callLnsApi(`/story-asset/${input.story}`, 'POST', input);

export const fetchStories = (): Promise<{
  code: number;
  data: $Lns.Story[];
}> => callLnsApi('/story');

export const fetchStory = (
  guid: string
): Promise<{
  code: number;
  data: $Lns.Story;
}> => callLnsApi(`/story/${guid}`);

export const fetchStoryHistory = (
  guid: string
): Promise<{
  code: number;
  data: $Lns.AuditLog[];
}> => callLnsApi(`/story/${guid}/audit-logs`);

export const fetchStoryByGuid = (
  guid: string
): Promise<{ code: number; data: $Lns.Story }> => callLnsApi(`/story/${guid}`);

export const createStory = (
  story: StoryFormData
): Promise<{ code: number; data: $Lns.Story }> =>
  callLnsApi('/story', 'POST', story);

export const updateStory = (
  story: StoryFormData
): Promise<{ code: number; data: $Lns.Story }> =>
  callLnsApi(`/story/${story.guid}`, 'POST', story);

export const deleteStory = (
  guid: string
): Promise<{ code: number; data: $Lns.Story; message?: string }> =>
  callLnsApi(`/story/${guid}`, 'DELETE');

export default fetchStories;
