import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  FormControl,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import {
  validateForm,
  passwordResetQuestions,
  textFieldMap,
  validatePasswordConfirm
} from 'pages/signup/utils';
import { SignUpFormData } from 'pages/signup/interface';
import LoadingButton from 'components/LoadingButton';
import i18n from 'i18n';

interface SignupFormI {
  onSave: (formData: SignUpFormData) => void;
  onCancel: () => void;
  isLoading: boolean;
  errors: { [key: string]: string };
}

const SignupForm: FunctionComponent<SignupFormI> = ({
  onSave,
  onCancel,
  isLoading,
  errors
}: SignupFormI) => {
  const questions = passwordResetQuestions();

  const [formData, setFormData] = useState<{
    email: string;
    password: string;
    passwordResetQuestion: string;
    passwordResetAnswer: string;
    passwordConfirm: string;
    contactNumber: string;
    username: string;
    errors: { [key: string]: string };
  }>({
    email: '',
    username: '',
    password: '',
    passwordResetQuestion: questions[0].value,
    passwordResetAnswer: '',
    passwordConfirm: '',
    contactNumber: '',
    errors: {}
  });

  useEffect(() => {
    setFormData({
      ...formData,
      errors
    });

    // eslint-disable-next-line
  }, [errors]);

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData({
      ...formData,
      errors: {
        ...formData.errors,
        [name]: validateForm(name, value),
        passwordConfirm: validatePasswordConfirm(formData, name, value)
      },
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  const onChangeResetPasswordQuestion = (
    e: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { value, name } = e.target;
    if (!name) return;

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const btnDisabled =
    Object.values(formData.errors).some(error => !!error) ||
    !formData.email ||
    !formData.contactNumber ||
    !formData.password ||
    !formData.passwordResetAnswer;

  return (
    <Card raised elevation={8}>
      <Box padding={3} onKeyPress={() => {}}>
        {textFieldMap().map((field: { [key: string]: string }) => {
          return (
            <TextField
              key={field.value}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type={field.type}
              id={field.value}
              label={field.label}
              name={field.value}
              onChange={onChangeField}
              error={!!formData?.errors[field.value]}
              helperText={
                formData.errors[field.value]
                  ? formData.errors[field.value]
                  : field.helperText
              }
              autoFocus
            />
          );
        })}

        <FormControl margin="normal" variant="outlined" fullWidth>
          <Select
            labelId="passwordResetQuestion"
            id="passwordResetQuestion"
            name="passwordResetQuestion"
            placeholder="Type"
            value={formData.passwordResetQuestion}
            onChange={onChangeResetPasswordQuestion}
          >
            {questions.map(question => {
              return (
                <MenuItem key={question.value} value={question.value}>
                  {question.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="text"
          value={formData.passwordResetAnswer}
          id="passwordResetAnswer"
          label={i18n.t('pages.signup.formLabel.passwordResetAnswer')}
          helperText={formData.errors.passwordResetAnswer}
          name="passwordResetAnswer"
          onChange={onChangeField}
          error={!!formData?.errors.passwordResetAnswer}
        />

        <Box display="grid" gridAutoFlow="column" gridGap={10}>
          <Button
            style={{ maxWidth: 'min-content', justifySelf: 'start' }}
            fullWidth
            variant="contained"
            color="default"
            onClick={onCancel}
          >
            {i18n.t('button.cancel')}
          </Button>
          <LoadingButton
            style={{ maxWidth: 'min-content', justifySelf: 'end' }}
            label={i18n.t('button.signup')}
            isLoading={isLoading}
            type="submit"
            fullWidth
            disabled={btnDisabled}
            variant="contained"
            color="secondary"
            onClick={() => onSave(formData)}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default SignupForm;
