import { useState, FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import { verifyEmail } from 'pages/password-reset/actions';
import { useDispatch } from 'react-redux';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';
import EmailForm from 'pages/password-reset/components/EmailForm';
import Wrapper from 'pages/password-reset/components/Wrapper';

const PasswordReset: FunctionComponent = () => {
  const [isLoading, toggleLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSave = (email: string) => {
    toggleLoading(true);
    verifyEmail(email)
      .then(() => {
        toggleLoading(false);
        navigate('/password-reset/success');
      })
      .catch(() => {
        toggleLoading(false);
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      });
  };

  const onCancel = () => {
    navigate('/signin');
  };

  return (
    <Wrapper>
      <EmailForm onSave={onSave} isLoading={isLoading} onCancel={onCancel} />
    </Wrapper>
  );
};

export default PasswordReset;
