import { ChangeEvent, FunctionComponent, useState } from 'react';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import i18n from 'i18n';
import { validateForm } from 'pages/administration/newswires/utils';
import FormData from 'pages/administration/security/users/userFormData';

interface FormI {
  user?: $Lns.User;
  onSave: (formData: FormData) => void;
  onCancel: () => void;
}

const Form: FunctionComponent<FormI> = ({ user, onSave, onCancel }: FormI) => {
  const [formData, setFormData] = useState<FormData>({
    guid: user?.guid || '',
    username: user?.username || '',
    email: user?.email || '',
    title: user?.title || '',
    team: user?.team || '',
    contactNumber: user?.contactNumber || '',
    userState: user?.userState || '',
    config: user?.config || {
      location: '',
      timezone: '',
      language: 'en',
      layoutSettings: {
        font: {
          fontFamily: '',
          fontSize: 1,
          fontWeight: 400
        }
      }
    },
    errors: {
      username: '',
      email: '',
      title: '',
      team: '',
      contactNumber: ''
    }
  });

  const btnDisabled =
    Object.values(formData.errors).some(e => !!e) ||
    !formData.username ||
    !formData.email;

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedFormData = {
      ...formData,
      [name]: value,
      errors: {
        ...formData.errors,
        [name]: validateForm(name, value)
      }
    };

    setFormData(updatedFormData);
  };

  const handleOnSave = () => {
    onSave(formData);
  };

  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
            id="username"
            label={i18n.t('pages.administration.users.formLabel.username')}
            value={formData.username}
            helperText={i18n.t(
              'pages.administration.users.formHelperText.usernameHelper'
            )}
            name="username"
            onChange={onChangeField}
            error={!!formData?.errors?.username}
            autoComplete="username"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="url"
            label={i18n.t('pages.administration.users.formLabel.email')}
            value={formData.email}
            helperText={formData.errors.email || 'Email'}
            name="email"
            onChange={onChangeField}
            error={!!formData?.errors?.email}
            autoComplete="email"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="title"
            label={i18n.t('pages.administration.users.formLabel.title')}
            value={formData.title}
            helperText={formData.errors.title || 'Title'}
            name="title"
            onChange={onChangeField}
            error={!!formData?.errors?.title}
            autoComplete="title"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="tea "
            label={i18n.t('pages.administration.users.formLabel.team')}
            value={formData.team}
            helperText={formData.errors.team || 'Team'}
            name="team"
            onChange={onChangeField}
            error={!!formData?.errors?.team}
            autoComplete="team"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="contact-number"
            label={i18n.t('pages.administration.users.formLabel.contactNumber')}
            value={formData.contactNumber}
            helperText={formData.errors.contactNumber || 'Contact Number'}
            name="contactNumber"
            onChange={onChangeField}
            error={!!formData?.errors?.contactNumber}
            autoComplete="contactNumber"
          />
        </Grid>

        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={btnDisabled}
              color="secondary"
              variant="contained"
              onClick={handleOnSave}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;

Form.defaultProps = {
  user: undefined
};
