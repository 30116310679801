import { callLnsApi } from 'utils/fetch';

export const createLocation = (
  location: $Lns.Location
): Promise<{
  code: number;
  data: $Lns.Location;
}> => {
  return callLnsApi('/location/', 'POST', location);
};

export const fetchLocations = (): Promise<{
  code: number;
  data: $Lns.Location[];
}> => callLnsApi('/location');

export const updateLocation = (
  location: $Lns.Location
): Promise<{
  code: number;
  data: $Lns.Location;
}> => callLnsApi(`/location/${location.guid}`, 'POST', location);

export const deleteLocation = (
  location: $Lns.Location
): Promise<{
  code: number;
}> => callLnsApi(`/location/${location.guid}`, 'DELETE');
