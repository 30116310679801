import { Box, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';
import 'video.js/dist/video-js.css';
import VideoPlayer from './VideoPlayer';

interface MediaItemI {
  mediaItem: $Lns.MediaItem;
  height: number;
  width: number;
  controls?: boolean;
}

const MediaItem: FunctionComponent<MediaItemI> = ({
  mediaItem,
  height,
  width,
  controls = false
}: MediaItemI) => {
  const type =
    mediaItem.type === 'stream' ? 'application/vnd.apple.mpegurl' : 'video/mp4';

  return (
    <>
      <Box height={height} width={width}>
        {mediaItem.type !== 'image' && (
          <VideoPlayer
            type={type}
            controls={controls}
            id={mediaItem.guid}
            src={mediaItem.sourceUrl}
            width={width}
            height={height}
          />
        )}
        {mediaItem.type === 'image' && (
          <img
            src={mediaItem.sourceUrl}
            alt={mediaItem.name}
            width={width}
            height={height}
          />
        )}
      </Box>
      <Box display="grid">
        <Typography variant="caption" noWrap>
          {mediaItem.name}
        </Typography>
      </Box>
    </>
  );
};

export default MediaItem;

MediaItem.defaultProps = {
  controls: false
};
