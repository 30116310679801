import { callLnsApi } from 'utils/fetch';
import { FormData as AssignmentFormData } from 'pages/assignments/formData';

export const fetchAssignments = (): Promise<{
  code: number;
  data: $Lns.Task[];
}> => callLnsApi('/task');

export const createAssignment = (
  assignmentItem: AssignmentFormData
): Promise<{
  code: number;
  data: $Lns.Task;
}> => callLnsApi('/task', 'POST', assignmentItem);

export const updateAssignment = (
  assignmentItem: AssignmentFormData
): Promise<{
  code: number;
  data: $Lns.Task;
}> => callLnsApi(`/task/${assignmentItem.guid}`, 'POST', assignmentItem);

export const deleteAssignment = (
  assignmentGuid: string
): Promise<{ code: number; message?: string }> =>
  callLnsApi(`/task/${assignmentGuid}`, 'DELETE');

export const fetchStories = (): Promise<{
  code: number;
  data: $Lns.Story[];
}> => callLnsApi('/story');

export const fetchStoryStates = (): Promise<{
  code: number;
  data: $Lns.StoryState[];
}> => callLnsApi('/story-state');

export const fetchStoryByGuid = (
  storyGuid: string
): Promise<{
  code: number;
  data: $Lns.Story;
}> => callLnsApi(`/story/${storyGuid}`);
