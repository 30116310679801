import i18n from 'i18n';
import validator from 'validator';

export const validateForm = (name: string, value: string): string => {
  let error = '';

  switch (name) {
    case 'city': {
      error = validator.isEmpty(value) ? i18n.t('formErrors.isEmpty') : '';
      break;
    }
    case 'country': {
      error = validator.isEmpty(value) ? i18n.t('formErrors.isEmpty') : '';
      break;
    }
    default: {
      break;
    }
  }
  return error;
};
