import { FunctionComponent, ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import i18n from 'i18n';
import Loading from 'components/Loading';
import EmptyState from 'components/EmptyState';
import { fetchPeripheralTemplates } from 'pages/administration/peripheral-settings/actions';
import { findTemplate } from 'pages/administration/peripheral-settings/utils';

interface TemplatePickerI {
  onSave: (template: $Lns.PeripheralTemplate) => void;
  onCancel: () => void;
}

const TemplatePicker: FunctionComponent<TemplatePickerI> = ({
  onSave,
  onCancel
}: TemplatePickerI) => {
  const [templates, setTemplates] = useState<$Lns.PeripheralTemplate[]>();
  const [template, setTemplate] = useState<$Lns.PeripheralTemplate>();

  useEffect(() => {
    fetchPeripheralTemplates().then(({ data }) => {
      if (data.length && data.length > 0) setTemplate(data[0]);
      setTemplates(data);
    });
  }, []);

  const onSelect = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const { value } = e.target;
    if (templates) setTemplate(findTemplate(value as string, templates));
  };

  const onSaveHandler = () => {
    if (template) onSave(template);
    else onCancel();
  };

  if (templates && templates.length > 0 && template) {
    return (
      <Box padding={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined" size="small" fullWidth required>
              <InputLabel>
                {i18n.t(
                  'pages.administration.peripherals.formLabel.addWithTemplate'
                )}
              </InputLabel>
              <Select
                id="selectTemplate"
                labelId="selectTemplate"
                name="template"
                placeholder="Template"
                value={template.guid}
                onChange={onSelect}
                variant="outlined"
                displayEmpty
                required
                label={i18n.t(
                  'pages.administration.peripherals.formLabel.addWithTemplate'
                )}
              >
                {templates.map(templateItem => {
                  return (
                    <MenuItem key={templateItem.guid} value={templateItem.guid}>
                      {templateItem.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>
                {i18n.t(
                  'pages.administration.peripherals.formHelperText.addWithTemplate'
                )}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} container spacing={2} justify="flex-end">
            <Grid item>
              <Button color="secondary" variant="outlined" onClick={onCancel}>
                {i18n.t('button.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={onSaveHandler}
              >
                {i18n.t('button.next')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
  if (templates && templates.length === 0) {
    return (
      <Box padding={4}>
        <EmptyState
          title={i18n.t('pages.administration.peripherals.emptyTemplate.title')}
          subTitle={i18n.t(
            'pages.administration.peripherals.emptyTemplate.subTitle'
          )}
        />
      </Box>
    );
  }
  return (
    <Box padding={4} minWidth={500}>
      <Loading />
    </Box>
  );
};

export default TemplatePicker;
