import { ChangeEvent, FunctionComponent, useState, useEffect } from 'react';
import i18n from 'i18n';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from '@material-ui/core';
import { AccountCircle, Email, Group, Info, Phone } from '@material-ui/icons';
import FormData from 'pages/administration/security/users/userFormData';
import { validateForm } from 'pages/user/utils';
import useStyles from 'pages/user/components/styles';

interface EditProfileI {
  user: $Lns.User;
  canEdit: boolean;
  onSaveCallback?: (formData: FormData) => void;
}

const EditProfile: FunctionComponent<EditProfileI> = ({
  user,
  canEdit,
  onSaveCallback
}: EditProfileI) => {
  const classes = useStyles();
  const noDataString = i18n.t('pages.users.formHelperText.noData');
  const [isEdit, setIsEdit] = useState(false);

  const [formData, setFormData] = useState<FormData>({
    guid: user.guid || '',
    username: user.username || '',
    email: user.email || '',
    title: user.title || '',
    team: user.team || '',
    contactNumber: user.contactNumber || '',
    userState: user.userState || '',
    config: user.config,
    errors: {
      username: '',
      email: '',
      title: '',
      team: '',
      contactNumber: ''
    }
  });

  useEffect(() => {
    const updatedFormData = {
      guid: user.guid || '',
      username: user.username || '',
      email: user.email || '',
      title: user.title || '',
      team: user.team || '',
      contactNumber: user.contactNumber || '',
      userState: user.userState || '',
      config: user.config,
      errors: {
        username: '',
        email: '',
        title: '',
        team: '',
        contactNumber: ''
      }
    };

    setFormData(updatedFormData);
  }, [user]);

  const toggleIsEdit = () => setIsEdit(!isEdit);

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedFormData = {
      ...formData,
      config: { ...user.config },
      [name]: value,
      errors: {
        ...formData.errors,
        [name]: validateForm(name, value)
      }
    };

    setFormData(updatedFormData);
  };

  const onSave = () => {
    if (onSaveCallback) onSaveCallback(formData);
    toggleIsEdit();
  };

  return (
    <>
      <Box className={classes.titleWrapperBox}>
        <Typography variant="h6" className={classes.grayPaperTitle}>
          {i18n.t('pages.users.formLabel.basicInfo')}
        </Typography>
        {canEdit && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={!isEdit ? toggleIsEdit : onSave}
            >
              {!isEdit && i18n.t('button.edit')}
              {isEdit && i18n.t('button.save')}
            </Button>
            {isEdit && (
              <Box paddingLeft={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={toggleIsEdit}
                >
                  {i18n.t('button.cancel')}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {!isEdit && (
        <List>
          <ListItem>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText
              primary={user.username || noDataString}
              secondary={i18n.t('pages.users.formLabel.username')}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Email />
            </ListItemIcon>
            <ListItemText
              primary={user.email || noDataString}
              secondary={i18n.t('pages.users.formLabel.email')}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText
              primary={user.title || noDataString}
              secondary={i18n.t('pages.users.formLabel.title')}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Group />
            </ListItemIcon>
            <ListItemText
              primary={user.team || noDataString}
              secondary={i18n.t('pages.users.formLabel.team')}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Phone />
            </ListItemIcon>
            <ListItemText
              primary={user.contactNumber || noDataString}
              secondary={i18n.t('pages.users.formLabel.contactNumber')}
            />
          </ListItem>
        </List>
      )}
      {isEdit && (
        <Box padding={2}>
          <TextField
            disabled
            variant="outlined"
            margin="normal"
            fullWidth
            id="username"
            label={i18n.t('pages.administration.users.formLabel.username')}
            value={formData.username}
            helperText={formData.errors.username || ''}
            name="username"
            onChange={onChangeField}
            error={!!formData?.errors?.username}
            autoComplete="username"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="url"
            label={i18n.t('pages.administration.users.formLabel.email')}
            value={formData.email}
            helperText={formData.errors.email || ''}
            name="email"
            onChange={onChangeField}
            error={!!formData?.errors?.email}
            autoComplete="email"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="title"
            label={i18n.t('pages.administration.users.formLabel.title')}
            value={formData.title}
            helperText={formData.errors.title || ''}
            name="title"
            onChange={onChangeField}
            error={!!formData?.errors?.title}
            autoComplete="title"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="team"
            label={i18n.t('pages.administration.users.formLabel.team')}
            value={formData.team}
            helperText={formData.errors.team || ''}
            name="team"
            onChange={onChangeField}
            error={!!formData?.errors?.team}
            autoComplete="team"
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="contact-number"
            label={i18n.t('pages.administration.users.formLabel.contactNumber')}
            value={formData.contactNumber}
            helperText={formData.errors.contactNumber || ''}
            name="contactNumber"
            onChange={onChangeField}
            error={!!formData?.errors?.contactNumber}
            autoComplete="contactNumber"
          />
        </Box>
      )}
    </>
  );
};

export default EditProfile;

EditProfile.defaultProps = {
  onSaveCallback: undefined
};
