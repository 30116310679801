import { FunctionComponent, useEffect, useState } from 'react';
import { Button, Grid, Dialog, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { fetchPeripheralsFromLS } from 'pages/administration/peripheral-settings/peripherals/actions';
import { DropResult } from 'react-beautiful-dnd';
import {
  buildRundownStoryObject,
  isCurrentlyActive,
  isOverTime
} from 'pages/rundowns/utils';
import {
  fetchRundown,
  fetchRundownStories,
  updateRundown
} from 'pages/rundowns/actions';
import { setRundownStories } from 'actions/default';
import toggleNotification from 'actions/notifications';
import PlayoutStories from 'pages/rundowns/components/PlayoutStories';
import RundownTimer from 'pages/rundowns/components/Timer';
import SparePlayoutStories from 'pages/rundowns/components/SparePlayoutStories';
import i18n from 'i18n';
import StoryForm from 'pages/stories/components/Form';
import { StoryFormData } from 'pages/stories/interface';
import { createStory } from 'pages/stories/actions';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import BtnWrapper from 'components/BtnWrapper';
import { Dns } from '@material-ui/icons';

const ShowRundown: FunctionComponent = () => {
  const { guid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editStory, setEditStory] = useState<$Lns.Story>();
  const [peripherals, setPeripherals] = useState<$Lns.Peripheral[]>([]);
  const [rundown, setRundown] = useState<$Lns.Rundown>();

  const {
    rundownStories: { [guid]: playoutStories }
  } = useSelector((state: $Lns.DefaultState) => state.default);

  const spareStories = playoutStories?.filter(ps => ps.isSpare);
  const activeStories = playoutStories?.filter(ps => !ps.isSpare);

  useEffect(() => {
    fetchPeripheralsFromLS().then(({ data }) => {
      setPeripherals(data.slice(0, 3));
    });
  }, []);

  useEffect(() => {
    fetchRundownStories(guid)
      .then(({ data }) => {
        const stories = data
          .slice()
          .sort((a, b) => (a.relativeOrder > b.relativeOrder ? 1 : -1));
        dispatch(setRundownStories({ [guid]: stories }));
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundowns.fetchStoriesError'),
            'error'
          )
        );
      });
    // eslint-disable-next-line
  }, [guid]);

  useEffect(() => {
    if (!rundown) {
      fetchRundown(guid).then(({ data }) => {
        setRundown(data[0]);

        const newSelectedRundown = data.find(
          (r: $Lns.Rundown) => r.guid === guid
        );

        if (newSelectedRundown) {
          setRundown(newSelectedRundown);
        } else {
          dispatch(
            toggleNotification(
              i18n.t('notifications.rundowns.notFoundError'),
              'error'
            )
          );
          navigate('/');
        }
      });
    }
  }, [dispatch, rundown, guid, navigate]);

  const onPublish = (status: boolean) => {
    if (!rundown) return;

    const updatedRundown = {
      ...rundown,
      isPublished: status
    };

    handleRundownUpdate(updatedRundown);
    setRundown(updatedRundown);
  };

  const handleRundownUpdate = (updatedRundown: $Lns.Rundown) => {
    updateRundown(updatedRundown).then(({ code }) => {
      if (code === 201) {
        dispatch(
          toggleNotification(
            i18n.t('notifications.rundowns.updateSuccess'),
            'success'
          )
        );
      }
    });
  };

  const onDragComplete = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination || !rundown || !playoutStories) {
      return;
    }

    // get current story at drag result index
    const stories = playoutStories.map(ps => ps.story);
    const currentStory = stories[result.destination.index];
    const isActive = isCurrentlyActive(currentStory, stories, rundown);
    const isFinished = isOverTime(currentStory, stories, rundown);
    if (isActive || isFinished) {
      dispatch(
        toggleNotification(
          i18n.t('notifications.rundowns.invalidOrder'),
          'warning'
        )
      );
      return;
    }

    const reorder = (
      list: $Lns.PlayoutStory[],
      startIndex: number,
      endIndex: number
    ) => {
      const ordered = Array.from(list);
      const [removed] = ordered.splice(startIndex, 1);
      ordered.splice(endIndex, 0, removed);

      const updated = ordered.map(ps => {
        return {
          ...ps,
          relativeOrder: ordered.indexOf(ps),
          story: {
            ...ps.story
          }
        };
      });

      return updated;
    };

    const reorderdStories = reorder(
      playoutStories,
      result.source.index,
      result.destination.index
    );

    const updatedRundown = {
      ...rundown,
      stories: reorderdStories.map(story => {
        return buildRundownStoryObject(story.story, story.relativeOrder);
      })
    };

    handleRundownUpdate(updatedRundown as unknown as $Lns.Rundown);

    dispatch(setRundownStories({ [guid]: reorderdStories }));
  };

  const onSpareClick = (playoutStory: $Lns.PlayoutStory) => {
    const existingSpare = spareStories?.find(
      ss => ss.guid === playoutStory.guid
    );

    if (existingSpare) {
      onToggleSpare(existingSpare, false);
    } else {
      onToggleSpare(playoutStory, true);
    }
  };

  const onToggleSpare = (playoutStory: $Lns.PlayoutStory, isSpare: boolean) => {
    if (!playoutStories || !rundown) return;

    const updatedPlayoutStories = playoutStories.map(ps => {
      if (ps.guid === playoutStory.guid) {
        return {
          ...ps,
          isSpare
        };
      }
      return ps;
    });

    dispatch(setRundownStories({ [guid]: updatedPlayoutStories }));
    const updatedRundown = {
      ...rundown,
      stories: updatedPlayoutStories.map(ps => {
        const currentPlayoutStory = buildRundownStoryObject(
          ps.story,
          ps.relativeOrder
        );

        return {
          ...currentPlayoutStory,
          isSpare: ps.isSpare
        };
      })
    };

    handleRundownUpdate(updatedRundown as unknown as $Lns.Rundown);
  };

  const onAddStory = () => {
    setEditStory({
      title: '',
      text: '',
      notes: '',
      plannedDuration: 0,
      slug: '',
      type: '',
      state: 'draft'
    } as $Lns.Story);
  };

  const onCancel = () => {
    setEditStory(undefined);
  };

  const onSave = async (formData: StoryFormData) => {
    // Create new story
    const { data: createdStory } = await createStory(formData).catch(() => {
      dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      return { data: null };
    });

    if (createdStory) {
      navigate(`/my-lns/${createdStory.guid}/edit/${rundown?.guid}`);
      dispatch(
        toggleNotification(
          i18n.t('notifications.stories.createSuccess'),
          'success'
        )
      );
    }

    setEditStory(undefined);
  };

  const toManageStories = () => {
    navigate(`/rundowns/${guid}/stories`);
  };

  if (!peripherals || !rundown) return <></>;

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={rundown.name.toUpperCase()}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<Dns color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              {!rundown.isPublished && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onPublish(true)}
                  data-test-id="publish"
                >
                  {i18n.t('pages.rundowns.button.publish')}
                </Button>
              )}
              {rundown.isPublished && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onPublish(false)}
                  data-test-id="unpublish"
                >
                  {i18n.t('button.unPublish')}
                </Button>
              )}
              <Button color="default" variant="contained" onClick={onAddStory}>
                {i18n.t('pages.stories.dialog.title')}
              </Button>
              <Button
                color="default"
                variant="contained"
                onClick={toManageStories}
              >
                {i18n.t('pages.rundowns.tooltip.stories')}
              </Button>
            </BtnWrapper>
          </Grid>
        </TitleBarWraper>
        <Grid item container spacing={4}>
          <Grid item xs={12}>
            <PlayoutStories
              rundown={rundown}
              peripherals={peripherals}
              playoutStories={activeStories || []}
              onSpareClick={onSpareClick}
              onDragComplete={onDragComplete}
            />
          </Grid>
          {spareStories && spareStories.length > 0 && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" color="primary">
                    {i18n.t('pages.rundowns.typography.spareTitle')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SparePlayoutStories
                    peripherals={peripherals}
                    playoutStories={spareStories}
                    onSpareClick={onSpareClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {rundown.isPublished && (
        <RundownTimer
          startTime={rundown.playoutDateTime}
          stories={activeStories || []}
        />
      )}
      {editStory && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={onCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <StoryForm onSave={onSave} onCancel={onCancel} />
        </Dialog>
      )}
    </>
  );
};

export default ShowRundown;
