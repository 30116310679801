import { Box, CircularProgress } from '@material-ui/core';
import { FunctionComponent } from 'react';

interface LoadingI {
  width?: string;
  height?: string;
}

const Loading: FunctionComponent<LoadingI> = ({ width, height }: LoadingI) => {
  return (
    <Box
      display="flex"
      width={width}
      height={height}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="secondary" />
    </Box>
  );
};

export default Loading;

Loading.defaultProps = {
  width: '100%',
  height: '50vh'
};
