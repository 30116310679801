import { Box, Button, Typography } from '@material-ui/core';
import { SentimentDissatisfied } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';
import colors from 'styles/colors';

interface EmptyStateI {
  title: string;
  subTitle: string;
  onBtnClick?: () => void;
  btnLabel?: string;
  compact?: boolean;
}

const EmptyState: FunctionComponent<EmptyStateI> = ({
  title,
  subTitle,
  onBtnClick,
  btnLabel,
  compact = false
}: EmptyStateI) => {
  const padding = compact ? 2 : 5;
  const titleTypographyVariant = compact ? 'body2' : 'subtitle1';
  const buttonVariant = compact ? 'text' : 'outlined';

  return (
    <Box display="grid" justifyItems="center" alignItems="center" gridGap={16}>
      <Box
        bgcolor={colors.backgrounds.primary}
        padding={padding}
        borderRadius={100}
      >
        <SentimentDissatisfied color="primary" />
      </Box>
      <Typography variant={titleTypographyVariant} color="primary">
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {subTitle}
      </Typography>
      {onBtnClick && (
        <Button variant={buttonVariant} color="primary">
          {btnLabel}
        </Button>
      )}
    </Box>
  );
};

export default EmptyState;

EmptyState.defaultProps = {
  onBtnClick: undefined,
  btnLabel: undefined,
  compact: false
};
