import { ChangeEvent, FunctionComponent, useState } from 'react';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import i18n from 'i18n';
import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';
import Asset from 'pages/stories/components/Asset';

interface EditAssetForm1 {
  initVal: AssetDynamic;
  onEdit: (formData: AssetDynamic, initVal: AssetDynamic) => void;
  onCancel: () => void;
}

const EditAssetForm: FunctionComponent<EditAssetForm1> = ({
  initVal,
  onEdit,
  onCancel
}: EditAssetForm1) => {
  const [formData, setFormData] = useState<AssetDynamic>(initVal);

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (formData.assetI && ['title'].includes(name)) {
      setFormData({
        ...formData,
        assetI: {
          ...formData.assetI,
          [name]: value
        }
      });
    }
  };

  const updateAssetDuration = (
    totalDuration: number,
    trackPoints: number[]
  ) => {
    if (formData.assetI) {
      setFormData({
        ...formData,
        assetI: {
          ...formData.assetI,
          totalDurationSec: totalDuration,
          durationInSeconds: totalDuration.toString(),
          clipStartSec: trackPoints[0],
          clipStopSec: trackPoints[1]
        }
      });
    }
  };

  return (
    <Box padding={4}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            disabled
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="position"
            name="position"
            label="position"
            value={formData.assetI?.positionInScript}
            onChange={onChangeField}
            autoComplete="type"
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="title"
            name="title"
            label="title"
            value={formData.assetI?.title}
            onChange={onChangeField}
            autoComplete="title"
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          {formData.assetI && (
            <Asset asset={formData.assetI} handleChange={updateAssetDuration} />
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            disabled
            id="url"
            name="url"
            label="url"
            value={formData.assetI?.url}
            onChange={onChangeField}
            autoComplete="url"
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="type"
            name="type"
            label="type"
            value={formData.assetI?.type}
            onChange={onChangeField}
            autoComplete="type"
            autoFocus
          />
        </Grid>
        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => onEdit(formData, initVal)}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditAssetForm;
