export enum AssetType {
  image = 'Image',
  audio = 'Audio',
  video = 'Video',
  dialogue = 'dialogue',
  default = 'Default'
}

export enum StoryState {
  locked = 'locked',
  archived = 'archived',
  approved = 'approved'
}
