import React, { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { CheckCircle, Warning } from '@material-ui/icons';
import notificationStyles from 'styles/notification';

const Notification: React.FunctionComponent = () => {
  const classes = notificationStyles();

  const notification = useSelector(
    (state: $Lns.NotificationState) => state.notifications.notification
  );

  const [open, toggleOpen] = useState(!!notification);
  const onClose = () => toggleOpen(!open);

  useEffect(() => {
    toggleOpen(!!notification);
  }, [notification]);

  if (!notification) return <></>;

  const icon = notification.type === 'success' ? <CheckCircle /> : <Warning />;

  return (
    <Snackbar
      data-test-id="notification"
      className={classes.root}
      open={open}
      onClose={onClose}
      onClick={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        icon={icon}
        variant="filled"
        severity={notification.type as 'success' | 'warning' | 'error' | 'info'}
        className={classes.alertRoot}
        onClose={onClose}
      >
        {notification.text}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
