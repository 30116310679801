import { callLnsApi } from 'utils/fetch';
import { MULTIPART_FORM_DATA } from 'constants/apiConstants';

export const getPeripheralAssets = (
  guid: string
): Promise<{
  code: number;
  data: $Lns.PeripheralAsset[];
}> => callLnsApi(`/media/peripherals/${guid}/assets`);

export const getPeripheralAsset = (
  prefGuid: string,
  assetGuid: string
): Promise<{
  code: number;
  data: $Lns.ObjectKey;
}> => callLnsApi(`/media/peripherals/${prefGuid}/asset/${assetGuid}`);

export const searchPeripheralAssets = (
  guid: string,
  query: string,
  type: string
): Promise<{
  code: number;
  data: $Lns.PeripheralAsset[];
}> =>
  callLnsApi(`/media/peripherals/${guid}/search?s=${query}&l=10&p=0&t=${type}`);

export const fetchPeripherals = (): Promise<{
  code: number;
  data: $Lns.Peripheral[];
}> => callLnsApi('/peripheral');

export const uploadMedia = (
  data: FormData,
  peripheralsGuid: string
): Promise<{ code: number; data: $Lns.ObjectKey }> =>
  callLnsApi(
    `/media/peripherals/${peripheralsGuid}/upload`,
    'POST',
    data,
    MULTIPART_FORM_DATA
  );

export const fetchPeripheralTypes = (): Promise<{
  code: number;
  data: $Lns.PeripheralType[];
}> => callLnsApi('/peripheral-type');
