import { Box } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';

interface TabPanelI {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel: FunctionComponent<TabPanelI> = (props: TabPanelI) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanel;

TabPanel.defaultProps = {
  children: undefined
};
