import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField
} from '@material-ui/core';
import { QueryBuilder } from '@material-ui/icons';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format } from 'date-fns';
import { validateForm } from 'pages/administration/rundown-templates/utils';
import i18n from 'i18n';

interface FormI {
  rundownTemplate?: $Lns.RundownTemplate;
  onSave: (formData: $Lns.RundownTemplate) => void;
  onCancel: () => void;
}

const Form: FunctionComponent<FormI> = ({
  rundownTemplate,
  onSave,
  onCancel
}: FormI) => {
  const [formData, setFormData] = useState({
    id: rundownTemplate?.id,
    guid: rundownTemplate?.guid,
    name: rundownTemplate?.name || '',
    playoutStartTime: rundownTemplate?.playoutStartTime || '00:00',
    playoutEndTime: rundownTemplate?.playoutEndTime || '00:00',
    schedule: {
      monday: rundownTemplate?.schedule.monday || false,
      tuesday: rundownTemplate?.schedule.tuesday || false,
      wednesday: rundownTemplate?.schedule.wednesday || false,
      thursday: rundownTemplate?.schedule.thursday || false,
      friday: rundownTemplate?.schedule.friday || false,
      saturday: rundownTemplate?.schedule.saturday || false,
      sunday: rundownTemplate?.schedule.sunday || false
    } as { [key: string]: boolean },
    errors: {
      name: '',
      playoutStartTime: '',
      playoutEndTime: ''
    }
  });

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    const updatedFormData = {
      ...formData,
      [name]: value,
      errors: {
        ...formData.errors,
        [name]: validateForm(name, value, formData)
      }
    };
    toggleSaveBtn(updatedFormData, rundownTemplate, name);
    scheduleCheckUpdate(updatedFormData, rundownTemplate);
    setFormData(updatedFormData);
  };

  const scheduleCheckUpdate = (
    formDataObj: $Lns.RundownTemplate,
    updateFormDataObj: any // eslint-disable-line
  ) => {
    let pinKey = '';
    const skipList = ['errors', 'schedule'];
    Object.keys(updateFormDataObj.schedule).forEach(pin => {
      if (
        pin in updateFormDataObj.schedule &&
        pin in formDataObj.schedule &&
        !skipList.includes(pin)
      ) {
        const val1 =
          updateFormDataObj.schedule[
            pin as keyof typeof updateFormDataObj.schedule
          ];

        const val2 =
          formDataObj.schedule[pin as keyof typeof formDataObj.schedule];

        if (val1 !== val2) {
          pinKey = pin;
        }
      }
    });
    toggleSaveBtn(updateFormDataObj.schedule, formDataObj?.schedule, pinKey);
  };

  const handleDateChange = (date: MaterialUiPickersDate, field: string) => {
    if (!date || Number.isNaN(date.getTime())) return;

    const tempDate = Date.parse(date?.toISOString());

    const time = format(tempDate, 'HH:mm');

    const secondField =
      field === 'playoutStartTime' ? 'playoutEndTime' : 'playoutStartTime';

    const updatedFormData = {
      ...formData,
      [field]: time,
      errors: {
        ...formData.errors,
        [field]: validateForm(field, time, formData),
        [secondField]: validateForm(field, formData[secondField], formData)
      }
    };
    toggleSaveBtn(updatedFormData, rundownTemplate, field);
    setFormData(updatedFormData);
  };

  const buildDate = (time: string) => {
    const hours = parseInt(time.split(':')[0], 10);
    const minutes = parseInt(time.split(':')[1], 10);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  };

  const onChangeSchedule = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    const updatedFormData = {
      ...formData,
      schedule: {
        ...formData.schedule,
        [name]: checked
      }
    };
    toggleSaveBtn(updatedFormData.schedule, rundownTemplate?.schedule, name);
    setFormData(updatedFormData);
  };

  const toggleSaveBtn = (
    updatedFormData: any, // eslint-disable-line
    origFormData: any, // eslint-disable-line
    key: string
  ) => {
    let checkFlg = true;
    const skipList = ['errors', 'schedule'];
    if (origFormData && Object.keys(origFormData).includes(key)) {
      const tempFormData = { ...updatedFormData };
      checkFlg = checkFormDataState(origFormData, tempFormData, skipList);
      setBtnDisabledChange(checkFlg);
    }
  };

  const checkFormDataState = (
    formDataObj: $Lns.RundownTemplate,
    updateFormDataObj: any, // eslint-disable-line
    skipList: string[]
  ) => {
    let checkFlg = true;
    Object.keys(updateFormDataObj).forEach(pin => {
      if (
        pin in updateFormDataObj &&
        pin in formDataObj &&
        !skipList.includes(pin)
      ) {
        const Val1 = updateFormDataObj[pin as keyof typeof updateFormDataObj];
        const Val2 = formDataObj[pin as keyof typeof formDataObj];
        if (Val1 !== Val2) {
          checkFlg = false;
        }
      }
    });
    return checkFlg;
  };

  const btnDisabled =
    Object.values(formData.errors).some(e => !!e) || !formData.name;

  const [BtnDisabledChange, setBtnDisabledChange] = useState(
    Object.values(formData.errors).some(e => !!e) || !!formData.name
  );

  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label={i18n.t(
              'pages.administration.rundownTemplates.formLabel.name'
            )}
            value={formData.name}
            helperText={formData.errors.name || 'Name'}
            name="name"
            onChange={onChangeField}
            error={!!formData?.errors?.name}
            autoComplete="name"
          />
        </Grid>

        <Grid item container spacing={4}>
          <Grid item xs={6}>
            <KeyboardTimePicker
              margin="normal"
              name="playoutStartTime"
              id="time-picker"
              label={i18n.t(
                'pages.administration.rundownTemplates.formLabel.startTime'
              )}
              ampm={false}
              inputVariant="outlined"
              keyboardIcon={<QueryBuilder />}
              value={buildDate(formData.playoutStartTime)}
              error={!!formData?.errors?.playoutStartTime}
              helperText={formData.errors.playoutStartTime || 'Start Time'}
              onChange={date => handleDateChange(date, 'playoutStartTime')}
              KeyboardButtonProps={{
                'aria-label': 'change time'
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <KeyboardTimePicker
              margin="normal"
              name="endTime"
              id="playoutEndTime"
              label={i18n.t(
                'pages.administration.rundownTemplates.formLabel.endTime'
              )}
              ampm={false}
              inputVariant="outlined"
              error={!!formData?.errors?.playoutEndTime}
              helperText={formData.errors.playoutEndTime || 'End Time'}
              keyboardIcon={<QueryBuilder />}
              value={buildDate(formData.playoutEndTime)}
              onChange={date => handleDateChange(date, 'playoutEndTime')}
              KeyboardButtonProps={{
                'aria-label': 'change time'
              }}
            />
          </Grid>

          <Grid item>
            <FormControl component="fieldset" aria-label="position">
              <FormLabel component="legend">Schedule</FormLabel>
              <FormGroup aria-label="position" row>
                {Object.keys(formData.schedule).map(day => {
                  return (
                    <FormControlLabel
                      key={day}
                      control={
                        <Checkbox
                          name={day}
                          onChange={onChangeSchedule}
                          checked={formData.schedule[day]}
                          color="primary"
                        />
                      }
                      style={{ textTransform: 'capitalize' }}
                      label={day}
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={btnDisabled || BtnDisabledChange}
              color="secondary"
              variant="contained"
              onClick={() => onSave(formData)}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;

Form.defaultProps = {
  rundownTemplate: undefined
};
