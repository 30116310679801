import { FunctionComponent } from 'react';
import {
  Audiotrack,
  CropOriginal,
  Notes,
  Videocam,
  Edit
} from '@material-ui/icons';
import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';
import { DialogueData } from 'pages/stories/interfaces/dialogueForm';
import { Box, Tooltip } from '@material-ui/core';
import { AssetType } from 'pages/stories/enums';
import { ImageAsset } from 'pages/stories/interfaces/imageAsset';
import { buildChilds, isSelfChild } from 'pages/stories/utils/asset';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import DialogueWrapper from 'pages/stories/components/Dialogue/Wrapper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import useTimeLineStyles from 'styles/timeLine';
import i18n from 'i18n';
import DotMenu from 'components/ThreeDotMenu';
import CustomTextArea from 'pages/stories/components/Form/CustomTextArea';
import shortid from 'shortid';

interface AssetsTimeline extends AssetDynamic {
  index?: number;
}

interface StoryScript {
  dynamicAssets: AssetsTimeline[];
  storyLock: boolean;
  onEditDialog: (formData: DialogueData) => void;
  onEditAsset: (asset: AssetDynamic) => void;
  onDeleteAsset: (index: number, child?: boolean) => void;
  onAddDialogue: (index: number) => void;
  onUpdateDialogue: (formData: DialogueData, initVals: DialogueData) => void;
}

const TimeLine: FunctionComponent<StoryScript> = ({
  dynamicAssets,
  storyLock,
  onEditDialog,
  onEditAsset,
  onDeleteAsset,
  onAddDialogue,
  onUpdateDialogue
}: StoryScript) => {
  const classesComp = useTimeLineStyles();

  const editDialogueAsset = (pin: AssetsTimeline) => {
    const initEditVal = {
      camera: +(pin.assetS?.camera || 0),
      presenter: pin.assetS?.presenter?.firstName || '',
      dialogue: pin.assetS?.script.text || '',
      instructions: pin.assetS?.script.presenterInstruction || '',
      readRate: pin.assetS?.presenter.readRateWordsPerSecond || 3,
      parentAsset: pin.index
    } as DialogueData;

    if (!storyLock) onEditDialog(initEditVal);
  };

  const updateDialogueText = (pin: AssetsTimeline, value: string) => {
    const initEditVal = {
      camera: +(pin.assetS?.camera || 0),
      presenter: pin.assetS?.presenter?.firstName || '',
      dialogue: pin.assetS?.script.text || '',
      instructions: pin.assetS?.script.presenterInstruction || '',
      readRate: pin.assetS?.presenter.readRateWordsPerSecond || 3,
      parentAsset: pin.index
    } as DialogueData;

    const updatesEditVal = { ...initEditVal, dialogue: value } as DialogueData;

    if (!storyLock) onUpdateDialogue(updatesEditVal, initEditVal);
  };

  const makingContextMenu = (Asset: ImageAsset, index: number) => {
    const menus = [
      {
        title: i18n.t('pages.stories.tooltip.addDialogue'),
        action: () => !storyLock && onAddDialogue(Asset?.positionInScript || 0)
      },
      {
        title: i18n.t('pages.stories.tooltip.removeDialogue'),
        action: () => !storyLock && onDeleteAsset(index)
      }
    ];

    return menus;
  };

  return (
    <>
      {dynamicAssets.map((assetItem, key) => {
        const assetTypeObj = assetItem.assetS || assetItem.assetI;
        if (isSelfChild(assetItem, dynamicAssets, key)) {
          return <></>;
        }

        const childObject = buildChilds(assetItem, dynamicAssets, key);

        if (assetItem.assetS) {
          childObject.unshift({ ...assetItem, index: key });
        }

        const condIncaseOfChilds = childObject && childObject.length > 0;
        const condIncaseOfAsset = assetTypeObj?.type !== AssetType.dialogue;
        const condIncaseOfDialogue = assetTypeObj?.type === AssetType.dialogue;

        return (
          <Timeline
            key={shortid.generate()}
            align="right"
            className={classesComp.paddingRm}
          >
            <TimelineItem>
              {condIncaseOfChilds && (
                <TimelineOppositeContent
                  className={classesComp.timelineContent}
                >
                  {assetItem.assetI && (
                    <Typography variant="body2" color="textSecondary">
                      {assetTypeObj?.relativeStartTime}
                    </Typography>
                  )}
                  {childObject.map(childs => {
                    const childIndex = childs.index || 0;
                    const textScript = childs.assetS?.script.text;
                    return (
                      <Paper elevation={3} className={classesComp.paper}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="end"
                        >
                          <Tooltip
                            title={i18n
                              .t('pages.stories.tooltip.editDialogue')
                              .toString()}
                          >
                            <Edit
                              color="secondary"
                              onClick={() => editDialogueAsset(childs)}
                            />
                          </Tooltip>
                          <Tooltip
                            title={i18n
                              .t('pages.stories.tooltip.removeDialogue')
                              .toString()}
                          >
                            <RemoveCircleOutlineIcon
                              data-test-id="remove-item"
                              color="secondary"
                              onClick={() =>
                                !storyLock && onDeleteAsset(childIndex, true)
                              }
                            />
                          </Tooltip>
                        </Box>
                        <Box width="100%" textAlign="left">
                          <DialogueWrapper
                            title={i18n.t('pages.stories.helperText.presenter')}
                            value={childs.assetS?.presenter.firstName || ''}
                          />
                          <DialogueWrapper
                            title={i18n.t('pages.stories.helperText.camera')}
                            value={childs.assetS?.camera || ''}
                          />
                          <CustomTextArea
                            value={textScript || ''}
                            storyLock={storyLock}
                            handleUpdate={value =>
                              updateDialogueText(childs, value)
                            }
                          />
                        </Box>
                      </Paper>
                    );
                  })}
                </TimelineOppositeContent>
              )}
              {condIncaseOfAsset && !condIncaseOfChilds && (
                <TimelineContent className={classesComp.displayContents}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classesComp.timelineAssetContent}
                  >
                    {assetTypeObj?.relativeStartTime}
                  </Typography>
                </TimelineContent>
              )}
              <TimelineSeparator>
                <TimelineDot>
                  {assetTypeObj?.type === AssetType.image && <CropOriginal />}
                  {assetTypeObj?.type === AssetType.audio && <Audiotrack />}
                  {assetTypeObj?.type === AssetType.video && <Videocam />}
                  {assetTypeObj?.type === AssetType.default && <Notes />}
                  {assetTypeObj?.type === AssetType.dialogue && <Notes />}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              {condIncaseOfDialogue && (
                <TimelineContent className={classesComp.displayContents}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classesComp.timelineAssetContent}
                  >
                    {assetTypeObj?.relativeStartTime}
                  </Typography>
                </TimelineContent>
              )}
              {condIncaseOfAsset && (
                <TimelineContent className={classesComp.displayContents}>
                  <Paper
                    elevation={3}
                    className={`${classesComp.timelineAssetContent} ${classesComp.paper}`}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="end"
                    >
                      <Box width="100%" paddingLeft={1} textAlign="left">
                        {assetTypeObj?.type}
                      </Box>
                      <Box position="relative">
                        {assetItem.assetI && (
                          <DotMenu
                            initVals={makingContextMenu(assetItem.assetI, key)}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box width="100%" onClick={() => onEditAsset(assetItem)}>
                      <Tooltip title={assetTypeObj?.title || ''}>
                        <Typography noWrap variant="subtitle1">
                          {assetTypeObj?.title || ''}
                        </Typography>
                      </Tooltip>

                      {assetTypeObj?.type === AssetType.image ? (
                        <img
                          className={classesComp.timelineAssetSection}
                          alt=""
                          src={assetItem.assetI?.proxyUrl}
                        />
                      ) : (
                        <></>
                      )}
                      {assetTypeObj?.type === AssetType.video ? (
                        <img
                          alt=""
                          className={classesComp.timelineAssetSection}
                          src={`data:image/gif;base64,${assetItem.assetI?.thumbnail}`}
                        />
                      ) : (
                        <></>
                      )}
                      {assetTypeObj?.type === AssetType.audio ? (
                        <Audiotrack
                          className={classesComp.timelineAssetSection}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Paper>
                </TimelineContent>
              )}
            </TimelineItem>
          </Timeline>
        );
      })}
    </>
  );
};
export default TimeLine;
