import { FunctionComponent } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignIn from 'pages/signin';
import Signup from 'pages/signup';
import LoggedInMain from 'components/LoggedInMain';
import PasswordResetRoutes from 'routes/PasswordReset';
import { EmailConfirmation } from 'pages/email-confirmation';

const LnsRoutes: FunctionComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/password-reset/*" element={<PasswordResetRoutes />} />
        <Route
          path="/email-confirmation/:token"
          element={<EmailConfirmation />}
        />
        <Route path="/*" element={<LoggedInMain />} />
      </Routes>
    </Router>
  );
};

export default LnsRoutes;
