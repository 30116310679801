import {
  AppBar,
  Box,
  Button,
  GridSize,
  Tab,
  Tabs,
  Tooltip
} from '@material-ui/core';
import {
  Audiotrack,
  CropOriginal,
  Notes,
  Videocam,
  Close,
  VerticalAlignBottom,
  VerticalAlignTop
} from '@material-ui/icons';
import { ChangeEvent, FunctionComponent, useState, useEffect } from 'react';
import { fetchPeripherals, fetchPeripheralTypes } from 'actions/peripheral';
import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';
import TabPanel from 'components/TabPanel';
import AssetManager from 'pages/stories/components/Asset/Manager';
import EmptyState from 'components/EmptyState';
import Users from 'pages/stories/components/Users';
import i18n from 'i18n';
import AssetUpload from 'pages/stories/components/Asset/Upload';

interface StoryBrowseTabsI {
  gridSizes: { [key: string]: GridSize };
  storyLock: boolean;
  fullScreen: boolean;
  onClose: () => void;
  onUpdateAssets: (asset: AssetDynamic) => void;
  onToggle12Grid: () => void;
}

const StoryBrowseTabs: FunctionComponent<StoryBrowseTabsI> = ({
  gridSizes,
  fullScreen,
  storyLock,
  onClose,
  onUpdateAssets,
  onToggle12Grid
}: StoryBrowseTabsI) => {
  const [tabValue, setTabValue] = useState(0);
  const [peripherals, setPeripherals] = useState<$Lns.Peripheral[]>([]);
  const [selectedPerif, setSelectedPeripheral] = useState<$Lns.Peripheral>();

  const handleChange = (
    event: ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    fetchPeripherals().then(({ data: pData, code: pCode }) => {
      if (pCode === 200 && pData.length > 0) {
        // filter for MAM
        fetchPeripheralTypes().then(({ data: tData, code: tCode }) => {
          if (tCode === 200 && tData.length > 0) {
            const mamType = tData.find(pin => pin.key.includes('mam'));

            const filteredData = pData.filter(peripheral => {
              return peripheral.type === mamType?.guid;
            });

            if (filteredData) {
              setPeripherals(filteredData);
              const selectedObj = filteredData.find(pin =>
                pin.name.includes('Metus')
              );
              setSelectedPeripheral(selectedObj);
            }
          }
        });
      }
    });
  }, []);

  const onSelectPeripheral = (pin: $Lns.Peripheral) => {
    setSelectedPeripheral(pin);
  };

  return (
    <Box>
      <Box component="span" position="relative">
        {fullScreen && <Box padding={2} />}
        {!fullScreen && (
          <>
            <Tooltip
              title={i18n.t('pages.stories.tooltip.closeWindow').toString()}
            >
              <Box component="span">
                <Button
                  onClick={onClose}
                  startIcon={<Close color="primary" />}
                />
              </Box>
            </Tooltip>

            <Tooltip
              title={i18n.t('pages.stories.tooltip.toggleWindow').toString()}
            >
              <Box component="span">
                <Button
                  onClick={onToggle12Grid}
                  startIcon={
                    gridSizes.browse === 12 ? (
                      <VerticalAlignTop color="primary" />
                    ) : (
                      <VerticalAlignBottom color="primary" />
                    )
                  }
                />
              </Box>
            </Tooltip>
          </>
        )}
      </Box>
      <AppBar position="static">
        <Tabs
          value={tabValue}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          aria-label="story form tabs"
        >
          <Tab
            label={i18n.t('pages.stories.tabLabel.images')}
            icon={<CropOriginal />}
          />
          <Tab
            label={i18n.t('pages.stories.tabLabel.graphics')}
            icon={<Notes />}
          />
          <Tab
            label={i18n.t('pages.stories.tabLabel.videos')}
            icon={<Videocam />}
          />
          <Tab
            label={i18n.t('pages.stories.tabLabel.audio')}
            icon={<Audiotrack />}
          />
          <Tab
            label={i18n.t('pages.stories.tabLabel.upload')}
            icon={<Notes />}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <Box padding={2}>
          <AssetManager
            storyLock={storyLock}
            protocol={selectedPerif}
            assetType="Image"
            onUpdateAssets={onUpdateAssets}
            peripherals={peripherals}
            onSelectPeripheral={onSelectPeripheral}
          />
          {peripherals === undefined && (
            <EmptyState
              title={i18n.t('pages.stories.tabLabel.images')}
              subTitle="No content yet"
            />
          )}
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box padding={2}>
          <EmptyState
            title={i18n.t('pages.stories.tabLabel.graphics')}
            subTitle="No content yet"
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Box padding={2}>
          <AssetManager
            storyLock={storyLock}
            protocol={selectedPerif}
            assetType="Video"
            onUpdateAssets={onUpdateAssets}
            peripherals={peripherals}
            onSelectPeripheral={onSelectPeripheral}
          />
          {peripherals === undefined && (
            <EmptyState
              title={i18n.t('pages.stories.tabLabel.videos')}
              subTitle="No content yet"
            />
          )}
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <Box padding={2}>
          <AssetManager
            storyLock={storyLock}
            protocol={selectedPerif}
            assetType="Audio"
            onUpdateAssets={onUpdateAssets}
            peripherals={peripherals}
            onSelectPeripheral={onSelectPeripheral}
          />
          {peripherals === undefined && (
            <EmptyState
              title={i18n.t('pages.stories.tabLabel.audio')}
              subTitle="No content yet"
            />
          )}
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={5}>
        <Box padding={2}>
          <Users />
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <Box padding={2}>
          <AssetUpload
            storyLock={storyLock}
            protocol={selectedPerif}
            peripheral={peripherals}
            onSelectPeripheral={onSelectPeripheral}
          />
          {peripherals === undefined && (
            <EmptyState
              title={i18n.t('pages.stories.tabLabel.images')}
              subTitle="No content yet"
            />
          )}
        </Box>
      </TabPanel>
    </Box>
  );
};

export default StoryBrowseTabs;
