import { Box, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';
import { InfoOutlined } from '@material-ui/icons';

interface TitleWaperI {
  title: string;
  subText: string;
  icon?: ReactNode;
  textIcon?: ReactNode;
}

const TitleWraper: FunctionComponent<TitleWaperI> = ({
  title,
  subText,
  icon,
  textIcon
}: TitleWaperI) => {
  return (
    <Box display="flex" style={{ overflowWrap: 'break-word' }}>
      <ListItem>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ color: 'primary' }}
          secondary={subText}
          primary={
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="left"
              alignItems="center"
            >
              {title}
              {textIcon}
            </Box>
          }
        />
      </ListItem>
    </Box>
  );
};

export default TitleWraper;

TitleWraper.defaultProps = {
  icon: <InfoOutlined color="primary" />,
  textIcon: undefined
};
