export const updatedObjects = (
  // eslint-disable-next-line
  array: Array<any>,
  // eslint-disable-next-line
  newObject: any
  // eslint-disable-next-line
): Array<any> => {
  return array.map(currentItem => {
    if (currentItem.guid === newObject.guid) {
      return {
        ...currentItem,
        ...newObject
      };
    }
    return currentItem;
  });
};

// eslint-disable-next-line
export const objectsWithItemRemoved = (item: any, objects: Array<any>) => {
  return objects.filter(obj => obj.guid !== item.guid);
};
