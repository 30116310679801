import { TextField } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

interface TimerFieldI {
  color: string;
  value: string;
}

const TimerField: FunctionComponent<TimerFieldI> = ({
  color,
  value
}: TimerFieldI) => {
  return (
    <TextField
      style={{
        padding: '0 10px',
        height: '30px',
        width: '120px',
        borderRadius: '5px',
        background: color,
        color: '#fff'
      }}
      InputProps={{ disableUnderline: true, style: { color: '#fff' } }}
      id="standard-size-small"
      defaultValue="Small"
      size="small"
      value={value}
    />
  );
};

export default TimerField;
