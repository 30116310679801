import { callLnsApi } from 'utils/fetch';

export const fetchStoryTypes = (): Promise<{
  code: number;
  data: $Lns.StoryType[];
}> => callLnsApi('/story-type');

export const createStoryType = (
  storyType: $Lns.StoryType
): Promise<{
  code: number;
  data: $Lns.StoryType;
}> => callLnsApi('/story-type', 'POST', storyType);

export const updateStoryType = (
  storyType: $Lns.StoryType
): Promise<{
  code: number;
  data: $Lns.StoryType;
}> => callLnsApi(`/story-type/${storyType.guid}`, 'POST', storyType);

export const deleteStoryType = (
  storyType: $Lns.StoryType
): Promise<{
  code: 204;
}> => callLnsApi(`/story-type/${storyType.guid}`, 'DELETE');
