import { FunctionComponent } from 'react';
import { Typography } from '@material-ui/core';
import i18n from 'i18n';
import { getEnumKey } from 'utils/enum';
import TooltipWrap from 'components/TooltipWrap';
import { TaskPriority } from 'pages/assignments/consts';
import { usePriorityStyles } from 'pages/assignments/styles';

interface PriorityTagI {
  priority: string | number;
  tooltip?: boolean;
}

const PriorityTag: FunctionComponent<PriorityTagI> = ({
  priority,
  tooltip
}: PriorityTagI) => {
  const priorityClasses = usePriorityStyles();

  const enumKey =
    typeof priority === 'number'
      ? getEnumKey(TaskPriority, priority) || 'normal'
      : priority;

  const coreTag = (
    <Typography
      className={
        priorityClasses[
          enumKey.toLowerCase() as 'normal' | 'low' | 'high' | 'urgent'
        ]
      }
    >
      {enumKey}
    </Typography>
  );

  if (tooltip)
    return (
      <TooltipWrap title={i18n.t('pages.assignments.tooltip.priority')}>
        {coreTag}
      </TooltipWrap>
    );

  return coreTag;
};

export default PriorityTag;

PriorityTag.defaultProps = {
  tooltip: false
};
