import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      minWidth: 400
    },
    input: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1),
      flex: 1
    },
    searchIcon: {
      marginRight: theme.spacing(1)
    },
    divider: {
      height: 28,
      margin: 4
    },
    userChips: {
      marginRight: theme.spacing(1),
      '&:first-child': {
        marginLeft: theme.spacing(1)
      }
    },
    searchResults: {
      position: 'fixed',
      width: (props: { width: number }) => props.width - theme.spacing(2),
      minWidth: 400,
      marginLeft: theme.spacing(2),
      marginTop: -theme.spacing(1),
      zIndex: 99
    }
  })
);

export default useStyles;
