import React, { FunctionComponent, useState } from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import logoImg from 'images/lns-logo-primary-color.png';
import SignupForm from 'pages/signup/components/Form';
import { signup } from 'pages/signup/actions';
import { useDispatch } from 'react-redux';
import toggleNotification from 'actions/notifications';
import { useNavigate } from 'react-router';
import { SignUpFormData } from 'pages/signup/interface';
import i18n from 'i18n';

const SignupContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [isLoading, toggleLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const onCancel = () => {
    navigate('/signin');
  };

  const onSave = (formData: SignUpFormData) => {
    toggleLoading(true);
    signup(formData)
      .then(({ errors: jsonErrors }) => {
        toggleLoading(false);
        if (jsonErrors) {
          const newErrors = jsonErrors.reduce(
            (accu: Record<string, unknown>, b: $Lns.ObjectKey) => {
              accu[b.source] = b.message;
              return accu;
            },
            {}
          );

          setErrors(newErrors);
          dispatch(
            toggleNotification(i18n.t('notifications.signup.error'), 'error')
          );
        } else {
          dispatch(
            toggleNotification(
              i18n.t('notifications.signup.success'),
              'success'
            )
          );
          navigate('/signin');
        }
      })
      .catch(() => {
        toggleLoading(false);
        dispatch(
          toggleNotification(
            i18n.t('notifications.signup.unavailable'),
            'error'
          )
        );
      });
  };

  return (
    <Box display="grid" alignContent="center" mt={5}>
      <Container component="main" maxWidth="sm">
        <Grid container spacing={4} justify="center" alignContent="center">
          <Grid item>
            <Box display="flex" justifyContent="center">
              <img alt="logo" src={logoImg} width="25%" height="25%" />
            </Box>
          </Grid>
          <Grid item>
            <SignupForm
              onSave={onSave}
              onCancel={onCancel}
              isLoading={isLoading}
              errors={errors}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SignupContainer;
