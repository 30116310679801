import { FunctionComponent } from 'react';
import TableBuilder from 'components/Table';
import i18n from 'i18n';
import { Grid } from '@material-ui/core';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import { Settings } from '@material-ui/icons';

const SocialMediaFeeds: FunctionComponent = () => {
  const rows = [i18n.t('pages.administration.socialMediaFeeds.tableRow.name')];

  const cells = [
    {
      fields: {
        name: { value: 'Facebook' }
      }
    },
    {
      fields: {
        name: { value: 'Instagram' }
      }
    },
    {
      fields: {
        name: { value: 'Twitter' }
      }
    }
  ];

  return (
    <Grid container spacing={2}>
      <TitleBarWraper>
        <Grid item xs={6}>
          <TitleWaper
            title={i18n.t(
              'links.sidebarNestedItems.administration.socialMediaFeeds'
            )}
            subText={i18n.t('pages.stories.formLabel.title')}
            icon={<Settings color="primary" />}
          />
        </Grid>
      </TitleBarWraper>
      <Grid item xs={12}>
        <TableBuilder rows={rows} cells={cells} />
      </Grid>
    </Grid>
  );
};

export default SocialMediaFeeds;
