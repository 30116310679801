import React, { FunctionComponent } from 'react';
import { Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import i18n from 'i18n';
import Dialog from 'components/Dialog';

export interface DeleteDialogI {
  onDelete: () => void;
  target: string;
  tooltip?: string;
  targetDeleteTitle?: string;
  targetDeletePrompt?: string;
  targetDeleteWarning?: string;
  btnDisabled?: boolean;
  lnsResource?: string;
  lnsAction?: string;
}

const DeleteDialog: FunctionComponent<DeleteDialogI> = ({
  onDelete,
  target,
  tooltip,
  targetDeleteTitle,
  targetDeletePrompt,
  targetDeleteWarning,
  btnDisabled = false,
  lnsResource,
  lnsAction
}: DeleteDialogI) => {
  return (
    <Dialog
      title={targetDeleteTitle || ''}
      tooltip={tooltip}
      openBtnLabel={i18n.t('deleteDialog.openBtnLabel')}
      iconBtn={<Delete color="primary" />}
      cancelLabel={i18n.t('button.cancel')}
      confirmLabel={i18n.t('button.confirm')}
      onConfirmCb={onDelete}
      btnDisabled={btnDisabled}
      lnsResource={lnsResource}
      lnsAction={lnsAction}
    >
      <Typography gutterBottom variant="body2">
        {targetDeletePrompt}
      </Typography>
      <Typography gutterBottom variant="h6">
        {target}
      </Typography>
      <Typography gutterBottom variant="body2">
        {targetDeleteWarning}
      </Typography>
    </Dialog>
  );
};

export default DeleteDialog;

DeleteDialog.defaultProps = {
  targetDeleteTitle: i18n.t('deleteDialog.title'),
  tooltip: i18n.t('deleteDialog.tooltip'),
  targetDeletePrompt: i18n.t('deleteDialog.prompt'),
  targetDeleteWarning: i18n.t('deleteDialog.warning'),
  btnDisabled: false,
  lnsResource: undefined,
  lnsAction: undefined
};
