import { callLnsApi } from 'utils/fetch';
import { Dispatch } from 'react';
import { SET_CURRENT_USER_PERMISSIONS } from 'redux/reducers/types';

export const fetchUserPermissions = (
  guid: string
): Promise<{
  code: number;
  data: $Lns.CurrentUserPermissions;
}> => {
  return callLnsApi(`/user/${guid}/permissions`);
};

export const setCurrentUserPermissions = (
  currentUserPermissions: $Lns.CurrentUserPermissions
): Dispatch<() => void> => {
  return (
    dispatch: Dispatch<{
      type: string;
      currentUserPermissions: $Lns.CurrentUserPermissions;
    }>
  ) => {
    dispatch({
      type: SET_CURRENT_USER_PERMISSIONS,
      currentUserPermissions
    });
  };
};
