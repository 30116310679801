import i18n from 'i18n';
import React, { ReactNode } from 'react';
import {
  Settings,
  Tune,
  Security,
  InsertDriveFile,
  PermMedia,
  SettingsInputSvideo,
  Description,
  Category,
  RssFeed,
  Dns,
  ListAlt,
  Theaters
} from '@material-ui/icons';
import { Resource, Action } from 'components/Authorization';

export const nestedItems = (): {
  name: string;
  guid: string;
  icon: ReactNode;
  lnsResource?: string;
  lnsAction?: string;
}[] => {
  return [
    {
      name: i18n.t('links.sidebarNestedItems.administration.setup'),
      guid: 'setup',
      icon: <Tune color="primary" />,
      lnsResource: Resource.SYSTEM_SETUP,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.rundownTemplates'),
      guid: 'rundown-templates',
      icon: <Dns color="primary" />,
      lnsResource: Resource.RUNDOWN_TEMPLATE,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.storyTypes'),
      guid: 'story-types',
      icon: <Description color="primary" />,
      lnsResource: Resource.STORY_TYPE,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.media'),
      guid: 'media',
      icon: <Theaters color="primary" />
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.storyPools'),
      guid: 'story-pools',
      icon: <ListAlt color="primary" />,
      lnsResource: Resource.STORY_POOL,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.peripherals'),
      guid: 'peripheral-settings',
      icon: <SettingsInputSvideo color="primary" />,
      lnsResource: Resource.PERIPHERAL,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.publishFolders'),
      guid: 'publish-folders',
      icon: <PermMedia color="primary" />,
      lnsResource: Resource.PUBLISH_FOLDER,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.locations'),
      guid: 'locations',
      icon: <PermMedia color="primary" />,
      lnsResource: Resource.LOCATION,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.storyGenres'),
      guid: 'story-genres',
      icon: <Category color="primary" />,
      lnsResource: Resource.STORY_GENRE,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.security'),
      guid: 'security',
      icon: <Security color="primary" />,
      lnsResource: Resource.SECURITY,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.fileTypes'),
      guid: 'file-types',
      icon: <InsertDriveFile color="primary" />,
      lnsResource: Resource.FILE_TYPE,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.newswires'),
      guid: 'newswires',
      icon: <RssFeed color="primary" />,
      lnsResource: Resource.NEWSWIRE,
      lnsAction: Action.ADMIN
    },
    {
      name: i18n.t('links.sidebarNestedItems.administration.socialMediaFeeds'),
      guid: 'social-media-feeds',
      icon: <Settings color="primary" />
    }
  ];
};
