import { FunctionComponent, MouseEvent, useState, useEffect } from 'react';
import {
  Box,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { addSeconds, isBefore } from 'date-fns';
import { useInterval } from 'utils/interval';
import {
  progressStyle,
  storyRowStyles,
  scriptRowStyle
} from 'pages/rundowns/styles';
import { secondsToDuration } from 'utils/time';
import {
  computeBackTime,
  computeCmlDuration,
  computeHitTime,
  currentRemainingPercentage,
  isCurrentlyActive,
  isOverTime
} from 'pages/rundowns/utils';
import {
  Edit,
  LowPriority,
  Visibility,
  Videocam,
  ExpandMore,
  ExpandLess,
  Description
} from '@material-ui/icons';
import DraggableComponent from 'components/DraggableComponent';
import StoryDetail from 'pages/rundowns/components/StoryDetail';
import format from 'date-fns/format';
import colors from 'styles/colors';
import clsx from 'clsx';
import i18n from 'i18n';
import { Link } from 'react-router-dom';
import { ScriptAsset } from 'pages/stories/interfaces/scriptAsset';
import { AssetType } from 'pages/stories/enums';
import { StyledTableCell } from 'components/StyledTableCell';
import { StyledTableRow } from 'components/StyledTableRow';

interface PlayoutStoryI {
  playoutStory: $Lns.PlayoutStory;
  playoutStories: $Lns.PlayoutStory[];
  peripherals: $Lns.Peripheral[];
  onSpareClick: (story: $Lns.PlayoutStory) => void;
  rundown: $Lns.Rundown;
  index: number;
}

const PlayoutStory: FunctionComponent<PlayoutStoryI> = ({
  playoutStory,
  playoutStories,
  peripherals,
  rundown,
  index,
  onSpareClick
}: PlayoutStoryI) => {
  const { story } = playoutStory;
  const [script, setScript] = useState<ScriptAsset[]>([]);
  const [showScript, toggleScript] = useState<boolean>(true);
  const stories = playoutStories.map(ps => ps.story);
  const [selectedStory, setSelectedStory] = useState<$Lns.Story>();
  const [percentage, setPercentage] = useState(0);
  const [contextMenu, toggleContextMenu] = useState(false);
  const storyStartTime = computeHitTime(story, stories, rundown);
  const storyEndTime = addSeconds(storyStartTime, story.calculatedDuration);

  const [isActive, toggleActive] = useState(
    isCurrentlyActive(story, stories, rundown)
  );

  const [isEnabled, toggleIsEnabled] = useState(
    isCurrentlyActive(story, stories, rundown)
  );

  useInterval(
    () => {
      if (!rundown.isPublished) return;
      const progress = currentRemainingPercentage(story, stories, rundown);
      setPercentage(progress);
      if (progress >= 100) {
        toggleActive(false);
        setPercentage(0);
      }
    },
    250,
    !isActive
  );

  useInterval(() => {
    const isNowActive =
      rundown.isPublished &&
      new Date() <= storyEndTime &&
      new Date() >= storyStartTime;

    if (isNowActive && !isActive) {
      toggleActive(true);
    }
  }, 1000);

  useInterval(() => {
    const isEnableIt = isBefore(new Date(), storyEndTime);
    if (isEnableIt && !isActive) {
      toggleIsEnabled(true);
    }
  }, 1000);

  const formattedHitTime = () => {
    const date = computeHitTime(story, stories, rundown);

    return format(date, 'HH:mm:ss');
  };

  const onClick = (e: MouseEvent) => {
    if (e.type === 'click') {
      setSelectedStory(story);
    } else if (e.type === 'contextmenu') {
      if (isFinished || isActive) return;

      e.preventDefault();
      toggleContextMenu(true);
    }
  };

  const onClose = () => {
    setSelectedStory(undefined);
  };

  const toggleShowScript = () => {
    toggleScript(!showScript);
  };

  const onContextClose = (e: MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    toggleContextMenu(false);
  };
  useEffect(() => {
    const scriptObjs = [];
    if (story.script.startsWith('[')) {
      const scriptArry = JSON.parse(story.script);
      if (scriptArry && scriptArry.length > 0) {
        for (let item = 0; item < scriptArry.length; item += 1) {
          const element = scriptArry[item];
          if (element.type === AssetType.dialogue) {
            const assetS = { ...element } as ScriptAsset;
            scriptObjs.push({ ...assetS });
          }
        }
      }
    }
    if (scriptObjs.length === 0) toggleScript(false);
    setScript(scriptObjs);
  }, [story]);
  const isFinished = isOverTime(story, stories, rundown);
  const progressClasses = progressStyle();
  const storyRowClasses = storyRowStyles();
  const scriptRowClasses = scriptRowStyle();

  const className = clsx({
    [storyRowClasses.default]: !isActive && !isFinished,
    [storyRowClasses.finished]: !isActive && isFinished,
    [storyRowClasses.active]: isActive || isEnabled
  });

  const getPeripheralIconColor = (peripheral: $Lns.Peripheral) => {
    return playoutStory[`is${peripheral.type}Ready` as keyof $Lns.PlayoutStory]
      ? colors.success.dark
      : colors.error.dark;
  };

  const returnStoryType = (type: number) => {
    const storyType = {
      1: 'PKG',
      2: 'LVO',
      3: 'FSN',
      4: 'LiveCopy',
      5: 'Opener Video',
      6: 'Bumper',
      7: '2-Way'
    } as { [key: number]: string };

    return storyType[type];
  };

  return (
    <>
      <StyledTableRow
        className={`story-${story.guid}`}
        classes={{ root: className }}
        onContextMenu={onClick}
        component={DraggableComponent(story.guid, index, !isFinished)}
      >
        <StyledTableCell align="left">
          <Box
            display="grid"
            gridAutoFlow="column"
            justifyItems="center"
            alignItems="center"
            gridGap={10}
          >
            <Description color="primary" />
            <Typography variant="h6" color="primary">
              {stories.indexOf(story) + 1}
            </Typography>
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">
          <Box
            display="grid"
            gridAutoFlow="column"
            gridGap={10}
            justifyItems="left"
            alignItems="left"
          >
            {peripherals.map(peripheral => {
              return (
                <Box
                  fontSize={10}
                  width="max-content"
                  bgcolor={getPeripheralIconColor(peripheral)}
                  color={colors.neutral[0]}
                  padding={1}
                  borderRadius={10}
                >
                  {peripheral.name.slice(0, 2).toUpperCase()}
                </Box>
              );
            })}
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">{story.slug}</StyledTableCell>
        <StyledTableCell align="left">
          <Box maxWidth="200px">
            <Typography noWrap variant="inherit" component="div">
              {story.title}
            </Typography>
          </Box>
        </StyledTableCell>
        <StyledTableCell align="left">
          {secondsToDuration(story.calculatedDuration)}
        </StyledTableCell>
        <StyledTableCell align="left">
          {computeCmlDuration(story, stories)}
        </StyledTableCell>
        <StyledTableCell align="left">{formattedHitTime()}</StyledTableCell>
        <StyledTableCell align="left">
          {computeBackTime(story, stories, rundown)}
        </StyledTableCell>
        <StyledTableCell align="left">
          {returnStoryType(+story.type)}
        </StyledTableCell>
        <StyledTableCell align="left">
          <Box display="flex">
            {!isActive && !isFinished && playoutStories.length > 1 && (
              <Tooltip title="Move to spare" aria-label="add">
                <IconButton
                  data-test-id="add-spare"
                  onClick={() => onSpareClick(playoutStory)}
                  color="primary"
                  style={{ transform: 'rotateY(180deg)' }}
                >
                  <LowPriority />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip
              title={i18n.t('pages.rundowns.tooltip.viewStory').toString()}
              aria-label="add"
            >
              <IconButton onClick={onClick} color="primary">
                <Visibility />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={i18n.t('pages.rundowns.tooltip.editStory').toString()}
              aria-label="add"
            >
              <Link to={`/my-lns/${story.guid}/edit`} target="_blank">
                <IconButton color="primary">
                  <Edit color="primary" />
                </IconButton>
              </Link>
            </Tooltip>
            {script.length > 0 && (
              <Tooltip title="toggle Script Row" aria-label="add">
                <IconButton color="primary" onClick={toggleShowScript}>
                  {showScript === false ? (
                    <ExpandMore color="primary" />
                  ) : (
                    <ExpandLess color="primary" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </StyledTableCell>
      </StyledTableRow>
      {showScript && (
        <StyledTableRow
          className={`story-${story.guid} ${scriptRowClasses.root}`}
          classes={{ root: className }}
        >
          <Box
            className={scriptRowClasses.scriptSection}
            gridAutoFlow="column"
            gridGap={10}
            justifyItems="left"
            alignItems="left"
            position="absolute"
            top={-5}
          >
            {script.map(pin => {
              if (pin.type !== 'dialogue') return <></>;

              return (
                <Box
                  fontSize={10}
                  width="max-content"
                  padding={1}
                  borderRadius={10}
                >
                  <Box display="flex">
                    <Box marginLeft={1} marginRight={1}>
                      <Videocam color="primary" />
                    </Box>
                    <Typography>{pin.presenter.firstName}</Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </StyledTableRow>
      )}
      <TableRow>
        <StyledTableCell colSpan={11} style={{ padding: 0 }}>
          <LinearProgress
            variant="determinate"
            value={percentage}
            color="primary"
            classes={progressClasses}
          />
        </StyledTableCell>
      </TableRow>
      {selectedStory && <StoryDetail story={selectedStory} onClose={onClose} />}
      <Menu
        id="story-menu"
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        anchorEl={() =>
          document.getElementsByClassName(`story-${story.guid}`)[0]
        }
        open={contextMenu}
        onClose={onContextClose}
      >
        <MenuItem dense onClick={() => onSpareClick(playoutStory)}>
          {i18n.t('pages.rundowns.menuItem.spare')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default PlayoutStory;
