import {
  ChangeEvent,
  KeyboardEvent,
  FunctionComponent,
  useReducer,
  useRef,
  useEffect
} from 'react';
import {
  Box,
  Chip,
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import i18n from 'i18n';
import { useDispatch } from 'react-redux';
import { fetchUsers } from 'actions/user';
import useStyles from 'components/SearchSelectUsers/styles';
import {
  initialState,
  State as ReducerState,
  reducer
} from 'components/SearchSelectUsers/reducer';
import toggleNotification from 'actions/notifications';

interface SearchSelectUsersI {
  maxSelect?: number | undefined;
  userList?: $Lns.User[] | undefined;
  selectedUserList?: $Lns.User[] | undefined;
  placeholderText?: string;
  title: string;
  onChangeSelect: (userList: $Lns.User[]) => void;
}

const SearchSelectUsers: FunctionComponent<SearchSelectUsersI> = ({
  maxSelect: propsMaxSelect,
  userList: propsUserList,
  selectedUserList: propsSelectedUserList,
  placeholderText,
  title,
  onChangeSelect
}: SearchSelectUsersI) => {
  const isMountedRef = useRef<boolean>(false);
  const reduxDispatch = useDispatch();
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const classes = useStyles({ width: inputFieldRef.current?.offsetWidth || 0 });

  const initislStateWithProps: ReducerState = {
    ...initialState,
    allUserList: propsUserList || initialState.allUserList,
    selectedUserList: propsSelectedUserList || initialState.selectedUserList,
    maxSelect: propsMaxSelect
  };

  const [
    { maxSelect, searchTerm, selectedUserList, filteredUserList },
    dispatch
  ] = useReducer(reducer, initislStateWithProps);

  const searchDisabled = maxSelect
    ? selectedUserList.length >= maxSelect // if lengh is bounded by maxSelect
    : false; // if maxSelect doesn't exist, then there are no boundaries

  const onHandleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete')
      dispatch({
        type: 'deleteLastUser'
      });
  };

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e && e.target)
      dispatch({
        type: 'onSearchTermChanged',
        payload: e.currentTarget.value || ''
      });
  };

  const handleOnSelect = (addUserItem: $Lns.User) => {
    dispatch({
      type: 'selectUser',
      payload: addUserItem
    });
  };

  const handleOnUnselect = (targetItem: $Lns.User) => {
    dispatch({
      type: 'unselectUser',
      payload: targetItem
    });
  };

  useEffect(() => {
    if (!propsUserList)
      fetchUsers()
        .then(json => {
          dispatch({
            type: 'setAllUserList',
            payload: json.data
          });
        })
        .catch(() =>
          reduxDispatch(
            toggleNotification(
              i18n.t('notifications.users.fetchAllError'),
              'error'
            )
          )
        );
    else
      dispatch({
        type: 'setAllUserList',
        payload: propsUserList
      });
  }, [propsUserList, reduxDispatch]);

  useEffect(() => {
    if (isMountedRef.current) onChangeSelect(selectedUserList);
  }, [selectedUserList, onChangeSelect]);

  // to check if component is mounted
  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  return (
    <Box position="relative" width="100%">
      <Paper component="form" className={classes.root} elevation={0}>
        <Box paddingX={2} paddingY={1}>
          <Typography variant="body2">{title}</Typography>
        </Box>
        {selectedUserList.length > 0 &&
          selectedUserList.map(userItem => {
            return (
              <Chip
                className={classes.userChips}
                label={userItem.username}
                onDelete={() => {
                  handleOnUnselect(userItem);
                }}
              />
            );
          })}
      </Paper>
      {!searchDisabled && (
        <TextField
          fullWidth
          disabled={searchDisabled}
          variant="outlined"
          className={classes.input}
          placeholder={searchDisabled ? '' : placeholderText}
          value={searchTerm}
          onKeyDown={onHandleKeyDown}
          onChange={onSearchFieldChange}
          inputProps={{ 'aria-label': 'search bar' }}
          ref={inputFieldRef}
        />
      )}
      {filteredUserList && (
        <Paper className={classes.searchResults}>
          <List>
            {filteredUserList.map(item => {
              return (
                <ListItem
                  key={item.guid}
                  button
                  onClick={() => {
                    handleOnSelect(item);
                  }}
                >
                  <ListItemText>{item.username}</ListItemText>
                </ListItem>
              );
            })}
            {filteredUserList.length === 0 && (
              <ListItem key={0}>
                <ListItemText primaryTypographyProps={{ variant: 'subtitle1' }}>
                  {i18n.t('searchbar.users.noResult')}
                </ListItemText>
              </ListItem>
            )}
          </List>
        </Paper>
      )}
    </Box>
  );
};

export default SearchSelectUsers;

SearchSelectUsers.defaultProps = {
  maxSelect: undefined,
  userList: undefined,
  selectedUserList: [],
  placeholderText: i18n.t('searchbar.users.placeholderText')
};
