import { FunctionComponent } from 'react';
import { Box, Button } from '@material-ui/core';

interface TopRightButtonI {
  onClick: () => void;
  label: string;
  disabled?: boolean;
}

const TopRightButton: FunctionComponent<TopRightButtonI> = ({
  onClick,
  label,
  disabled
}: TopRightButtonI) => {
  return (
    <Box width="100%" display="flex" justifyContent="flex-end">
      <Button
        color="secondary"
        variant="contained"
        disabled={disabled}
        onClick={onClick}
      >
        {label}
      </Button>
    </Box>
  );
};

export default TopRightButton;

TopRightButton.defaultProps = {
  disabled: false
};
