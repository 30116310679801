import TableBuilder from 'components/Table';
import i18n from 'i18n';
import { FunctionComponent } from 'react';
import { Resource, ProtectedAdminActionField } from 'components/Authorization';

interface FileTypeListI {
  fileTypes: $Lns.FileType[];
  onEdit: (fileType: $Lns.FileType) => void;
  onDelete: (fileType: $Lns.FileType) => void;
}

const FileTypeList: FunctionComponent<FileTypeListI> = ({
  fileTypes,
  onEdit,
  onDelete
}: FileTypeListI) => {
  const rows = [
    i18n.t('pages.administration.fileTypes.tableRow.name'),
    i18n.t('tableRow.actions')
  ];

  const cells = fileTypes.map(fileType => {
    return {
      fields: {
        name: { value: fileType.name },
        guid: { value: fileType.guid, hidden: true },
        actions: {
          value: (
            <ProtectedAdminActionField
              lnsResource={Resource.FILE_TYPE}
              target={fileType.name}
              onEdit={() => onEdit(fileType)}
              onDelete={() => onDelete(fileType)}
            />
          )
        }
      }
    };
  });

  return <TableBuilder rows={rows} cells={cells} />;
};

export default FileTypeList;
