import i18n from 'i18n';
import validator from 'validator';

export const validateForm = (name: string, value: string): string => {
  let error = '';

  if (name === 'port' || name === 'timeout') {
    error = !validator.isNumeric(value) ? i18n.t('formErrors.isNumeric') : '';
  }

  if (name === 'host') {
    error = !validator.isIP(value) ? i18n.t('formErrors.isIp') : '';
  }

  return error;
};
