export const findTypeName = (
  tarGuid: string,
  typeItems: $Lns.PeripheralType[]
): string | undefined => {
  const typeSearched = typeItems.find(typeItem => typeItem.guid === tarGuid);
  if (typeSearched) return typeSearched.name;
  return undefined;
};

export const findProtocolName = (
  tarGuid: string,
  protocols: $Lns.Protocol[]
): string | undefined => {
  const protocolSearched = protocols.find(
    protocolItem => protocolItem.guid === tarGuid
  );

  if (protocolSearched) return protocolSearched.name;
  return undefined;
};

export const findProtocol = (
  tarGuid: string,
  protocols: $Lns.Protocol[]
): $Lns.Protocol | undefined => {
  const protocolSearched = protocols.find(
    protocolItem => protocolItem.guid === tarGuid
  );

  if (protocolSearched) return protocolSearched;
  return undefined;
};

export const findTemplate = (
  tarGuid: string,
  templates: $Lns.PeripheralTemplate[]
): $Lns.PeripheralTemplate | undefined => {
  const templateSearched = templates.find(
    templateItem => templateItem.guid === tarGuid
  );

  if (templateSearched) return templateSearched;
  return undefined;
};
