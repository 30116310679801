import i18n from 'i18n';
import { StoryFormData } from 'pages/stories/interface';

const validateForm = (name: string, value: string): string => {
  let error = '';

  if (name === 'title') {
    error = value.length < 3 ? i18n.t('formErrors.minLength') : '';
  }

  if (name === 'slug') {
    error = value.length < 3 ? i18n.t('formErrors.minLength') : '';
  }

  if (name === 'notes' || name === 'text') {
    error = value.length < 3 ? i18n.t('formErrors.minLength') : '';
  }

  return error;
};

export const getInitialState = (story?: $Lns.Story): StoryFormData => {
  return {
    title: story?.title || '',
    notes: story?.notes || '',
    calculatedDuration: story?.plannedDuration || 0,
    plannedDuration: story?.plannedDuration || 0,
    slug: story?.slug || '',
    type: story?.type || '',
    text: story?.text || '',
    script: story?.script || '[]',
    guid: story?.guid || '',
    pools: story?.pools || [],
    location: story?.location || '',
    genre: story?.genre || '',
    storyState: story?.storyState || '',
    errors: {
      title: '',
      slug: '',
      type: ''
    }
  };
};

export const getStoryState = (
  guid: string,
  storyStates: $Lns.StoryState[]
): $Lns.StoryState | undefined => {
  return storyStates.find(st => st.guid === guid);
};

export type State = {
  formData: StoryFormData;
  story: $Lns.Story | undefined;
  rundown: $Lns.Rundown | undefined;
};

type Action =
  | {
      type: 'setStory';
      payload: $Lns.Story | undefined;
    }
  | {
      type: 'setRundown';
      payload: $Lns.Rundown | undefined;
    }
  | {
      type: 'setFormData';
      payload: StoryFormData;
    };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setStory': {
      return {
        ...state,
        story: action.payload
      };
    }
    case 'setRundown': {
      return {
        ...state,
        rundown: action.payload
      };
    }
    case 'setFormData': {
      return {
        ...state,
        formData: action.payload
      };
    }
    default:
      return state;
  }
};

export default validateForm;
