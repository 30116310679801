import { MULTIPART_FORM_DATA, APPLICATION_JSON } from 'constants/apiConstants';

interface Options {
  method?: string;
  mode: 'cors';
  credentials: 'same-origin';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers: any;
  body?: string;
  host?: string;
  hostOverride?: string;
}

export const callLnsApi = (
  path: string,
  method?: string,
  // eslint-disable-next-line
  data?: any,
  contentType?: string
  // eslint-disable-next-line
): Promise<any> => {
  const host = `${process.env.REACT_APP_LNS_SERVER}/api` || '';
  return callApi(host, path, method, data, contentType);
};

export const callSsoApi = (
  path: string,
  method?: string,
  // eslint-disable-next-line
  data?: any
  // eslint-disable-next-line
): Promise<any> => {
  const host = `${process.env.REACT_APP_SSO_SERVER}/api` || '';
  return callApi(host, path, method, data);
};

export const callMockApi = (
  path: string,
  method?: string,
  // eslint-disable-next-line
  data?: any
  // eslint-disable-next-line
): Promise<any> => {
  const host = process.env.REACT_APP_MOCK_API_URL || '';
  return callApi(host, path, method, data);
};

const callApi = (
  host: string,
  path: string,
  method = 'GET',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any = null,
  contentType = APPLICATION_JSON
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const url = [host, path].join('');

  const options: Options = {
    method,
    mode: 'cors',
    credentials: 'same-origin',
    headers: { 'Content-Type': contentType }
  };

  const token = localStorage.getItem('token');

  if (data && contentType === APPLICATION_JSON) {
    options.body = JSON.stringify(data); // body data type must match "Content-Type" header
  } else {
    options.body = data; // body data type must match "Content-Type" header
    if (contentType === MULTIPART_FORM_DATA)
      delete options.headers['Content-Type'];
  }

  if (token) {
    options.headers.Authorization = `Bearer ${token}`;
  }

  return fetch(url, options).then(r => {
    const statusToReject = [401, 404, 500];
    if (statusToReject.includes(r.status)) {
      return new Promise((resolve, reject) => {
        r.json().then(errorData => {
          reject(new Error(`error ${errorData.code}: ${errorData.message}`));
        });
      });
    }
    if (r.status === 204) return undefined; // success case for delete
    return r.json(); // success case for everything else
  });
};

export default callApi;
