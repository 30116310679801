import { Alert, AlertTitle } from '@material-ui/lab';
import { FunctionComponent } from 'react';

export const Unauthorized: FunctionComponent = () => {
  return (
    <Alert severity="warning" data-test-id="error-unauthorized">
      <AlertTitle>Unauthorized</AlertTitle>
      You are not authorized to view this page —{' '}
      <strong>contact admin to request permissions</strong>
    </Alert>
  );
};
