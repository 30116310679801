export const findTaskByGuid = (
  searchGuid: string,
  tasks: $Lns.Task[]
): $Lns.Task | undefined => {
  const taskSearched = tasks.find(taskItem => taskItem.guid === searchGuid);
  if (taskSearched) return taskSearched;
  return undefined;
};

export const findTaskStateByGuid = (
  searchGuid: string,
  taskStates: $Lns.TaskState[]
): $Lns.TaskState | undefined => {
  const taskStateSearched = taskStates.find(
    taskStateItem => taskStateItem.guid === searchGuid
  );

  if (taskStateSearched) return taskStateSearched;
  return undefined;
};

export const findTaskStateGuidByName = (
  searchName: string,
  taskStates: $Lns.TaskState[]
): string | undefined => {
  const taskStateSearched = taskStates.find(
    taskStateItem => taskStateItem.name === searchName
  );

  if (taskStateSearched) return taskStateSearched.guid;
  return undefined;
};

export const findStoryStateNameByGuid = (
  searchGuid: string,
  storyStates: $Lns.StoryState[]
): string => {
  if (storyStates && storyStates.length === 0) return 'loading';

  const storyStateSearched = storyStates.find(
    storyStatesItem => storyStatesItem.guid === searchGuid
  );

  if (storyStateSearched) return storyStateSearched.name;
  return 'unknown';
};
