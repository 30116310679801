import { makeStyles } from '@material-ui/core';
import colors from 'styles/colors';

export const systemSetupStyles = makeStyles(() => ({
  heading: {
    color: colors.primary[500],
    borderBottom: '2px solid',
    borderColor: colors.primary[500],
    marginBottom: '14px',
    marginTop: '14px'
  }
}));
