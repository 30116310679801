import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from 'redux/reducers/types';
import { Reducer } from 'redux';

interface Action {
  notification: {
    text: string;
    type: string;
  };
  type: string;
}

const initialState = {
  notification: undefined
};

const NotificationReducer: Reducer<$Lns.Notification, Action> = (
  state = initialState,
  action: Action
) => {
  const { type } = action;
  switch (type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: action.notification
      };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: undefined
      };
    default:
      return state;
  }
};

export default NotificationReducer;
