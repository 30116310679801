import { FunctionComponent, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { makeCells } from 'pages/stories/components/utils';
import AssetDuration from 'pages/stories/components/Asset/AssetDuration';
import TableBuilder from 'components/Table';
import MediaItem from 'pages/stories/components/Asset/MediaItem';
import i18n from 'i18n';
import {
  INIT_DURATION_SECONDS,
  INIT_START_DURATION_SECONDS
} from 'pages/stories/components/Asset/constants';

interface AssetFormI {
  asset: $Lns.PeripheralAsset;
  storyLock: boolean;
  assetDetail: $Lns.ObjectKey[];
  onSave: (totalDuration: number, trackPoints: number[]) => void;
  onCancel: () => void;
}

const AssetForm: FunctionComponent<AssetFormI> = ({
  asset,
  storyLock,
  assetDetail,
  onSave,
  onCancel
}: AssetFormI) => {
  const cells = makeCells(assetDetail);
  const [assetDuration, setAssetDuration] = useState<number>();

  const rows = Object.keys(assetDetail[0]).map(item => {
    return item.toLocaleUpperCase();
  });

  const assetDurationObj = {
    totalDuration: INIT_DURATION_SECONDS,
    trackPoints: [INIT_START_DURATION_SECONDS, INIT_DURATION_SECONDS]
  };

  const onLoadedAssetCb = (element: React.ChangeEvent<HTMLVideoElement>) => {
    const duration = Math.round(element.target.duration || 0);
    setAssetDuration(duration);
  };

  const handleAssetDuration = (
    totalDuration: number,
    trackPoints: number[]
  ) => {
    assetDurationObj.totalDuration = totalDuration;
    assetDurationObj.trackPoints = trackPoints;
  };

  const onSelect = () => {
    onSave(assetDurationObj.totalDuration, assetDurationObj.trackPoints);
  };

  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MediaItem
            peripheralAsset={asset}
            onLoadedVideoCb={onLoadedAssetCb}
          />
        </Grid>
        <Grid item xs={12}>
          {(asset.type === 'Audio' || asset.type === 'Video') && (
            <Grid item container justify="center">
              <TableBuilder rows={rows} cells={cells} />
              {assetDuration && (
                <AssetDuration
                  duration={assetDuration}
                  clipStartSec={0}
                  clipStopSec={assetDuration}
                  onChangeHandle={handleAssetDuration}
                />
              )}
            </Grid>
          )}
        </Grid>

        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={storyLock}
              color="secondary"
              variant="contained"
              onClick={onSelect}
            >
              {i18n.t('button.select')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetForm;
