import { FormData, defaultState } from 'pages/assignments/formData';

export const LnsTaskToFormData = (task: $Lns.Task): FormData => {
  return {
    guid: task.guid,
    title: task.title,
    description: task.description,
    story:
      typeof task.story !== 'string' && typeof task.story !== 'undefined'
        ? task.story.guid
        : task.story || '',
    storyObj: typeof task.story !== 'string' ? task.story : undefined,
    assignedAt: task.assignedAt,
    dateDue: task.dateDue,
    assignedBy:
      typeof task.assignedBy !== 'string' &&
      typeof task.assignedBy !== 'undefined'
        ? task.assignedBy.guid
        : task.assignedBy || '',
    assignedByObj:
      typeof task.assignedBy !== 'string' ? task.assignedBy : undefined,
    assignedTo:
      typeof task.assignedTo !== 'string' &&
      typeof task.assignedTo !== 'undefined'
        ? task.assignedTo.guid
        : task.assignedTo || '',
    assignedToObj:
      typeof task.assignedTo !== 'string' ? task.assignedTo : undefined,
    priority: task.priority,
    taskState:
      typeof task.taskState !== 'string' &&
      typeof task.taskState !== 'undefined'
        ? task.taskState.guid
        : task.taskState || '',
    taskStateObj:
      typeof task.taskState !== 'string' ? task.taskState : undefined,
    errors: defaultState.errors
  };
};
