import { Box, Button, Card, Typography } from '@material-ui/core';
import CustomTextField from 'components/CustomTextField';
import LoadingButton from 'components/LoadingButton';
import i18n from 'i18n';
import validateForm from 'pages/signin/utils';
import { validatePasswordConfirm } from 'pages/signup/utils';
import { FunctionComponent, ChangeEvent, useState } from 'react';

interface FormI {
  onSave: (password: string) => void;
  onCancel: () => void;
  isLoading: boolean;
}

const Form: FunctionComponent<FormI> = ({
  onSave,
  onCancel,
  isLoading
}: FormI) => {
  const [formData, setFormData] = useState({
    password: '',
    passwordConfirm: '',
    errors: {
      password: '',
      passwordConfirm: ''
    }
  });

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setFormData({
      ...formData,
      errors: {
        ...formData.errors,
        [name]: validateForm(name, value),
        passwordConfirm: validatePasswordConfirm(formData, name, value)
      },
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  const btnDisabled =
    Object.values(formData.errors).some(e => !!e) ||
    !formData.password ||
    !formData.passwordConfirm;

  return (
    <Card raised elevation={8}>
      <Box mt={2} display="flex" justifyContent="center">
        <Typography variant="h5" color="primary">
          {i18n.t('pages.passwordReset.typography.title')}
        </Typography>
      </Box>
      <Box padding={2}>
        <CustomTextField
          label={i18n.t('pages.signup.formLabel.password')}
          name="password"
          type="password"
          helperText={i18n.t('pages.signup.helperText.password')}
          value={formData.password}
          error={!!formData.errors.password}
          onChange={onChangeField}
        />

        <CustomTextField
          label={i18n.t('pages.signup.formLabel.passwordConfirm')}
          name="passwordConfirm"
          type="password"
          helperText={i18n.t('pages.signup.helperText.passwordConfirm')}
          value={formData.passwordConfirm}
          error={!!formData.errors.passwordConfirm}
          onChange={onChangeField}
        />

        <Box display="grid" gridAutoFlow="column" gridGap={10} pt={4}>
          <Button
            style={{ maxWidth: 'min-content', justifySelf: 'start' }}
            fullWidth
            variant="contained"
            color="default"
            onClick={onCancel}
          >
            {i18n.t('button.cancel')}
          </Button>
          <LoadingButton
            style={{ maxWidth: 'min-content', justifySelf: 'end' }}
            label={i18n.t('button.send')}
            isLoading={isLoading}
            type="submit"
            fullWidth
            disabled={btnDisabled}
            variant="contained"
            color="secondary"
            onClick={() => onSave(formData.password)}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default Form;
