import React, { createRef, FunctionComponent } from 'react';
import shortid from 'shortid';
import tableStyles from 'styles/table';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { DropResult } from 'react-beautiful-dnd';
import { TableCellI } from 'interfaces/tableCell';
import { StyledTableCell } from 'components/StyledTableCell';
import { StyledTableRow } from 'components/StyledTableRow';
import DroppableComponent from 'components/DroppableComponent';
import DraggableComponent from 'components/DraggableComponent';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

interface TableBuilderI {
  rows: string[];
  cells: TableCellI[];
  // eslint-disable-next-line
  onRowClick?: (rowItem: any) => void;
  onDragComplete?: (result: DropResult) => void;
  isDraggable?: boolean;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const classes = tableStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
};

const TableBuilder: FunctionComponent<TableBuilderI> = ({
  rows,
  cells,
  isDraggable,
  onDragComplete,
  onRowClick,
  ...props
}: TableBuilderI) => {
  const classes = tableStyles();
  const tableRef = createRef<HTMLDivElement>();

  const onDragEnd = (result: DropResult) => {
    if (!onDragComplete) return;
    onDragComplete(result);
  };

  const RowClickNotAllow = ['actions'];

  // eslint-disable-next-line
  const onCellClick = (cell: any, fields: any) => {
    if (
      (typeof cell.value === 'string' || typeof cell.value === 'number') &&
      !RowClickNotAllow.includes(cell.value)
    ) {
      if (onRowClick) onRowClick(fields);
    }
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer
      className={classes.tableContainer}
      ref={tableRef}
      {...props}
    >
      <Table size="small" stickyHeader aria-label="simple table">
        <TableHead>
          {cells.length > 10 && (
            <TableRow className={classes.footerRow}>
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, { label: 'All', value: -1 }]}
                colSpan={rows.length}
                count={cells.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          )}
          <TableRow>
            {rows.map(row => {
              return (
                <StyledTableCell key={shortid.generate()} align="center">
                  {row}
                </StyledTableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody component={DroppableComponent(onDragEnd)}>
          {(rowsPerPage > 0
            ? cells.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : cells
          ).map((cell, index) => {
            const keys = Object.keys(cell.fields);
            const id = shortid.generate();
            return (
              <StyledTableRow
                component={DraggableComponent(id, index, !!isDraggable)}
                selected={cell.selected}
                key={id}
              >
                {keys.map(key => {
                  const cellItem = cell.fields[key];
                  if (cellItem.hidden) return <React.Fragment key={key} />;
                  return (
                    <TableCell
                      key={shortid.generate()}
                      align="left"
                      onClick={() => {
                        onCellClick(cellItem, cell.fields);
                      }}
                    >
                      <Box maxWidth="200px">
                        <Typography
                          variant="inherit"
                          noWrap
                          component="div"
                          color="textSecondary"
                        >
                          {cellItem.value}
                        </Typography>
                      </Box>
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableBuilder;

TableBuilder.defaultProps = {
  onRowClick: undefined,
  isDraggable: false,
  onDragComplete: undefined
};
