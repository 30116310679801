import i18n from 'i18n';
import validator from 'validator';

export const validateForm = (name: string, value: string): string => {
  let error = '';

  if (name === 'name' || name === 'key') {
    error = validator.isEmpty(value) ? i18n.t('formErrors.isEmpty') : '';
    error = !validator.isAlphanumeric(value)
      ? i18n.t('formErrors.isAlphanumeric')
      : '';
  }
  return error;
};
