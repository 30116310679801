export const filterUsers = (
  filterString: string,
  listToFilter: $Lns.User[],
  selectedUsers: $Lns.User[]
): $Lns.User[] => {
  // list of search terms to be filter for
  const filterKeys = filterString.split(' ').filter(item => item);
  // list guids that are selected should be ommited
  const selectedUsersGuids = selectedUsers.map(user => user.guid);
  // the loop that actually checks for the onditions
  return listToFilter.filter((user: $Lns.User) => {
    const selected = selectedUsersGuids.includes(user.guid);
    const nameString = user.username;
    return (
      !selected &&
      filterKeys.every(filterKey =>
        nameString.includes(filterKey.toLowerCase())
      )
    );
  });
};
