import { callLnsApi } from 'utils/fetch';
import { FormData as TypeFormData } from 'pages/administration/peripheral-settings/types/formData';
import { FormData as PeripheralFormData } from 'pages/administration/peripheral-settings/peripherals/formData';
import { FormData as ProtocolFormData } from 'pages/administration/peripheral-settings/protocols/formData';

// PERIPHERAL TYPES
export const fetchPeripheralTypes = (): Promise<{
  code: number;
  data: $Lns.PeripheralType[];
}> => callLnsApi('/peripheral-type');

export const createPeripheralType = (
  typeItem: TypeFormData
): Promise<{
  code: number;
  data: $Lns.PeripheralType;
}> => callLnsApi('/peripheral-type/', 'POST', typeItem);

export const updatePeripheralType = (
  typeItem: TypeFormData
): Promise<{
  code: number;
  data: $Lns.PeripheralType;
}> => callLnsApi(`/peripheral-type/${typeItem.guid}`, 'POST', typeItem);

export const deletePeripheralType = (
  typeItem: $Lns.PeripheralType
): Promise<{ code: number; message?: string }> =>
  callLnsApi(`/peripheral-type/${typeItem.guid}`, 'DELETE');

// PERIPHERAL TEMPLATES
export const fetchPeripheralTemplates = (): Promise<{
  code: number;
  data: $Lns.PeripheralTemplate[];
}> => callLnsApi('/peripheral-template');

export const createPeripheralTemplate = (
  templateItem: PeripheralFormData
): Promise<{
  code: number;
  data: $Lns.PeripheralTemplate;
}> => callLnsApi('/peripheral-template/', 'POST', templateItem);

export const updatePeripheralTemplate = (
  templateItem: PeripheralFormData
): Promise<{
  code: number;
  data: $Lns.PeripheralTemplate;
}> =>
  callLnsApi(`/peripheral-template/${templateItem.guid}`, 'POST', templateItem);

export const deletePeripheralTemplate = (
  templateItem: $Lns.PeripheralTemplate
): Promise<{ code: number; message?: string }> =>
  callLnsApi(`/peripheral-template/${templateItem.guid}`, 'DELETE');

// PERIPHERALS
export const fetchPeripherals = (): Promise<{
  code: number;
  data: $Lns.Peripheral[];
}> => callLnsApi('/peripheral');

export const createPeripheral = (
  peripheral: PeripheralFormData
): Promise<{
  code: number;
  data: $Lns.PeripheralTemplate;
}> => callLnsApi('/peripheral/', 'POST', peripheral);

export const updatePeripheral = (
  peripheral: PeripheralFormData
): Promise<{
  code: number;
  data: $Lns.Peripheral;
}> => callLnsApi(`/peripheral/${peripheral.guid}`, 'POST', peripheral);

export const deletePeripheral = (
  peripheral: $Lns.Peripheral
): Promise<{ code: number; message?: string }> =>
  callLnsApi(`/peripheral/${peripheral.guid}`, 'DELETE');

// PROTOCOLS
export const fetchProtocols = (): Promise<{
  code: number;
  data: $Lns.Protocol[];
}> => callLnsApi('/protocol');

export const createProtocol = (
  protocol: ProtocolFormData
): Promise<{
  code: number;
  data: $Lns.Protocol;
}> => callLnsApi('/protocol/', 'POST', protocol);

export const updateProtocol = (
  protocol: ProtocolFormData
): Promise<{
  code: number;
  data: $Lns.Protocol;
}> => callLnsApi(`/protocol/${protocol.guid}`, 'POST', protocol);

export const deleteProtocol = (
  protocol: $Lns.Protocol
): Promise<{ code: number; message?: string }> =>
  callLnsApi(`/protocol/${protocol.guid}`, 'DELETE');
