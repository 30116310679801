export interface FormData {
  id?: number;
  guid?: string;
  title: string;
  description: string;
  story?: string;
  storyObj?: $Lns.Story;
  dateDue: string;
  dateDueObj?: Date;
  assignedTo?: string;
  assignedToObj?: $Lns.User;
  assignedBy?: string;
  assignedByObj?: $Lns.User;
  assignedAt: string;
  assignedAtObj?: Date;
  priority: number;
  taskState: string;
  taskStateObj?: $Lns.TaskState;
  errors: { [key: string]: string };
}

export const defaultState = {
  title: '',
  description: '',
  story: '',
  dateDue: '',
  assignedTo: '',
  assignedBy: '',
  assignedAt: '',
  priority: 0,
  taskState: '',
  errors: {
    title: '',
    description: '',
    story: '',
    dateDue: '',
    assignedTo: '',
    assignedBy: '',
    assignedAt: '',
    priority: '',
    taskState: ''
  }
};
