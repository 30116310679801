import { Box, Container, Grid } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';
import logoImg from 'images/lns-logo-primary-color.png';

interface WrapperI {
  children: ReactNode;
}

const Wrapper: FunctionComponent<WrapperI> = ({ children }: WrapperI) => {
  return (
    <Box display="grid" alignContent="center" mt={5}>
      <Container component="main" maxWidth="sm">
        <Grid container spacing={4} justify="center" alignContent="center">
          <Grid item>
            <Box display="flex" justifyContent="center">
              <img alt="logo" src={logoImg} width="25%" height="25%" />
            </Box>
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Wrapper;
