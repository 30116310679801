import { FunctionComponent, useState } from 'react';
import { IconButton, Box } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import ContextMenu from 'components/contextMenu';

export interface ThreeDotMenuI {
  title: string;
  action: () => void;
}

interface MenuInit {
  initVals: ThreeDotMenuI[];
}

const DotMenu: FunctionComponent<MenuInit> = ({ initVals }: MenuInit) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 500);
  };

  const contextMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  return (
    <IconButton
      id="more"
      aria-label="more"
      onClick={contextMenu}
      onBlur={handleClose}
      style={{ padding: 0 }}
    >
      <MoreVert />
      {anchorEl && (
        <Box
          position="absolute"
          left={0}
          top={0}
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          <ContextMenu items={[...initVals]} />
        </Box>
      )}
    </IconButton>
  );
};

export default DotMenu;
