import FormData from 'pages/administration/security/users/userFormData';
import { FONT_FAMILIES } from 'constants/fontSettings';

export type State = {
  viewUser: $Lns.User | undefined;
  userGroups: $Lns.UserGroup[] | undefined;
  userPermission: $Lns.CurrentUserPermissions[] | undefined;
  userStates: $Lns.UserState[] | undefined;
  isMe: boolean;
  canEdit: boolean;
  isEdit: boolean;
  formData: FormData;
};

type Action =
  | {
      type: 'reset';
      payload: State;
    }
  | {
      type: 'setCurrUser' | 'setViewUser';
      payload: $Lns.User;
    }
  | {
      type: 'setUserGroups';
      payload: $Lns.UserGroup[];
    }
  | {
      type: 'setUserPermission';
      payload: $Lns.CurrentUserPermissions[];
    }
  | {
      type: 'setUserStates';
      payload: $Lns.UserState[];
    }
  | {
      type: 'setGuid' | 'setIsEdit' | 'setCanEdit';
      payload: string;
    }
  | {
      type: 'setFormData';
      payload: FormData;
    };

export const initialState = {
  viewUser: undefined,
  userGroups: undefined,
  userStates: undefined,
  userPermission: undefined,
  isMe: false,
  canEdit: false,
  isEdit: false,
  formData: {
    guid: '',
    username: '',
    email: '',
    title: '',
    team: '',
    contactNumber: '',
    userState: '',
    config: {
      location: '',
      timezone: '',
      language: 'en',
      layoutSettings: {
        font: {
          fontFamily: FONT_FAMILIES[0],
          fontSize: 1,
          fontWeight: 400
        }
      }
    },
    errors: {
      username: '',
      email: '',
      title: '',
      team: '',
      contactNumber: ''
    }
  }
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setViewUser':
      return {
        ...state,
        viewUser: action.payload
      };
    case 'setUserGroups':
      return {
        ...state,
        userGroups: action.payload
      };
    case 'setUserPermission':
      return {
        ...state,
        userPermission: action.payload
      };
    case 'setUserStates':
      return {
        ...state,
        userStates: action.payload
      };
    case 'setFormData':
      return {
        ...state,
        formData: action.payload
      };
    case 'reset':
      return action.payload;
    default:
      return state;
  }
};
