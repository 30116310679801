import {
  addSeconds,
  eachDayOfInterval,
  format,
  isAfter,
  isBefore,
  parse
} from 'date-fns';
import i18n from 'i18n';
import { secondsToDuration } from 'utils/time';
import validator from 'validator';
import { FormData } from './formData';

const getAllBefore = (selectedStory: $Lns.Story, stories: $Lns.Story[]) => {
  const i = stories.indexOf(selectedStory);
  return i > -1 ? stories.slice(0, i) : [];
};

const getAllAfter = (selectedStory: $Lns.Story, stories: $Lns.Story[]) => {
  const i = stories.indexOf(selectedStory);
  return i > -1 ? stories.slice(i, stories.length) : [];
};

export const buildRundownStoryObject = (
  story: $Lns.Story,
  index: number,
  isSpare?: boolean
): $Lns.PlayoutStoryStory => {
  return {
    story: story.guid,
    relativeOrder: index,
    durationSec: story.calculatedDuration,
    note: '',
    isSpare,
    isCgReady: false,
    isPrompterReady: false,
    isVideoReady: false,
    rundownOrder: index
  };
};

export const generateRundownsFromTemplate = (
  formData: FormData,
  rundownTemplates: $Lns.RundownTemplate[]
): $Lns.Rundown[] | null => {
  const template = rundownTemplates.find(
    rt => rt.guid === formData.rundownTemplate
  );

  if (template && formData.startDate && formData.endDate) {
    // get days for selected range start-end
    const days = eachDayOfInterval({
      start: formData.startDate,
      end: formData.endDate
    });

    // build rundowns where there exist a schedule for days range
    const newRundowns = days.map(date => {
      const wday = format(date, 'EEEE').toLowerCase();
      const schedule = template.schedule[wday];
      if (schedule) {
        const playoutDateTime = parse(
          template.playoutStartTime,
          'HH:mm:ss',
          new Date(date)
        );

        const playoutEndTime = parse(
          template.playoutEndTime,
          'HH:mm:ss',
          new Date(date)
        );

        const totalDurationSec =
          (playoutEndTime.valueOf() - playoutDateTime.valueOf()) / 1000;

        return buildRundownFromTemplate(
          template,
          playoutDateTime,
          totalDurationSec,
          formData.category
        );
      }
      return null as unknown as $Lns.Rundown;
    });

    return newRundowns;
  }
  return [];
};

export const buildRundownFromTemplate = (
  template: $Lns.RundownTemplate,
  playoutDateTime: Date,
  totalDurationSec: number,
  category: string
): $Lns.Rundown => {
  return {
    name: [template.name, format(playoutDateTime, 'yyyy-MM-dd')].join('-'),
    rundownTemplate: template.guid,
    category,
    totalDurationSec,
    playoutDateTime: playoutDateTime.toISOString()
  } as $Lns.Rundown;
};

export const validateForm = (
  name: string,
  value: string | Date,
  formData: FormData
): string => {
  let error = '';

  if (name === 'name') {
    error = value.toString().length < 3 ? i18n.t('formErrors.minLength') : '';
  }

  if (name === 'playoutDateTime') {
    error = validator.isDate(value.toString())
      ? i18n.t('formErrors.isDate')
      : '';
  }

  if (name === 'startDate') {
    error =
      formData.endDate && formData.endDate < value
        ? i18n.t('pages.rundowns.formErrors.startDate')
        : '';
  }
  if (name === 'endDate') {
    error =
      formData.startDate && formData.startDate > value
        ? i18n.t('pages.rundowns.formErrors.endDate')
        : '';
  }

  return error;
};

export const computeBackTime = (
  story: $Lns.Story,
  stories: $Lns.Story[],
  rundown: $Lns.Rundown
): string => {
  const nextStories = getAllAfter(story, stories);

  const totalRemainingDuration = nextStories.reduce((total, curr) => {
    return total + curr.calculatedDuration;
  }, 0);

  const startTime = computeHitTime(story, stories, rundown);
  const storyEndTime = addSeconds(startTime, story.calculatedDuration);
  const endTime = addSeconds(startTime, totalRemainingDuration).valueOf();

  const remainingDuration = Math.round(
    (endTime.valueOf() - new Date().valueOf()) / 1000
  );

  if (storyEndTime < new Date()) {
    return secondsToDuration(0);
  }

  const remaining =
    new Date() <= startTime ? totalRemainingDuration : remainingDuration;

  return secondsToDuration(remaining);
};

export const computeHitTime = (
  story: $Lns.Story,
  stories: $Lns.Story[],
  rundown: $Lns.Rundown
): Date => {
  const previousStories = getAllBefore(story, stories);

  if (previousStories) {
    const totalDuration = previousStories.reduce((total, curr) => {
      return total + curr.calculatedDuration;
    }, 0);

    const startTime = new Date(rundown.playoutDateTime);
    return addSeconds(startTime, totalDuration);
  }

  return new Date(rundown.playoutDateTime);
};

export const currentRemainingPercentage = (
  story: $Lns.Story,
  stories: $Lns.Story[],
  rundown: $Lns.Rundown
): number => {
  const start = computeHitTime(story, stories, rundown).valueOf();
  const end = addSeconds(start, story.calculatedDuration).valueOf();
  const now = +new Date();

  return Math.round(((now - start) / (end - start)) * 100);
};

export const computeCmlDuration = (
  story: $Lns.Story,
  stories: $Lns.Story[]
): string => {
  let { calculatedDuration } = story;
  const previousStories = getAllBefore(story, stories);
  if (previousStories) {
    calculatedDuration += previousStories.reduce((total, curr) => {
      return total + curr.calculatedDuration;
    }, 0);
  }

  return secondsToDuration(calculatedDuration);
};

export const isCurrentlyActive = (
  story: $Lns.Story,
  stories: $Lns.Story[],
  rundown: $Lns.Rundown
): boolean => {
  const storyStartTime = computeHitTime(story, stories, rundown);
  const storyEndTime = addSeconds(storyStartTime, story.calculatedDuration);

  return (
    isAfter(new Date(), storyStartTime) && isBefore(new Date(), storyEndTime)
  );
};

export const isOverTime = (
  story: $Lns.Story,
  stories: $Lns.Story[],
  rundown: $Lns.Rundown
): boolean => {
  return computeHitTime(story, stories, rundown) < new Date();
};

export const getTotalDurationSec = (rundownStories: $Lns.Story[]): number => {
  return rundownStories.reduce((a, b) => {
    return b.calculatedDuration + a;
  }, 0);
};

export const formattedPlayoutDate = (rundown: $Lns.Rundown): string => {
  return format(new Date(rundown.playoutDateTime), 'EEE dd.MM, HH:mm');
};
