import { FunctionComponent, useState, useRef } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { ImageAsset } from 'pages/stories/interfaces/imageAsset';
import { AssetType } from 'pages/stories/enums';
import AudioPlayer from 'material-ui-audio-player';
import assetFormStyles from 'pages/stories/styles/assetForm';
import i18n from 'i18n';
import AssetDuration from 'pages/stories/components/Asset/AssetDuration';
import {
  INIT_DURATION_SECONDS,
  INIT_START_DURATION_SECONDS
} from 'pages/stories/components/Asset/constants';

interface AssetI {
  asset: ImageAsset;
  handleChange: (totalDuration: number, trackPoints: number[]) => void;
}

const Asset: FunctionComponent<AssetI> = ({ asset, handleChange }: AssetI) => {
  const classes = assetFormStyles();
  const refElement = useRef<HTMLVideoElement>(null);

  const [assetDuration, setAssetDuration] = useState<number>(
    +asset.durationInSeconds
  );

  const assetDurationObj = {
    totalDuration: asset.totalDurationSec,
    trackPoints: [
      asset?.clipStartSec || INIT_START_DURATION_SECONDS,
      asset?.clipStopSec || INIT_DURATION_SECONDS
    ]
  };

  const onloadAsset = () => {
    if (refElement.current) {
      const duration = Math.round(
        refElement.current?.duration || INIT_START_DURATION_SECONDS
      );
      setAssetDuration(duration);
    }
  };

  const handleAssetDuration = (
    totalDuration: number,
    trackPoints: number[]
  ) => {
    assetDurationObj.totalDuration = totalDuration;
    assetDurationObj.trackPoints = trackPoints;
    handleChange(totalDuration, trackPoints);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {i18n.t('pages.stories.typography.previewAsset')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {asset.type === AssetType.image && (
          <Box width="100%">
            <Box className={`${classes.centerWidth100}`}>
              <img className={classes.root} alt="" src={asset?.proxyUrl} />
            </Box>
          </Box>
        )}
        {asset.type === AssetType.audio && (
          <Box width="100%">
            <audio ref={refElement} onLoadedData={onloadAsset}>
              <source src={asset?.proxyUrl} type="audio/x-wav" />
              <track src="captions_es.vtt" kind="captions" />
            </audio>
            <AudioPlayer src={asset?.proxyUrl} />
          </Box>
        )}
        {asset.type === AssetType.video && (
          <Box width="100%">
            <video
              width="100%"
              height="300"
              ref={refElement}
              controls
              onLoadedData={onloadAsset}
            >
              <source src={asset?.proxyUrl} type="video/mp4" />
              <track src="captions_es.vtt" kind="captions" />
            </video>
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        {assetDuration &&
          (asset.type === AssetType.video ||
            asset.type === AssetType.audio) && (
            <AssetDuration
              duration={assetDuration}
              clipStartSec={assetDurationObj.trackPoints[0]}
              clipStopSec={assetDurationObj.trackPoints[1]}
              onChangeHandle={handleAssetDuration}
            />
          )}
      </Grid>
    </Grid>
  );
};

export default Asset;
