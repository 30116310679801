import i18n from 'i18n';
import validator from 'validator';

export const validateForm = (
  name: string,
  value: string,
  publishFolders: { name: string; guid: string }[]
): string => {
  let error = '';

  error = validator.isEmpty(value) ? i18n.t('formErrors.isEmpty') : '';

  if (publishFolders.some(p => p.name === value)) {
    error = i18n.t('formErrors.isUnique');
  }

  return error;
};
