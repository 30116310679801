import { FunctionComponent } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import i18n from 'i18n';
import DetailDialog from 'components/DetailDialog';

interface ItemDetailI {
  protocolItem: $Lns.Protocol;
  onClose: () => void;
}

const ItemDetail: FunctionComponent<ItemDetailI> = ({
  protocolItem,
  onClose
}: ItemDetailI) => {
  const DialogContent = () => (
    <Box>
      <List>
        <ListItem>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText
            primary={protocolItem.name}
            secondary={i18n.t('pages.administration.protocols.formLabel.name')}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText
            primary={protocolItem.classname}
            secondary={i18n.t(
              'pages.administration.protocols.formLabel.classname'
            )}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText
            primary={JSON.stringify(protocolItem.config, null, 2)}
            primaryTypographyProps={{ variant: 'body1' }}
            secondary={i18n.t(
              'pages.administration.protocols.formLabel.config'
            )}
          />
        </ListItem>
      </List>
    </Box>
  );

  const dialogActions = () => {
    return (
      <Button variant="outlined" color="secondary" onClick={onClose}>
        {i18n.t('button.close')}
      </Button>
    );
  };

  return (
    <DetailDialog
      dialogTitle={protocolItem.name}
      dialogContent={<DialogContent />}
      dialogActions={dialogActions()}
      onHandleClose={onClose}
    />
  );
};

export default ItemDetail;
