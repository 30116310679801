import {
  findTaskByGuid,
  findTaskStateByGuid,
  findTaskStateGuidByName,
  findStoryStateNameByGuid
} from 'pages/assignments/utils/search';
import { LnsTaskToFormData } from 'pages/assignments/utils/formdata';
import {
  opStates as importedOpStates,
  useOpStates
} from 'pages/assignments/utils/opStates';
import { filterAvailableTaskStates } from 'pages/assignments/utils/taskStates';
import { TaskPriority } from 'pages/assignments/consts';

// Re-exports
export type opStates = importedOpStates;
export {
  useOpStates,
  findTaskByGuid,
  findTaskStateByGuid,
  findTaskStateGuidByName,
  findStoryStateNameByGuid,
  filterAvailableTaskStates,
  LnsTaskToFormData
};

// Other Utitilies that doesn't fit into other Categories
export const getTaskPriorityObject = (): {
  keys: string[];
  values: number[];
} => {
  const taskPriorityEnum = Object.keys(TaskPriority);

  const values = taskPriorityEnum
    .slice(0, taskPriorityEnum.length / 2)
    .map(valueItem => valueItem as unknown as number);

  const keys = taskPriorityEnum.slice(
    taskPriorityEnum.length / 2,
    taskPriorityEnum.length
  );

  return { keys, values };
};
