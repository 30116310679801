import { createStyles, makeStyles, Theme } from '@material-ui/core';
import colors from 'styles/colors';

const storiesStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      width: 400
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1
    },
    iconButton: {
      padding: 10
    },
    divider: {
      height: 28,
      margin: 4
    }
  })
);

export const stateStyles = makeStyles((theme: Theme) =>
  createStyles({
    approved: {
      backgroundColor: colors.success.medium,
      color: theme.palette.getContrastText(colors.success.medium)
    },
    pendingApproval: {
      backgroundColor: colors.warning.medium,
      color: theme.palette.getContrastText(colors.warning.medium)
    },
    draft: {
      backgroundColor: colors.info.medium,
      color: theme.palette.getContrastText(colors.info.medium)
    }
  })
);

export const editStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'sticky',
      top: theme.spacing(6),
      background: '#fff',
      zIndex: 2
    }
  })
);

export const taskStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    }
  })
);

export default storiesStyles;
