import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { AssetType } from 'pages/stories/enums';
import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';
import i18n from 'i18n';
import DialogueWrapper from 'pages/stories/components/Dialogue/Wrapper';
import { ScriptAsset } from 'pages/stories/interfaces/scriptAsset';
import { ImageAsset } from 'pages/stories/interfaces/imageAsset';
import { buildScriptRows } from 'pages/stories/utils/asset';
import { StoryFormData } from 'pages/stories/interface';
import { ScriptRows } from 'pages/stories/interfaces/scriptRow';
import { componentStyle } from 'styles/componentStyle';

interface DialogueForm1 {
  initVal: AssetDynamic[];
  formData: StoryFormData;
}

const ProducerDialogue: FunctionComponent<DialogueForm1> = ({
  initVal,
  formData
}: DialogueForm1) => {
  const [prodScript, setProdScript] = useState<ScriptRows[]>([]);
  const generalStyles = componentStyle();

  const renderSubItems = (row: ScriptRows) => {
    if (row.dialogues) {
      let voiceOverObj = [...row.dialogues] as ScriptAsset[];
      if (voiceOverObj.length === 0) {
        if (row.Asset.type === AssetType.dialogue) {
          voiceOverObj = [row.Asset] as ScriptAsset[];
        }
      }
      return (
        <>
          {voiceOverObj.map(voiceOverObjItem => {
            return (
              <Box marginBottom={1} padding={0}>
                <Typography variant="subtitle1">
                  <Typography component="span">
                    {voiceOverObjItem.title}:
                  </Typography>
                  <Typography
                    className={generalStyles.wordBreak}
                    variant="inherit"
                    component="span"
                  >
                    {voiceOverObjItem.script.text}
                  </Typography>
                </Typography>
              </Box>
            );
          })}
        </>
      );
    }
    return <></>;
  };

  const getAssetName = (row: ScriptRows): string => {
    if (row.Asset.type !== AssetType.dialogue) {
      const asset = { ...row.Asset } as ImageAsset;
      return asset.title;
    }
    return '';
  };

  useEffect(() => {
    const scriptRows = buildScriptRows(initVal);
    setProdScript(scriptRows);
  }, [initVal]);

  const columns = [
    {
      headerName: i18n.t('pages.stories.tabLabel.relativeTime'),
      width: '20%',
      content: (row: ScriptRows) => row.relativeTime
    },
    {
      headerName: i18n.t('pages.stories.tabLabel.notes'),
      width: '20%',
      content: () => ''
    },
    {
      headerName: i18n.t('pages.stories.tabLabel.videos'),
      width: '30%',
      content: getAssetName
    },
    {
      headerName: i18n.t('pages.stories.tabLabel.audio'),
      width: '30%',
      content: renderSubItems
    }
  ];

  return (
    <Box padding={4}>
      <Grid container spacing={0}>
        <Box display="flex" marginBottom={2} flexDirection="column">
          <Box width="100%" display="flex" marginBottom={1}>
            <DialogueWrapper title="Story" value={formData.title} />
          </Box>
          <Box width="100%" display="flex" marginBottom={1}>
            <DialogueWrapper title="Slug" value={formData.slug} />
          </Box>
        </Box>
        <Box width="100%" display="flex">
          {columns.map(header => {
            return (
              <Box
                padding={2}
                width={header.width}
                textAlign="center"
                border="2px solid grey"
              >
                <Typography className={generalStyles.wordBreak} component="h4">
                  {header.headerName}
                </Typography>
              </Box>
            );
          })}
        </Box>
        {prodScript.map(producerScriptItem => {
          return (
            <Box width="100%" display="flex">
              {columns.map(col => {
                return (
                  <Box padding={2} width={col.width} border="1px solid grey">
                    <Typography
                      className={generalStyles.wordBreak}
                      variant="inherit"
                      component="span"
                    >
                      {col.content(producerScriptItem)}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ProducerDialogue;
