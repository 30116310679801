import { TableBody } from '@material-ui/core';
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided
} from 'react-beautiful-dnd';

const DroppableComponent =
  (
    onDragEnd: (result: DropResult, provided: ResponderProvided) => void
    // eslint-disable-next-line
  ) =>
  // eslint-disable-next-line
  (props: any) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="1" direction="vertical">
          {provided => {
            return (
              <TableBody
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...props}
              >
                {props.children}
                {provided.placeholder}
              </TableBody>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  };

export default DroppableComponent;
