import { callLnsApi } from 'utils/fetch';

export const fetchStoryGenres = (): Promise<{
  code: number;
  data: $Lns.StoryGenre[];
}> => callLnsApi('/story-genre');

export const createStoryGenre = (
  storyGenre: $Lns.StoryGenre
): Promise<{
  code: number;
  data: $Lns.StoryGenre;
}> => callLnsApi('/story-genre/', 'POST', storyGenre);

export const updateStoryGenre = (
  storyGenre: $Lns.StoryGenre
): Promise<{
  code: number;
  data: $Lns.StoryGenre;
}> => callLnsApi(`/story-genre/${storyGenre.guid}`, 'POST', storyGenre);

export const deleteStoryGenre = (
  storyGenre: $Lns.StoryGenre
): Promise<{
  code: number;
}> => callLnsApi(`/story-genre/${storyGenre.guid}`, 'DELETE');
