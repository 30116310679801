import { callLnsApi } from 'utils/fetch';

export const fetchStoryPools = (): Promise<{
  code: number;
  data: $Lns.StoryPool[];
}> => callLnsApi('/pool');

export const createStoryPool = (
  storyPool: $Lns.StoryPool
): Promise<{
  code: number;
  data: $Lns.StoryPool;
}> => {
  return callLnsApi('/pool', 'POST', storyPool);
};

export const updateStoryPool = (
  storyPool: $Lns.StoryPool
): Promise<{
  code: number;
  data: $Lns.StoryPool;
}> => {
  return callLnsApi(`/pool/${storyPool.guid}`, 'POST', storyPool);
};

export const updateStoryPoolStories = (data: {
  pool: string;
  stories: { story: string }[];
}): Promise<{
  code: number;
  data: $Lns.StoryPool;
}> => {
  return callLnsApi(`/pool/${data.pool}/stories`, 'POST', data);
};

export const deleteStoryPool = (
  storyPool: $Lns.StoryPool
): Promise<{ code: number; message?: string }> =>
  callLnsApi(`/pool/${storyPool.guid}`, 'DELETE');

export const fetchStoryPoolStories = (
  storyPoolGuid: string
): Promise<{ code: number; data: $Lns.Story[] }> => {
  return callLnsApi(`/pool/${storyPoolGuid}/story`);
};
