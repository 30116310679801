import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import { Grid } from '@material-ui/core';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import SecurityIcon from '@material-ui/icons/Security';

const Security: FunctionComponent = () => {
  const navigate = useNavigate();

  const onRowClick = (rowItem: { guid: { value: string } }) => {
    navigate(rowItem.guid.value);
  };

  const cells = [
    {
      fields: {
        name: {
          value: i18n.t('pages.administration.security.tableCell.users')
        },
        guid: { value: '/administration/security/users', hidden: true }
      }
    },
    {
      fields: {
        name: {
          value: i18n.t('pages.administration.security.tableCell.userGroups')
        },
        guid: { value: '/administration/security/user-groups', hidden: true }
      }
    }
  ];

  return (
    <Grid container spacing={2}>
      <TitleBarWraper>
        <Grid item xs={6}>
          <TitleWaper
            title={i18n.t('links.sidebarNestedItems.administration.security')}
            subText={i18n.t('pages.stories.formLabel.title')}
            icon={<SecurityIcon color="primary" />}
          />
        </Grid>
      </TitleBarWraper>
      <Grid item xs={12}>
        <TableBuilder
          rows={['Sub-menu']}
          cells={cells}
          onRowClick={onRowClick}
        />
      </Grid>
    </Grid>
  );
};

export default Security;
