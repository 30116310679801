import { FunctionComponent, ReactNode, useEffect } from 'react';
import { fetchStoryPools } from 'actions/storyPools';
import { setInitialData } from 'actions/default';
import { useDispatch } from 'react-redux';
import { fetchStoryGenres } from 'actions/storyGenres';
import { fetchStoryTypes } from 'actions/storyTypes';
import { fetchStoryStates } from 'pages/assignments/actions';
import { fetchStoryCategories } from 'actions/storyCategory';
import { fetchLocations } from 'actions/location';
import { fetchTaskStates } from 'actions/task';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';

interface DataLoaderI {
  children: ReactNode;
}

const DataLoader: FunctionComponent<DataLoaderI> = ({
  children
}: DataLoaderI) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPromises: [
      Promise<{ data: $Lns.StoryPool[] } | void>,
      Promise<{ data: $Lns.StoryGenre[] } | void>,
      Promise<{ data: $Lns.StoryType[] } | void>,
      Promise<{ data: $Lns.StoryCategory[] } | void>,
      Promise<{ data: $Lns.StoryState[] } | void>,
      Promise<{ data: $Lns.Location[] } | void>,
      Promise<{ data: $Lns.TaskState[] } | void>
    ] = [
      fetchStoryPools(),
      fetchStoryGenres(),
      fetchStoryTypes(),
      fetchStoryCategories(),
      fetchStoryStates(),
      fetchLocations(),
      fetchTaskStates()
    ];

    Promise.all(fetchPromises)
      .then(
        ([pools, genres, types, categories, states, locations, taskStates]) => {
          if (
            pools &&
            genres &&
            states &&
            types &&
            categories &&
            locations &&
            taskStates
          )
            dispatch(
              setInitialData({
                storyPools: pools.data,
                storyStates: states.data,
                storyGenres: genres.data,
                storyTypes: types.data,
                storyCategories: categories.data,
                locations: locations.data,
                taskStates: taskStates.data
              })
            );
        }
      )
      .catch(() => {
        dispatch(
          toggleNotification(i18n.t('notifications.dataLoader.error'), 'error')
        );
      });
  }, [dispatch]);

  return <div>{children}</div>;
};

export default DataLoader;
