import { FunctionComponent } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { DropResult } from 'react-beautiful-dnd';
import { StyledTableCell } from 'components/StyledTableCell';
import shortid from 'shortid';
import tableStyles from 'styles/table';
import PlayoutStory from 'pages/rundowns/components/PlayoutStoryRow';
import DroppableComponent from 'components/DroppableComponent';
import EmptyState from 'components/EmptyState';
import i18n from 'i18n';

interface PlayoutStoriesI {
  playoutStories: $Lns.PlayoutStory[];
  peripherals: $Lns.Peripheral[];
  rundown: $Lns.Rundown;
  onDragComplete: (result: DropResult) => void;
  onSpareClick: (story: $Lns.PlayoutStory) => void;
}

const PlayoutStories: FunctionComponent<PlayoutStoriesI> = ({
  playoutStories,
  rundown,
  peripherals,
  onSpareClick,
  onDragComplete
}: PlayoutStoriesI) => {
  const rows = [
    i18n.t('pages.rundowns.tableRow.img'),
    i18n.t('pages.rundowns.tableRow.peripherals'),
    i18n.t('pages.rundowns.tableRow.slug'),
    i18n.t('pages.rundowns.tableRow.title'),
    i18n.t('pages.rundowns.tableRow.duration'),
    i18n.t('pages.rundowns.tableRow.cmlDuration'),
    i18n.t('pages.rundowns.tableRow.hitTime'),
    i18n.t('pages.rundowns.tableRow.backTime'),
    i18n.t('pages.rundowns.tableRow.type'),
    i18n.t('tableRow.actions')
  ];

  const classes = tableStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        {playoutStories.length === 0 && (
          <EmptyState
            title={i18n.t('pages.rundown.emptyState.title')}
            subTitle={i18n.t('pages.rundown.emptyState.subTitle')}
          />
        )}
        {playoutStories.length > 0 && (
          <TableContainer className={classes.tableContainer}>
            <Table
              size="small"
              stickyHeader
              aria-label="simple table"
              data-test-id="playout-stories"
            >
              <TableHead>
                <TableRow>
                  {rows.map(row => {
                    return (
                      <StyledTableCell
                        className={classes}
                        key={shortid.generate()}
                        align="center"
                      >
                        {row}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody component={DroppableComponent(onDragComplete)}>
                {playoutStories.map((playoutStory, index) => {
                  return (
                    <PlayoutStory
                      playoutStory={playoutStory}
                      playoutStories={playoutStories}
                      rundown={rundown}
                      peripherals={peripherals}
                      onSpareClick={onSpareClick}
                      index={index}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};

export default PlayoutStories;
