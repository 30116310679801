import validator from 'validator';
import i18n from 'i18n';

const validateUsername = (username: string) => {
  const re = /^[a-zA-Z0-9_-]+$/;
  return re.test(String(username).toLowerCase());
};

export const validateForm = (name: string, value: string): string => {
  let error = '';

  if (name === 'email') {
    error = !validator.isEmail(value)
      ? i18n.t('pages.signup.formError.email')
      : '';
  }

  if (name === 'password') {
    //  minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1,
    error = !validator.isStrongPassword(value)
      ? i18n.t('pages.signup.formError.password')
      : '';
  }

  if (name === 'contactNumber') {
    error = !validator.isMobilePhone(value)
      ? i18n.t('pages.signup.formError.contactNumber')
      : '';
  }

  if (name === 'username') {
    error = !validateUsername(value)
      ? i18n.t('pages.signup.formError.username')
      : '';
  }

  return error;
};

export const validatePasswordConfirm = (
  formData: { passwordConfirm: string; password: string },
  name: string,
  value: string
): string => {
  let error = '';
  if (name === 'password') {
    error =
      formData.passwordConfirm && formData.passwordConfirm !== value
        ? i18n.t('pages.signup.formError.passwordConfirm')
        : '';
  }

  if (name === 'passwordConfirm') {
    error =
      formData.password && formData.password !== value
        ? i18n.t('pages.signup.formError.passwordConfirm')
        : '';
  }

  return error;
};

export const passwordResetQuestions = (): { text: string; value: string }[] => {
  return [
    {
      text: i18n.t('pages.signup.formSelect.passwordResetQuestion1'),
      value: 'What was the name of your childhood best friend?'
    },
    {
      text: i18n.t('pages.signup.formSelect.passwordResetQuestion2'),
      value: 'What was the name of your first manager at your first job?'
    },
    {
      text: i18n.t('pages.signup.formSelect.passwordResetQuestion3'),
      value: 'What is the name of the road you grew up on?'
    }
  ];
};

export const textFieldMap = (): { [key: string]: string }[] => {
  return [
    {
      type: 'text',
      value: 'email',
      label: i18n.t('pages.signup.formLabel.email'),
      helperText: i18n.t('pages.signup.helperText.email')
    },
    {
      type: 'text',
      value: 'username',
      label: i18n.t('pages.signup.formLabel.username'),
      helperText: i18n.t('pages.signup.helperText.username')
    },
    {
      type: 'text',
      value: 'contactNumber',
      label: i18n.t('pages.signup.formLabel.contactNumber'),
      helperText: i18n.t('pages.signup.helperText.contactNumber')
    },
    {
      type: 'password',
      value: 'password',
      label: i18n.t('pages.signup.formLabel.password'),
      helperText: i18n.t('pages.signup.helperText.password')
    },
    {
      type: 'password',
      value: 'passwordConfirm',
      label: i18n.t('pages.signup.formLabel.passwordConfirm'),
      helperText: i18n.t('pages.signup.helperText.passwordConfirm')
    }
  ];
};
