import { FunctionComponent } from 'react';
import TableBuilder from 'components/Table';
import i18n from 'i18n';
import { Box } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import {
  Resource,
  Action,
  WithPermissions,
  ProtectedIconButton
} from 'components/Authorization';
import DeleteDialog from 'components/DeleteDialog';

interface StoryGenreListI {
  storyGenres: $Lns.StoryGenre[];
  onRemove: (storyPool: $Lns.StoryGenre) => void;
  onEdit: (storyPool: $Lns.StoryGenre) => void;
  onRowClick: (rowItem: { name: { value: string } }) => void;
}

const StoryGenreList: FunctionComponent<StoryGenreListI> = ({
  storyGenres,
  onRemove,
  onEdit,
  onRowClick
}: StoryGenreListI) => {
  const rows = [
    i18n.t('pages.administration.storyGenres.tableRow.name'),
    i18n.t('tableRow.actions')
  ];

  const cells = storyGenres.map(genre => {
    return {
      fields: {
        name: { value: genre.name },
        actions: {
          value: (
            <Box display="flex">
              <ProtectedIconButton
                lnsResource={Resource.STORY_GENRE}
                lnsAction={Action.EDIT}
                size="small"
                onClick={() => onEdit(genre)}
              >
                <Edit color="primary" />
              </ProtectedIconButton>
              <WithPermissions
                lnsResource={Resource.STORY_GENRE}
                lnsAction={Action.DELETE}
              >
                <DeleteDialog
                  lnsResource={Resource.STORY_GENRE}
                  lnsAction={Action.DELETE}
                  onDelete={() => onRemove(genre)}
                  target={genre.name}
                  btnDisabled={false}
                />
              </WithPermissions>
            </Box>
          )
        }
      }
    };
  });

  return <TableBuilder rows={rows} cells={cells} onRowClick={onRowClick} />;
};

export default StoryGenreList;
