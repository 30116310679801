import { AssetDynamic } from 'pages/stories/interfaces/assetDynamic';
import { INIT_DURATION_SECONDS } from 'pages/stories/components/Asset/constants';
import buildImageAssetObj from 'pages/stories/utils/imageObject';
import shortid from 'shortid';

export const buildAssetItem = (
  peripheralAsset: $Lns.PeripheralAsset,
  storyGuid: string,
  assetType: string,
  trackPoints: number[],
  totalDuration: number
): AssetDynamic => {
  const url = new URL(peripheralAsset.path);

  const assetItem = {
    name: peripheralAsset.name.substr(0, 45),
    description: 'Description',
    sourceUrn: url.origin,
    assetType,
    guid: shortid.generate(),
    renderUrl: peripheralAsset.path,
    playoutServerUrl: peripheralAsset.playoutUrl,
    thumbnail: peripheralAsset.thumbnail,
    durationSec: totalDuration || INIT_DURATION_SECONDS,
    story: storyGuid,
    clipStartSec: trackPoints[0] || INIT_DURATION_SECONDS,
    clipStopSec: trackPoints[1] || INIT_DURATION_SECONDS,
    playoutTimepointSec: INIT_DURATION_SECONDS,
    totalDurationSec: totalDuration || INIT_DURATION_SECONDS
  };

  return {
    ...assetItem,
    assetI: buildImageAssetObj(assetItem, 0, assetType)
  };
};
