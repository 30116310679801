import { callLnsApi } from 'utils/fetch';
import { FormData } from 'pages/administration/security/groups/formData';

export const fetchAllUserGroups = (): Promise<{
  code: number;
  data: $Lns.UserGroup[];
}> => callLnsApi('/access/access-group');

// CRUD By USER GROUP GUID
export const createUserGroup = (
  formData: FormData
): Promise<{
  code: number;
  data: $Lns.UserGroup;
}> => callLnsApi('/access/access-group/', 'POST', formData);

export const updateUserGroupByGuid = (
  formData: FormData
): Promise<{
  code: number;
  data: $Lns.UserGroup;
}> => callLnsApi(`/access/access-group/${formData.guid}`, 'POST', formData);

export const fetchUserGroupByGuid = (
  userGroupGuid: string
): Promise<{
  code: number;
  data: {
    guid: string;
    name: string;
    users: $Lns.User[];
    lnsObjectPermissions: $Lns.LnsObjectPermission[];
  };
}> => callLnsApi(`/access/access-group/${userGroupGuid}`);

export const removeUserGroupByGuid = (
  userGroup: $Lns.UserGroup
): Promise<{
  code: number;
  message?: string;
}> => callLnsApi(`/access/access-group/${userGroup.guid}`, 'DELETE');

// Other Specific Ops
export const fetchAllLnsObjects = (): Promise<{
  code: number;
  data: $Lns.LnsObject[];
}> => callLnsApi('/access/lns-object');

export const fetchUsersInUserGroupByGuid = (
  userGroupGuid: string
): Promise<{
  code: number;
  data: $Lns.User[];
}> => callLnsApi(`/access/access-group/${userGroupGuid}/users`);

export const bulkUpdateUsersInUserGroupByGuid = (
  userGroupGuid: string,
  users: { user: string }[]
): Promise<{
  code: number;
  data: $Lns.User[];
}> =>
  callLnsApi(`/access/access-group/${userGroupGuid}/users`, 'POST', {
    accessGroup: '',
    users
  });

export const updateLnsObjectPermissionOfUserGroup = (
  newLnsObjectPermission: $Lns.LnsObjectPermission
): Promise<{
  code: number;
  data: $Lns.LnsObjectPermission;
}> =>
  callLnsApi(
    `/access/lns-object-permission/${newLnsObjectPermission.guid}`,
    'POST',
    newLnsObjectPermission
  );
