import { ChangeEvent, FunctionComponent } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { SetupFormData } from 'pages/administration/setup/formData';
import { validateForm } from 'pages/administration/setup/utils';
import { updateFormData } from 'utils/form';
import { systemSetupStyles } from 'styles/systemSetup';
import i18n from 'i18n';

interface SetupFormI {
  config: SetupFormData;
  setConfigData: (formData: SetupFormData) => void;
}

const SetupForm: FunctionComponent<SetupFormI> = ({
  config,
  setConfigData
}: SetupFormI) => {
  const classes = systemSetupStyles();

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedFormData = updateFormData(e, config, validateForm);
    setConfigData(updatedFormData);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item md={7}>
        <Typography variant="h6" className={classes.heading}>
          {i18n.t('pages.administration.setup.formLabel.mosSetting')}
        </Typography>

        <Grid item container spacing={4}>
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              id="ncsId"
              label={i18n.t('pages.administration.setup.formLabel.ncsId')}
              value={config?.ncsId}
              helperText={
                config?.errors?.ncsId ||
                i18n.t('pages.administration.setup.helperText.ncsId')
              }
              name="ncsId"
              onChange={onChangeField}
              error={!!config?.errors?.ncsId}
              autoComplete="ncsId"
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              id="host"
              label={i18n.t('pages.administration.setup.formLabel.host')}
              value={config?.host}
              helperText={
                config?.errors?.host ||
                i18n.t('pages.administration.setup.helperText.host')
              }
              name="host"
              onChange={onChangeField}
              error={!!config?.errors?.host}
              autoComplete="host"
            />
          </Grid>

          <Grid item container spacing={4}>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                id="upperPort"
                label={i18n.t('pages.administration.setup.formLabel.upperPort')}
                value={config?.upperPort}
                type="number"
                helperText={
                  config?.errors?.upperPort ||
                  i18n.t('pages.administration.setup.formLabel.upperPort')
                }
                name="upperPort"
                onChange={onChangeField}
                error={!!config?.errors?.upperPort}
                autoComplete="upperPort"
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                id="lowerPort"
                type="number"
                label={i18n.t('pages.administration.setup.formLabel.lowerPort')}
                value={config?.lowerPort}
                helperText={
                  config?.errors?.lowerPort ||
                  i18n.t('pages.administration.setup.formLabel.lowerPort')
                }
                name="lowerPort"
                onChange={onChangeField}
                error={!!config?.errors?.lowerPort}
                autoComplete="lowerPort"
              />
            </Grid>
          </Grid>
          <Grid item container spacing={4}>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                type="number"
                inputProps={{ min: 0 }}
                id="timeout"
                label={i18n.t('pages.administration.setup.formLabel.timeout')}
                value={config?.timeout}
                helperText={
                  config?.errors?.timeout ||
                  i18n.t('pages.administration.setup.helperText.timeout')
                }
                name="timeout"
                onChange={onChangeField}
                error={!!config?.errors?.timeout}
                autoComplete="timeout"
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                id="workingFolder"
                label={i18n.t(
                  'pages.administration.setup.formLabel.workingFolder'
                )}
                value={config?.workingFolder}
                helperText={
                  config?.errors?.workingFolder ||
                  i18n.t('pages.administration.setup.helperText.workingFolder')
                }
                name="workingFolder"
                onChange={onChangeField}
                error={!!config?.errors?.workingFolder}
                autoComplete="workingFolder"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={7}>
        <Typography variant="h6" className={classes.heading}>
          {i18n.t('pages.administration.setup.formLabel.emailSetup')}
        </Typography>
        <Grid item container spacing={4}>
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              id="emailHost"
              label={i18n.t('pages.administration.setup.formLabel.emailHost')}
              value={config?.emailHost}
              helperText={
                config?.errors?.emailHost ||
                i18n.t('pages.administration.setup.formLabel.emailHost')
              }
              name="emailHost"
              onChange={onChangeField}
              error={!!config?.errors?.emailHost}
              autoComplete="emailHost"
            />
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              id="emailPort"
              label={i18n.t('pages.administration.setup.formLabel.emailPort')}
              value={config?.emailPort}
              helperText={
                config?.errors?.emailPort ||
                i18n.t('pages.administration.setup.formLabel.emailPort')
              }
              name="emailPort"
              onChange={onChangeField}
              error={!!config?.errors?.emailPort}
              autoComplete="emailPort"
              type="number"
            />
          </Grid>

          <Grid item container spacing={4}>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                type="email"
                id="emailAddress"
                label={i18n.t(
                  'pages.administration.setup.formLabel.emailAddress'
                )}
                value={config?.emailAddress}
                helperText={
                  config?.errors?.emailAddress ||
                  i18n.t('pages.administration.setup.formLabel.emailAddress')
                }
                name="emailAddress"
                onChange={onChangeField}
                error={!!config?.errors?.emailAddress}
                autoComplete="emailAddress"
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                margin="normal"
                id="emailPassword"
                type="password"
                label={i18n.t(
                  'pages.administration.setup.formLabel.emailPassword'
                )}
                value={config?.emailPassword}
                helperText={
                  config?.errors?.emailPassword ||
                  i18n.t('pages.administration.setup.formLabel.emailPassword')
                }
                name="emailPassword"
                onChange={onChangeField}
                error={!!config?.errors?.emailPassword}
                autoComplete="emailPassword"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={7}>
        <Typography variant="h6" className={classes.heading}>
          {i18n.t('pages.administration.setup.formLabel.licenseSetup')}
        </Typography>
        <Grid item container spacing={4}>
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              id="licenseKey"
              label={i18n.t('pages.administration.setup.formLabel.licenseKey')}
              value={config?.licenseKey}
              helperText={
                config?.errors?.licenseKey ||
                i18n.t('pages.administration.setup.helperText.licenseKey')
              }
              name="licenseKey"
              onChange={onChangeField}
              error={!!config?.errors?.licenseKey}
              autoComplete="ncsId"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SetupForm;
