import { FormControl, Grid, MenuItem, Select } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import i18n from 'i18n';
import { ChangeEvent, FunctionComponent } from 'react';
import { snakeToCamel } from 'utils/string';
import { FormData } from '../formData';

interface SeriesFormFieldsI {
  onChangeType: (e: ChangeEvent<{ name?: string; value: unknown }>) => void;
  formData: FormData;
  getCategory: (guid: string) => $Lns.StoryCategory | undefined;
  rundownTemplates: $Lns.RundownTemplate[];
  storyCategories: $Lns.StoryCategory[];
  onChangeSeriesDate: (field: string, date: MaterialUiPickersDate) => void;
}

const SeriesFormFields: FunctionComponent<SeriesFormFieldsI> = ({
  rundownTemplates,
  getCategory,
  storyCategories,
  onChangeType,
  onChangeSeriesDate,
  formData
}: SeriesFormFieldsI) => {
  const getRundownTemplate = (guid: string) => {
    return rundownTemplates.find(s => s.guid === guid);
  };

  return (
    <>
      <Grid item>
        <FormControl margin="normal" variant="outlined" fullWidth size="small">
          <Select
            labelId="category"
            id="category"
            name="category"
            placeholder={i18n.t('pages.rundowns.placeHolder.category')}
            required
            value={formData.category}
            onChange={onChangeType}
            displayEmpty
            renderValue={(value: unknown) => {
              const key = getCategory(value as string)?.key;
              return i18n.t(
                key
                  ? `pages.rundowns.formLabel.categoryType.${snakeToCamel(key)}`
                  : 'pages.rundowns.formLabel.category'
              );
            }}
          >
            {storyCategories.map(storyCategory => (
              <MenuItem value={storyCategory.guid}>
                {i18n.t(
                  `pages.rundowns.formLabel.categoryType.${snakeToCamel(
                    storyCategory.key
                  )}`
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl margin="normal" variant="outlined" fullWidth size="small">
          <Select
            labelId="rundownTemplate"
            id="rundownTemplate"
            name="rundownTemplate"
            placeholder={i18n.t('pages.rundowns.placeHolder.rundownTemplate')}
            required
            value={formData.rundownTemplate}
            onChange={onChangeType}
            displayEmpty
            renderValue={(value: unknown) =>
              getRundownTemplate(value as string)?.name || 'Rundown Template'
            }
          >
            {rundownTemplates.map(rundownTemplate => (
              <MenuItem value={rundownTemplate.guid}>
                {rundownTemplate.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item container spacing={4}>
        <Grid item xs={6}>
          <DatePicker
            margin="normal"
            name="startDate"
            fullWidth
            emptyLabel={i18n.t('pages.rundowns.formLabel.startDate')}
            minDate={new Date()}
            size="small"
            id="startDate"
            placeholder={i18n.t('pages.rundowns.formLabel.startDate')}
            inputVariant="outlined"
            error={!!formData?.errors?.startDate}
            helperText={formData.errors.startDate}
            value={formData.startDate}
            onChange={date => onChangeSeriesDate('startDate', date)}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            margin="normal"
            name="endDate"
            fullWidth
            emptyLabel={i18n.t('pages.rundowns.formLabel.endDate')}
            minDate={new Date()}
            size="small"
            id="endDate"
            placeholder={i18n.t('pages.rundowns.formLabel.endDate')}
            inputVariant="outlined"
            error={!!formData?.errors?.endDate}
            helperText={formData.errors.endDate}
            value={formData.endDate}
            onChange={date => onChangeSeriesDate('endDate', date)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default SeriesFormFields;
