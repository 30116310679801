import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  InputLabel
} from '@material-ui/core';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import { clipTypes } from 'pages/administration/story-types/constants';
import { validateForm } from 'pages/administration/story-types/utils';
import { updateFormData } from 'utils/form';
import i18n from 'i18n';

interface FormI {
  onSave: (formData: $Lns.StoryType) => void;
  onCancel: () => void;
  storyType?: $Lns.StoryType;
}

const Form: FunctionComponent<FormI> = ({
  onSave,
  onCancel,
  storyType
}: FormI) => {
  const [formData, setFormData] = useState({
    id: storyType?.id || 0,
    durationCalculationMethod: storyType?.durationCalculationMethod || 0,
    guid: storyType?.guid || '',
    name: storyType?.name || '',
    key: storyType?.key || '',
    isPrompterRequired: storyType?.isPrompterRequired || false,
    isCgRequired: storyType?.isCgRequired || false,
    clipType: storyType?.clipType || 0,
    errors: {
      name: '',
      key: '',
      isPrompterRequired: '',
      isCgRequired: '',
      clipType: ''
    }
  });

  const [BtnDisabledChange, setBtnDisabledChange] = useState(
    !!formData.name || !!Object.values(formData.errors).some(e => !!e)
  );

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const updatedFormData = updateFormData(e, formData, validateForm);
    toggleSaveBtn(updatedFormData, name || '');
    setFormData(updatedFormData);
  };

  const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    const updatedData = {
      ...formData,
      [e.target.name]: e.target.checked
    };
    toggleSaveBtn(updatedData, name || '');
    setFormData(updatedData);
  };

  const onChangeType = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value, name } = e.target;
    const ValueToNum = value as keyof typeof formData;

    const updatedData = {
      ...formData,
      [name || '']: +ValueToNum
    };
    toggleSaveBtn(updatedData, name || '');
    setFormData(updatedData);
  };

  const toggleSaveBtn = (updatedFormData: $Lns.StoryType, key: string) => {
    let checkFlg = true;
    const skipList = ['errors'];
    if (storyType && Object.keys(storyType).includes(key)) {
      const tempFormData = { ...updatedFormData };
      checkFlg = checkFormDataState(storyType, tempFormData, skipList);
      setBtnDisabledChange(checkFlg);
    }
  };

  const checkFormDataState = (
    formDataObj: $Lns.StoryType,
    upDateFormData: $Lns.StoryType,
    skipList: string[]
  ) => {
    let checkFlg = true;
    Object.keys(upDateFormData).forEach(pin => {
      if (
        pin in upDateFormData &&
        pin in formDataObj &&
        !skipList.includes(pin)
      ) {
        const Val1 = upDateFormData[pin as keyof typeof upDateFormData];
        const Val2 = formDataObj[pin as keyof typeof formDataObj];
        if (Val1 !== Val2) {
          checkFlg = false;
        }
      }
    });
    return checkFlg;
  };

  const durationCalculationMethods = [
    { value: 0, key: 'plannedDuration' },
    { value: 1, key: 'script' },
    { value: 2, key: 'video' }
  ];

  const btnDisabled =
    !formData.name ||
    !formData.key ||
    !!Object.values(formData.errors).some(e => !!e);

  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label={i18n.t('pages.administration.storyTypes.formLabel.name')}
            value={formData.name}
            helperText={formData.errors.name || i18n.t('helperText.required')}
            name="name"
            onChange={onChangeField}
            error={!!formData?.errors?.name}
            autoComplete="name"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="key"
            label={i18n.t('pages.administration.storyTypes.formLabel.key')}
            name="key"
            value={formData.key}
            helperText={formData.errors.key || i18n.t('helperText.required')}
            onChange={onChangeField}
            error={!!formData?.errors?.key}
            autoComplete="key"
            required
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl margin="normal" variant="outlined" fullWidth required>
            <InputLabel>
              {i18n.t('pages.administration.storyTypes.helperText.clipType')}
            </InputLabel>
            <Select
              labelId="clipType"
              id="clipType"
              name="clipType"
              placeholder={i18n.t(
                'pages.administration.storyTypes.placeHolder.clipType'
              )}
              value={formData.clipType}
              onChange={onChangeType}
              displayEmpty
              label={i18n.t(
                'pages.administration.storyTypes.helperText.clipType'
              )}
            >
              {Object.keys(clipTypes).map(clipType => {
                return (
                  <MenuItem key={clipType} value={clipType}>
                    {clipTypes[clipType]}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>{i18n.t('helperText.required')}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl margin="normal" variant="outlined" fullWidth required>
            <InputLabel>
              {i18n.t('pages.stories.formLabel.plannedDuration')}
            </InputLabel>
            <Select
              labelId="durationCalculationMethod"
              id="durationCalculationMethod"
              name="durationCalculationMethod"
              placeholder={i18n.t(
                'pages.administration.storyTypes.placeHolder.durationCalculationMethod'
              )}
              value={formData.durationCalculationMethod}
              onChange={onChangeType}
              displayEmpty
              label={i18n.t('pages.stories.formLabel.plannedDuration')}
            >
              {durationCalculationMethods.map(durationCalculationMethod => {
                return (
                  <MenuItem
                    key={durationCalculationMethod.key}
                    value={durationCalculationMethod.value}
                  >
                    {durationCalculationMethod.key}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>{i18n.t('helperText.required')}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset" aria-label="position">
            <FormGroup row>
              <FormControlLabel
                label={i18n.t(
                  'pages.administration.storyTypes.formLabel.isPrompterRequired'
                )}
                control={
                  <Checkbox
                    name="isPrompterRequired"
                    onChange={onChangeCheckbox}
                    checked={formData.isPrompterRequired}
                    color="primary"
                  />
                }
              />

              <FormControlLabel
                label={i18n.t(
                  'pages.administration.storyTypes.formLabel.isCgRequired'
                )}
                control={
                  <Checkbox
                    name="isCgRequired"
                    onChange={onChangeCheckbox}
                    checked={formData.isCgRequired}
                    color="primary"
                  />
                }
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={btnDisabled || BtnDisabledChange}
              color="secondary"
              variant="contained"
              onClick={() => onSave(formData)}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;

Form.defaultProps = {
  storyType: undefined
};
