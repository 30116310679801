import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import { Grid, IconButton, Paper } from '@material-ui/core';
import PublishFolders from 'pages/publish-folders/components/List';
import { useNavigate } from 'react-router';
import { Search, Folder } from '@material-ui/icons';
import storiesStyles from 'pages/stories/styles';
import { fetchPublishFolders } from 'actions/publishFolders';
import toggleNotification from 'actions/notifications';
import { useDispatch } from 'react-redux';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import i18n from 'i18n';
import BasicTextField from 'components/BasicTextField';

const ListPublishedFolders: FunctionComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = storiesStyles();

  const [publishedFolders, setPublishedFolders] = useState<
    $Lns.PublishFolder[]
  >([]);

  const [publishedFoldersOrig, setPublishedFoldersOrig] = useState<
    $Lns.PublishFolder[]
  >([]);

  useEffect(() => {
    fetchPublishFolders()
      .then(({ data }) => {
        setPublishedFolders(data || []);
        setPublishedFoldersOrig(data || []);
      })
      .catch(() =>
        dispatch(toggleNotification('Failed to load publish folders', 'error'))
      );
  }, [dispatch]);

  const onRowClick = (rowItem: {
    guid: { value: string };
    name: { value: string };
  }) => {
    const {
      guid: { value: guidValue },
      name: { value: nameValue }
    } = rowItem;

    navigate(`/publish-folders/${guidValue}`, { state: { name: nameValue } });
  };

  const onEditClick = (rowItem: $Lns.PublishFolder) => {
    navigate(`/publish-folders/${rowItem.guid}/edit`, {
      state: { name: rowItem.name }
    });
  };

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newStorypool = publishedFoldersOrig.filter(story => {
      return story.guid.includes(search) || story.name.includes(search);
    });
    setPublishedFolders(newStorypool);
  };

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={i18n.t('links.sidebar.publishFolders')}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<Folder color="primary" />}
            />
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={onSearchFieldChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <PublishFolders
            publishFolders={publishedFolders}
            onRowClick={onRowClick}
            onEditClick={onEditClick}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ListPublishedFolders;
