import toggleNotification from 'actions/notifications';
import { fetchStoryPoolStories } from 'actions/storyPools';
import StoriesTable from 'components/Story/Table';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Loading from 'components/Loading';
import i18n from 'i18n';

interface StoryPoolTabsListI {
  storyPool: $Lns.StoryPool;
  onToggleStory: (story: $Lns.Story) => void;
  highlightedStories: $Lns.Story[];
}

const StoryPoolStories: FunctionComponent<StoryPoolTabsListI> = ({
  storyPool,
  highlightedStories,
  onToggleStory
}: StoryPoolTabsListI) => {
  const [stories, setStories] = useState<$Lns.Story[]>([]);
  const [isStoriesLoaded, setIsStoriesLoaded] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    fetchStoryPoolStories(storyPool.guid)
      .then(({ data }) => {
        setStories(data);
        setIsStoriesLoaded(true);
      })
      .catch(() =>
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyPools.storiesFetchError'),
            'error'
          )
        )
      );
  }, [dispatch, storyPool]);

  // eslint-disable-next-line
  const onRowClick = (storyRow: any) => {
    const story = stories.find(
      (s: $Lns.Story) => s.guid === storyRow.guid.value
    );

    if (story) onToggleStory(story);
  };

  if (!isStoriesLoaded) return <Loading />;
  return (
    <StoriesTable
      data-test-id="browse-stories"
      stories={stories}
      storyPool={storyPool}
      onClick={onRowClick}
      highlightedStories={highlightedStories}
    />
  );
};

export default StoryPoolStories;
