import i18n from 'i18n';
import validator from 'validator';

const getTimestamp = (time: string) => {
  const hours = parseInt(time.split(':')[0], 10);
  const minutes = parseInt(time.split(':')[1], 10);
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date.valueOf();
};

export const validateForm = (
  name: string,
  value: string,
  formData: $Lns.RundownTemplate
): string => {
  let error = '';

  if (name === 'name') {
    error = validator.isEmpty(value) ? i18n.t('formErrors.isEmpty') : '';
  }

  if (name === 'startTime' || name === 'endTime') {
    error = !validator.isNumeric(value) ? i18n.t('formErrors.isNumeric') : '';
  }

  if (name === 'startTime') {
    const startTimestamp = getTimestamp(value);
    const endTimestamp = getTimestamp(formData.playoutEndTime);
    error =
      startTimestamp > endTimestamp
        ? i18n.t('pages.administration.rundownTemplates.formErrors.startTime')
        : '';
  }

  if (name === 'endTime') {
    const endTimestamp = getTimestamp(value);
    const startTimestamp = getTimestamp(formData.playoutStartTime);
    error =
      endTimestamp < startTimestamp
        ? i18n.t('pages.administration.rundownTemplates.formErrors.endTime')
        : '';
  }

  return error;
};
