import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select
} from '@material-ui/core';
import CustomTextField from 'components/CustomTextField';
import i18n from 'i18n';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import { updateFormData } from 'utils/form';
import { FormData } from 'pages/administration/media/formData';
import { validateForm } from 'pages/administration/media/utils';

interface FormI {
  onSave: (formData: FormData) => void;
  onCancel: () => void;
  mediaItem?: $Lns.MediaItem;
}

const Form: FunctionComponent<FormI> = ({
  onSave,
  onCancel,
  mediaItem
}: FormI) => {
  const [formData, setFormData] = useState({
    guid: mediaItem?.guid || '',
    name: mediaItem?.name || '',
    type: mediaItem?.type || '',
    sourceUrl: mediaItem?.sourceUrl || '',
    errors: {
      name: '',
      sourceUrl: ''
    }
  });

  const onChangeSelect = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const { value, name } = e.target;

    const updatedFormData = {
      ...formData,
      [name || '']: value
    };
    toggleSaveBtn(updatedFormData, name || '');
    setFormData(updatedFormData);
  };

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedFormData = updateFormData(e, formData, validateForm);
    const { name } = e.target;
    toggleSaveBtn(updatedFormData, name);
    setFormData(updatedFormData);
  };

  const toggleSaveBtn = (updatedFormData: FormData, key: string) => {
    let checkFlg = true;
    const skipList = ['errors'];
    if (mediaItem && Object.keys(mediaItem).includes(key)) {
      const tempFormData = { ...updatedFormData };
      checkFlg = checkFormDataState(mediaItem, tempFormData, skipList);
      setBtnDisabledChange(checkFlg);
    }
  };

  const checkFormDataState = (
    formDataObj: $Lns.MediaItem,
    updateFormDataObj: FormData,
    skipList: string[]
  ) => {
    let checkFlg = true;
    Object.keys(updateFormDataObj).forEach(pin => {
      if (
        pin in updateFormDataObj &&
        pin in formDataObj &&
        !skipList.includes(pin)
      ) {
        const Val1 = updateFormDataObj[pin as keyof typeof updateFormDataObj];
        const Val2 = formDataObj[pin as keyof typeof formDataObj];
        if (Val1 !== Val2) {
          checkFlg = false;
        }
      }
    });
    return checkFlg;
  };

  const btnDisabled =
    Object.values(formData.errors).some(e => !!e) ||
    !formData.name ||
    !formData.type ||
    !formData.sourceUrl;

  const [BtnDisabledChange, setBtnDisabledChange] = useState(
    Object.values(formData.errors).some(e => !!e) ||
      !!formData.name ||
      !!formData.type ||
      !!formData.sourceUrl
  );

  return (
    <Box padding={4}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CustomTextField
            onChange={onChangeField}
            value={formData.name}
            label={i18n.t('pages.administration.media.formLabel.name')}
            name="name"
            error={!!formData?.errors.name}
            helperText={formData.errors.name || i18n.t('helperText.required')}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl variant="outlined" size="small" fullWidth required>
            <Select
              id="type"
              name="type"
              placeholder={i18n.t(
                'pages.administration.media.placeHolder.type'
              )}
              required
              value={formData.type}
              displayEmpty
              onChange={onChangeSelect}
              renderValue={() =>
                formData?.type
                  ? i18n.t(
                      `pages.administration.media.placeHolder.${formData?.type}`
                    )
                  : i18n.t('pages.administration.media.placeHolder.type')
              }
            >
              {['image', 'video', 'stream'].map(currentType => {
                return (
                  <MenuItem key={currentType} value={currentType}>
                    {i18n.t(
                      `pages.administration.media.formLabel.${currentType}`
                    )}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <CustomTextField
            onChange={onChangeField}
            value={formData.sourceUrl}
            label={i18n.t('pages.administration.media.formLabel.sourceUrl')}
            name="sourceUrl"
            error={!!formData?.errors.sourceUrl}
            helperText={
              formData.errors.sourceUrl || i18n.t('helperText.required')
            }
          />
        </Grid>

        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              disabled={btnDisabled || BtnDisabledChange}
              variant="contained"
              onClick={() => onSave(formData)}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;

Form.defaultProps = {
  mediaItem: undefined
};
