import React, { FunctionComponent, ReactNode, useState } from 'react';
import { Collapse, List, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import sideBarListItemStyles from 'styles/sideBarListItem';
import shortid from 'shortid';
import { ProtectedListItem } from 'components/Authorization/components/ProtectedListItem';

interface ProtectedSidebarListItemI {
  title: string;
  icon: ReactNode;
  nestedItems?: {
    name: string;
    guid: string;
    icon: ReactNode;
    lnsResource?: string;
    lnsAction?: string;
  }[];
  sideBarOpen: boolean;
  handleDrawerOpen: () => void;
  onClickMain?: () => void;
  onClickNestedItem?: (item: $Lns.StoryPool | $Lns.Rundown) => void;
  linkPath?: string;
  nestedItemsPath?: string;
  lnsResource?: string;
  lnsAction?: string;
  selected?: string;
}

export const ProtectedSidebarListItem: FunctionComponent<ProtectedSidebarListItemI> =
  ({
    title,
    icon,
    nestedItems,
    sideBarOpen,
    handleDrawerOpen,
    onClickMain,
    onClickNestedItem,
    linkPath,
    nestedItemsPath,
    lnsResource,
    lnsAction,
    selected
  }: ProtectedSidebarListItemI) => {
    const classes = sideBarListItemStyles();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
      setOpen(!open);
      if (onClickMain) onClickMain();
      if (linkPath) {
        navigate(linkPath);
      }
      if (!sideBarOpen) {
        handleDrawerOpen();
      }
    };

    const nestedLinkSelected = (guid: string) => {
      if (!guid || !nestedItemsPath) return false;
      if (guid === selected) return true;
      return false;
    };

    const linkSelected = () => {
      if (!linkPath) return false;
      if (selected === undefined && window.location.pathname.includes(linkPath))
        return true;

      return false;
    };

    return (
      <>
        <ProtectedListItem
          selected={linkSelected()}
          button
          key={title}
          onClick={handleClick}
          lnsResource={lnsResource}
          lnsAction={lnsAction}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            primary={title}
            primaryTypographyProps={{ color: 'primary' }}
          />
          {nestedItems && (open ? <ExpandLess /> : <ExpandMore />)}
        </ProtectedListItem>
        {nestedItems && (
          <Collapse in={open && sideBarOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {nestedItems.map(listItem => {
                return (
                  <ProtectedListItem
                    selected={nestedLinkSelected(listItem.guid)}
                    key={shortid.generate()}
                    button
                    className={classes.nested}
                    lnsResource={listItem.lnsResource}
                    lnsAction={listItem.lnsAction}
                    onClick={() =>
                      onClickNestedItem && onClickNestedItem(listItem)
                    }
                  >
                    <ListItemIcon>{listItem.icon}</ListItemIcon>
                    <ListItemText
                      primary={listItem.name}
                      primaryTypographyProps={{ color: 'primary' }}
                    />
                  </ProtectedListItem>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  };

ProtectedSidebarListItem.defaultProps = {
  nestedItems: undefined,
  onClickNestedItem: undefined,
  linkPath: undefined,
  nestedItemsPath: undefined,
  lnsResource: undefined,
  lnsAction: undefined,
  selected: undefined,
  onClickMain: undefined
};
