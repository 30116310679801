import { FunctionComponent } from 'react';
import { useCanAccess } from 'components/Authorization/utils';
import i18n from 'i18n';
import DeleteDialog, { DeleteDialogI } from 'components/DeleteDialog';
import { Action } from 'components/Authorization/consts';

interface ProtectedDeleteDialogI extends DeleteDialogI {
  lnsResource?: string;
  lnsOwners?: string[];
}

export const ProtectedDeleteDialog: FunctionComponent<ProtectedDeleteDialogI> =
  ({
    lnsResource,
    lnsOwners,
    tooltip,
    btnDisabled,
    ...props
  }: ProtectedDeleteDialogI) => {
    const canAccess = useCanAccess(lnsResource, Action.DELETE, lnsOwners);

    return (
      <DeleteDialog
        lnsResource={lnsResource}
        lnsAction={Action.DELETE}
        tooltip={
          canAccess ? tooltip : i18n.t('authorization.tooltip.notAuthorized')
        }
        btnDisabled={!canAccess ? true : btnDisabled}
        {...props}
      />
    );
  };

ProtectedDeleteDialog.defaultProps = {
  lnsResource: undefined,
  lnsOwners: []
};
