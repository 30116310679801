import { ChangeEvent } from 'react';

export const updateFormData = (
  e: ChangeEvent<HTMLInputElement>,
  // eslint-disable-next-line
  formData: any,
  // eslint-disable-next-line
  validateForm: any
  // eslint-disable-next-line
): any => {
  const { name, value } = e.target;
  return {
    ...formData,
    [name]: value,
    errors: {
      ...formData.errors,
      [name]: validateForm(name, value)
    }
  };
};

export const updateFormKeyValue = (
  key: string,
  // eslint-disable-next-line
  value: any,
  // eslint-disable-next-line
  formData: any,
  // eslint-disable-next-line
  validateForm: any
  // eslint-disable-next-line
): any => {
  return {
    ...formData,
    [key]: value,
    errors: {
      ...formData.errors,
      [key]: validateForm(key, value)
    }
  };
};

// eslint-disable-next-line
export const isFormValid = (requiredFields: string[], formData: any) => {
  const errors = requiredFields.some(field => !!formData.errors[field]);
  const allFieldsSet = !requiredFields.some(field => !formData[field]);
  return !errors && allFieldsSet;
};
