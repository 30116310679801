import { InputBase } from '@material-ui/core';
import i18n from 'i18n';
import { ChangeEvent, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

interface BasicTextFieldI {
  className?: string;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  inputProps?: Record<string, unknown>;
}

const BasicTextField: FunctionComponent<BasicTextFieldI> = ({
  className,
  placeholder,
  onChange,
  inputProps
}: BasicTextFieldI) => {
  const { dir } = useSelector((state: $Lns.DefaultState) => state.default);
  const direction = dir ?? i18n.dir();

  return (
    <InputBase
      className={`${direction}  ${className}`}
      placeholder={placeholder}
      onChange={onChange}
      inputProps={inputProps}
    />
  );
};

BasicTextField.defaultProps = {
  className: undefined,
  placeholder: undefined,
  onChange: pin => pin,
  inputProps: {}
};

export default BasicTextField;
