import { FunctionComponent, useState, ChangeEvent } from 'react';
import { Dialog, Grid, IconButton, Paper } from '@material-ui/core';
import { setStoryTypes } from 'actions/default';
import {
  createStoryType,
  deleteStoryType,
  updateStoryType
} from 'actions/storyTypes';
import { Search, Description } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import storiesStyles from 'pages/stories/styles';
import toggleNotification from 'actions/notifications';
import Form from 'pages/administration/story-types/components/Form';
import StoryTypeList from 'pages/administration/story-types/components/List';
import i18n from 'i18n';
import TitleWaper from 'components/TitleWraper';
import BtnWrapper from 'components/BtnWrapper';
import TitleBarWraper from 'components/TitleBarWraper';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import BasicTextField from 'components/BasicTextField';
import Detail from './components/Detail';

const StoryTypes: FunctionComponent = () => {
  const { storyTypes } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const dispatch = useDispatch();
  const [showStoryType, toggleShowStoryType] = useState<$Lns.StoryType>();
  const [editStoryType, setEditStoryType] = useState<$Lns.StoryType>();

  const [filteredStoryTypes, setFilteredStoryTypes] =
    useState<$Lns.StoryType[]>(storyTypes);

  const classes = storiesStyles();

  const onAdd = () => {
    setEditStoryType({
      guid: '',
      durationCalculationMethod: 0,
      name: '',
      key: '',
      clipType: 0,
      isPrompterRequired: false,
      isCgRequired: false
    } as $Lns.StoryType);
  };

  const onEdit = (storyType: $Lns.StoryType) => {
    setEditStoryType(storyType);
  };

  const onEditCancel = () => {
    setEditStoryType(undefined);
  };

  const onSave = async (formData: $Lns.StoryType) => {
    let updatedStoryTypes: $Lns.StoryType[] = [];
    if (!editStoryType) return;

    const storyType = {
      name: formData.name,
      key: formData.key,
      clipType: formData.clipType,
      durationCalculationMethod: formData.durationCalculationMethod,
      isPrompterRequired: formData.isPrompterRequired,
      isCgRequired: formData.isCgRequired
    } as $Lns.StoryType;

    if (formData.guid) {
      storyType.guid = formData.guid;
      updateStoryType(storyType).then(({ data }) => {
        updatedStoryTypes = storyTypes.map(st => {
          if (st.guid === formData.guid) {
            return {
              ...st,
              ...data
            };
          }
          return st;
        });
        dispatch(setStoryTypes(updatedStoryTypes));
        setFilteredStoryTypes(updatedStoryTypes);
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyTypes.updateSuccess'),
            'success'
          )
        );
      });
    } else {
      updatedStoryTypes = [storyType, ...storyTypes];
      createStoryType(storyType).then(({ data }) => {
        setFilteredStoryTypes([data, ...filteredStoryTypes]);
        dispatch(setStoryTypes([data, ...storyTypes]));
        dispatch(
          toggleNotification(
            i18n.t('notifications.storyTypes.createSuccess'),
            'success'
          )
        );
      });
    }
    setEditStoryType(undefined);
  };

  const onDelete = (storyType: $Lns.StoryType) => {
    deleteStoryType(storyType).then(() => {
      const updatedStoryTypes = storyTypes.filter(
        st => st.guid !== storyType.guid
      );
      dispatch(
        toggleNotification(
          i18n.t('notifications.storyTypes.deleteSuccess'),
          'success'
        )
      );
      dispatch(setStoryTypes(updatedStoryTypes));
    });
  };

  const onShow = (storyType: $Lns.StoryType) => {
    toggleShowStoryType(storyType);
  };

  const onClose = () => {
    toggleShowStoryType(undefined);
  };

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newStoriesTypes = storyTypes.filter(story => {
      return story.name.includes(search) || story.key.includes(search);
    });
    setFilteredStoryTypes(newStoriesTypes);
  };

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={i18n.t(
                'links.sidebarNestedItems.administration.storyTypes'
              )}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<Description color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <ProtectedButton
                lnsResource={Resource.STORY_TYPE}
                lnsAction={Action.CREATE}
                color="secondary"
                variant="contained"
                onClick={onAdd}
              >
                {i18n.t('pages.administration.storyTypes.button.add')}
              </ProtectedButton>
            </BtnWrapper>
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={onSearchFieldChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <StoryTypeList
            storyTypes={filteredStoryTypes}
            onEdit={onEdit}
            onDelete={onDelete}
            onShow={onShow}
          />
        </Grid>
      </Grid>
      {editStoryType && (
        <Dialog
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <Form
            onSave={onSave}
            storyType={editStoryType}
            onCancel={onEditCancel}
          />
        </Dialog>
      )}
      {showStoryType && <Detail storyType={showStoryType} onClose={onClose} />}
    </>
  );
};

export default StoryTypes;
