import { FunctionComponent } from 'react';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import { ProtectedAdminActionField, Resource } from 'components/Authorization';

interface StoryPoolListI {
  storyPools: $Lns.StoryPool[];
  onDelete: (storyPool: $Lns.StoryPool) => void;
  onEdit: (storyPool: $Lns.StoryPool) => void;
}

const StoryPoolList: FunctionComponent<StoryPoolListI> = ({
  storyPools,
  onDelete,
  onEdit
}: StoryPoolListI) => {
  const rows = [
    i18n.t('pages.administration.storyPools.tableRow.name'),
    i18n.t('tableRow.actions')
  ];

  const cells = storyPools.map(storyPool => {
    return {
      fields: {
        name: { value: storyPool.name },
        actions: {
          value: (
            <ProtectedAdminActionField
              target={storyPool.name}
              targetDeleteWarning={i18n.t('deleteDialog.storyPool.prompt')}
              onEdit={() => onEdit(storyPool)}
              onDelete={() => onDelete(storyPool)}
              lnsResource={Resource.STORY_POOL}
            />
          )
        }
      }
    };
  });

  return <TableBuilder rows={rows} cells={cells} />;
};

export default StoryPoolList;
