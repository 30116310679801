import { makeStyles, Theme } from '@material-ui/core';

const assetManagerStyle = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 6,
    padding: theme.spacing(0.2),
    width: '100%',
    height: '135px',
    objectFit: 'cover',
    '&:hover, &:focus': {
      borderStyle: 'solid',
      border: 2,
      borderColor: theme.palette.primary.main
    }
  }
}));

export default assetManagerStyle;
