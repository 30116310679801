import { ChangeEvent, FunctionComponent, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { Public, Schedule } from '@material-ui/icons';
import i18n from 'i18n';
import { timezones } from 'utils/timezones';
import useStyles from 'pages/user/components/styles';

export type FormData = {
  location: string;
  timezone: string;
};

interface EditLocaleI {
  user: $Lns.User;
  canEdit: boolean;
  onSaveCallback?: (formData: FormData) => void;
}

const EditLocale: FunctionComponent<EditLocaleI> = ({
  user,
  canEdit,
  onSaveCallback
}: EditLocaleI) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [location, setLocation] = useState(user.config.location);
  const [timezone, setTimezone] = useState(user.config.timezone);
  const toggleIsEdit = () => setIsEdit(!isEdit);
  const noDataString = i18n.t('pages.users.formHelperText.noData');

  const onSave = () => {
    if (onSaveCallback) onSaveCallback({ location, timezone });
    toggleIsEdit();
  };

  const handleTimezoneChange = (e: ChangeEvent<{ value: unknown }>) => {
    setTimezone(e.target.value as string);
  };

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    setLocation(e.target.value);
  };

  return (
    <>
      <Box className={classes.titleWrapperBox}>
        <Typography variant="h6" className={classes.grayPaperTitle}>
          {i18n.t('pages.users.formLabel.locale')}
        </Typography>
        {canEdit && (
          <Box display="flex" justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={!isEdit ? toggleIsEdit : onSave}
            >
              {!isEdit && i18n.t('button.edit')}
              {isEdit && i18n.t('button.save')}
            </Button>
            {isEdit && (
              <Box paddingLeft={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={toggleIsEdit}
                >
                  {i18n.t('button.cancel')}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {!isEdit && (
        <List>
          <ListItem>
            <ListItemIcon>
              <Public />
            </ListItemIcon>
            <ListItemText
              primary={user.config?.location || noDataString}
              secondary={i18n.t('pages.users.formLabel.location')}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Schedule />
            </ListItemIcon>
            <ListItemText
              primary={user.config?.timezone || noDataString}
              secondary={i18n.t('pages.users.formLabel.timezone')}
            />
          </ListItem>
        </List>
      )}
      {isEdit && (
        <Box padding={2}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="location"
            label={i18n.t('pages.users.formLabel.location')}
            value={location}
            // helperText={formData.errors.contactNumber || ''}
            name="location"
            onChange={onChangeField}
            // error={!!formData?.errors?.contactNumber}
            autoComplete="location"
          />
          <br />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-timezone-label">
              {i18n.t('pages.users.formLabel.timezone')}
            </InputLabel>
            <Select
              labelId="select-timezone-label"
              id="select-timezone"
              value={timezone}
              onChange={handleTimezoneChange}
              label={i18n.t('pages.users.formLabel.timezone')}
              placeholder={i18n.t('pages.users.formHelperText.timezone')}
            >
              {timezones.map(item => (
                <MenuItem key={item.name} value={item.name}>
                  {item.name} {item.offset >= 0 ? '-' : '+'}
                  {Math.abs(item.offset / 60)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default EditLocale;

EditLocale.defaultProps = {
  onSaveCallback: undefined
};
