import { FunctionComponent } from 'react';
import { Box, Tooltip } from '@material-ui/core';
import { Edit, FindInPage, List } from '@material-ui/icons';
import { secondsToDuration } from 'utils/time';
import { formattedPlayoutDate } from 'pages/rundowns/utils';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import i18n from 'i18n';
import BooleanIcon from 'components/BooleanIcon';
import GridTable from 'components/GridTable';
import {
  ProtectedIconButton,
  Resource,
  Action
} from 'components/Authorization';

interface ListRundownsI {
  rundowns: $Lns.Rundown[];
  onEdit: (rundown: $Lns.Rundown) => void;
  onEditStories: (rundown: $Lns.Rundown) => void;
  onShow: (rundown: $Lns.Rundown) => void;
  onRowClick: (guid: string) => void;
}

const ListRundowns: FunctionComponent<ListRundownsI> = ({
  rundowns,
  onEdit,
  onEditStories,
  onShow,
  onRowClick
}: ListRundownsI) => {
  const columnsBuilder = (): GridColDef[] => {
    const columns: GridColDef[] = [
      {
        field: 'name',
        headerName: i18n.t('pages.rundowns.tableRow.name'),
        flex: 1
      },
      {
        field: 'playoutDateTime',
        headerName: i18n.t('pages.rundowns.tableRow.playoutDateTime'),
        flex: 1
      },
      {
        field: 'totalDuration',
        headerName: i18n.t('pages.rundowns.tableRow.totalDuration'),
        flex: 1
      },
      {
        field: 'numberOfCamera',
        headerName: 'Number of cameras',
        flex: 1,
        renderCell: () => 1
      },
      {
        field: 'isPublished',
        headerName: i18n.t('pages.rundowns.tableRow.isPublished'),
        flex: 1,
        renderCell: ({ row: { isPublished } }: GridCellParams) => (
          <BooleanIcon value={isPublished} />
        )
      },
      {
        field: 'actions',
        disableColumnMenu: true,
        disableReorder: true,
        hideSortIcons: true,
        headerName: i18n.t('tableRow.actions'),
        flex: 1,
        renderCell: (params: GridCellParams) => {
          const rundown = rundowns.find(r => r.guid === params.id);
          if (!rundown) return <></>;
          return (
            <Box display="flex">
              <Tooltip
                title={i18n.t('pages.rundowns.tooltip.view').toString()}
                aria-label="view"
              >
                <ProtectedIconButton
                  size="small"
                  color="primary"
                  onClick={() => onShow(rundown)}
                  lnsResource={Resource.RUNDOWN}
                  lnsAction={Action.READ}
                >
                  <FindInPage />
                </ProtectedIconButton>
              </Tooltip>

              <Tooltip
                title={i18n.t('pages.rundowns.tooltip.stories').toString()}
                aria-label="view"
              >
                <ProtectedIconButton
                  data-test-id="edit"
                  size="small"
                  color="primary"
                  onClick={() => onEditStories(rundown)}
                  lnsResource={Resource.RUNDOWN}
                  lnsAction={Action.EDIT}
                >
                  <List />
                </ProtectedIconButton>
              </Tooltip>

              {!rundown.isPublished && (
                <Tooltip
                  title={i18n.t('pages.rundowns.tooltip.edit').toString()}
                  aria-label="add"
                >
                  <ProtectedIconButton
                    size="small"
                    color="primary"
                    onClick={() => onEdit(rundown)}
                    lnsResource={Resource.RUNDOWN}
                    lnsAction={Action.EDIT}
                  >
                    <Edit />
                  </ProtectedIconButton>
                </Tooltip>
              )}
            </Box>
          );
        }
      }
    ];

    return columns;
  };

  const cellsBuilder = () => {
    const mapFn = (rundown: $Lns.Rundown) => {
      return {
        id: rundown.guid,
        name: rundown.name,
        playoutDateTime: formattedPlayoutDate(rundown),
        totalDuration: secondsToDuration(rundown.totalDurationSec),
        isLocked: rundown.isLocked,
        isPublished: rundown.isPublished,
        actions: []
      };
    };

    return rundowns.map(mapFn);
  };

  return (
    <GridTable
      columns={columnsBuilder()}
      cells={cellsBuilder()}
      onRowClick={params => onRowClick(params.id.toString())}
    />
  );
};

export default ListRundowns;
