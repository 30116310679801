import React from 'react';
import ReactDOM from 'react-dom';
import 'index.css';
import 'typeface-work-sans';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import { CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';
import store from 'redux/store';
import 'i18n';

ReactDOM.render(
  <>
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
