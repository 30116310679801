import { FunctionComponent, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid
} from '@material-ui/core';
import { TabPanel } from '@material-ui/lab';
import { snakeToSpaces } from 'utils/string';

interface LnsAccessTabPanelI {
  lnsObject: $Lns.LnsObject;
  lnsObjectPermission: $Lns.LnsObjectPermission;
  onHandleChange: (
    newPermissions: $Lns.LnsObjectPermission,
    targetObject: $Lns.LnsObject
  ) => void;
}

const LnsAccessTabPanel: FunctionComponent<LnsAccessTabPanelI> = ({
  lnsObject,
  lnsObjectPermission,
  onHandleChange
}: LnsAccessTabPanelI) => {
  const [accessRights, setAccessRights] = useState<$Lns.LnsObjectPermission>(
    lnsObjectPermission ? lnsObjectPermission.actions : []
  );

  const onChange = (right: string) => {
    const newAccessRights = { ...accessRights };
    if (Object.keys(newAccessRights).includes(right)) {
      newAccessRights[right] = !newAccessRights[right];
    }
    setAccessRights(newAccessRights);

    onHandleChange(
      { ...lnsObjectPermission, actions: { ...newAccessRights } },
      lnsObject
    );
  };

  const isChecked = (right: string) => {
    if (Object.keys(accessRights).includes(right)) return accessRights[right];
    return false;
  };

  return (
    <TabPanel value={lnsObject.guid} key={lnsObject.guid}>
      <Grid container alignItems="center" spacing={4}>
        <Grid item>
          <FormControl component="fieldset" aria-label="position">
            <FormGroup aria-label="position" row>
              {lnsObject.actions.map(action => {
                return (
                  <FormControlLabel
                    key={action}
                    control={
                      <Checkbox
                        name={action}
                        onChange={() => onChange(action)}
                        checked={isChecked(action)}
                        color="primary"
                      />
                    }
                    label={snakeToSpaces(action)}
                    labelPlacement="top"
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

export default LnsAccessTabPanel;
