import React, { FunctionComponent, useState } from 'react';
import TableBuilder from 'components/Table';
import { Box, Button, Dialog, Link, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { StoryFormData } from 'pages/stories/interface';
import { createStory } from 'pages/stories/actions';
import DetailDialog from 'components/DetailDialog';
import StoryForm from 'pages/stories/components/Form';
import toggleNotification from 'actions/notifications';
// eslint-disable-next-line
import parse from 'html-react-parser';
import i18n from 'i18n';
import { useNavigate } from 'react-router';

interface NewswireStoriesI {
  newswireStories: $Lns.NewswireStory[];
  selectedNewswireStory?: $Lns.NewswireStory;
  onHandleClose: () => void;
  onRowClick: (rowItem: { guid: { value: string } }) => void;
}

const NewswireStories: FunctionComponent<NewswireStoriesI> = ({
  newswireStories,
  selectedNewswireStory,
  onHandleClose,
  onRowClick
}: NewswireStoriesI) => {
  const rows = ['Title', 'Creator', 'Date published'];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [newStory, setNewStory] = useState<$Lns.Story>();

  const cells = newswireStories.map((newswire: $Lns.NewswireStory) => {
    return {
      fields: {
        title: { value: newswire.title },
        guid: { value: newswire.guid, hidden: true },
        creator: { value: newswire.createdBy },
        datePublished: {
          value: new Date(newswire.publishedDate).toLocaleDateString()
        }
      }
    };
  });

  const dialogContent = (newswire: $Lns.NewswireStory) => {
    return (
      <>
        <Box display="grid">
          <Typography variant="caption">
            {i18n.t('pages.administration.newswires.typography.createdBy')}{' '}
            {newswire.createdBy}
          </Typography>
          <Typography variant="caption">
            {i18n.t('pages.administration.newswires.typography.publishedAt')}{' '}
            {new Date(newswire.publishedDate).toLocaleDateString()}
          </Typography>
        </Box>
        <Typography variant="body1">{parse(newswire.description)}</Typography>
        <Link href={newswire.link} target="_blank">
          {newswire.link}
        </Link>
      </>
    );
  };

  const dialogActions = () => {
    return (
      <>
        <Button variant="outlined" color="secondary" onClick={onHandleClose}>
          Close
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={onHandleStoryCreate}
        >
          {i18n.t('pages.administration.newswires.button.createStory')}
        </Button>
      </>
    );
  };

  const onHandleStoryCreate = () => {
    onHandleClose();
    if (selectedNewswireStory) {
      setNewStory({
        title: selectedNewswireStory.title,
        text: selectedNewswireStory.description,
        slug: ''
      } as $Lns.Story);
    }
  };

  const onCreateCancel = () => {
    setNewStory(undefined);
  };

  const handleApiError = () => {
    dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
  };

  const onSave = async (formData: StoryFormData) => {
    const { data: createdStory } = await createStory(formData).catch(() => {
      handleApiError();
      return { data: null };
    });

    if (createdStory) {
      navigate(`/my-lns/${createdStory.guid}/edit`);
      dispatch(
        toggleNotification(
          i18n.t('notifications.newswires.createSuccess'),
          'success'
        )
      );
      setNewStory(undefined);
    }
  };

  return (
    <>
      <TableBuilder rows={rows} cells={cells} onRowClick={onRowClick} />
      {selectedNewswireStory && (
        <DetailDialog
          dialogTitle={selectedNewswireStory.title}
          dialogContent={dialogContent(selectedNewswireStory)}
          dialogActions={dialogActions()}
          onHandleClose={onHandleClose}
        />
      )}
      {newStory && (
        <Dialog onClose={onCreateCancel} open fullWidth maxWidth="sm">
          <StoryForm
            onSave={onSave}
            onCancel={onCreateCancel}
            defaultState={newStory}
          />
        </Dialog>
      )}
    </>
  );
};

export default NewswireStories;

NewswireStories.defaultProps = {
  selectedNewswireStory: undefined
};
