import {
  Box,
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import {
  Subject,
  Link as LinkIcon,
  Schedule,
  WatchLater,
  Person,
  AssignmentInd
} from '@material-ui/icons';
import { FunctionComponent } from 'react';
import i18n from 'i18n';
import { format } from 'date-fns';
import { WithPermissions } from 'components/Authorization';
import DetailDialog from 'components/DetailDialog';
import DeleteDialog from 'components/DeleteDialog';
import TooltipWrap from 'components/TooltipWrap';
import { findTaskStateGuidByName, useOpStates } from 'pages/assignments/utils';
import { TaskStates } from 'pages/assignments/consts';
import { FormData } from 'pages/assignments/formData';
import PriorityTag from 'pages/assignments/components/PriorityTag';

interface AssignmentDetailI {
  assignment: FormData;
  currentLnsUser: $Lns.User;
  isAdmin: boolean;
  taskStates: $Lns.TaskState[];
  onEdit: (assignment: $Lns.Task) => void;
  onSave: (formData: FormData) => void;
  onDelete: (assignmentGuid: string) => void;
  onClose: () => void;
}

const AssignmentDetail: FunctionComponent<AssignmentDetailI> = ({
  assignment,
  currentLnsUser,
  isAdmin,
  taskStates,
  onEdit,
  onSave,
  onDelete,
  onClose
}: AssignmentDetailI) => {
  const {
    title,
    description,
    priority,
    taskStateObj,
    storyObj,
    assignedByObj,
    assignedAt,
    assignedToObj,
    dateDue
  } = assignment;

  const noDataString = i18n.t('noData');

  const { canEdit, canDelete, canAccept, canReject } = useOpStates(
    assignment,
    currentLnsUser,
    isAdmin
  );

  const handleOnAccept = () => {
    const newFormData = {
      ...assignment,
      taskState: findTaskStateGuidByName(TaskStates.ASSIGNED, taskStates) || '',
      assignedTo: currentLnsUser.guid
    };

    onSave(newFormData);
    onClose();
  };

  const handleOnReject = () => {
    onSave({
      ...assignment,
      taskState: findTaskStateGuidByName(TaskStates.REJECTED, taskStates) || ''
    });
    onClose();
  };

  const dialogContent = () => {
    return (
      <Box>
        <List>
          <ListItem>
            <ListItemIcon>
              <Subject />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2'
              }}
              primary={description || noDataString}
              secondary={i18n.t('pages.assignments.formLabel.description')}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <Subject />
            </ListItemIcon>
            <ListItemText
              primary={storyObj?.title || noDataString}
              secondary={i18n.t('pages.assignments.formLabel.storyTitle')}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText
              primary={storyObj?.slug || noDataString}
              secondary={i18n.t('pages.assignments.formLabel.storySlug')}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText
              primary={assignedByObj?.username || noDataString}
              secondary={i18n.t('pages.assignments.formLabel.assignedBy')}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Schedule />
            </ListItemIcon>
            <ListItemText
              primary={format(new Date(assignedAt), 'Pp')}
              secondary={i18n.t('pages.assignments.formLabel.assignedAt')}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AssignmentInd />
            </ListItemIcon>
            <ListItemText
              primary={assignedToObj?.username || noDataString}
              secondary={i18n.t('pages.assignments.formLabel.assignedTo')}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <WatchLater />
            </ListItemIcon>
            <ListItemText
              primary={format(new Date(dateDue), 'Pp')}
              secondary={i18n.t('pages.assignments.formLabel.dateDue')}
            />
          </ListItem>
        </List>
      </Box>
    );
  };

  const dialogActions = () => {
    const handleOnEdit = () => {
      onEdit(assignment as $Lns.Task);
    };

    const handleOnDelete = () => {
      if (assignment?.guid) onDelete(assignment?.guid || '');
    };

    return (
      <Box width="100%" display="flex" paddingX={1} alignItems="center">
        <Box paddingX={1}>
          <WithPermissions
            lnsResource="task"
            lnsAction="edit"
            disable={!canDelete}
          >
            <DeleteDialog
              lnsResource="task"
              lnsAction="edit"
              onDelete={handleOnDelete}
              tooltip={i18n.t('pages.assignments.tooltip.delete')}
              target={title}
              targetDeleteTitle={i18n.t('deleteDialog.assignments.title')}
              targetDeletePrompt={i18n.t('deleteDialog.assignments.prompt')}
              targetDeleteWarning={i18n.t('deleteDialog.assignments.warning')}
              btnDisabled={false}
            />
          </WithPermissions>
        </Box>
        <Box display="flex" flex="1" justifyContent="flex-end">
          <WithPermissions
            lnsResource="task"
            lnsAction="edit"
            disable={!canEdit}
          >
            <Box paddingRight={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOnEdit}
              >
                {i18n.t('button.edit')}
              </Button>
            </Box>
          </WithPermissions>
          {canAccept && (
            <Box paddingRight={1}>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleOnAccept}
              >
                {i18n.t('pages.assignments.button.accept')}
              </Button>
            </Box>
          )}
          {canReject && (
            <Box paddingRight={1}>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleOnReject}
              >
                {i18n.t('pages.assignments.button.reject')}
              </Button>
            </Box>
          )}
          <Box>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              {i18n.t('button.close')}
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  const dialogTitle = () => {
    return (
      <Box>
        <Box
          width="100%"
          paddingTop={2}
          paddingRight={1}
          display="flex"
          alignItems="center"
          style={{ wordBreak: 'break-all' }}
        >
          <Typography component="h2">{title}</Typography>
        </Box>
        <Box
          width="100%"
          top={0}
          right={0}
          paddingTop={2}
          paddingRight={1}
          display="flex"
          alignItems="center"
        >
          <Box>
            <PriorityTag priority={priority} tooltip />
          </Box>
          {taskStateObj && (
            <Box paddingLeft={1}>
              <TooltipWrap
                title={i18n.t('pages.assignments.tooltip.taskState')}
              >
                <Chip label={taskStateObj.name} />
              </TooltipWrap>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <DetailDialog
      dialogTitle={dialogTitle()}
      dialogContent={dialogContent()}
      dialogActions={dialogActions()}
      onHandleClose={onClose}
    />
  );
};

export default AssignmentDetail;
