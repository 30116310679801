import { makeStyles } from '@material-ui/core';
import themeStyle from 'styles/theme';
import colors from 'styles/colors';

const useTimeLineStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(0.5, 1),
    marginBottom: theme.spacing(1)
  },
  timelineContent: {
    marginBottom: theme.spacing(2)
  },
  textArea: {
    width: '100%',
    minWidth: '100%',
    maxWidth: 'fit-content',
    border: theme.spacing(0),
    fontFamily: themeStyle.typography.fontFamily,
    fontSize: theme.spacing(2),
    background: colors.backgrounds.grey10
  },
  timelineAssetContent: {
    width: theme.spacing(22),
    height: theme.spacing(20),
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2),
    textAlign: 'center',
    padding: theme.spacing(0)
  },
  timelineAssetSection: {
    width: theme.spacing(22),
    height: theme.spacing(14)
  },
  displayContents: {
    display: 'contents'
  },
  paddingRm: {
    margin: 'unset !important',
    paddingTop: 'unset',
    paddingBottom: 'unset'
  }
}));
export default useTimeLineStyles;
