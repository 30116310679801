import { Box, Tooltip } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';

interface TooltipWrapI {
  title: string;
  children: ReactNode;
}

const TooltipWrap: FunctionComponent<TooltipWrapI> = ({
  title,
  children
}: TooltipWrapI) => {
  return (
    <Tooltip title={title}>
      <Box component="span" display="flex">
        {children}
      </Box>
    </Tooltip>
  );
};

export default TooltipWrap;
