import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import { Box, Dialog, Grid } from '@material-ui/core';
import EmptyState from 'components/EmptyState';
import { useDispatch, useSelector } from 'react-redux';
import { createStory, deleteStory, updateStory } from 'pages/stories/actions';
import { useNavigate } from 'react-router';
import { StoryFormData } from 'pages/stories/interface';
import { DropResult } from 'react-beautiful-dnd';
import Stories from 'pages/stories/components/List';
import toggleNotification from 'actions/notifications';
import StoryForm from 'pages/stories/components/Form';
import TitleWaper from 'components/TitleWraper';
import BtnWrapper from 'components/BtnWrapper';
import TitleBarWraper from 'components/TitleBarWraper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from 'components/TabPanel';
import i18n from 'i18n';
import { fetchUserStories, fetchUserTasks } from 'actions/user';
import { StarOutline } from '@material-ui/icons';
import { ProtectedButton, Resource, Action } from 'components/Authorization';
import { getInitialState } from 'pages/stories/utils';
import { StoryState } from 'pages/stories/enums';
import { socket } from 'components/RealTimeUpdates';
import ListAssignments from 'pages/assignments/components/AssignmentList';
import {
  createAssignment,
  updateAssignment,
  deleteAssignment
} from 'pages/assignments/actions';
import { useHasFullResourceAccess } from 'components/Authorization/utils';
import DialogDetails from 'pages/assignments/components/DialogDetails';
import DialogForm from 'pages/assignments/components/DialogForm';
import { LnsTaskToFormData, findTaskByGuid } from 'pages/assignments/utils';
import { FormData } from 'pages/assignments/formData';
import Loading from 'components/Loading';
import { handleFetchUser } from 'pages/user/utils';

const StoriesContainer: FunctionComponent = () => {
  const [stories, setStories] = useState<$Lns.Story[]>([]);
  const [tasks, setTasks] = useState<$Lns.Task[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editStory, setEditStory] = useState<$Lns.Story>();
  const [viewAssignment, setViewAssignment] = useState<FormData>();
  const [editAssignment, setEditAssignment] = useState<FormData>();
  const [currTab, setCurrTab] = useState(0);
  const isAdmin = useHasFullResourceAccess('TASK');

  const { currentSsoUser, currentLnsUser, taskStates, storyStates } =
    useSelector((state: $Lns.DefaultState) => state.default);

  const fetchSetUserTasks = useCallback(() => {
    fetchUserTasks(currentSsoUser.guid).then(({ data }) => {
      setTasks(
        [...data].sort(
          (a, b) =>
            new Date(b?.assignedAt).getTime() -
            new Date(a?.assignedAt).getTime()
        )
      );
    });
  }, [currentSsoUser.guid]);

  const setUserStories = useCallback(
    (data: $Lns.Story[]) => {
      setStories(
        [
          ...data.filter(storyItem => {
            const storyState = storyStates.find(state => {
              return state.guid === storyItem.storyState;
            });

            return storyState?.name !== StoryState.archived;
          })
        ].reverse()
      );
    },
    [storyStates]
  );

  useEffect(() => {
    fetchUserStories(currentSsoUser.guid).then(({ data }) => {
      setUserStories(data);
    });

    // Component Did Mount Portion
    socket.off('assignment-list-refresh', fetchSetUserTasks);
    socket.on('assignment-list-refresh', fetchSetUserTasks);
    fetchSetUserTasks();

    // Component Will UnMount Portion
    return () => {
      socket.off('assignment-list-refresh', fetchSetUserTasks);
    };
  }, [currentSsoUser, storyStates, fetchSetUserTasks, setUserStories]);

  const onSave = async (formData: StoryFormData) => {
    let updatedStories: $Lns.Story[] = [];

    const { data: createdStory } = await createStory(formData).catch(() => {
      dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
      return { data: null };
    });

    if (createdStory) {
      updatedStories = [createdStory, ...stories];

      setStories(updatedStories);
      navigate(`/my-lns/${createdStory.guid}/edit`);
      dispatch(
        toggleNotification(
          i18n.t('notifications.stories.createSuccess'),
          'success'
        )
      );
    }

    setEditStory(undefined);
  };

  const onDeleteClick = (story: $Lns.Story) => {
    deleteStory(story.guid)
      .then(() => {
        const filteredStories = stories.filter((currentStory: $Lns.Story) => {
          return currentStory.guid !== story.guid;
        });
        setStories(filteredStories);
        dispatch(
          toggleNotification(
            i18n.t('notifications.stories.removeSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.stories.removeError'),
            'error'
          )
        );
      });
  };

  const onEditClick = (story: $Lns.Story) => {
    navigate(`/my-lns/${story.guid}/edit`);
  };

  const onEditCancel = () => {
    setEditStory(undefined);
  };

  const onAdd = () => {
    setEditStory({
      title: '',
      text: '',
      notes: '',
      plannedDuration: 0,
      slug: '',
      type: '',
      state: 'draft'
    } as $Lns.Story);
  };

  const onCancel = () => {
    setEditStory(undefined);
  };

  const onDragComplete = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorder = (
      list: $Lns.Story[],
      startIndex: number,
      endIndex: number
    ) => {
      const ordered = Array.from(list);
      const [removed] = ordered.splice(startIndex, 1);
      ordered.splice(endIndex, 0, removed);

      return ordered;
    };

    const reorderdStories = reorder(
      stories,
      result.source.index,
      result.destination.index
    );

    setStories(reorderdStories);
  };

  const handleChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    setCurrTab(newValue);
  };

  const onArchive = (story: $Lns.Story) => {
    const formData = getInitialState(story);

    const archiveState = storyStates.find(state => {
      return state.name === StoryState.archived;
    });

    if (archiveState) {
      const input = { ...formData, storyState: archiveState.guid };
      handleStoryUpdate(input);
    }
  };

  const handleStoryUpdate = (input: StoryFormData) => {
    updateStory(input)
      .then(({ code }) => {
        if (code === 201) {
          dispatch(
            toggleNotification(
              i18n.t('notifications.stories.updateSuccess'),
              'success'
            )
          );
          fetchUserStories(currentSsoUser.guid).then(({ data }) => {
            setUserStories(data);
          });
        }
      })
      .catch(() =>
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'))
      );
  };

  const onViewAssignment = async (assignment: $Lns.Task) => {
    const assignByUser = await handleFetchUser(assignment.assignedBy);
    const input = { ...assignment, assignedBy: assignByUser } as $Lns.Task;
    setViewAssignment(LnsTaskToFormData(input));
  };

  const onCancelView = () => {
    setViewAssignment(undefined);
  };

  const onViewEditAssignment = (assignment: $Lns.Task) => {
    const assignmentObj = findTaskByGuid(assignment.guid, tasks);
    if (assignmentObj) onEditAssignment(assignmentObj);
  };

  const onEditAssignment = async (assignment: $Lns.Task) => {
    const assignByUser = await handleFetchUser(assignment.assignedBy);
    const input = { ...assignment, assignedBy: assignByUser } as $Lns.Task;
    setEditAssignment(LnsTaskToFormData(input));
  };

  const onSaveForm = (formData: FormData) => {
    if (formData.guid) {
      updateAssignment(formData).then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.assignments.updateSuccess'),
            'success'
          )
        );
      });
    } else {
      createAssignment(formData).then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.assignments.createSuccess'),
            'success'
          )
        );
      });
    }
    setEditAssignment(undefined);
  };

  const onCancelForm = () => {
    setEditAssignment(undefined);
  };

  const onDeleteAssignment = (assignmentGuid: string) => {
    deleteAssignment(assignmentGuid).then(() => {
      dispatch(
        toggleNotification(
          i18n.t('notifications.assignments.deleteSuccess'),
          'success'
        )
      );
    });
  };

  // Loading Screen
  if (isAdmin === undefined || tasks === undefined || taskStates === undefined)
    return <Loading />;
  // END Loading Screen

  return (
    <Box gridGap={15}>
      <Grid container direction="row" spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={i18n.t('links.sidebar.myLns')}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<StarOutline color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <ProtectedButton
                tooltip={i18n.t('pages.stories.button.add')}
                color="secondary"
                variant="contained"
                onClick={onAdd}
                lnsResource={Resource.STORY}
                lnsAction={Action.CREATE}
                data-test-id="addStoryBtn"
              >
                {i18n.t('pages.stories.button.add')}
              </ProtectedButton>
            </BtnWrapper>
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          {/* tabs */}
          <Box paddingBottom={2}>
            <Tabs
              value={currTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={i18n.t('pages.stories.tabLabel.myStories')} />
              <Tab label={i18n.t('pages.stories.tabLabel.myTasks')} />
            </Tabs>
          </Box>
          {/* tabs */}
          <TabPanel value={currTab} index={0}>
            {!stories.length && (
              <EmptyState
                title={i18n.t('pages.stories.emptyState.title')}
                subTitle={i18n.t('pages.stories.emptyState.subTitle')}
              />
            )}
            {stories.length > 0 && (
              <Stories
                stories={stories}
                onDeleteClick={onDeleteClick}
                onEditClick={onEditClick}
                onDragComplete={onDragComplete}
                onArchiveClick={onArchive}
              />
            )}
          </TabPanel>
          <TabPanel value={currTab} index={1}>
            {!tasks.length && (
              <EmptyState
                title={i18n.t('pages.stories.emptyTask.title')}
                subTitle={i18n.t('pages.stories.emptyTask.subTitle')}
              />
            )}
            {tasks.length > 0 && (
              <>
                <ListAssignments
                  isAdmin={isAdmin}
                  currentLnsUser={currentLnsUser}
                  assignments={tasks}
                  onViewAssignment={onViewAssignment}
                  onEditAssignment={onEditAssignment}
                  onDeleteAssignment={onDeleteAssignment}
                />
                {editAssignment && (
                  <DialogForm
                    isAdmin={isAdmin}
                    currentLnsUser={currentLnsUser}
                    assignment={editAssignment}
                    onSave={onSaveForm}
                    onCancel={onCancelForm}
                    taskStates={taskStates}
                  />
                )}
                {viewAssignment && (
                  <DialogDetails
                    assignment={viewAssignment}
                    currentLnsUser={currentLnsUser}
                    isAdmin={isAdmin}
                    taskStates={taskStates}
                    onEdit={onViewEditAssignment}
                    onSave={onSaveForm}
                    onDelete={onDeleteAssignment}
                    onClose={onCancelView}
                  />
                )}
              </>
            )}
          </TabPanel>
        </Grid>
      </Grid>
      {editStory && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <StoryForm onSave={onSave} onCancel={onCancel} />
        </Dialog>
      )}
    </Box>
  );
};

export default StoriesContainer;
