import { FunctionComponent, useEffect, useState } from 'react';
import StyledDialog from 'components/Dialog';
import UserList from 'components/UserList';
import { fetchUsers } from 'actions/user';

import i18n from 'i18n';

interface ListAddDialogI {
  addedUsers: $Lns.User[];
  onConfirmCb: (users: $Lns.User[]) => void;
}

const ListAddDialog: FunctionComponent<ListAddDialogI> = ({
  addedUsers,
  onConfirmCb
}: ListAddDialogI) => {
  const [allUsers, setAllUsers] = useState<$Lns.User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<$Lns.User[]>([]);

  const onChangeSelected = (selected: $Lns.User[]) => {
    setSelectedUsers(selected);
  };

  useEffect(() => {
    fetchUsers().then(({ data }) => {
      setAllUsers(data);
    });
  }, []);

  const handleConfirmCb = () => {
    onConfirmCb(selectedUsers);
  };

  return (
    <StyledDialog
      title={i18n.t('pages.administration.userGroups.dialogTitle.addUsers')}
      openBtnLabel={i18n.t('pages.administration.userGroups.dialogButton.add')}
      cancelLabel={i18n.t('button.cancel')}
      confirmLabel={i18n.t('button.confirm')}
      onConfirmCb={handleConfirmCb}
      confirmBtnDisabled={selectedUsers.length === 0}
    >
      <UserList
        userItems={allUsers.filter(user => {
          const addedUsersGuids = addedUsers.map(addedUser => addedUser.guid);
          return !addedUsersGuids.includes(user.guid);
        })}
        onChangeSelected={onChangeSelected}
        userItemXs={12}
        userItemSm={6}
        selectable
      />
    </StyledDialog>
  );
};

export default ListAddDialog;
