import i18n from 'i18n';
import validator from 'validator';

export const validateForm = (name: string, value: string): string => {
  let error = '';

  if (name === 'name') {
    error = validator.isEmpty(value) ? i18n.t('formErrors.isEmpty') : '';
  }

  if (name === 'sourceUrl') {
    error = !validator.isURL(value) ? i18n.t('formErrors.isUrl') : '';
  }

  return error;
};
