import { FunctionComponent } from 'react';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import {
  findTypeName,
  findProtocolName
} from 'pages/administration/peripheral-settings/utils';
import { ProtectedAdminActionField, Resource } from 'components/Authorization';

interface PeripheralListI {
  peripherals: $Lns.Peripheral[];
  peripheralTypes: $Lns.PeripheralType[];
  protocols: $Lns.Protocol[];
  onShow: (peripheral: $Lns.Peripheral) => void;
  onEdit: (peripheral: $Lns.Peripheral) => void;
  onDelete: (peripheral: $Lns.Peripheral) => void;
}

const PeripheralList: FunctionComponent<PeripheralListI> = ({
  peripherals,
  peripheralTypes,
  protocols,
  onShow,
  onEdit,
  onDelete
}: PeripheralListI) => {
  const rows = [
    i18n.t('pages.administration.peripherals.tableRow.name'),
    i18n.t('pages.administration.peripherals.tableRow.type'),
    i18n.t('pages.administration.peripherals.tableRow.protocol'),
    i18n.t('tableRow.actions')
  ];

  const cells = peripherals.map(peripheral => {
    return {
      fields: {
        name: { value: peripheral.name },
        type: { value: findTypeName(peripheral.type, peripheralTypes) },
        protocol: { value: findProtocolName(peripheral.protocol, protocols) },
        actions: {
          value: (
            <ProtectedAdminActionField
              lnsResource={Resource.PERIPHERAL}
              target={peripheral.name}
              onShow={() => onShow(peripheral)}
              onEdit={() => onEdit(peripheral)}
              onDelete={() => onDelete(peripheral)}
            />
          )
        }
      }
    };
  });

  return <TableBuilder rows={rows} cells={cells} />;
};

export default PeripheralList;
