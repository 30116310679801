import { Grid, Button, Box, TextField } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CustomTextField from 'components/CustomTextField';
import i18n from 'i18n';
import { ChangeEvent, FunctionComponent, useState } from 'react';

interface FormData extends $Lns.Task {
  errors?: $Lns.ObjectKey;
}

interface SingleFormFieldsI {
  initVals: FormData;
  onSave: (formData: FormData) => void;
  onCancel: () => void;
}

const SingleFormFields: FunctionComponent<SingleFormFieldsI> = ({
  initVals,
  onSave,
  onCancel
}: SingleFormFieldsI) => {
  const [formData, setFormDataObj] = useState<FormData>(initVals);

  const onChangeField = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFormDataObj({
      ...formData,
      [name || '']: value
    });
    if (name === 'title') {
      setBtnDisabled(false);
    }
  };

  const onChangeDate = (date: MaterialUiPickersDate) => {
    setFormDataObj({
      ...formData,
      dateDue: date?.toISOString() || ''
    });
  };

  const [BtnDisabled, setBtnDisabled] = useState(
    !formData.title ||
      formData.title.length === 0 ||
      formData.description.length === 0
  );

  return (
    <Box padding={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CustomTextField
            onChange={onChangeField}
            value={formData.title}
            label="Title"
            name="title"
            error={!!formData?.errors?.name}
            helperText={formData?.errors?.name || i18n.t('helperText.required')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            fullWidth
            onChange={onChangeField}
            value={formData.description}
            label="Description"
            name="description"
            variant="outlined"
            error={!!formData?.errors?.name}
            helperText={formData?.errors?.name || i18n.t('helperText.required')}
          />
        </Grid>
        <Grid item xs={12}>
          <DateTimePicker
            margin="normal"
            name="date_due"
            fullWidth
            ampm={false}
            emptyLabel={i18n.t('pages.rundowns.formLabel.playoutDateTime')}
            minDate={new Date()}
            size="small"
            id="date_due"
            placeholder={i18n.t('pages.rundowns.formLabel.playoutDateTime')}
            inputVariant="outlined"
            error={!!formData?.errors?.dateDue}
            helperText={formData?.errors?.dateDue}
            value={formData.dateDue}
            onChange={date => onChangeDate(date)}
          />
        </Grid>
        <Grid item container spacing={2} justify="flex-end">
          <Grid item>
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              {i18n.t('button.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={BtnDisabled}
              color="secondary"
              variant="contained"
              onClick={() => onSave(formData)}
            >
              {i18n.t('button.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleFormFields;
