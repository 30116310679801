import { User } from 'interface';
import { callLnsApi } from 'utils/fetch';

export const fetchUserByGuid = (
  userGuid: string | User
): Promise<{ code: number; data: $Lns.User }> =>
  callLnsApi(`/user/${userGuid}`);

export const updateUser = (
  user: $Lns.User
): Promise<{ code: number; data: $Lns.User }> =>
  callLnsApi(`/user/${user.guid}`, 'POST', user);

export const fetchUsers = (): Promise<{ code: number; data: $Lns.User[] }> => {
  return callLnsApi('/user');
};

export const fetchUserTasks = (
  guid: string
): Promise<{
  code: number;
  data: $Lns.Task[];
}> => callLnsApi(`/user/${guid}/task`);

export const fetchUserStories = (
  guid: string
): Promise<{
  code: number;
  data: $Lns.Story[];
}> => callLnsApi(`/user/${guid}/stories`);

export const fetchUserAccessGroups = (
  guid: string
): Promise<{
  code: number;
  data: $Lns.UserGroup[];
}> => callLnsApi(`/user/${guid}/access-groups`);
