import { fetchUserByGuid } from 'actions/user';
import { Dispatch } from 'react';
import {
  SET_CURRENT_SSO_USER,
  SET_CURRENT_LNS_USER,
  PURGE_SESSION
} from 'redux/reducers/types';
import { callSsoApi } from 'utils/fetch';

export const signIn = (
  username: string,
  password: string
): Promise<{
  code: number;
  message?: string;
  refreshToken: string;
  token: string;
  tokenExpiresOn: number;
}> => {
  const data = {
    auth_system: 'local',
    username,
    password
  };

  return callSsoApi('/login', 'POST', data);
};

export const fetchUser = (): Promise<{ code: number; error: string }> => {
  return callSsoApi('/token/user');
};

export const setCurrentLnsUser = (
  currentSsoUser: $Lns.SsoUser
): Dispatch<() => void> => {
  return (
    dispatch: Dispatch<{
      type: string;
      currentLnsUser: $Lns.User;
    }>
  ) => {
    fetchUserByGuid(currentSsoUser.guid).then(({ data }) => {
      dispatch({
        type: SET_CURRENT_LNS_USER,
        currentLnsUser: data
      });
    });
  };
};

export const setCurrentSsoUser = (
  currentSsoUser: $Lns.SsoUser
): Dispatch<() => void> => {
  return (
    dispatch: Dispatch<{
      type: string;
      currentSsoUser: $Lns.SsoUser;
    }>
  ) => {
    dispatch({
      type: SET_CURRENT_SSO_USER,
      currentSsoUser
    });
  };
};

export const purgeSession = (): Dispatch<() => void> => {
  return (
    dispatch: Dispatch<{
      type: string;
    }>
  ) => {
    dispatch({
      type: PURGE_SESSION
    });
  };
};
