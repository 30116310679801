import { callLnsApi } from 'utils/fetch';

export const fetchNewswireServices = (): Promise<{
  data: $Lns.NewswireService[];
}> => {
  return callLnsApi('/newswire');
};

export const fetchNewswireServicesByGuid = (
  newswireServiceGuid: string
): Promise<{ data: $Lns.NewswireService }> => {
  return callLnsApi(`/newswire/${newswireServiceGuid}`);
};

export const fetchNewswireServiceStories = (
  newswireServiceGuid: string
): Promise<{ data: $Lns.NewswireStory[] }> => {
  return callLnsApi(`/newswire/${newswireServiceGuid}/story`);
};

export const fetchNewswireFormats = (): Promise<{
  data: $Lns.NewswireFormat[];
}> => {
  return callLnsApi('/newswire-format');
};

export const fetchNewswireFormatByGuid = (
  newswireFormatGuid: string
): Promise<$Lns.NewswireStory> => {
  return callLnsApi(`/newswire-format/${newswireFormatGuid}`);
};

export const createNewswireService = (
  newswireService: $Lns.NewswireService
): Promise<{ code: number; data: $Lns.NewswireService[] }> => {
  return callLnsApi('/newswire', 'POST', newswireService);
};

export const updateNewswireService = (
  newswireService: $Lns.NewswireService
): Promise<{ code: number; data: $Lns.NewswireService[] }> => {
  return callLnsApi(
    `/newswire/${newswireService.guid}`,
    'POST',
    newswireService
  );
};
export const deleteNewswireService = (
  newswireServiceGuid: string
): Promise<{ code: number; message?: string }> =>
  callLnsApi(`/newswire/${newswireServiceGuid}`, 'DELETE');
