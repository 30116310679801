import { FunctionComponent } from 'react';
import i18n from 'i18n';
import { Box, Typography } from '@material-ui/core';
import EmptyState from 'components/EmptyState';
import useStyles from 'pages/user/components/styles';

interface EditUserGroupI {
  userGroups: $Lns.UserGroup[];
}

const EditUserGroup: FunctionComponent<EditUserGroupI> = ({
  userGroups
}: EditUserGroupI) => {
  const classes = useStyles();
  return (
    <>
      <Box paddingTop={2} padding={2}>
        <Typography variant="h6" className={classes.grayPaperTitle}>
          {i18n.t('pages.users.formLabel.userGroups')}
        </Typography>
      </Box>
      {userGroups && (
        <Box padding={2}>
          {userGroups.length > 0 &&
            userGroups.map(item => (
              <Typography key={item.guid} variant="body1">
                {item.name}
              </Typography>
            ))}
          {userGroups.length === 0 && (
            <EmptyState
              title={i18n.t('pages.users.userGroups.emptyState.title')}
              subTitle={i18n.t('pages.users.userGroups.emptyState.subTitle')}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default EditUserGroup;
