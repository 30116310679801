import { makeStyles } from '@material-ui/core';

export const contextMenuStyle = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: theme.spacing(20),
    height: '100%',
    borderColor: 'black',
    padding: 'UNSET',
    zIndex: 999,
    backgroundColor: theme.palette.background.paper
  },
  paddingNone: {
    padding: 'unset !important',
    zIndex: 999
  }
}));
