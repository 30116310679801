import { Dispatch } from 'react';
import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from 'redux/reducers/types';

const hideNotification = (): { type: string } => {
  return {
    type: HIDE_NOTIFICATION
  };
};

const showNotification = (notification: string, type: string) => {
  return {
    type: SHOW_NOTIFICATION,
    notification: {
      text: notification,
      type
    }
  };
};

const toggleNotification = (
  notification: string,
  type: string
): Dispatch<() => void> => {
  return (dispatch: Dispatch<unknown>) => {
    dispatch(showNotification(notification, type));

    window.setTimeout(() => {
      dispatch(hideNotification());
    }, 3000);
  };
};

export default toggleNotification;
