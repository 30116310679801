import TableBuilder from 'components/Table';
import i18n from 'i18n';
import { FunctionComponent } from 'react';
import {
  ProtectedAdminActionField,
  Resource,
  Action,
  useWithPermissions
} from 'components/Authorization';

interface StoryPoolListI {
  storyPools: $Lns.StoryPool[];
  onEdit: (storyPool: $Lns.StoryPool) => void;
  onRowClick: (rowItem: {
    guid: { value: string };
    name: { value: string };
  }) => void;
}

const StoryPoolList: FunctionComponent<StoryPoolListI> = ({
  storyPools,
  onEdit,
  onRowClick
}: StoryPoolListI) => {
  const onRowClickWithPermissions = useWithPermissions(
    onRowClick,
    Resource.STORY_POOL,
    Action.READ
  );

  const rows = [
    i18n.t('pages.storyPools.tableRow.name'),
    i18n.t('tableRow.actions')
  ];

  const cells = storyPools.map(storyPool => {
    return {
      fields: {
        guid: { value: storyPool.guid, hidden: true },
        name: { value: storyPool.name },
        actions: {
          value: (
            <ProtectedAdminActionField
              target={storyPool.name}
              onEdit={() => onEdit(storyPool)}
              lnsResource={Resource.STORY_POOL}
            />
          )
        }
      }
    };
  });

  return (
    <TableBuilder
      rows={rows}
      cells={cells}
      onRowClick={onRowClickWithPermissions}
    />
  );
};

export default StoryPoolList;
