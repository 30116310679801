export const storeTokens = (token: string, tokenExpiresOn: number): void => {
  localStorage.setItem('token', token);
  localStorage.setItem('tokenExpiresOn', tokenExpiresOn.toString());
};

export const removeTokens = (): void => {
  localStorage.removeItem('token');
  localStorage.removeItem('tokenExpiresOn');
};

export const tokenExpired = (tokenExpiresOn: string | null): boolean => {
  return (
    !!tokenExpiresOn && parseInt(tokenExpiresOn, 10) < new Date().valueOf()
  );
};
