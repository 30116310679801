import { ImageAsset } from 'pages/stories/interfaces/imageAsset';

const buildImageAssetObj = (
  assetObj: $Lns.CreateStoryAsset,
  key: number,
  type: string
): ImageAsset => {
  return {
    relativeStartTime: '00:00:00',
    title: assetObj.name,
    type,
    guid: assetObj.guid || '',
    url: assetObj.renderUrl,
    proxyUrl: assetObj.renderUrl,
    playoutServerUrl: assetObj.playoutServerUrl,
    thumbnail: assetObj?.thumbnail,
    durationInSeconds: assetObj.totalDurationSec.toString(),
    positionInScript: key,
    clipStartSec: assetObj?.clipStartSec || undefined,
    clipStopSec: assetObj?.clipStopSec || undefined
  };
};
export default buildImageAssetObj;
