import { FunctionComponent } from 'react';
import i18n from 'i18n';
import TableBuilder from 'components/Table';
import DefaultAdminActionField from 'pages/administration/components/DefaultAdminActionField';
import { findTypeName } from 'pages/administration/peripheral-settings/utils';

interface ListI {
  templateItems: $Lns.PeripheralTemplate[];
  typeItems: $Lns.PeripheralType[];
  onShow: (userItem: $Lns.PeripheralTemplate) => void;
  onEdit: (userItem: $Lns.PeripheralTemplate) => void;
  onDelete: (userItem: $Lns.PeripheralTemplate) => void;
}

const List: FunctionComponent<ListI> = ({
  templateItems,
  typeItems,
  onShow,
  onEdit,
  onDelete
}: ListI) => {
  const rows = [
    i18n.t('pages.administration.peripheralTemplates.tableRow.name'),
    i18n.t('pages.administration.peripheralTemplates.tableRow.type'),
    i18n.t('tableRow.actions')
  ];

  const cells = templateItems.map((item: $Lns.PeripheralTemplate) => {
    return {
      fields: {
        name: { value: item.name },
        type: { value: findTypeName(item.type, typeItems) },
        actions: {
          value: (
            <DefaultAdminActionField
              target={item.name}
              onShow={() => onShow(item)}
              onEdit={() => onEdit(item)}
              onDelete={() => onDelete(item)}
            />
          )
        }
      }
    };
  });

  return <TableBuilder rows={rows} cells={cells} />;
};

export default List;
