type State = {
  guid: string;
  name: string;
  users: $Lns.User[];
  lnsObjectPermissions: $Lns.LnsObjectPermission[];
  loaded: boolean;
};

type Action =
  | {
      type: 'fetchedUserGroup';
      payload: State;
    }
  | {
      type: 'reloadUserList';
      payload: $Lns.User[];
    };

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'fetchedUserGroup':
      return {
        ...state,
        ...action.payload
      };
    case 'reloadUserList':
      return {
        ...state,
        users: action.payload
      };
    default:
      return state;
  }
};
