import { makeStyles } from '@material-ui/core';

const assetFormStyle = makeStyles(theme => ({
  root: {
    maxWidth: theme.spacing(81.5),
    maxHeight: theme.spacing(75),
    width: '100%'
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: `0 ${theme.spacing(1)}px`
  },
  paddingAlign: {
    padding: `0 ${theme.spacing(1)}px`
  },
  width30: {
    width: '30%',
    padding: '15px'
  },
  minDimentions: {
    width: '100%',
    minHeight: '300px'
  },
  centerWidth100: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: `0 ${theme.spacing(1)}px`,
    width: '100%'
  }
}));

export default assetFormStyle;
