import { FunctionComponent } from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { AccountCircle, Email, Group, Info, Phone } from '@material-ui/icons';
import i18n from 'i18n';
import DetailDialog from 'components/DetailDialog';

interface ItemDetailI {
  userItem: $Lns.User;
  onClose: () => void;
}

const ItemDetail: FunctionComponent<ItemDetailI> = ({
  userItem,
  onClose
}: ItemDetailI) => {
  const DialogContent = () => (
    <Box>
      <List>
        <ListItem>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText
            primary={userItem.username}
            secondary={i18n.t('pages.administration.users.formLabel.username')}
          />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <Email />
          </ListItemIcon>
          <ListItemText
            primary={userItem.email}
            secondary={i18n.t('pages.administration.users.formLabel.email')}
          />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <Info />
          </ListItemIcon>
          <ListItemText
            primary={userItem.title}
            secondary={i18n.t('pages.administration.users.formLabel.title')}
          />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <Group />
          </ListItemIcon>
          <ListItemText
            primary={userItem.team}
            secondary={i18n.t('pages.administration.users.formLabel.team')}
          />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <Phone />
          </ListItemIcon>
          <ListItemText
            primary={userItem.contactNumber}
            secondary={i18n.t(
              'pages.administration.users.formLabel.contactNumber'
            )}
          />
        </ListItem>
      </List>
    </Box>
  );

  const dialogActions = () => {
    return (
      <Button variant="outlined" color="secondary" onClick={onClose}>
        {i18n.t('button.close')}
      </Button>
    );
  };

  return (
    <DetailDialog
      dialogTitle={userItem.username}
      dialogContent={<DialogContent />}
      dialogActions={dialogActions()}
      onHandleClose={onClose}
    />
  );
};

export default ItemDetail;
