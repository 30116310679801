import { FunctionComponent, useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Add, Visibility, Description } from '@material-ui/icons';
import { secondsToDuration } from 'utils/time';
import TableBuilder from 'components/Table';
import i18n from 'i18n';
import colors from 'styles/colors';
import StoryDetail from 'pages/rundowns/components/StoryDetail';

interface SparePlayoutStoriesI {
  playoutStories: $Lns.PlayoutStory[];
  peripherals: $Lns.Peripheral[];
  onSpareClick: (story: $Lns.PlayoutStory) => void;
}

const SparePlayoutStories: FunctionComponent<SparePlayoutStoriesI> = ({
  playoutStories,
  peripherals,
  onSpareClick
}: SparePlayoutStoriesI) => {
  const [selectedStory, setSelectedStory] = useState<$Lns.Story>();

  const onClose = () => {
    setSelectedStory(undefined);
  };

  const showStory = (item: { guid: { value: string } }) => {
    const playoutStory = playoutStories.find(pin => {
      return pin.story.guid === item.guid.value;
    });

    if (playoutStory) {
      const { story } = playoutStory;
      setSelectedStory(story);
    }
  };

  const rows = [
    i18n.t('pages.rundowns.tableRow.img'),
    i18n.t('pages.rundowns.tableRow.peripherals'),
    i18n.t('pages.rundowns.tableRow.slug'),
    i18n.t('pages.rundowns.tableRow.title'),
    i18n.t('pages.rundowns.tableRow.duration'),
    i18n.t('pages.rundowns.tableRow.plannedDuration'),
    i18n.t('tableRow.actions')
  ];

  const cells = playoutStories.map((playoutStory, index) => {
    const { story } = playoutStory;

    const getPeripheralIconColor = (peripheral: $Lns.Peripheral) => {
      return playoutStory[
        `is${peripheral.type}Ready` as keyof $Lns.PlayoutStory
      ]
        ? colors.success.dark
        : colors.error.dark;
    };

    return {
      fields: {
        guid: { value: story.guid, hidden: true },
        img: {
          value: (
            <Box
              display="grid"
              gridAutoFlow="column"
              justifyItems="center"
              alignItems="center"
              gridGap={10}
            >
              <Description color="primary" />
              <Typography variant="h6" color="primary">
                {index + 1}
              </Typography>
            </Box>
          )
        },
        peripherals: {
          value: (
            <Box
              display="grid"
              gridAutoFlow="column"
              gridGap={10}
              maxWidth="max-content"
              justifyItems="left"
              alignItems="left"
            >
              {peripherals.map(peripheral => {
                return (
                  <Box
                    fontSize={10}
                    width="max-content"
                    bgcolor={getPeripheralIconColor(peripheral)}
                    color={colors.neutral[0]}
                    padding={1}
                    borderRadius={10}
                  >
                    {peripheral.name.slice(0, 2).toUpperCase()}
                  </Box>
                );
              })}
            </Box>
          )
        },
        slug: { value: story.slug },
        title: { value: story.title },
        duration: { value: secondsToDuration(story.calculatedDuration) },
        plannedDuration: { value: secondsToDuration(story.plannedDuration) },
        action: {
          value: (
            <Box>
              <Tooltip
                title={i18n.t('pages.rundowns.tooltip.add').toString()}
                aria-label="add"
              >
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => onSpareClick(playoutStory)}
                >
                  <Add />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={i18n.t('pages.rundowns.tooltip.viewStory').toString()}
                aria-label="add"
              >
                <IconButton
                  onClick={() => setSelectedStory(story)}
                  color="primary"
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </Box>
          )
        }
      }
    };
  });

  return (
    <>
      <TableBuilder
        data-test-id="spare-stories"
        rows={rows}
        cells={cells}
        isDraggable={false}
        onRowClick={showStory}
      />
      {selectedStory && <StoryDetail story={selectedStory} onClose={onClose} />}
    </>
  );
};

export default SparePlayoutStories;
