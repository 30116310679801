import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from 'redux/reducer';

export const history = createBrowserHistory({});

/**
 * Logs all actions and states after they are dispatched.
 */
/* eslint-disable no-console */
const logger =
  (store: $Lns.Store) =>
  (next: (apiResult: $Lns.StoreAction) => $Lns.StoreAction) =>
  (action: $Lns.StoreAction) => {
    console.groupCollapsed(action.type);
    console.log('Previous state', store.getState());
    console.info('Dispatch', action);
    const result = next(action);
    console.log('Next state', store.getState());
    console.groupEnd();
    return result;
  };

const initialState = {};
const enhancers = [];
const middleware = [thunk, logger];

declare global {
  // eslint-disable-next-line
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: unknown;
  }
}

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export default createStore(rootReducer, initialState, composedEnhancers);
