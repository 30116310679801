import { Card, CardContent, Grid, List } from '@material-ui/core';
import { FunctionComponent, ReactNode } from 'react';
import { componentStyle } from 'styles/componentStyle';

interface TitleBarWraperI {
  children: ReactNode;
}

const TitleBarWraper: FunctionComponent<TitleBarWraperI> = ({
  children
}: TitleBarWraperI) => {
  const syles = componentStyle();
  return (
    <Grid item xs={12} data-test-id="title-bar-wrapper">
      <Card>
        <CardContent className={syles.paddinBottomNone}>
          <List aria-label="main mailbox folders">
            <Grid container direction="row">
              {children}
            </Grid>
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default TitleBarWraper;
