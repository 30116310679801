import { FunctionComponent, useState, useEffect } from 'react';
import { Box, Grid, Dialog } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { taskStyles } from 'pages/stories/styles';
import { fetchUsers } from 'actions/user';
import { createTask } from 'actions/task';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TaskForm from 'pages/stories/components/Task/Form';
import toggleNotification from 'actions/notifications';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import i18n from 'i18n';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

const UsersContainer: FunctionComponent = () => {
  const [users, setUsers] = useState<$Lns.User[]>([]);
  const [addTask, setAddTask] = useState<$Lns.Task>();
  const dispatch = useDispatch();
  const { guid } = useParams();

  const { currentSsoUser } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const classes = taskStyles();

  useEffect(() => {
    fetchUsers().then(({ data }) => setUsers([...data].reverse()));
  }, []);

  const onEditCancel = () => {
    setAddTask(undefined);
  };

  const onSave = (formData: $Lns.Task) => {
    const inputData = {
      ...formData,
      story: guid,
      assignedAt: new Date().toISOString()
    };
    createTask(inputData)
      .then(() => {
        dispatch(
          toggleNotification(
            i18n.t('notfications.stories.taskAssign.createSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(
          toggleNotification(
            i18n.t('notifications.stories.taskAssign.createError'),
            'error'
          )
        );
      });

    setAddTask(undefined);
  };

  const onAdd = (pin: $Lns.User) => {
    setAddTask({
      title: '',
      description: '',
      story: guid,
      assignedTo: pin.guid,
      assignedBy: currentSsoUser.guid,
      dateDue: new Date().toISOString(),
      assignedAt: ''
    } as $Lns.Task);
  };

  const onCancel = () => {
    setAddTask(undefined);
  };

  return (
    <Box gridGap={15}>
      <Grid container direction="row" spacing={2}>
        <List className={classes.root} aria-label="mailbox folders">
          {users.map(pin => {
            return (
              <Box display="grid">
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={pin.username} secondary="User" />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => onAdd(pin)}>
                      <Add />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Box>
            );
          })}
        </List>
      </Grid>
      {addTask && (
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={onEditCancel}
          aria-labelledby="simple-dialog-title"
          open
        >
          <TaskForm onSave={onSave} initVals={addTask} onCancel={onCancel} />
        </Dialog>
      )}
    </Box>
  );
};

export default UsersContainer;
