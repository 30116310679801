import { FunctionComponent, useEffect, useState, ChangeEvent } from 'react';
import { Grid, IconButton, Paper } from '@material-ui/core';
import { useNavigate } from 'react-router';
import StoryPoolList from 'pages/story-pools/components/List';
import { Search, ListAlt } from '@material-ui/icons';
import storiesStyles from 'pages/stories/styles';
import { fetchStoryPools } from 'actions/storyPools';
import toggleNotification from 'actions/notifications';
import { useDispatch } from 'react-redux';
import i18n from 'i18n';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import { Resource, Action, useWithPermissions } from 'components/Authorization';
import BasicTextField from 'components/BasicTextField';

const NewsPoolContainer: FunctionComponent = () => {
  const navigate = useNavigate();
  const [storyPools, setStoryPools] = useState<$Lns.StoryPool[]>([]);
  const [storyPoolsOrig, setStoryPoolsOrig] = useState<$Lns.StoryPool[]>([]);
  const classes = storiesStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSetStoryPools = () => {
      fetchStoryPools()
        .then(({ data }) => {
          setStoryPools(data);
          setStoryPoolsOrig(data);
        })
        .catch(() =>
          dispatch(
            toggleNotification('Failed to load publish folders', 'error')
          )
        );
    };

    fetchSetStoryPools();
  }, [dispatch]);

  const onEdit = (storyPool: $Lns.StoryPool) => {
    navigate(`/story-pools/${storyPool.guid}/edit`);
  };

  const onRowClick = useWithPermissions(
    (storyPool: { guid: { value: string }; name: { value: string } }) => {
      const {
        guid: { value: guidValue }
      } = storyPool;
      navigate(`/story-pools/${guidValue}`);
    },
    Resource.STORY_POOL,
    Action.READ
  );

  const onSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    const newStorypool = storyPoolsOrig.filter(story => {
      return story.guid.includes(search) || story.name.includes(search);
    });
    setStoryPools(newStorypool);
  };

  return (
    <>
      <Grid container spacing={2}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={i18n.t('links.sidebar.storyPools')}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<ListAlt color="primary" />}
            />
          </Grid>
        </TitleBarWraper>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <BasicTextField
              className={classes.input}
              placeholder={i18n.t('placeholders.search')}
              onChange={onSearchFieldChange}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <StoryPoolList
            storyPools={storyPools}
            onEdit={onEdit}
            onRowClick={onRowClick}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NewsPoolContainer;
