import peripheralsJson from 'stories/fixtures/peripherals.json';

export const fetchPeripheralsFromLS = (): Promise<{
  data: $Lns.Peripheral[];
}> => {
  const peripheralsString = localStorage.getItem('peripherals');

  if (!peripheralsString) {
    const peripherals = JSON.stringify(peripheralsJson);
    localStorage.setItem('peripherals', peripherals);
    return Promise.resolve(peripheralsJson);
  }

  return Promise.resolve(JSON.parse(peripheralsString));
};

export const updatePeripheralsInLS = (
  updatedPeripherals: $Lns.Peripheral[]
): Promise<{ data: $Lns.Peripheral[] }> => {
  const peripheralsJsonString = JSON.stringify({
    code: 200,
    data: updatedPeripherals
  });

  localStorage.setItem('peripherals', peripheralsJsonString);
  return Promise.resolve({ code: 200, data: updatedPeripherals });
};
