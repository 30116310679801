import { ScriptAsset } from 'pages/stories/interfaces/scriptAsset';
import { DialogueData } from 'pages/stories/interfaces/dialogueForm';

const buildDialogueObj = (
  scriptObj: DialogueData,
  user: $Lns.User,
  pos: number
): ScriptAsset => {
  let position = pos;
  if (scriptObj?.parentAsset !== undefined) {
    position = scriptObj.parentAsset;
  }
  return {
    relativeStartTime: '00:00:00',
    title: user.username,
    type: 'dialogue',
    camera: scriptObj.camera.toString(),
    presenter: {
      firstName: user.username,
      lastName: user.username,
      title: '',
      readRateWordsPerSecond: scriptObj.readRate
    },
    script: {
      text: scriptObj.dialogue,
      presenterInstruction: scriptObj.instructions
    },
    durationInSeconds: 20,
    positionInScript: position
  };
};

const updateDialogueObj = (
  scriptObj: DialogueData,
  pos: number
): ScriptAsset => {
  return {
    relativeStartTime: '00:00:00',
    title: scriptObj.presenter,
    type: 'dialogue',
    camera: scriptObj.camera.toString(),
    presenter: {
      firstName: scriptObj.presenter,
      lastName: scriptObj.presenter,
      title: '',
      readRateWordsPerSecond: scriptObj.readRate
    },
    script: {
      text: scriptObj.dialogue,
      presenterInstruction: scriptObj.instructions
    },
    durationInSeconds: 20,
    positionInScript: pos
  };
};

export { updateDialogueObj, buildDialogueObj };
