import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Button, Grid, Tab, Tabs } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  fetchPublishFolderStoriesByGuid,
  fetchPublishFolders,
  updatePublishFolderStories
} from 'actions/publishFolders';
import { TableCellI } from 'interfaces/tableCell';
import { TabContext, TabPanel } from '@material-ui/lab';
import Stories from 'pages/rundowns/components/Stories';
import Loading from 'components/Loading';
import StoryPoolTabs from 'pages/story-pools/components/Tabs';
import toggleNotification from 'actions/notifications';
import i18n from 'i18n';
import TitleWaper from 'components/TitleWraper';
import TitleBarWraper from 'components/TitleBarWraper';
import BtnWrapper from 'components/BtnWrapper';
import { fetchStoryPools } from 'actions/storyPools';
import TooltipWrap from 'components/TooltipWrap';
import {
  Folder,
  Description,
  Warning as WarningIcon
} from '@material-ui/icons';
import { generalStyle } from 'pages/rundowns/styles';
import { secondsToDuration } from 'utils/time';
import { StoryState } from 'pages/stories/enums';

const EditPublishFolder: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { guid } = useParams();
  const generalStyles = generalStyle();

  const [tableCells, setTableCells] = useState<TableCellI[]>([]);
  const [storyPools, setStoryPools] = useState<$Lns.StoryPool[]>([]);
  const [publishFolder, setPublishFolder] = useState<$Lns.PublishFolder>();

  const { storyStates } = useSelector(
    (state: $Lns.DefaultState) => state.default
  );

  const [publishFolderStories, setPublishFolderStories] = useState<
    $Lns.Story[]
  >([]);

  const fetchSetStates = (fetchGuid: string) => {
    fetchPublishFolderStoriesByGuid(fetchGuid).then(({ data }) => {
      setPublishFolderStories(data);
    });
    fetchStoryPools().then(({ data }) => {
      setStoryPools(data);
    });
  };

  useEffect(() => {
    const getIcon = (story: $Lns.Story) => {
      const warning = (
        <TooltipWrap title={i18n.t('notifications.publishFolders.notApprove')}>
          <WarningIcon color="error" />
        </TooltipWrap>
      );

      const normalICon = <Description color="primary" />;

      const approveState = storyStates.find(stateItem => {
        return stateItem.name === StoryState.approved;
      });

      if (approveState)
        if (story.storyState === approveState.guid) return normalICon;
      return warning;
    };

    const cells = publishFolderStories.map((story, index) => {
      return {
        selected: false,
        fields: {
          pos: { value: index },
          guid: { value: story.guid, hidden: true },
          img: {
            value: getIcon(story)
          },
          slug: { value: story.slug },
          title: { value: story.title },
          duration: { value: secondsToDuration(story.calculatedDuration) },
          plannedDuration: { value: secondsToDuration(story.plannedDuration) }
        }
      };
    });
    setTableCells(cells);
  }, [publishFolderStories, storyStates]);

  useEffect(() => {
    fetchSetStates(guid);
    fetchPublishFolders().then(({ data }) => {
      const publishFolderObj = data.find(pin => {
        return pin.guid === guid;
      });
      setPublishFolder(publishFolderObj);
    });
  }, [guid]);

  const onUpdate = () => {
    updatePublishFolderStories(guid, publishFolderStories)
      .then(() => {
        fetchSetStates(guid);
        dispatch(
          toggleNotification(
            i18n.t('notifications.publishFolders.updateSuccess'),
            'success'
          )
        );
      })
      .catch(() => {
        dispatch(toggleNotification(i18n.t('notifications.apiError'), 'error'));
        fetchSetStates(guid);
      });
  };

  const onToggleStory = (story: $Lns.Story) => {
    const exists = publishFolderStories.find(
      currentStory => currentStory.guid === story.guid
    );

    if (exists) {
      setPublishFolderStories(
        publishFolderStories.filter(
          currentStory => currentStory.guid !== story.guid
        )
      );
    } else {
      setPublishFolderStories([...publishFolderStories, story]);
    }
  };

  const onCancel = () => {
    navigate('/publish-folders');
  };

  if (!storyPools || !publishFolderStories) return <Loading />;

  return (
    <Box>
      <Grid container direction="row" spacing={4}>
        <TitleBarWraper>
          <Grid item xs={6}>
            <TitleWaper
              title={publishFolder?.name || ''}
              subText={i18n.t('pages.stories.formLabel.title')}
              icon={<Folder color="primary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <BtnWrapper>
              <Button color="default" variant="contained" onClick={onCancel}>
                {i18n.t('button.cancel')}
              </Button>
              <Button variant="outlined" color="secondary" onClick={onUpdate}>
                {i18n.t('button.update')}
              </Button>
            </BtnWrapper>
          </Grid>
        </TitleBarWraper>
        <Grid item xs={6}>
          <TabContext value="stories">
            <Tabs value="stories" scrollButtons="on" variant="scrollable">
              <Tab
                label={i18n.t('pages.storyPools.helperText.selectedStories')}
                value="stories"
              />
            </Tabs>
            <TabPanel value="stories" className={generalStyles.paddingTopOnly}>
              <Stories
                tableCells={tableCells}
                stories={publishFolderStories}
                onToggleStory={onToggleStory}
              />
            </TabPanel>
          </TabContext>
        </Grid>

        <Grid item xs={6}>
          <StoryPoolTabs
            storyPools={storyPools}
            highlightedStories={publishFolderStories}
            onToggleStory={onToggleStory}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditPublishFolder;
