import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';

interface ConfigRowI {
  configName: string;
  configValue: string | boolean | number | undefined;
  configType: string;
  configDescription: string;
  onChangeCallback: (
    rowDataName: string,
    rowDataValue: string | boolean | number | undefined
  ) => void;
}

const ConfigRow: FunctionComponent<ConfigRowI> = ({
  configName,
  configValue: configValueProp,
  configType,
  configDescription,
  onChangeCallback
}: ConfigRowI) => {
  let configValueDefault: string | number | boolean = false;
  // undefined doesn't work for selector, hence such setup
  if (configValueProp !== undefined) configValueDefault = configValueProp;
  else if (configType === 'string') configValueDefault = '';
  else if (configType === 'number') configValueDefault = 0;

  const [configValue, setConfigValue] = useState<string | boolean | number>(
    configValueDefault
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setConfigValue(value);
  };

  const onSelect = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const { value } = e.target;
    setConfigValue(value as boolean);
  };

  useEffect(() => {
    onChangeCallback(configName, configValue);
  }, [configName, configValue, onChangeCallback]);

  if (configType === 'boolean')
    return (
      <Grid item xs={12}>
        <FormControl variant="outlined" size="small" fullWidth required>
          <InputLabel>{configName}</InputLabel>
          <Select
            id="selectBoolean"
            labelId="selectProtocol"
            name="protocol"
            placeholder="protocol"
            value={configValue}
            onChange={onSelect}
            variant="outlined"
            displayEmpty
            required
            label={configName}
          >
            <MenuItem key={0} value="true">
              TRUE
            </MenuItem>
            <MenuItem key={1} value="false">
              FALSE
            </MenuItem>
          </Select>
          <FormHelperText>{configDescription}</FormHelperText>
        </FormControl>
      </Grid>
    );
  return (
    <Grid item xs={12}>
      <TextField
        id={configName}
        name={configName}
        type={configType}
        value={configValue}
        onChange={onChange}
        label={configName}
        helperText={configDescription}
        variant="outlined"
        size="small"
        margin="normal"
        fullWidth
        required
      />
    </Grid>
  );
};

export default ConfigRow;
