import { createStyles, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import colors from 'styles/colors';

export const StyledTableRow = withStyles(() =>
  createStyles({
    root: {
      textAlign: 'left',
      cursor: 'pointer',
      backgroundColor: colors.backgrounds.grey,
      color: colors.neutral[40],
      '&:hover': {
        backgroundColor: colors.backgrounds.grey20
      }
    }
  })
)(TableRow);
